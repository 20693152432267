import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";
import { PrivateRoutes, PublicRoutes } from "./routes.js";
import { DashboardLayout } from "../layout/dashboard/index.js";
// import Layout from "../containers/Layout";
// import { AuthLayout } from "../containers";
 import { RoleRoute } from "./roleroute.js";

// export const history = createBrowserHistory();

const RouterConfig = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isRole = useSelector((state) => state?.auth.isRole);
  const company = useSelector((state) => state?.auth.company);
  return (
      <Routes>
        {PublicRoutes.map((R) => {
          return <Route path={R.path} element={
            isAuthenticated &&  isRole.some((role)=> ["superadmin", "admin"].includes(role)) ?
              <Navigate replace to="/dashboardsuperadmin" />
            :
            isAuthenticated &&  isRole.some((role)=> ["bo", "tm", "ta"].includes(role)) ?
            <Navigate replace to={`/${company}/dashboard`} />
            :
              <R.element/>
          } />
        })}
        <Route element={<DashboardLayout />}>
          {PrivateRoutes.map((R) => {
           if(isAuthenticated){
            return <Route key={R.path} path={R.path} element={
              <RoleRoute roles={R.roles}>
                <R.element />
              </RoleRoute>
            }/>
           }
           return <Route key={R.path} path={R.path} element={<Navigate to={`/${company}`} replace/>} />
          
          })

          }
        </Route>
          <Route path="*" element={<Navigate to="/" replace/>} />

      </Routes>
  );
};

export const AppRouter = RouterConfig;

