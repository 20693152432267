import { Helmet } from "react-helmet-async";
 import { InvoicePDF } from "../../sections/invoice/index.js";

export default function InvoiceDetails(){
    return (
        <>
            <Helmet>
                <title>Invoice List Page</title>
            </Helmet>
            <InvoicePDF />
        </>
    )
}