import { Helmet } from "react-helmet-async";
 import { InvoiceListView } from "../../sections/invoice/index.js";

export default function CompanyPage(){
    return (
        <>
            <Helmet>
                <title>Invoice List Page</title>
            </Helmet>
            <InvoiceListView />
        </>
    )
}