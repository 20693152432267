const Status = [
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'}
  ]
  const role = [
    {value: 'bo', label: 'Business Owner'},
    {value: 'tm', label: 'Training Manager'},
    {value: 'ta', label: 'Trainer Assessor'},
    {value: 'te', label: 'Trainee'}
  ]
  
  const _IDType = [
    {value: 'NRIC', label: 'NRIC'},
    {value: 'FIN', label: 'FIN'},
    {value: 'Others', label: 'Others'}
  ]

  const role_bo = [
    {value: 'bo', label: 'Business Owner'},
    {value: 'tm', label: 'Training Manager'},
    {value: 'ta', label: 'Trainer Assessor'},
    {value: 'te', label: 'Trainee'}
  ]

  const role_tm = [
    {value: 'ta', label: 'Trainer Assessor'},
    {value: 'te', label: 'Trainee'}
  ]

  const _nricType = [
    {value: 'Singapore Pink IC', label: 'Singapore Citizen'},
    {value: 'Singapore Blue IC', label: 'Singapore Permanent Resident'},
    {value: 'FIN/Work Permit', label: 'FIN/Work Permit'},
    {value: 'Others', label: 'Other'}
  ]

  export {
    Status,
    role,
    _IDType,
    role_bo,
    role_tm,
    _nricType

  }