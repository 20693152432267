import { Helmet } from "react-helmet-async";
 import { CompanyCreateUpdateUser } from "../../sections/company/users/index.js";

export default function CompanyCreateUserPage(){
    return (
        <>
            <Helmet>
                <title>Company Create User</title>
            </Helmet>
            <CompanyCreateUpdateUser />
        </>
    )
}