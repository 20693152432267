
import { useState, useEffect} from 'react';
import {result, sumBy} from 'lodash';
import { Stack , Container, alpha, useTheme, Typography, Card, Box} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { useSelector } from 'react-redux';
import { useSettingsContext } from '../../components/settings/index.js';
import { bgGradient } from '../../theme/css.js';
import Iconify from '../../components/iconify/iconify.js';
import { GET_ALL_DASHBOARD } from "../../graphql/modules/dashboard.js";

import { useQuery } from "@apollo/react-hooks";
import { fCurrency } from '../../util/format-number.js';


export function DashboardAdmin({img, ...other}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const [results, setResults] = useState(null);
  const theme = useTheme();
  const settings = useSettingsContext();

  const { data, loading, error, refetch } = useQuery(GET_ALL_DASHBOARD, {
    variables: {
      companyName: company,
      },
      fetchPolicy: "cache-and-network",
    });

  

    useEffect(() => {
      if(data){
        setResults(data?.getAllDashboard);
      }
  }, [data])


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3} >
        <Grid xs="12" md={8}>
          <Stack flexDirection={{xs: 'column', md: 'row'}}
          sx={{
            ...bgGradient({
              direction: '135deg',
              startColor: alpha(theme.palette.primary.light, 0.2),
              endColor: alpha(theme.palette.primary.main, 0.2)
            }),
            height: {md: 1},
            borderRadius: 2,
            position: 'relative',
            color: 'primary.darker',
            backgroundColor: 'common.white'
          }}
          {...other}
          >
            
            <Stack
            flexGrow={1}
            justifyContent="center"
            alignItems={{xs: 'center', md: 'flex-start'}}
            sx={{
              p: {
                xs: theme.spacing(5, 3, 0,3),
                md: theme.spacing(5)
              },
              textAlign: {xs: 'center', md: 'left'}
            }}
            >
              <Typography variant='h5' sx={{mb: 2, whiteSpace: 'pre-line'}}>Hi Welcome back {fullName} 👋</Typography>
              <Typography
              variant="body2"
              sx={{
                opacity: 0.8,
                maxWidth: 360,
                mb: {xs: 3, xl: 5}
              }}
              >
                this is the description or annoucement here

              </Typography>

            </Stack>
          </Stack>

          {img && (
            <Stack
            component="span"
            justifyContent="center"
            sx={{
              p: {xs: 5, md: 3},
              maxWidth: 360,
              mx: 'auto'
            }}>
              {img}
            </Stack>
          )}

        </Grid>

        <Grid xs="12" md={4}>
          <Stack flexDirection={{xs: 'column', md: 'row'}}
          sx={{
            ...bgGradient({
              direction: '135deg',
              startColor: alpha(theme.palette.primary.light, 0.2),
              endColor: alpha(theme.palette.primary.main, 0.2)
            }),
            height: {md: 1},
            borderRadius: 2,
            position: 'relative',
            color: 'primary.darker',
            backgroundColor: 'common.white'
          }}
          {...other}
          >
            
            <Stack
            flexGrow={1}
            justifyContent="center"
            alignItems={{xs: 'center', md: 'flex-start'}}
            sx={{
              p: {
                xs: theme.spacing(5, 3, 0,3),
                md: theme.spacing(5)
              },
              textAlign: {xs: 'center', md: 'left'}
            }}
            >
              <Typography variant='h3' sx={{mb: 2, whiteSpace: 'pre-line'}}>Hello</Typography>
              <Typography
              variant="body2"
              sx={{
                opacity: 0.8,
                maxWidth: 360,
                mb: {xs: 3, xl: 5}
              }}
              >
                this is the description or annoucement here

              </Typography>

            </Stack>
          </Stack>

          {img && (
            <Stack
            component="span"
            justifyContent="center"
            sx={{
              p: {xs: 5, md: 3},
              maxWidth: 360,
              mx: 'auto'
            }}>
              {img}
            </Stack>
          )}

        </Grid>

        <Grid xs="12" md={4}>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Super Admin Users</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{results?.countUsers}</Typography>
            </Box>
        <Iconify icon={'codicon:organization'}  width={42} sx={{ color: "success" }} />
      </Card>

        </Grid>

        <Grid xs="12" md={4}>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Client</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{results?.countCompany}</Typography>
            </Box>
        <Iconify icon={'simple-line-icons:organization'}  width={42} sx={{ color: "success" }} />
      </Card>
      </Grid>

        <Grid xs="12" md={4}>
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Invoice</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{fCurrency(sumBy(results?.invoiceresult, 'totalAmount'))}</Typography>
            </Box>
        <Iconify icon={'arcticons:zoho-invoice'}  width={42} sx={{ color: "success" }} />
      </Card>
        </Grid>
      </Grid>
    </Container>
  );
}