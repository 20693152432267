import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha ,Box, Stack, Typography } from '@mui/material';
import FileThumbnail, { fileData } from '../file-thumbnail/index.js';

//
import Iconify from '../iconify/index.js';

// ----------------------------------------------------------------------

export default function UploadBox({file, placeholder, error, disabled, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    ...other,
  });

  const hasError = isDragReject || error;

  return (
    <Stack
        spacing={2}
        direction="row"
        alignItems="center"
    >
    <Box
      {...getRootProps()}
      sx={{
        m: 0.5,
        width: 64,
        height: 64,
        flexShrink: 0,
        display: 'flex',
        borderRadius: 1,
        cursor: 'pointer',
        alignItems: 'center',
        color: 'text.disabled',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...(hasError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        '&:hover': {
          opacity: 0.72,
        },
        ...sx,
      }}
    >
      <input {...getInputProps()} />
      {placeholder || <Iconify icon="eva:cloud-upload-fill" width={64} />}
    </Box>

    <Typography variant='subtitle2'>{file ? file.name : "Please use the template above."}</Typography>

    </Stack>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  placeholder: PropTypes.object,
  sx: PropTypes.object,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
