import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import { fCurrency, fNumber } from '../../../util/format-number.js';
import {useTheme, Box, MenuItem, CardHeader, ButtonBase, Card } from '@mui/material';
// components
import Iconify from 'src/components/iconify/index.js';
import Chart, { useChart } from 'src/components/chart/index.js';
import CustomPopover, { usePopover } from 'src/components/custom-popover/index.js';

// ----------------------------------------------------------------------

export default function AppAreaInstalled({ title, subheader, chart, ...other }) {
  const theme = useTheme();

  const {
    colors = [
      [theme.palette.info.light, theme.palette.info.main],
      [theme.palette.success.light, theme.palette.success.main],
      [theme.palette.warning.light, theme.palette.warning.main],
      [theme.palette.error.light, theme.palette.error.main],
      [theme.palette.grey[50], theme.palette.grey[500]]
    ],
    categories,
    series,
    options,
  } = chart;

  const popover = usePopover();

  const [seriesData, setSeriesData] = useState('2024');

  const chartOptions = useChart({
    colors: colors.map((colr) => colr[1]),
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: colors.map((colr) => [
          { offset: 0, color: colr[0] },
          { offset: 100, color: colr[1] },
        ]),
      },
    },
    xaxis: {
      categories,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fCurrency(value)
      }
    },
    
    ...options,
    plotOptions: {
      bar: {
        distributed: true
      }
    },
  });

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose();
      setSeriesData(newValue);
    },
    [popover]
  );

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
        />

        {series.map((item) => (
          <Box key={item.year} sx={{ mt: 3, mx: 3 }}>
            {item.year === seriesData && (
              <Chart dir="ltr" type="bar" series={item.data} options={chartOptions} height={364} />
            )}
          </Box>
        ))}
      </Card>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {series.map((option) => (
          <MenuItem
            key={option.year}
            selected={option.year === seriesData}
            onClick={() => handleChangeSeries(option.year)}
          >
            {option.year}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}

AppAreaInstalled.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
};
