import { Helmet } from "react-helmet-async";
 import { InvoiceCreateUpdate } from "../../sections/company/finance/invoice/index.js";

export default function CompanyCreateInvoice(){
    return (
        <>
            <Helmet>
                <title>Create Invoice</title>
            </Helmet>
            <InvoiceCreateUpdate />
        </>
    )
}