import { alpha } from '@mui/material/styles/index.js';
import { buttonClasses } from '@mui/material/Button/index.js';

// ----------------------------------------------------------------------
const COLORS = ['PRIMARY', 'secondary', 'info', 'success', 'warning', 'error']
export function button(theme) {
    const lightMode = theme.palette.mode === 'light';
    const rootStyles = (ownerState) => {
        const inheritColor = ownerState.color === 'inherit';
        const containedVariant = ownerState.variant === 'contained';
        const outlinedVariant = ownerState.variant === 'outlined';
        const textVariant = ownerState.variant === 'text';
        const softVariant = ownerState.variant === 'soft';
        const smallSize = ownerState.size === 'small';
        const mediumSize = ownerState.size === 'medium';
        const largeSize = ownerState.size === 'large';

        const defaultStyle = {
            ...(inheritColor && {
                ...(containedVariant && {
                    color: lightMode ? theme.palette.common.white : theme.palette.grey[800],
                    backgroundColor: lightMode ? theme.palette.grey[800] : theme.palette.common.white,
                    '&:hover': {
                        backgroundColor: lightMode ? theme.palette.grey[700] : theme.palette.grey[400]
                    }
                })
            })
        }
       return   [defaultStyle]
    }
  return {
    MuiButton:{
        styleOverrides: {
            root: ({ownerState}) => rootStyles(ownerState),
        }
    }
  };
}