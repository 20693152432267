import gql from "graphql-tag";


export const GET_ALL_INVOICE = gql`
query GetAllInvoice($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getAllInvoice(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
        paidCount
        pendingCount
        overdueCount
        draftCount
  }
}`;

export const GET_TP_INVOICE_NT = gql`
query getTpInvoice($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
  getTpInvoice(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;



export const CREATE_INVOICE = gql`
mutation CreateInvoice($data: InvoiceInput!, $fullName: String!, $companyName: String!) {
    createInvoice(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_INVOICE = gql`
mutation DeleteInvoice($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteInvoice(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;


export const UPDATE_INVOICE = gql`
mutation UpdateCompany($_id: ID!, $data: InvoiceInput!, $fullName: String!, $companyName: String!) {
  updateInvoice( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;