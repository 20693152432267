import {  useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import {Box, ButtonBase, ListItemButton, Stack, Divider, MenuItem, ListItemText, alpha, Badge, Button, Typography, IconButton, Collapse} from '@mui/material';
import { useBoolean } from '../../../hooks/use-boolean.js';
// utils
import { fCurrency } from '../../../util/format-number.js';
import {_AssessmentMethod, _ampm} from '../../../util/course.js';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
// components
import Iconify from '../../../components/iconify/index.js';
import { RHFSelect, RHFTextField, RHFRadioGroup } from '../../../components/hook-form/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import TechnicalSkillsForm from './company-course-assessment-tsc.js';
// ----------------------------------------------------------------------


const SUBSCRIPTION_DATA = [
    {id: 'basic', name: 'Basic', price: 100},
    {id: 'standard', name: 'Standard', price: 150},
    {id: 'premium', name: 'Premium', price: 200},
    {id: 'customized', name: 'Customized', price: 250}
]


// ----------------------------------------------------------------------

export default function AsessmentRecordForm(trainer) {
  const { control, setValue, watch, resetField, reset, register } = useFormContext();
  const [itemIndex, setItemIndex] = useState(null)
  const [itemContent, setItemContent] = useState(null)
  const collapse = useBoolean();
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'assessmentRecordForm'
  });

  const handleAdd = () => {
    append({
      assessmentMethod: values?.assessmentMethod
    });
  };


  const values = watch();

  const onClickMethod = (res, index) => {
    setItemIndex(index)
    setItemContent(res)
    collapse.onToggle();

  };




  const handleRemove = (index) => {
    setValue('technicalSkills', '')
    values?.assessmentRecordForm.splice(index, 1);
    // unregister(`assessmentRecordForm[${itemIndex}]?.technicalSkills`)
   // remove(index)

  };

  const renderItem = (
    <>
    {fields?.map((res, index) => (
        <>
          <ListItemButton
          key={res.id}
          color={collapse.value ? 'inherit' : 'default'}
          onClick={() => {
            onClickMethod(res, index);
          }}
          sx={{
            p: 1,
            mb: 0.5,
            borderRadius: 1,
          }}
        >
                <ListItemText
                primary={res?.assessmentMethod}
                primaryTypographyProps={{
                  noWrap: true,
                  variant: 'subtitle1',
                  color: 'primary'
                }}
              />


                      {/* <IconButton  onClick={() => collapse.onToggle} color='info'> 
                        <Iconify icon={collapse.onFalse ? "raphael:arrowdown" : "raphael:arrowup"} />
                        </IconButton> */}

                      <IconButton  onClick={() => remove(index)}
                       color='error'> 
                        <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      
        
          </ListItemButton>

    <TechnicalSkillsForm nestIndex={index} collapse={collapse} {...{ control, register, values }} />
    </>
    ))}



    </>
  )

  const renderList = (
    <Stack
    direction="row"
    justifyContent="space-between"
 
    sx={{
      width: '100%',
      flexShrink: 0,
      borderRadius: 1.5,
      bgcolor: 'background.default'
    }}
  >
     <Stack sx={{ p: 2, flexGrow: 1}}>
     <RHFSelect
        name="assessmentMethod"
        label="Assessment Method"
        InputLabelProps={{ shrink: true }}
        
        sx={{
          maxWidth: { sx: '100%' },
        }}
      >
          {_AssessmentMethod.map((result) => {
              return (
                <MenuItem key={result.id} value={result.id}>
                  {result.label}
                </MenuItem>
              )
            })}
    </RHFSelect>

    <Scrollbar sx={{ p: 2 }}>
            {renderItem}
    </Scrollbar>



    <Stack sx={{ p: 2, flexGrow: 1}}>
        <Button
        disableRipple
        color="inherit"
        onClick={handleAdd}
        endIcon={
          <Badge color="error" variant="dot">
            <Iconify icon="flat-color-icons:plus" />
          </Badge>
        }
      >
        Add
      </Button>
    </Stack>
    
  
     </Stack>
  </Stack>
  );


  return (
    <Stack
      spacing={1}
      direction="row"
      flexGrow={1}
      sx={{
        height: {
          xs: '62vh',
        },
        p: 2
      }}
    >
        {renderList}

      </Stack>
  );
}
