import { Helmet } from "react-helmet-async";
 import { AuditLogListView } from "../../sections/auditlog/index.js";

export default function AuditLogListPage(){
    return (
        <>
            <Helmet>
                <title>Auditlog List Page</title>
            </Helmet>
            <AuditLogListView />
        </>
    )
}