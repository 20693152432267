import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
// @mui
import {LoadingButton} from '@mui/lab';
import {Box, Stack, Button, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {
  RHFCheckbox,
  RHFTextField,
  RHFRadioGroup,
  RHFAutocomplete,
} from '../../../components/hook-form/index.js';

// ----------------------------------------------------------------------

import { CREATE_VENUE } from "../../../graphql/modules/_client/venue.js";
import { useMutation } from "@apollo/react-hooks";

export default function AddressNewForm({ open, onClose, company, fullName }) {
  const company1 = useSelector((state) => state?.auth?.company);
  const { enqueueSnackbar } = useSnackbar();
  const [CreateVenueMutation] =  useMutation(CREATE_VENUE);

  const NewAddressSchema = Yup.object().shape({
    room: Yup.string().required('Room is required'),
    unit: Yup.string().required('Unit is required'),
    block: Yup.string().required('Block Number is required'),
    floor: Yup.string().required('Floor is required'),
    street: Yup.string().required('Street is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    wheelChairAccess: Yup.boolean(),
    building: Yup.string()

  });

  const defaultValues = {
    room: '',
    unit: '',
    floor: '',
    address: '',
    street: '',
    wheelChairAccess: false,
    block: '',
    building: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const {data: { createVenue }} = await CreateVenueMutation({
        variables: {
          companyName: company || company1,
          fullName: fullName,
            data: {
              venue: {
                room: data?.room,
                unit: data?.unit,
                floor: data?.floor,
                address: data?.address,
                street: data?.street,
                wheelChairAccess: data?.wheelChairAccess,
                block: data?.block,
                building: data?.building,
                postalCode: data?.postalCode
              },
              createdBy: fullName,
              dateCreated: new Date(),
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });
      if(createVenue?.success){
        reset();
        enqueueSnackbar("Created successfully", {variant: "success"});
        onClose();
      }else {
        enqueueSnackbar("Error", {variant: "error"});
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>New address</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >

              <RHFTextField name="block" label="Block Number" />
              <RHFTextField name="floor" label="Floor" />
              <RHFTextField name="unit" label="Unit Number" />
            </Box>

            <RHFTextField name="street" label="Street" />

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >
       

              <RHFTextField name="building" label="Building" />
              <RHFTextField name="room" label="Room" />
              <RHFTextField name="postalCode" label="Postal Code" />
            </Box>


            <RHFCheckbox name="wheelChairAccess" label="Wheelchair access?" />
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Address
          </LoadingButton>
            <Button color="inherit" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AddressNewForm.propTypes = {
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  open: PropTypes.bool,
};
