import gql from "graphql-tag";
export const GET_ASSESSMENTSUMMARY_BY_ID = gql`
query GetAssessmentSummaryById($classID: String!, $userID: String!, $companyName: String!) {
  getAssessmentSummaryById(classID: $classID, userID: $userID,  companyName: $companyName) {
        code
        success
        message
        results        
  }
}`;


export const SEARCH_ASSESSMENT_SSG  = gql`
query SearchAssessmentSSG($courseRunId: String!, $courseReferenceNumber: String!, $uen: String!, $companyName: String!, $encryptionkey: String!, $_companyName: String!) {
  searchAssessmentSSG(courseRunId: $courseRunId, courseReferenceNumber: $courseReferenceNumber, uen: $uen, companyName: $companyName, encryptionkey: $encryptionkey, _companyName: $_companyName) {
        code
        success
        message
        results        
  }
}`;


// export const CREATE_ASSESSMENT_SUMMARY = gql`
// mutation CreateAssessmentSummary($data: AssessmentSummaryInput!, $fullName: String!, $companyName: String!, $uen: String!) {
//   createAssessmentSummary(data: $data, fullName: $fullName, companyName: $companyName, uen: $uen) {
//     code
//     success
//     message
//   }
// }`;

export const UPDATE_VOID_ASSESSMENT= gql`
mutation UpdateAssessmentSummary($data: AssessmentUpdateVoidInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  updateAssessmentSummary(data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;


// ASSESSMENT SUMMARY

export const CREATE_ASSESSMENT_SUMMARY = gql`
mutation AddAssessmentSummary($_id: ID!, $traineeID: ID!, $data: AssSumInput!, $fullName: String!, $companyName: String!) {
  addAssessmentSummary(_id: $_id, traineeID: $traineeID, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_ASSESSMENT_SUMMARY = gql`
mutation updateAssSummary($_id: ID!, $traineeID: ID!, $data: AssSumInput!, $fullName: String!, $companyName: String!) {
  updateAssSummary(_id: $_id, traineeID: $traineeID, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const SUBMIT_ASSESSMENT_SUMMARY = gql`
mutation SubmitAssessment($data: AssessmentSumInput!, $fullName: String!, $companyName: String!, $uen: String!, $encryptionkey: String!, $_companyName: String!) {
  submitAssessment(data: $data, fullName: $fullName, companyName: $companyName, uen: $uen, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;








