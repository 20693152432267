import { PropTypes } from "prop-types";
import { m } from 'framer-motion';
import { Box, IconButton, Badge, badgeClasses } from "@mui/material";
import Iconify from "../../../components/iconify/iconify.js";
import { useSettingsContext } from "../../../components/settings/index.js";


export default function SettingsButton({ sx }) {
    const settings = useSettingsContext();

    return (
        <Badge
        color="error"
        variant="dot"
        invisible={!settings.canReset}
        sx={{
            [`& .${badgeClasses.badge}`]: {
                top: 8,
                right: 8
            },
            ...sx
        }}
        >
            <Box
            component={m.div}
              animate={{
              rotate: [0, settings.open ? 0 : 360]
            }}
            transition={{
                duration: 12,
                ease: 'linear',
                repeat: Infinity
            }}
            >
                <IconButton
                component={m.button}
                 whileTap="tap"
                 whileHover="hover"
                 aria-label="settings"
                 onClick={settings.onToggle}
                 sx={{
                    width: 40,
                    height: 40
                 }}
                 >
                    <Iconify icon="solar:settings-bold-duotone" width={24} />
                </IconButton>
            </Box>
        </Badge>
    );
}

SettingsButton.propTypes = {
    sx: PropTypes.object
}