import PropTypes from 'prop-types';
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
import LinkItem from './link-item.js';


export default function CustomBreadcrumbs({links, action, heading, moreLink, activeLast, sx, ...other}){

    const lastLink = links[links?.length -1].name;

    return (
        <Box sx={{ ...sx }}>
            <Stack direction="row" alignItems="center">
                <Box sx={{ flexGrow: 1, py: 2}}>
                    {heading && (
                        <Typography variant="h4" gutterBottom>{heading}</Typography>
                    )}

                    {!!links.length && (
                        <Breadcrumbs separator={<Separator />} {...other}>
                            {links?.map((link) => (
                                <LinkItem
                                    key={link.name || ''}
                                    link={link}
                                    activeLast={activeLast}
                                    disabled={link.name === lastLink}
                                />
                            ))}
                        </Breadcrumbs>

                    )}
                </Box>
                {action && <Box sx={{flexShrink: 0}}>{action}</Box> }
            </Stack>

            {!!moreLink?.map && (
                <Box sx={{ mt:2}}>
                    {moreLink?.map((href) => (
                        <Link
                            key={href}
                            href={href}
                            variant="body2"
                            targer="_blank"
                            rel="noopener"
                            sx={{display: 'table'}}
                        >   
                        {href}
                        </Link>
                    ))}
                </Box>
            )}
        </Box>
    )
}

CustomBreadcrumbs.propTypes = {
    sx: PropTypes.object,
    action: PropTypes.node,
    link: PropTypes.array,
    heading: PropTypes.string,
    moreLink: PropTypes.array,
    activeLast: PropTypes.bool
}

function Separator(){
    return (
        <Box
        component="span"
        sx={{
            width: 4,
            height: 4,
            borderRadius: '50%',
            bgcolor: 'text.disabled'
        }}
        />
    )
}