import gql from "graphql-tag";

export const AUTH_CREDENTIALS = gql`
  mutation authLogin($userName: String, $password: String, $companyName: String) {
    authLogin(userName: $userName, password: $password, companyName: $companyName) {
        code
        message
        success
        results
        token
        company
        companyProfile
        isAuthenticated
    }
  }`;

export const AUTH_GENERATEOTP = gql`
  mutation generateOTP($data: AuthInput!, $companyName: String!) {
    generateOTP(data: $data, companyName: $companyName) {
      code
      success
      message
    }
  }`;

  export const AUTH_VERIFY = gql`
  mutation verifyOTP($token: String!, $companyName: String!) {
    verifyOTP(token: $token, companyName: $companyName) {
      code
      success
      message
    }
  }`;