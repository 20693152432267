import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Box, Pagination, paginationClasses} from '@mui/material';
import { useSettingsContext } from '../../../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../../components/table/index.js';
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import { useBoolean } from '../../../../../hooks/use-boolean.js';

import ClassESignRow from './class-attendance-esign-row.js';
import ClassEsignToolbar from './class-enrolment-table-esign-toolbar.js';
// import ClassTraineeDialog from './class-trainee-dialog.js';
import Label from '../../../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';




// GRAPHQL

import { GET_COURSE_SESSION } from "../../../../../graphql/modules/_client/attendance.js";
import { GET_CLASS_BY_ID } from "../../../../../graphql/modules/_client/class.js";
 import { useQuery } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'confirmed', label: 'Confirmed'},
  {value: 'cancelled', label: 'Cancelled'}
]
const TABLE_HEAD = [
  {id: 'modeOfTraining', label: 'Mode of Training'},
  {id: 'startDate', label: 'Session Start Date'},
  {id: 'startTime', label: 'Session Start Time'},
  {id: 'startEndTime', label: 'Session End Time'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function ClassESignAttendance({companyinfo}) {
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const traineeDialog = useBoolean();


  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [signature, setSignature] = useState(state?.users?.signature || null );
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [courseSession, setCourseSession] = useState(null);
  
 
  const {data: session} = useQuery(GET_COURSE_SESSION, {
    variables: {
      courseRunId: state?.class?.courseRunId,
      uen: companyProfile?.uen,
      courseReferenceNumber: state?.class?.course?.tsgRefNumber,
      sessionId: "",
      encryptionkey: companyProfile?.encryptionKey,
      _companyName: companyProfile?.companyName,
    },
    fetchPolicy: "cache-and-network",
  })

  const { data: data, loading, error, refetch } = useQuery(GET_CLASS_BY_ID, {
    variables: {
      limit: 50,
      offset: table?.page,
      searchText: searchText,
      companyName: companyinfo?.info?.databaseName || company,
      order: table.order || 'asc',
      orderBy: table.orderBy || 'fullName',
      _id: companyinfo?.class?._id
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getClassesById)
        setCourseSession(session?.getCourseSession)
      }

  }, [data, session])



    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (id) => {
      console.log(id)
      
});


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-classes', {state: {info: companyinfo, users: row}})
  });

const handleView = useCallback(
  (row) => {
    navigate('/company-enrolment-session', {state: {class: row, info: companyinfo}})
  },
  []
);

const handleEdit = useCallback(
  (id) => {
    console.log(id)
    // router.push(paths.dashboard.job.edit(id));
  },
  []
);

const handleDelete = useCallback((id) => {
  console.info('DELETE', id);
}, []);

  const handleCreateNewClass = useCallback(() => {
    navigate('/company-create-update-classes', {state: {info: companyinfo}})
 
});



  return (
     <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${companyinfo?.class?.course?.courseTitle}`}
          links={[
           {name: 'Dashboard'},
           {name: 'Class'},
           {name: 'Class Attendance E-Sign'}
          ]}
       />

  

        <Card>
         {/* <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'confirmed' && 'success') ||  (tab.value === 'completed' && 'info') ||  (tab.value === 'draft' && 'warning')  ||  (tab.value === 'cancelled' && 'error') || 'default'
                }
                >
                  {tab.value === 'all' && results?.results?.enrolment?.length}
                  {tab.value === 'confirmed' && results?.confirmed}
                  {tab.value === 'completed' && results?.completed}
                  {tab.value === 'draft' && results?.draft}
                  {tab.value === 'cancelled' && results?.cancelled}
              </Label>
              }
           />
  
          ))}

         </Tabs> */}

          {/* <ClassEsignToolbar onFilters={handleFilters} /> */}
         <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>
                {companyinfo?.class?.sessions?.map((row, index) => (
                      <ClassESignRow
                      key={row?.id}
                      index={index}
                      row={row}
                      refetch={refetch}
                      classinfo={state?.class}
                      onViewRow={() => handleView(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                      trainee={results?.results}
                      courseRunId={state?.class?.courseRunId}
                      uen={companyProfile?.uen}
                      courseReferenceNumber={state?.class?.course?.tsgRefNumber}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>
          </Scrollbar>

          {/* <TablePaginationCustom 
            count={courseSession?.results?.data?.courseRun?.sessions?.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          /> */}
        </Card>
        {/* <ClassTraineeDialog 
          title="Enrol Trainee"
          open={traineeDialog.value}
          onClose={traineeDialog.onFalse}
          companyInfo={state?.info}
          classinfo={state?.class}
          handleRefetch={refetch}
        /> */}

    </Container>
  );
}

ClassESignAttendance.propTypes = {
  companyinfo: PropTypes.object,
};
