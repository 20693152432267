import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import {Box, Stack, Dialog, TextField, Typography, InputAdornment, ListItemButton, listItemButtonClasses, Grid, TableContainer, Table, TableBody} from '@mui/material';
import {useTable,   getComparator, TableHeadCustom, TablePaginationCustom} from '../../../../components/table/index.js';
import { GET_CLASSES } from "../../../../graphql/modules/_client/class.js";
import { GET_ALL_COMPANY } from "../../../../graphql/modules/company.js";
import { useQuery } from "@apollo/react-hooks";
import { useSnackbar } from '../../../../components/snackbar/index.js';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import {LoadingButton} from '@mui/lab';
import EnrolmentTableRow from './enrolment-table-row.js';

// import SearchNotFound from 'src/components/search-not-found';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  {},
  {id: 'fullName', label: 'Fullname'},
  {id: 'nricFin', label: 'NRIC/FIN/Others'},
  {id: 'enrolmentID', label: 'Enrolment ID'},
  {id: 'employerName', label: 'Employer Name'},
  {id: 'status', label: 'Status'}
]


export default function AddressListDialog({ title = 'Address Book', list, action, open, onClose, selected, onSelect}) {
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const company = useSelector((state) => state?.auth?.company);
  
  const [searchAddress, setSearchAddress] = useState('');
  const [searchText, setSearch] = useState("");
  const [selectedClass, onSelectClass] = useState("");
  const [selectedEnrolment, onSelectedEnrolment] = useState([]);
 // const [checkSelect, setCheckSelect] = useState(null);
 // const checkSelect = false;
 const table = useTable();
  const [companyName, setCompanyName] = useState("iatos");
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, error, refetch } = useQuery(GET_CLASSES, {
    variables: {
      limit: 10,
      offset: 0,
      searchText: searchText,
      companyName: companyProfile?.databaseName || company,
      order: 'Desc',
      orderBy: 'courseDates.start',
      isRole: isRole
      },
      fetchPolicy: "cache-and-network",
    });


  useEffect(() => {
      if(data){
        setResults(data?.getClasses)
      }
      if(open){
        onSelectedEnrolment([])
      }
    
  }, [data, open])


  const dataFiltered = applyFilter({
    inputData: selectedClass?.enrolment,
    comparator: getComparator(table.order, table.orderBy),
    query: searchAddress,
  });

  const notFound = !dataFiltered?.length && !!searchAddress;

  const handleSearchAddress = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const onConfirmed = useCallback(() => {
      if(selectedEnrolment.length !== 0){
        onSelect(selectedEnrolment);
        selected(selectedClass);
        onClose();
      }else {
        enqueueSnackbar('Please select trainee.', {variant: 'error'});
      }

    },
    [onClose, onSelect, selectedEnrolment]
  );

  const handleSelectClass = useCallback((event) => {
    onSelectClass(event);
  }, []);

  const onSelectRow = useCallback((row) => {
    const isDuplicate = selectedEnrolment?.some(item => item?.traineeInfo?.nricFin === row?.traineeInfo?.nricFin);
    if(!isDuplicate){
      onSelectedEnrolment(selectedEnrolment => [...selectedEnrolment, row]);
    }else{
     const unSelected =  selectedEnrolment?.filter(item => item?.traineeInfo?.nricFin !== row?.traineeInfo?.nricFin);
     onSelectedEnrolment(unSelected)


     // onSelectedEnrolment(updatedItems);
    }
    // else {
    //   const updatedItems = selectedEnrolment?.some(item => item?.traineeInfo?.nricFin !== row?.traineeInfo?.nricFin);
    //   onSelectedEnrolment(updatedItems);
    // }
  
  }, [selectedEnrolment]);

  // const onSelectRow = useCallback((row) => {
  //   console.log(row)
  //   onSelectedEnrolment([...selectedEnrolment, row]);
  //   console.log(selectedEnrolment)
  // }, [selectedEnrolment]);


  const renderList = (
    <Stack
      spacing={0.5}
      sx={{
        p: 0.5,
        maxHeight: 80 * 8,
        overflowX: 'hidden',
      }}
    >
      {results?.results?.map((item) => (
        <Stack
          key={item?._id}
          spacing={0.5}
          component={ListItemButton}
          onClick={() => handleSelectClass(item)}
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`&.${listItemButtonClasses.selected}`]: {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{item?.classCode}</Typography>
          </Stack>

          {item?.course?.courseTitle && (
            <Box sx={{ color: 'primary.main', typography: 'caption' }}>{item?.course?.courseTitle}</Box>
          )}


          {item.courseRunId && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {item.courseRunId}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> {title} </Typography>

        {action && action}
      </Stack>

      <Grid container sx={{p: 3}}>
        <Grid xs={12} md={3}>
        <Stack>
          <TextField
            value={searchText}
            onChange={handleSearchAddress}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {notFound ? (
          renderList
        ) : (
          renderList
        )}
        </Grid>
        <Grid xs={12} md={9} sx={{pl: 1, pr: 1}}>
        <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  
                <TableBody>
                {dataFiltered?.map((row) => (
                      <EnrolmentTableRow
                      key={row?.id}
                      row={row}
                      // onViewRow={() => handleView(row)}
                      // onDeleteRow={() => handleDeleteRow(row)}
                      // onEditRow={() => handleEditRow(row)}
                      // onCancelledRow={() => handleCancelledRow(row)}
                      // onCompleted={() => handleCompleted(row)}
                      onSelectRow={() => onSelectRow(row)}
                      // selected={checkSelect}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>

        </Grid>
      </Grid>
      <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3 }}>
      <LoadingButton variant="contained" onClick={onConfirmed}>Confirm</LoadingButton>
      <LoadingButton variant="contained" onClick={() => onClose()}>Close</LoadingButton>
      </Stack>

    </Dialog>
  );
}

AddressListDialog.propTypes = {
  action: PropTypes.node,
  list: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.func,
  title: PropTypes.string,
};

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator,  query }) {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0]?.traineeInfo, b[0]?.traineeInfo);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);


  if (query) {
    return inputData.filter(
      (tr) =>
      tr.traineeInfo.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      tr.traineeInfo.nricFin.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      tr.ssgenrolment.referenceNumber.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      tr.traineeInfo.nricFin.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return inputData;
}
