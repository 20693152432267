import { random, sum, flattenDeep, transform } from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { parseISO, add } from 'date-fns';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Card, Container, Typography, Stack, Divider, IconButton, MenuItem, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers';
import { fDate } from '../../../../util/formatTime.js';
import { _invoiceStatus } from '../../../../util/invoice.js';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { useResponsive } from '../../../../hooks/useResponsive.js';
import Iconify from 'src/components/iconify/index.js';
import { useSettingsContext } from '../../../../components/settings/index.js';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { courseMode } from 'src/util/course.js';
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RFHUpload
  } from 'src/components/hook-form';

import InvoiceNewEditDetails from './invoice-edit-details.js';
import AddressListDialog from './address-list-dialog.js';

import { CREATE_CLIENT_INVOICE, UPDATE_CLIENT_INVOICE } from "../../../../graphql/modules/_client/invoiceClient.js";
import { useMutation } from "@apollo/react-hooks";


export default function InvoiceCreateUpdate({ currentInvoice }) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const companyInfo  = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const [selectedClass, setSelectedClass] = useState({});
  const navigate = useNavigate();
  
  const [CreateInvoiceMutation] =  useMutation(CREATE_CLIENT_INVOICE);
  const [UpdateInvoiceMutation] =  useMutation(UPDATE_CLIENT_INVOICE);
  const { enqueueSnackbar } = useSnackbar();
  const dateNowPlus14Days = add(new Date(), { days: 14 });  



    const settings = useSettingsContext();
    const upMd = useResponsive('up', 'md');
    const { state } = useLocation();
    const to = useBoolean();

 
    const NewInvoiceSchema = Yup.object().shape({
      invoiceToInfo: Yup.mixed().nullable().required('Invoice to is required'),
      createDate: Yup.mixed().nullable().required('Create date is required'),
      dueDate: Yup.mixed()
        .required('Due date is required')
        .test(
          'date-min',
          'Due date must be later than create date',
          (value, { parent }) => value.getTime() > parent.createDate.getTime()
        ),
      // not required
    });


    const defaultValues = useMemo(
      () => ({
   
        invoiceNumber: state?.invoiceNumber || `INVNO-${random(10000)}`,
        status: state?.status || 'draft',
        taxes: state?.taxes || companyInfo?.gst,
        discount: state?.discount || 0,
        createDate: state?.createDate ? parseISO(state?.createDate) : new Date(),
        dueDate: state?.dueDate ? parseISO(state?.dueDate) : dateNowPlus14Days,
        invoiceTo: state?.invoiceTo || null,
        invoiceToInfo: state?.invoiceToInfo || null,
        items: state?.items || [],
        totalAmount: state?.totalAmount || 0,
        totalGrants: state?.totalGrants || 0,
        grantDetails: state?.grantDetails[0] || [],
        courseInfo: state?.courseInfo || {
          courseTitle: '',
          tgsReferenceNumber: '',
          courseRun: '',
          modeOfTraining: '',
          courseStart: '',
          courseEnd: ''
        }
      }),
      [state]
    );

  

  
    const methods = useForm({
      resolver: yupResolver(NewInvoiceSchema),
      defaultValues,
    });
  
    const {
      reset,
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onSubmit = handleSubmit(async (data) => {
      try {
      if(state){
        const {data: { updateClientInvoice }} = await UpdateInvoiceMutation({
          variables: {
            companyName: companyInfo?.databaseName,
            fullName: fullName,
            _id: state?._id,
              data: {
                invoiceNumber: data?.invoiceNumber,
                status: data?.status,
                taxes: parseFloat(data?.taxes),
                items: data?.items,
                totalAmount: parseFloat(data?.totalAmount),
                discount: data?.discount,
                createDate: data?.createDate,
                dueDate: data?.dueDate,
                invoiceToInfo: data?.invoiceToInfo,
                items: data?.items,
                totalGrants: parseFloat(data?.totalGrants),
                grantDetails: data?.grantDetails,
                courseInfo: data?.courseInfo,
                modifiedBy: fullName,
                dateModified: new Date(),
              }
            }
        });
    
        if(updateClientInvoice?.success){
          reset();
          enqueueSnackbar(state ? 'Update success!' : 'Create success!', {variant: 'success'});
          navigate(-1);
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created', {variant: 'error'});
        }
      }else {
        const {data: { createClientInvoice }} = await CreateInvoiceMutation({
          variables: {
            companyName: companyInfo?.databaseName,
            fullName: fullName,
              data: {
                invoiceNumber: data?.invoiceNumber,
                status: data?.status,
                taxes: parseFloat(data?.taxes),
                items: data?.items,
                totalAmount: data?.totalAmount,
                discount: parseFloat(data?.discount),
                createDate: data?.createDate,
                dueDate: data?.dueDate,
                invoiceToInfo: data?.invoiceToInfo,
                items: data?.items,
                totalGrants: parseFloat(data?.totalGrants),
                grantDetails: data?.grantDetails,
                courseInfo: data?.courseInfo,
                createdBy: fullName,
                dateCreated: new Date(),
                modifiedBy: fullName,
                dateModified: new Date(),
              }
            }
        });
    
        if(createClientInvoice?.success){
          reset();
          enqueueSnackbar(state ? 'Update success!' : 'Create success!', {variant: 'success'});
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created', {variant: 'error'});
        }
      }

      } catch (error) {
        console.log(error);
      }
 
    });

    useEffect(() => {
      if(!state){
        const modeOfTrainingValue = courseMode?.map((result) => {
          if(result.id === selectedClass?.modeOfTraining){
             return result?.value
          }
          })
  
        setValue('items', values.invoiceTo?.map((item) => ({
          description:  item?.traineeInfo?.fullName,
          nricFin: item?.traineeInfo.nricFin,
          quantity: 1,
          price: selectedClass?.course?.courseFee,
          total: selectedClass?.course?.courseFee,
        })))
         // COURSE DETAILS
        setValue('courseInfo', {
          courseTitle: selectedClass?.course?.courseTitle,
          tsgRefNumber: selectedClass?.course?.tsgRefNumber,
          courseRun: selectedClass?.courseRunId,
          modeOfTraining: modeOfTrainingValue,
          courseStart: fDate(selectedClass?.courseDates?.start),
          courseEnd: fDate(selectedClass?.courseDates?.end)
        })
  
        if(values.invoiceTo){
          // TRAINEE AND EMPLOYER
          if(values.invoiceTo[0]?.sponsorshipType === 'Individual'){
            setValue('invoiceToInfo', values.invoiceTo[0]?.traineeInfo)
          }else{
            setValue('invoiceToInfo', values.invoiceTo[0]?.employer)
          } 
          // GRANTS
          const exists = values.invoiceTo.some(obj => obj.hasOwnProperty('grant'));
          if(exists){
            const totalGrants = values?.invoiceTo?.map((item) => item?.grant?.eligibleGrants?.map((res) => res?.amount));
            const flattenedArray = flattenDeep(totalGrants);
            setValue('totalGrants', sum(flattenedArray)?.toFixed(2))
            
            const uniqueValues = [
              ...new Set(
                values?.invoiceTo?.flatMap(obj =>
                  obj?.grant?.eligibleGrants?.map(item => item.schemeName) || []
                ) || []
              )
            ];

           //[...new Set(values?.invoiceTo?.flatMap(obj => obj?.grant?.eligibleGrants?.map(item => item.schemeName)))];
       
            const dataResult = uniqueValues?.map((res) => {
              return {
                schemeName: res
              }
            })

            // const uniqueArray = values?.invoiceTo?.filter(
            //   (obj, index, self) =>
            //     index ===
            //     self?.findIndex((t) => t.grant?.eligibleGrants?.schemeName === obj?.grant?.eligibleGrants?.schemeName)
            // );
            //setValue('grantDetails', uniqueArray[0].grant)
              
            setValue('grantDetails', {eligibleGrants: dataResult})
          }
          
          // DISCOUNT CALCULATE
          const totalDiscount = values?.invoiceTo?.map((item) => parseFloat(item?.fees?.discountAmount));
          setValue('discount', sum(totalDiscount))
        }
      }
    

    }, [values?.invoiceTo]);




    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>

                  <Stack spacing={{ xs: 1, sm: 2 }} direction="row"  justifyContent="space-between" alignItems="center">
                  <CustomBreadcrumbs
                heading={state ? "Update Invoice" : "Create a new Invoice"}
                links={[
                {name: 'Invoice'},
                {name: 'New Invoice'}
                ]}
            />
            <LoadingButton variant="contained" onClick={() => navigate(-1)}>Go Back</LoadingButton>
            </Stack>

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
        
        {/* BILLING ADDRESS */}

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              From:
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{companyInfo?.companyName}</Typography>
            <Typography variant="body2">{companyInfo?.address}</Typography>
            <Typography variant="body2">{companyInfo?.contactNo}</Typography>
            <Typography variant="body2">{companyInfo?.email}</Typography>
          </Stack>
        </Stack>

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              To:
            </Typography>
            <IconButton onClick={to.onTrue}>
              <Iconify icon={'mingcute:add-line'} />
            </IconButton>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{values?.invoiceToInfo?.fullName || values?.invoiceToInfo?.empcompanyName}</Typography>
            <Typography variant="subtitle2">{values?.invoiceToInfo?.email || values?.invoiceToInfo?.contact?.emailAddress}</Typography>
            <Typography variant="body2">{values?.invoiceToInfo?.mobileNo ||  values?.invoiceToInfo?.contact?.contactNumber?.phoneNumber}</Typography>
            <Typography variant="body2">{values?.invoiceToInfo?.contact?.fullName ? `Att: ${values?.invoiceToInfo?.contact?.fullName}` : ''}</Typography>
          </Stack>
        </Stack>

              <AddressListDialog
              title="Class"
              open={to.value}
              onClose={to.onFalse}
              onSelect={(selectedTrainee) => setValue('invoiceTo', selectedTrainee)}
              selected={setSelectedClass}
            />
            </Stack>

              {/* BILLING DATE */}

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
          >
            <RHFTextField
            name="invoiceNumber"
            label="Invoice number"
          />

              <RHFSelect
              fullWidth
              name="status"
              label="Status"
              InputLabelProps={{ shrink: true }}
            >
              {_invoiceStatus?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </RHFSelect>
            <Controller
              name="createDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Invoice Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

            <Controller
              name="dueDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Due date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />


  
            </Stack>

            <InvoiceNewEditDetails />
            </Card>

            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>


        <LoadingButton
        color="inherit"
          size="large"
          variant="outlined"
          type="submit"
          loading={isSubmitting} 
        >
          {state ? 'Update Invoice' : 'Create Invoice'}
        </LoadingButton>
      </Stack>
         </Container>
        </FormProvider>
    )
}