import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwtDecoded from "jwt-decode";
import { logoutUsers } from "../store/modules/index.js";


export const RoleRoute = ({roles = [], children }) => {
    const dispatch = useDispatch();
    const jwtToken = useSelector((state) => state?.auth.jwtToken);
    const isRole = useSelector((state) => state?.auth.isRole);
    const decodedToken = jwtDecoded(jwtToken);
    const currentDate = new Date();
    if(decodedToken.exp * 1000 < currentDate.getTime()){
        dispatch(logoutUsers());
    }


    return roles.some((role)=> isRole.includes(role)) ? children : <Navigate to="/" replace />
}