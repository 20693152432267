
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {result, sumBy} from 'lodash';
import {CircularProgress, Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Stack, Typography, Grid, Divider,
Dialog, Box, DialogActions
} from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { useSettingsContext } from '../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../components/table/index.js';
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../routes/components/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';
import Iconify from '../../components/iconify/iconify.js';
import InvoiceTableToolBar from './invoice-table-toolbar.js';
import InvoiceTableRow from './invoice-table-row.js';
import InvoicePDF from './invoice-pdf-view.js'
import Label from '../../components/label/index.js';
import Scrollbar from '../../components/scrollbar/scrollbar.js';
import InvoiceAnalytic from './invoice-analytics.js';

import { GET_ALL_INVOICE, DELETE_INVOICE } from "../../graphql/modules/invoice.js";
import { useQuery, useMutation } from "@apollo/react-hooks";


const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'paid', label: 'Paid'},
  {value: 'pending', label: 'Pending'},
  {value: 'overdue', label: 'Overdue'},
  {value: 'draft', label: 'Draft'}
]


const TABLE_HEAD = [
  {id: 'companyName', label: 'Company Name'},
  {id: 'createDate', label: 'Created Date'},
  {id: 'dueDate', label: 'Due Date'},
  {id: 'totalAmount', label: 'Amount'},
  {id: 'status', label: 'Status'},
  {id: 'send', label: 'Invoice Sent?'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [
    {id: 'basic', label: 'Basic'},
    {id: 'standard', label: 'Standar'},
    {id: 'premium', label: 'Premium'}
  ],
  status: 'all'
}

export function InvoiceListView({img, ...other}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [invoiceDetails, setInvoice] = useState(null);
  const [DeleteInvoiceMutation] =  useMutation(DELETE_INVOICE);
  const invoicePdf = useBoolean();

  const { data, loading, error, refetch } = useQuery(GET_ALL_INVOICE, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyName,
      order: table.order,
      orderBy: table.orderBy || 'createDate'
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getAllInvoice)
      }
  }, [data])

  const getInvoiceLength = (status) => results?.results?.filter((item) => item.status === status).length;
  const getPercentByStatus = (status) => (getInvoiceLength(status) / results?.results?.length) * 100;
  const getTotalAmount = (status) =>
  sumBy(
    results?.results?.filter((item) => item.status === status),
    'totalAmount'
  );

  const handleFilters = useCallback((name, value) => {
    setSearch(value)
  },
  [table]
);

const handleViewInvoice = useCallback((row) => {
  setInvoice(row);
  invoicePdf.onTrue();
},
[]
);




const handleEditRow = useCallback((row) => {
  navigate('/invoice-create-update', {state: row})
});

const handleDeleteRow = useCallback(async (id) => {

  const {data: { deleteInvoice }} = await DeleteInvoiceMutation({
    variables: {
      _id: id,
      companyName: "iatos",
      fullName: fullName,
    }
  })

  if(deleteInvoice?.success){
    console.log("deleted")
    refetch();
  }else {
    console.log("failed")
  }
  },
  [refetch]
);

const handleFilterStatus = useCallback(
  (event, newValue) => {
    if(newValue !== "all"){
      setFilters(newValue);
      setSearch(newValue);
    }else {
      setFilters(newValue);
      setSearch('');
    }

  },
  [table]
);



  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>

<CustomBreadcrumbs
         heading="Invoice List"
         links={[
          {name: 'Dashboard'},
          {name: 'Invoice'},
          {name: 'List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          href={'/invoice-create-update'}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            New Invoice
          </Button>
         }
        />


            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Total"
                total={results?.count}
                percent={100}
                price={sumBy(results?.results, 'totalAmount')}
                icon="solar:bill-list-bold-duotone"
                color={theme.palette.info.main}
              />

              <InvoiceAnalytic
                title="Paid"
                total={getInvoiceLength('paid')}
                percent={getPercentByStatus('paid')}
                price={getTotalAmount('paid')}
                icon="solar:file-check-bold-duotone"
                color={theme.palette.success.main}
              />

              <InvoiceAnalytic
                title="Pending"
                total={getInvoiceLength('pending')}
                percent={getPercentByStatus('pending')}
                price={getTotalAmount('pending')}
                icon="solar:sort-by-time-bold-duotone"
                color={theme.palette.warning.main}
              />

              <InvoiceAnalytic
                title="Overdue"
                total={getInvoiceLength('overdue')}
                percent={getPercentByStatus('overdue')}
                price={getTotalAmount('overdue')}
                icon="solar:bell-bing-bold-duotone"
                color={theme.palette.error.main}
              />

              <InvoiceAnalytic
                title="Draft"
                total={getInvoiceLength('draft')}
                percent={getPercentByStatus('draft')}
                price={getTotalAmount('draft')}
                icon="solar:file-corrupted-bold-duotone"
                color={theme.palette.text.secondary}
              />
            </Stack>
         

        <Card>

        <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'paid' && 'success') ||  (tab.value === 'pending' && 'warning') || (tab.value === 'overdue' && 'error') ||'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'paid' && results?.paidCount}
                  {tab.value === 'pending' && results?.pendingCount}
                  {tab.value === 'overdue' && results?.overdueCount}
                  {tab.value === 'draft' && results?.draftCount}
              </Label>
              }
           />
  
          ))}

         </Tabs>

        <InvoiceTableToolBar  onFilters={handleFilters}/>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
 

                   <TableBody>

                {results?.results?.map((row) => (
                      <InvoiceTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewInvoice(row)} 
                      onEditRow={() => handleEditRow(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                    />
                ))}
  

                </TableBody>


              </Table>
          </TableContainer>
          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

        {/* PRINT PDF */}
        <Dialog fullScreen open={invoicePdf.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={invoicePdf.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoiceDetails={invoiceDetails} companyProfile={companyProfile}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>


    </Container>
  );
}