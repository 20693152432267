
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../components/custom-popover/index.js';
import Iconify from "src/components/iconify/iconify.js";
import { ConfirmDialog } from '../../components/custom-dialog/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';
import { parseISO, format } from 'date-fns';

export default function AuditLogTableRow({
    row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onSelectRow
}){
    const { user, title, activity, dateCreated} = row;
    const popover = usePopover();
    const confirm = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{ display: 'flex', alignItems: 'center'}}>
                <ListItemText 
                    primary={user}
                    primaryTypographyProps={{ typography: 'body2'}}
                    secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled'
                    }}
                />
            </TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{title}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{activity}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{format(parseISO(dateCreated), "dd/MM/yyyy hh:mm:ss")}</TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 180}}
    >
        <MenuItem onClick={onViewRow}>
            <Iconify icon="pajamas:eye" />
            View Account
        </MenuItem>
        <MenuItem onClick={onEditRow}>
            <Iconify icon="solar:pen-bold" />
            Update
        </MenuItem>

        <MenuItem onClick={() => {
            confirm.onTrue();
            popover.onClose();
         }} sx={{ color: 'error.main'}}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
        </MenuItem>
            


    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${title}?`}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

</>
 )

}

AuditLogTableRow.propTypes = {
    onViewRow: PropTypes.func,
  };
  