import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {SSGTime, fDate} from '../../../../../util/formatTime.js';
import { Page, View, Text, Image, Document, Font, StyleSheet } from '@react-pdf/renderer';
// utils


// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col2: { width: '20%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle2: { fontSize: 9, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 5},
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          padding: '40px 24px 120px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '20%',
          paddingRight: 16,
        },
        tableCell_3: {
          width: '30%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultRemark: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
      }),
    []
  );
// ----------------------------------------------------------------------

export default function AssessmentSummaryPDF({ traineeInfo, assessmentSummary, classinfo, companyProfile }) {

 
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;


    const {assessmentStartTime, assessmentEndTime, criteriaRemarks, signature, trainerSignature, criteriaResults, createdDate, createdBy, grade, score, dateCreated} = assessmentSummary;
    const _trainerSignature = trainerSignature ? trainerSignature : null;
    const _traineeSignature = signature ? signature : null;
    const {course, classCode} = classinfo;

  const styles = useStyles();

  return (
    <Document >
      <Page size="A4" style={styles.page}>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
    
      <View style={styles.col7}><Text style={styles.h4}>Assessment Summary Record</Text></View>

      <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Course Unit: ${course?.courseTitle}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`TGS Ref No: ${course?.tsgRefNumber}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Course Skill Code:  ${course?.courseSkillCode}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Candidate NRIC: ${traineeInfo?.nricFin}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Candidate Name: ${traineeInfo?.fullName}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessment Date: ${assessmentSummary?.createdDate ? fDate(assessmentSummary?.createdDate) : 'NA'}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessor Name: ${assessmentSummary?.createdBy}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessment Duration: ${SSGTime(assessmentStartTime)} - ${SSGTime(assessmentEndTime)}`}</Text>
              </View>
           </View>
       
          </View>




        <Text style={[styles.subtitle1, styles.mb8]}>FINAL OUTCOME OF THE ASSESSMENT </Text>
        <Text style={[styles.subtitle2, styles.mb8]}>This candidate has been assessed as:  </Text>
        <View style={[styles.gridContainer, styles.mb40]}>
            <View style={styles.col6}>
            <Text style={styles.body2}>{`[${criteriaResults === "Pass" ? "X" : ""}] Competent`}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={styles.body2}>{`[${criteriaResults === "Fail" ? "X" : ""}] Not Yet Competent`}</Text>
          </View>
        </View>

        <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow1}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Candidate Sign: `}</Text>
                {
                  _traineeSignature ?
                  <Image source={signature} style={{ width: 48, height: 48 }} />
                  :
                  ""
                }
               
              </View>
              <View style={[styles.tableCell_1, styles.titleheader1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Date: ${fDate(dateCreated)}`}</Text>
              </View>
           </View>

           <View style={styles.tableRow1}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Assessor Sign:`}</Text>
                {
                  _trainerSignature ?
                  <Image source={_trainerSignature} style={{ width: 48, height: 48 }} />
                  :
                  ""
                }
              </View>
              <View style={[styles.tableCell_1, styles.titleheader1]}>
                <Text style={[styles.subtitle2, styles.textMargin]}>{`Date: ${fDate(dateCreated)}`}</Text>
              </View>
           </View>
        </View>

  
        <Text style={[styles.subtitle2, styles.mb8]}>By signing, the candidate is agreeing to accept the assessment outcome.</Text>
        <Text style={[styles.subtitle1, styles.mb8]}>Feedback on outcome:</Text>
        <Text style={[styles.subtitle2, styles.mb8]}>{criteriaRemarks}</Text>
  
      </Page>
    </Document>
  );
}

AssessmentSummaryPDF.propTypes = {
  traineeInfo: PropTypes.node,
  assForm: PropTypes.node,
  classinfo: PropTypes.node
};
