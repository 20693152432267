import { Helmet } from "react-helmet-async";
// import { DashboardAdmin } from "../../sections/dashboard/dashboardadmin.js";
import { CompanyUsersListView } from "../../sections/company/users/index.js";

export default function UsersTP(){
    return (
        <>
            <Helmet>
                <title>Users Management</title>
            </Helmet>
           <CompanyUsersListView />
        </>
    )
}