import { inputLabelClasses, outlinedInputClasses, inputBaseClasses, filledInputClasses } from '@mui/material';
import { alpha } from '@mui/material/styles/index.js';

// ----------------------------------------------------------------------

export  function textfield(theme) {
  const color = {
    focused: theme.palette.text.primary,
    active: theme.palette.text.secondary,
    placeholder: theme.palette.text.disabled
  };
  const font = {
    label: theme.typography.body1,
    value: theme.typography.body2
  }

  return {
     MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1)
        }
      }
     },
     MuiFormLabel: {
      styleOverrides: {
        root: {
          ...font.value,
          color: color.placeholder,
          [`&.${inputLabelClasses.shrink}`]: {
            ...font.label,
            fontWeight: 600,
            color: color.active
          },
          [`&.${inputLabelClasses.focused}`]: {
            color: color.focused
          },
          [`&.${inputLabelClasses.error}`]: {
            color: theme.palette.error.main,
          },
          [`&.${inputLabelClasses.disabled}`]: {
            color: theme.palette.text.disabled,
          },
          [`&.${inputLabelClasses.filled}`]: {
            color: 'translate(12px, 6px) scale(0.75)',
          }
        }
      }
     },
     MuiInputbase: {
      styleOverrides: {
        root: {
          [`&.${inputBaseClasses.disabled}`]:{
            '& svg': {
              color: theme.palette.text.disabled
            }
          }
        },
        input: {
          ...font.value,
          '&::placeholder' :{
            opacity: 1,
            color: color.placeholder
          }
        }
      }
     },
     MuiInput: {
      styleOverrides: {
        underlined: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.32)
          },
          '&:after': {
            borderBottomColor: color.focused
          }
        }
      }
     },
     MuiOutlinedInput: {
      styleOverrides:{
        root: {
          [`&.${outlinedInputClasses.focused}`]:{
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.focused,
            }
          },
          [`&.${outlinedInputClasses.error}`]:{
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.error.main
            }
          },
          [`&.${outlinedInputClasses.disabled}`]:{
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.action.disabledBackground
            }
          }
        },
        notchedOutline: {
          borderColor: alpha(theme.palette.grey[500], 0.2),
          transition: theme.transitions.create(['border-color'], {
            duration: theme.transitions.duration.shortest
          })
        }

      }
     },
     MuitFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16)
          },
          [`&.${filledInputClasses.focused}`]:{
            backgroundColor: alpha(theme.palette.grey[500], 0.16)
          },
          [`&.${filledInputClasses.error}`]:{
            backgroundColor: alpha(theme.palette.error.main, 0.08),
            [`&.${filledInputClasses.focused}`]:{
              backgroundColor: alpha(theme.palette.error.main, 0.16),
            }
          },
          [`&.${filledInputClasses.disabled}`]:{
            backgroundColor: theme.palette.action.disabledBackground
          }
        }
      }
     }
  }
}