import { Helmet } from "react-helmet-async";
import { LoginForm } from "../sections/auth/index.js";

export default function LoginPage(){
    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <LoginForm />
        </>
    )
}