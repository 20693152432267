import { Helmet } from "react-helmet-async";
import { CompanyClassListView } from "../../sections/company/class/index.js";
export default function ClassTP(){
    return (
        <>
            <Helmet>
                <title>Class Management</title>
            </Helmet>
            <CompanyClassListView />
        </>
    )
}