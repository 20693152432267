import gql from "graphql-tag";

export const GET_ALL_COMPANY = gql`
query getAllCompany($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getAllCompany(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        activeCount
        inactiveCount
        results
  }
}`;

export const CREATE_COMPANY = gql`
mutation CreateCompany($data: CompanyInput!, $fullName: String!, $companyName: String!) {
  createCompany(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;
export const DELETE_COMPANY = gql`
mutation DeleteCompany($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteCompany(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;


export const UPDATE_COMPANY = gql`
mutation UpdateCompany($_id: ID!, $data: CompanyInput!, $fullName: String!, $companyName: String!) {
  updateCompany( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;
