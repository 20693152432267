import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Stack, MenuItem, Checkbox, TextField, InputLabel, IconButton, FormControl, OutlinedInput, InputAdornment, Select } from '@mui/material';
import Iconify from '../../../../components/iconify/index.js';

export default function CompanyTableToolBar({
    filters,
    onFilters,
    subsOptions
}){
    // const popover = usePopover();
    
    const handleFilterName = useCallback((event) => {
        onFilters('name', event.target.value)
    }, [onFilters])

    const handleFilterSubscription = useCallback((event) =>{
        onFilters('subscription', typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
    },[onFilters])

    return (
        <>
            <Stack
                spacing={2}
                alignItems={{xs: 'flex-end', md: 'center'}}
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                sx={{
                    p: 2.5,
                    pr: {xs: 2.5, md: 1},
                }}
        
            >


                <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >
                    <TextField
                    fullWidth
                    value={filters?.name}
                    onChange={handleFilterName}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}} />
                            </InputAdornment>
                        )
                    }}
                    />
            
                </Stack>


            </Stack>
        </>
    )
}

CompanyTableToolBar.propTypes = {
    filters: PropTypes.object,
    onFilters: PropTypes.func,
    subsOptions: PropTypes.array
}