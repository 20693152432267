import { useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import {Box, Stack, Button, Divider, Typography} from '@mui/material';
import Iconify from '../../components/iconify/index.js';
import { RHFTextField, RHFMultiSelect } from '../../components/hook-form/index.js';

const _ROLES = [
  {value: "bo", label: "Business Owner"},
  {value: "tm", label: "Trainer Manager"},
  {value: "ta", label: "Trainer Assessor"},
  {value: "tr", label: "Trainer"}
]
export default function SubscriptionRouteDetails() {
  const { control, setValue, watch, resetField } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'routing',
  });

  const values = watch();


  const handleAdd = () => {
    append({
        subheader: '',
        items: [{
          title: '',
          path: '',
          icon: '',
          role: []
        }]
  
    });
  };

  const handleItem = (index) => {
    append({
        items: [{
          title: '',
          path: '',
          icons: '',
          role: []
        }]
  
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };


  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Routing Details:
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
            <RHFTextField
                name={`routing[${index}].subheader`}
                label="Sub Header"
                InputLabelProps={{ shrink: true }}
              />

              {
                item?.items.map((res, id) => (
                  <>
                  <RHFTextField
                  name={`routing[${index}].items[${id}].title`}
                  label="Routing Name"
                  InputLabelProps={{ shrink: true }}
                />
  
                <RHFTextField
                  name={`routing[${index}].items[${id}].path`}
                  label="Routing URL"
                  InputLabelProps={{ shrink: true }}
                />
  
  
              <RHFTextField
                  name={`routing[${index}].items[${id}].icon`}
                  label="Routing Icon"
                  InputLabelProps={{ shrink: true }}
                />
  
              <RHFMultiSelect
                  checkbox
                  name={`routing[${index}].items[${id}].role`}
                  label="Role"
                  options={_ROLES}
                  sx={{ minWidth: 280 }}
                />
                </>
                ))
              }




            </Stack>
            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              size="small"
              color="success"
              startIcon={<Iconify icon="gg:add" />}
              onClick={() => handleItem(index)}
            >
              Add
            </Button>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => handleRemove(index)}
            >
              Remove
            </Button>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Routing
        </Button>

      </Stack>

    </Box>
  );
}
