import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Box, Pagination, paginationClasses} from '@mui/material';
import { useSettingsContext } from '../../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../components/table/index.js';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import Iconify from '../../../../components/iconify/iconify.js';
import CompanyTableRow from './class-enrolment-table-row.js';
import CompanyTableToolBar from './class-enrolment-table-toolbar.js';
import ClassTraineeDialog from './class-trainee-dialog.js';
import Label from '../../../../components/label/index.js';
import { SSGDate } from '../../../../util/formatTime.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import JobItem from './class-enrolment-table-row.js';


// GRAPHQL
import { GET_ENROLMENT_CLASS_ID, CANCEL_ENROLMENT, REMOVE_TRAINEE_IN_ENROLMENT } from "../../../../graphql/modules/_client/class.js";
 import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'All', label: 'All'},
  {value: 'Confirmed', label: 'Confirmed'},
  {value: 'Cancelled', label: 'Cancelled'}
]
const TABLE_HEAD = [
  {id: 'No', label: 'No #'},
  {id: 'fullName', label: 'Full Name'},
  {id: 'nricFin', label: 'NRIC/FIN/Others'},
  {id: 'email', label: 'Email'},
  {id: 'mobileNo', label: 'Phone No'},
  {id: 'referenceNumber', label: 'Enrolment ID'},
  {id: 'sponsorshipType', label: 'Sponsorship Type'},
  {id: 'grant', label: 'Grants'},
  {id: 'collectionStatus', label: 'Payment Status'},
  {id: 'status', label: 'Enrolment Status'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function ClassCandidates({companyinfo}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const traineeDialog = useBoolean();

  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [traineeInfo, setTraineeInfo] = useState(null);
  const [companyName, setCompanyName] = useState("iatos");
  const [title, setTitle] = useState('Enrol Trainee');
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);

  const [CancelEnrolmentMutation] =  useMutation(CANCEL_ENROLMENT);
  const [RemoveTraineeInEnrolment] =  useMutation(REMOVE_TRAINEE_IN_ENROLMENT);


  const { data, loading, error, refetch } = useQuery(GET_ENROLMENT_CLASS_ID, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyinfo?.info?.databaseName || company,
      order: table.order,
      orderBy: table.orderBy,
      _id: companyinfo?.class?._id
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getEnrolmentClassId)
      }
  }, [data, setSearch])


    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (row) => {
      const {data: { deleteTraineeEnrolment }} = await RemoveTraineeInEnrolment({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.class?._id,
          fullName: fullName,
          traineeID: row?.traineeInfo?._id,
          enrolmentID: row?.enrolmentID
          }
      });
      if(deleteTraineeEnrolment?.success){
        enqueueSnackbar("Delete Successfully!", {variant: "success"});
        refetch();
        }else {
          enqueueSnackbar(deleteTraineeEnrolment?.message, {variant: "error"});
        }
    
});


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        console.log(newValue)
        if(newValue !== "All"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
    setTraineeInfo(row)
    traineeDialog.onTrue();
    setTitle('Update Trainee')
  });

    const handleView = useCallback(
      (row) => {
        navigate('/company-enrolment-session', {state: {class: row, info: companyinfo}})
      },
      []
    );

    const onCancel = useCallback(async(row) => {
      const {traineeInfo, employer, fees, ssgenrolment} = row;
      const {data: { cancelEnrolment }} = await CancelEnrolmentMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.class?._id,
          fullName: fullName,
          referenceNumber: ssgenrolment?.referenceNumber,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
            data: {
                enrolment: {
                  course: { run: {id: state?.class.courseRunId }, referenceNumber: state?.class?.course?.tsgRefNumber},
                  traineeInfo: {
                    _id: traineeInfo?._id,
                    nricFin: traineeInfo?.nricFin,
                    idType: traineeInfo?.idType,
                    fullName: traineeInfo?.fullName,
                    dob: SSGDate(traineeInfo?.dob),
                    email: traineeInfo?.email,
                    mobileNo: traineeInfo?.mobileNo
                  },
                  employer: {
                    uen: employer?.uen,
                    contact: {
                      fullName: employer?.fullName,
                      emailAddress: employer?.emailAddress,
                      contactNumber: {
                        areaCode: employer?.areaCode,
                        countryCode: employer?.countryCode,
                        phoneNumber: employer?.phoneNumber
                      }
                    }
                  },
                  fees: {discountAmount: fees?.discountAmount, collectionStatus: fees?.collectionStatus}
                }
            }
          }
      });

      if(cancelEnrolment?.success){
          enqueueSnackbar("Cancelled successfully!", {variant: "success"});
          refetch();
      }else {
        enqueueSnackbar(cancelEnrolment?.message, {variant: "error"});
      }
    }, []);



const handleEdit = useCallback(
  (id) => {
    console.log(id)
    // router.push(paths.dashboard.job.edit(id));
  },
  []
);

const handleDelete = useCallback((id) => {
  console.info('DELETE', id);
}, []);

  const handleCreateNewClass = useCallback(() => {
    navigate('/company-create-update-classes', {state: {info: companyinfo}})
 
});

const handleCloseDialog = useCallback((id) => {
  traineeDialog.onFalse();
  refetch();
})


  return (
     <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${companyinfo?.class?.course?.courseTitle}`}
          links={[
           {name: 'Dashboard'},
           {name: 'Class'},
           {name: 'Class Enrolment'}
          ]}
          action ={

           <Button
           componet={RouterLink}
           onClick={() =>{
            setTraineeInfo(null);
            traineeDialog.onTrue();
           }}
           variant="contained"
           startIcon={<Iconify icon="mingcure:add-line" />}
           >
             Enrol Trainee
          </Button>
        }
       />

  

        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'All' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'Confirmed' && 'success') ||  (tab.value === 'Completed' && 'info') ||  (tab.value === 'Draft' && 'warning')  ||  (tab.value === 'Cancelled' && 'error') || 'default'
                }
                >
                  {tab.value === 'All' && results?.count}
                  {tab.value === 'Confirmed' && results?.confirmed}
                  {tab.value === 'Completed' && results?.completed}
                  {tab.value === 'Draft' && results?.draft}
                  {tab.value === 'Cancelled' && results?.cancelled}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <CompanyTableToolBar onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
            <Scrollbar>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row, index) => (
                      <CompanyTableRow
                      key={index}
                      indexNo={index}
                      row={row}
                      classinfo={state?.class}
                      onViewRow={() => handleView(row)}
                      onDeleteRow={() => handleDeleteRow(row)}
                      onEditRow={() => handleEditRow(row)}
                      onCancel={() => onCancel(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
              </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          /> */}
        </Card>
        <ClassTraineeDialog 
          title={title}
          open={traineeDialog.value}
          onClose={handleCloseDialog}
          companyInfo={state?.info}
          classinfo={state?.class}
          traineeUp={traineeInfo}
        />

    </Container>
  );
}

ClassCandidates.propTypes = {
  companyinfo: PropTypes.object,
};
