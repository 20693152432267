import { Badge, IconButton, useTheme } from '@mui/material';
import { Stack, Divider, Typography, Drawer, drawerClasses, Tooltip } from '@mui/material';

import { paper } from '../../../theme/css.js';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';

import { useSettingsContext } from '../context/index.js';


import PresetsOptions from './presets-option.js';
import BaseOptions from './base-option.js';
import FullScreenOption from './fullscreen-option.js';
import LayoutOptions from './layout-option.js';

export default function SettingsDrawer(){
    const theme = useTheme();
    const settings = useSettingsContext();

    const labelStyles = {
        mb: 1.5,
        color: 'text.disabled',
        fontWeight: 'fontWeightSemiBold'
    }

    const renderHead = (
        <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{py: 2, pr: 1, pl:2.5}}
        >
            <Typography variant='h6' sx={{ flexGrow: 1}}>Settings</Typography>
            <Tooltip title="Reset">
                <IconButton onClick={settings.onReset}>
                    <Badge color="error" variant="dot" invisible={!settings.canReset}>
                        <Iconify icon="solar:restart-bold" />
                    </Badge>
                </IconButton>

            </Tooltip>
            <IconButton onClick={settings.onClose}>
                <Iconify icon="mingcute:close-line" />
            </IconButton>

        </Stack>
    )

    const renderMode = (
        <div>
            <Typography variant="caption" component="div" sx={{...labelStyles}}>Mode</Typography>

            <BaseOptions
            value={settings.themeMode}
            onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
            options={['light', 'dark']}
            icons={['sun', 'moon']}
            />
        </div>
    )

    const renderContrast = (
        <div>
            <Typography variant="caption" component="div" sx={{...labelStyles}}>Contrast</Typography>

            <BaseOptions
            value={settings.themeContrast}
            onChange={(newValue) => settings.onUpdate('themeContrast', newValue)}
            options={['default', 'bold']}
            icons={['contrast', 'contrast_bold']}
            />
        </div>
    )

    const renderPresets = (
        <div>
            <Typography variant="caption" component="div" sx={{...labelStyles}}>Presets</Typography>

            <PresetsOptions value={settings.themeColorPresets}
            onChange={(newValue) => settings.onUpdate('themeColorPresets', newValue)} />
        </div>
    )

    // const renderDirection = (
    //     <div>
    //     <Typography variant="caption" component="div" sx={{...labelStyles}}>Direction</Typography>

    //     <BaseOptions
    //     value={settings.themeDirection}
    //     onChange={(newValue) => settings.onUpdate('themeDirection', newValue)}
    //     options={['ltr', 'rtl']}
    //     icons={['align_left', 'align_right']}
    //     />
    // </div>
    // )
    // {renderDirection}

    const renderLayout = (
        <div>
            <Typography variant="caption" component="div" sx={{...labelStyles}}>Layout</Typography>

            <LayoutOptions value={settings.themeLayout}
            onChange={(newValue) => settings.onUpdate('themeLayout', newValue)} 
            options={['vertical', 'mini']}
            />
        </div>
    )
    return (
     <Drawer
     anchor="right"
     open={settings.open}
     onClose={settings.onClose}
     slotProps={{
        backdrop: {invisible: true}
     }}
     sx={{
        [`& .${drawerClasses.paper}`]: {
            ...paper({theme, bgColor: theme.palette.background.default}),
            width: 280,
        }
     }}
     >
        {renderHead}
        <Divider sx={{borderStyle: 'dashed'}} />
        <Scrollbar>
            <Stack spacing={3} sx={{ p: 3}}>

                {renderMode}
                {renderContrast}
   
                {renderPresets}
                {renderLayout}
            </Stack>

        </Scrollbar>
        <FullScreenOption />
     </Drawer>   
    )
}