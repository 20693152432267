import { Helmet } from "react-helmet-async";
import { CompanyCourseListView } from "../../sections/company/course/index.js";

export default function CourseTP(){
    return (
        <>
            <Helmet>
                <title>Course Management</title>
            </Helmet>
            <CompanyCourseListView />
        </>
    )
}