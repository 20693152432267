function flattenObject(obj, parent = '', res = {}) {
    for (let key in obj) {
      let propName = parent ? parent + '.' + key : key;
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else if (Array.isArray(obj[key])) {
        obj[key].forEach((item, index) => {
          flattenObject(item, `${propName}[${index}]`, res);
        });
      } else {
        res[propName] = obj[key] === '' ? null : obj[key];
      }
    }
    return res;
  }


  function capitalizeHeader(header) {
    return header.replace(/(?:^|\s|\.|-|\[|\])\S/g, x => x.toUpperCase());
  }



  function convertToCSV(data) {
    // Flatten all objects in the data array
    const flattenedData = data.map(item => flattenObject(item));
    // Get original headers
    const originalHeaders = Object.keys(flattenedData[0]);
    // Capitalize headers
    const capitalizedHeaders = originalHeaders.map(capitalizeHeader);
    const array = [capitalizedHeaders].concat(flattenedData);
  
    return array.map((row, index) => {
      if (index === 0) {
        return row.join(',');
      } else {
        return originalHeaders.map(header => `"${row[header] !== undefined ? row[header] : ''}"`).join(',');
      }
    }).join('\n');
  }
  
  // Function to trigger the download of the CSV file
  function downloadCSV(csvContent, filename) {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  
  // Function to export data as CSV
  function exportDataToCSV(data, filename = 'export.csv') {
    const csvContent = convertToCSV(data);
    downloadCSV(csvContent, filename);
  }

export {
    exportDataToCSV
}