import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useMemo, useState } from 'react';
import { useSettingsContext } from '../../../components/settings/index.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import {Box, Card, Stack, Typography, Container, MenuItem} from '@mui/material';
import { fData } from '../../../util/format-number.js';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {

  RHFTextField,
  RHFUploadAvatar,
  RHFSelect,
  RFHUpload
} from 'src/components/hook-form';

import { UPDATE_COMPANY } from "../../../graphql/modules/company.js";
import { useMutation } from "@apollo/react-hooks";

const Status = [
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'Inactive'}
]


// ----------------------------------------------------------------------

export default function GeneralViewCompany({ state }) {
  // const router = useRouter();
  const {fullName} = useSelector((state) => state?.auth?.user);
  const settings = useSettingsContext();
  const [UpdateCompanyMutation] = useMutation(UPDATE_COMPANY);

   const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    address: Yup.string().required('Address is required'),
    uen: Yup.string().required('UEN is required'),
    contactNo: Yup.string().required('Contact No is required'),
    email: Yup.string().required('Email is required'),
    gst: Yup.string().required('GST  is required'),
    gstNumber: Yup.string().required('GST Number  is required'),
    bankName: Yup.string().required('Bank Name  is required'),
    accountName: Yup.string().required('Account Name  is required'),
    accountNo: Yup.string().required('Account Number  is required'),
    paynowId: Yup.string().required('Paynow Id  is required'),
    status: Yup.string().required('Status  is required'),
    logo: Yup.mixed().nullable().required('Logo is required'),
    encryptionKey: Yup.string().required('Encryption key is required'),
    databaseName: Yup.string().required('Database Name  is required'),
  });

  const defaultValues = useMemo(
    () => ({
      logo: `${process.env.PUBLIC_URL}/logo/${state?.companyName}/${state?.logo}` || null,
      companyName: state?.companyName || '',
      tpFiles: state?.tpFiles || [],
      address: state?.address || '',
      uen: state?.uen || '',
      contactNo: state?.contactNo || '',
      email: state?.email || '',
      gst: state?.gst || '',
      gstNumber: state?.gstNumber || '',
      bankName: state?.bankName || '',
      accountName: state?.accountName || '',
      accountNo: state?.accountNo || '',
      paynowId: state?.paynowId || '',
      termsAndCondition: state?.termsAndCondition || null,
      paymentInstruction: state?.paymentInstruction || '',
      status: state?.status || true,
      encryptionKey: state?.encryptionKey || '',
      databaseName: state?.databaseName || '',
    }),
    [state] 
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setFiles,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  console.log(values)
  const onSubmit = handleSubmit(async (data) => {
    try {
      if(state){
        var dataRes;
        if(data?.logo?.preview || data?.tpFiles){
          dataRes = {
            companyLogo:  data?.logo || null,
            logo: data?.logo?.name || null,
            tpFiles: data?.tpFiles || null,
            companyName: data?.companyName, 
            address: data?.address, 
            uen: data?.uen, 
            contactNo: data?.contactNo,
            email: data?.email,
            gst: data?.gst,
            gstNumber: data?.gstNumber,
            bankName: data?.bankName,
            accountName: data?.accountName,
            accountNo: data?.accountNo,
            paynowId: data?.paynowId,
            termsAndCondition: data?.termsAndCondition,
            paymentInstruction: data?.paymentInstruction,
            databaseName: data?.databaseName,
            status: data?.status,
            certificate: data?.certificate,
            keyCert: data?.keyCert,
            encryptionKey: data?.encryptionKey,
            createdBy: "user",
            dateCreated: new Date(),
            modifiedBy: "user",
            dateModified: new Date(),
          }
        }else {
          dataRes = {
            companyName: data?.companyName, 
            address: data?.address, 
            uen: data?.uen, 
            contactNo: data?.contactNo,
            email: data?.email,
            gst: data?.gst,
            gstNumber: data?.gstNumber,
            bankName: data?.bankName,
            accountName: data?.accountName,
            accountNo: data?.accountNo,
            paynowId: data?.paynowId,
            termsAndCondition: data?.termsAndCondition,
            paymentInstruction: data?.paymentInstruction,
            databaseName: data?.databaseName,
            status: data?.status,
            certificate: data?.certificate,
            keyCert: data?.keyCert,
            encryptionKey: data?.encryptionKey,
            createdBy: "user",
            dateCreated: new Date(),
            modifiedBy: "user",
            dateModified: new Date(),
          }
        }
      const {data: { updateCompany }} = await UpdateCompanyMutation({
        variables: {
          _id: state?._id,
          companyName: "iatos",
          fullName: fullName,
            data: dataRes
          }
      });
  
      if(updateCompany?.message){
        console.log("success")
        reset();
          enqueueSnackbar(state ? 'Update success!' : 'Create success!');
      }else {
        console.log("error")
        enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
      }
    }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('logo', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleDropFiles = useCallback(
    (acceptedFiles) => {
      const files = values.tpFiles || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('tpFiles', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('tpFiles', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue('tpFiles', []);
  }, [setValue]);


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3} sx={{py: 2}}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 17, pb: 5, px: 3 }}>


            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="logo"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

          </Card>
          <Card sx={{ pt: 2, pb: 2, px: 3, mt:1 }}>
            <Box sx={{ mb: 5 }}>
            <Typography variant="h6">Upload Certificate Files</Typography>
            <RFHUpload
                multiple
                name="tpFiles"
                maxSize={3145728}
                onDrop={handleDropFiles}
                onRemove={handleRemoveFile}
                // onRemoveAll={handleRemoveAllFiles}
                // onUpload={() => console.info('ON UPLOAD')}
              />
            </Box>
            </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
               <RHFTextField name="companyName" label="Company Name"/>
              <RHFTextField name="address" label="Address" />
              <RHFTextField name="contactNo" label="Contact No"/>
              <RHFTextField name="email" label="Email Address"/>
              <RHFTextField name="uen" label="UEN"/>
              <RHFTextField name="gstNumber" label="GST Registration No"/>


              <RHFTextField name="gst" label="GST" />
              <RHFTextField name="bankName" label="Bank Name" />
              <RHFTextField name="accountName" label="Bank Account Name" />
              <RHFTextField name="accountNo" label="Account No" />
              <RHFTextField name="paynowId" label="Paynow ID" />
              <RHFSelect
                name="status"
                label="Status"
                InputLabelProps={{ shrink: true }}
                
              >
                {Status?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>

              <RHFTextField multiline rows={4} name="termsAndCondition" label="Terms & Conditions"/>
              
              <RHFTextField multiline rows={4} name="paymentInstruction" label="Payment Instructions" />

              <RHFTextField name="encryptionKey" label="Encryption Key"/>
              <RHFTextField name="databaseName" label="Database Name" />
            </Box>  

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!state ? 'Create Company' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>

        
      </Grid>
    </FormProvider>
    </Container>
  );
}

GeneralViewCompany.propTypes = {
  state: PropTypes.object,
};
