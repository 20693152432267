import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {RouterLink} from '../../routes/components/index.js';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import { useSettingsContext } from '../../components/settings/index.js';
import {Container, styled, Box, Card, Table, Stack, Divider, TableRow, TableHead, TableCell, TableBody, Button, Typography, TableContainer} from '@mui/material';
import { fDate } from '../../util/formatTime.js';
import Iconify from '../../components/iconify/iconify.js';
import { fCurrency } from '../../util/format-number.js';
import Label from '../../components/label/index.js';

import Scrollbar from '../../components/scrollbar/index.js';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    textAlign: 'right',
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function SubscriptionDetails({ invoice }) {
    const { state } = useLocation();
  const [currentStatus, setCurrentStatus] = useState(state?.status);
  const settings = useSettingsContext();

  const handleChangeStatus = useCallback((event) => {
    setCurrentStatus(event.target.value);
  }, []);




  const renderList = (
    <TableContainer sx={{ overflow: 'unset', mt: 5 }}>
      <Scrollbar>
        <Table sx={{ minWidth: 960 }}>
          <TableHead>
            <TableRow>
              <TableCell width={40}>#</TableCell>

              <TableCell sx={{ typography: 'subtitle2' }}>Routing Name</TableCell>

              <TableCell>URL Routing</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Icon</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state?.routing.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>

                <TableCell>
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">{row.subheader}</Typography>
                  </Box>
                </TableCell>
                {row?.items?.map((res) => (
                  <>
                <TableCell>{res?.path}</TableCell>
                <TableCell>{res?.role?.join(', ')}</TableCell>
                <TableCell>{res?.icon}</TableCell>
                  </>
                ))}
     
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
         <CustomBreadcrumbs
         heading="Subscription List"
         links={[
          {name: 'Dashboard'},
          {name: 'Subscription'},
          {name: 'List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          href={'/company-create-update'}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            Update
          </Button>
         }
        />
      <Card sx={{ pt: 5, px: 5 }}>
        
        <Box
          rowGap={5}
          display="grid"
          alignItems="center"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >

          <Stack sx={{ typography: 'body2' }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Subscription
            </Typography>
            <Typography variant="subtitle1">{state?.title}</Typography>
          </Stack>

          <Stack sx={{ typography: 'body2' }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Amount
            </Typography>
            <Typography variant="subtitle1">{fCurrency(state?.amount)}</Typography>
            
          </Stack>

          <Stack sx={{ typography: 'body2' }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Description
            </Typography>
            <Typography variant="subtitle1">{state?.details}</Typography>
          </Stack>
        </Box>

        {renderList}

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

      </Card>
    </Container>
  );
}

SubscriptionDetails.propTypes = {
  invoice: PropTypes.object,
};
