
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "../../../../components/iconify/iconify.js";
import Label from '../../../../components/label/index.js';
import { ConfirmDialog } from '../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { fDate } from '../../../../util/formatTime.js';

export default function CompanyTableRow({row, indexNo, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onCancel, classinfo}){
  const { traineeInfo, ssgenrolment, grant, employer, fees, sponsorshipType, course } =row;
    const popover = usePopover();
    const confirm = useBoolean();
    const cancelled = useBoolean();

 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{indexNo + 1}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.fullName}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.nricFin}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.email}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.mobileNo}</TableCell>
            
            <TableCell sx={{whiteSpace: 'nowrap'}}>{ssgenrolment?.referenceNumber}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{employer && sponsorshipType === 'Employer' ? 'Employer' : course && sponsorshipType === 'Individual'  ? "Individual" : ""  }</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{grant ? "Yes" : "No"}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{fees?.collectionStatus}</TableCell>
            <TableCell>
              <Label
                variant="soft"
                color={
                  (ssgenrolment?.status === 'Confirmed' && 'success') ||
                  (ssgenrolment?.status === 'Completed' && 'info') ||
                  (ssgenrolment?.status === 'Cancelled' && 'error') || 
                  (ssgenrolment?.status === 'Draft' && 'warning') ||
                  'default'
                }
              >
                {ssgenrolment?.status}
              </Label>

        </TableCell>
            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 230}}
    >
        {
          ssgenrolment?.status === 'Confirmed' ?
          <>
          <MenuItem onClick={onEditRow} >
            <Iconify icon="solar:pen-bold" />
            Update
          </MenuItem>

          <MenuItem onClick={() => {
            cancelled.onTrue();
            popover.onClose();
            }} sx={{ color: 'warning.main'}}>
                <Iconify icon="pajamas:cancel" />
                Cancel Enrolment
            </MenuItem>
          </>
          :
          ssgenrolment?.status === 'Cancelled' ?
          <MenuItem>No Action </MenuItem>
          :
          <>

            <MenuItem onClick={onEditRow} >
              <Iconify icon="solar:pen-bold" />
              Update
            </MenuItem>

            <MenuItem onClick={() => {
            confirm.onTrue();
            popover.onClose();
             }} sx={{ color: 'error.main'}}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
        </MenuItem>
          
          </>

        }


            


    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${traineeInfo?.fullName}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

        <ConfirmDialog
        open={cancelled.value}
        onClose={cancelled.onFalse}
        title="Cancel Enrolment"
        content={`Are you sure you want to cancel enrolment to ${traineeInfo?.fullName}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onCancel();
            cancelled.onFalse();
          }}>
            Cancel
          </Button>
        }
      />



</>
 )

}

CompanyTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    classinfo: PropTypes.object,
    onCancel: PropTypes.func,
    indexNo: PropTypes.any
  };
  

// // @mui
// import {Link, Box, Card, Stack, Avatar, Divider, MenuItem, IconButton, Typography, ListItemText} from '@mui/material';
// // utils
// import { fDate } from '../../../util/formatTime.js';
// import { fCurrency } from '../../../util/format-number.js';
// // routes
// // import { paths } from 'src/routes/paths';
// // components
// import Iconify from '../../../components/iconify/index.js';
// import CustomPopover, { usePopover } from '../../../components/custom-popover/index.js';

// // ----------------------------------------------------------------------

// export default function JobItem({ row, onView, onEdit, onDelete }) {
//   const popover = usePopover();

//   const { course, classCode, courseDates, dateCreated, sessions, venue } =row;

//   return (
//     <>
//       <Card>
//       <Stack justifyContent="flex-end" direction="row" spacing={2}>
//       <IconButton onClick={popover.onOpen}>
//           <Iconify icon="eva:more-vertical-fill" />
//         </IconButton>
//     </Stack>
//         <Stack sx={{ p: 3, pb: 2 }}>
//           <Avatar
//             alt={course.courseTitle}
//             src={classCode}
//             variant="rounded"
//             sx={{ width: 48, height: 48, mb: 2 }}
//           />

//           <ListItemText
//             sx={{ mb: 1 }}
//             primary={course?.courseTitle}
//             secondary={`Start Date: ${fDate(courseDates?.start)} - End Date: ${fDate(courseDates?.end)}`}
//             primaryTypographyProps={{
//               typography: 'subtitle1',
//             }}
//             secondaryTypographyProps={{
//               mt: 1,
//               component: 'span',
//               typography: 'caption',
//               color: 'text.disabled',
//             }}
//           />

//           <Stack
//             spacing={0.5}
//             direction="row"
//             alignItems="center"
//             sx={{ color: 'primary.main', typography: 'caption' }}
//           >
//             <Iconify width={16} icon="solar:users-group-rounded-bold" />
//             Trainee
//           </Stack>
//           <Stack
//             spacing={0.5}
//             direction="row"
//             alignItems="center"
//             sx={{ color: 'primary.main', typography: 'caption' }}
//           >
//             <Iconify width={16} icon="mdi:address-marker" />
//             venue: {`${venue?.block} ${venue.street} #${venue?.unit} ${venue?.room} ${venue.building} ${venue.postalCode}`}
//           </Stack>
//         </Stack>

//         <Divider sx={{ borderStyle: 'dashed' }} />

//         <Box rowGap={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" sx={{ p: 3 }}>
//           {[
//             {
//               label: classCode,
//               icon: <Iconify width={16} icon="carbon:skill-level-basic" sx={{ flexShrink: 0 }} />,
//             },
//             {
//                 label: sessions?.map((res) => res?.trainer?.fullName + ","),
//                 icon: <Iconify width={16} icon="solar:user-rounded-bold" sx={{ flexShrink: 0 }} />,
//             },

//           ].map((item) => (
//             <Stack
//               key={item.label}
//               spacing={0.5}
//               flexShrink={0}
//               direction="row"
//               alignItems="center"
//               sx={{ color: 'text.disabled', minWidth: 0 }}
//             >
//               {item.icon}
//               <Typography variant="caption" noWrap>
//                 {item.label}
//               </Typography>
//             </Stack>
//           ))}
//         </Box>
//       </Card>

//       <CustomPopover
//         open={popover.open}
//         onClose={popover.onClose}
//         arrow="left-bottom"
//         sx={{ width: 140 }}
//       >
//         <MenuItem
//           onClick={() => {
//             popover.onClose();
//             onView();
//           }}
//         >
//           <Iconify icon="solar:eye-bold" />
//           View
//         </MenuItem>

//         <MenuItem
//           onClick={() => {
//             popover.onClose();
//             onEdit();
//           }}
//         >
//           <Iconify icon="solar:pen-bold" />
//           Edit
//         </MenuItem>

//         <MenuItem
//           onClick={() => {
//             popover.onClose();
//             onDelete();
//           }}
//           sx={{ color: 'error.main' }}
//         >
//           <Iconify icon="solar:trash-bin-trash-bold" />
//           Delete
//         </MenuItem>
//       </CustomPopover>
//     </>
//   );
// }

// JobItem.propTypes = {
//   row: PropTypes.object,
//   onDelete: PropTypes.func,
//   onEdit: PropTypes.func,
//   onView: PropTypes.func,
// };
