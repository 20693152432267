import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSettingsContext } from '../../components/settings/index.js';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {Box, Card, Stack, Button,Switch, Typography, FormControlLabel, Container, MenuItem} from '@mui/material';
import { fData } from '../../util/format-number.js';
import Iconify from 'src/components/iconify';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { useSnackbar } from '../../components/snackbar/index.js';
import FormProvider, {

  RHFTextField,
  RHFUploadAvatar,
  RHFSelect,
  RHFMultiSelect
} from 'src/components/hook-form';

// GRAPHQL
import { CREATE_USER, UPDATE_USERS } from "../../graphql/modules/users.js";
import { useMutation } from "@apollo/react-hooks";

const Status = [
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'Inactive'}
]
const role = [
  {value: 'superadmin', label: 'Super Admin'},
  {value: 'admin', label: 'Admin'}
]

// ----------------------------------------------------------------------

export default function CreateUpdateUser() {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const { state } = useLocation();
  const settings = useSettingsContext();
  const navigate = useNavigate();
  const isrole = useSelector((state) => state?.auth?.isRole);
  const isuserRole = ['superadmin', 'admin'].some(r=> isrole.includes(r))

  
  const [CreateUserMutation] =  useMutation(CREATE_USER);
  const [UpdateUsersMutation] = useMutation(UPDATE_USERS);
  const { enqueueSnackbar } = useSnackbar();



  const NewUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    mobileNo: Yup.string().required('Mobile No is required'),
    email: Yup.string().required('Email required'),
    status: Yup.string().required('Status is required'),
    userRole: Yup.array().required('Role is required')
  });

  const defaultValues = useMemo(
    () => ({
      logo: state ? `${process.env.PUBLIC_URL}/logo/${state?.logo}` : null,
      fullName: state?.fullName || '',
      userName: state?.userName || '',
      password: state?.password || '',
      mobileNo: state?.mobileNo || '',  
      email: state?.email || '',
      status: state?.status || '',
      userRole: state?.userRole || []
    }),
    [state]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();



  const onSubmit = handleSubmit(async (data) => {
    try {

      if(state){
          var dataRes;
          if(data?.logo.preview){
            dataRes = {
              companyLogo:  data?.logo || null,
              fullName: data?.fullName,
              userName: data?.userName, 
              password: data?.password,
              mobileNo: data?.mobileNo, 
              email: data?.email, 
              status: data?.status,
              userRole: data?.userRole,
              createdBy: "user",
              dateCreated: new Date(),
              modifiedBy: "user",
              dateModified: new Date(),
            }
          }else {
            dataRes = {
              fullName: data?.fullName,
              userName: data?.userName, 
              password: data?.password,
              mobileNo: data?.mobileNo, 
              email: data?.email, 
              status: data?.status,
              userRole: data?.userRole,
              createdBy: "user",
              createdDate: new Date(),
              modifiedBy: "user",
              modifiedDate: new Date(),
            }
          }
        const {data: { updateUsers }} = await UpdateUsersMutation({
          variables: {
            _id: state?._id,
            companyName: "iatos",
            fullName: fullName,
              data: dataRes
            }
        });
    
        if(updateUsers?.message){
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
            navigate('/users-list-view')
        }else {
          console.log("error")
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }else {
        const {data: { createUsers }} = await CreateUserMutation({
          variables: {
            companyName: "iatos",
            fullName: fullName,
              data: {
                fullName: data?.fullName,
                userName: data?.userName, 
                password: data?.password,
                mobileNo: data?.mobileNo, 
                email: data?.email, 
                status: data?.status,
                userRole: data?.userRole,
                createdBy: "user",
                createdDate: new Date(),
                modifiedBy: "user",
                modifiedDate: new Date(),
              }
            }
        });
    
        if(createUsers?.message){
          reset();
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
        }else {
          console.log("error")
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }

    } catch (error) {
      console.log(error);
    }
  });


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('logo', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (

    <FormProvider methods={methods} onSubmit={onSubmit}>
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
         <CustomBreadcrumbs
         heading={isuserRole ? !state ? 'Create Super Admin' : 'Update Super Admin' :  !state ? 'Create User' : 'Update User'}
         links={[
          {name: 'Dashboard'},
          {name: isuserRole ? "Super Admin User" : "User"},
          {name: !state ? 'Create' : 'Update'}
         ]}
        />
      <Grid container spacing={3} sx={{py: 2}}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 17, pb: 5, px: 3 }}>


            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="logo"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>  
          </Card>
 
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="fullName" label="Fullname" />
              <RHFTextField name="userName" label="User Name" />
              <RHFTextField name="password" type="password" label="Password" />
              <RHFTextField name="mobileNo" label="Mobile No" />
              <RHFTextField name="email" label="Email" />
              <RHFMultiSelect
                checkbox
                name="userRole"
                label="User Role"
                options={role}
                sx={{ minWidth: 280 }}
              />
              <RHFSelect
                name="status"
                label="Status"
                InputLabelProps={{ shrink: true }}
              >
                {Status?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>
            </Box>  

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!state ? 'Create User' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>

        
      </Grid>
      </Container>
    </FormProvider>

  );
}