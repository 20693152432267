
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../components/custom-popover/index.js';
import Iconify from "../../../components/iconify/iconify.js";
import Label from '../../../components/label/index.js';
import { ConfirmDialog } from '../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../hooks/use-boolean.js';
import { fDate } from '../../../util/formatTime.js';
import { parseISO, format } from 'date-fns';

export default function CompanyTableRow({row, selected, onSelected, onDeleteRow, onCancelledRow, onEditRow, onViewRow, onCompleted, onRevert, onPrint}){
  const isRole = useSelector((state) => state?.auth?.isRole);
  const { courseRunId, course, classCode, courseDates, sessions, venue, venue_old, status } =row;
  const venueDetails = venue?.block || venue?.postalCode ? `${venue?.block} ${venue?.street} #${venue?.floor}-${venue?.unit} ${venue?.room} ${venue?.building} ${venue?.postalCode}` : venue_old;

  const setTrainer = sessions.map(item => item?.trainer?.fullName)
  const filterTrainer = [...new Set(setTrainer)];

    const popover = usePopover();
    const confirm = useBoolean();
    const cancel = useBoolean();
    const completed = useBoolean();
    const revertBack = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{courseRunId}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{fDate(courseDates.start)}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{classCode}</TableCell>
            <TableCell>{course?.courseTitle}</TableCell>
            <TableCell sx={{width: 320}}>{venueDetails}</TableCell>
            <TableCell>{filterTrainer.join(", ")}</TableCell>
            <TableCell>
              <Label
                variant="soft"
                color={
                  (status.toLowerCase() === 'confirmed' && 'success') ||
                  (status.toLowerCase() === 'completed' && 'info') ||
                  (status.toLowerCase() === 'cancelled' && 'error') || 
                  (status.toLowerCase() === 'draft' && 'warning') ||
                  'default'
                }
              >
                {status}
              </Label>
        </TableCell>
            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 180}}
    >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onViewRow();
          }}
        >
        <Iconify icon="lets-icons:view" />
            View Class
        </MenuItem>

          {
            isRole.some((role)=> ["bo", "tm"].includes(role)) ? 
            <>
            {
              status === 'Draft' ?
              <>
                <MenuItem onClick={onEditRow}>
                <Iconify icon="solar:pen-bold" />
                Update Class
                </MenuItem>

                <MenuItem onClick={() => {
                confirm.onTrue();
                popover.onClose();
                }} sx={{ color: 'error.main'}}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete Class
                </MenuItem>
              </>
    
            :
             status === 'Confirmed' ?
             <>
              <MenuItem onClick={onEditRow}>
              <Iconify icon="solar:pen-bold" />
              Update Class
              </MenuItem>
              <MenuItem onClick={() => {
                cancel.onTrue();
                popover.onClose();
              }} sx={{ color: 'error.main'}}>
                  <Iconify icon="typcn:cancel" />
                  Cancel Class
              </MenuItem>
              <MenuItem onClick={() => {
                completed.onTrue();
                popover.onClose();
              }} sx={{ color: 'success'}}>
                  <Iconify icon="lets-icons:done-ring-round" />
                  Completed Class
              </MenuItem>

              </>
              :
               status === 'Cancelled' ?
               <MenuItem onClick={() => {
                confirm.onTrue();
                popover.onClose();
                }} sx={{ color: 'error.main'}}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                    Delete Class
                </MenuItem>
                :
                status === 'Completed' ?
                <>
                <MenuItem onClick={() => {
                revertBack.onTrue();
                popover.onClose();
                }} sx={{ color: 'error.success'}}>
                    <Iconify icon="grommet-icons:revert" />
                    Revert Status
                </MenuItem>
                </> 
                :
                <></>
            }
             </>
     
            :
            <></>
          }

          {
            isRole.some((role)=> ["bo", "tm"].includes(role)) ? 
            <MenuItem
            onClick={() => {
              popover.onClose();
              onPrint();
            }}
          >
          <Iconify icon="ic:round-print" />
             Print Class
          </MenuItem>
            :
            <></>
          }


            



    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

<ConfirmDialog
        open={cancel.value}
        onClose={cancel.onFalse}
        title="Cancel"
        content={`Are you sure want to cancel ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={onCancelledRow}>
            Cancel
          </Button>
        }
      />

      <ConfirmDialog
        open={completed.value}
        onClose={completed.onFalse}
        title="Completed"
        content={`Are you sure want to update ${classCode} status to completed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onCompleted();
            completed.onFalse();
          }}>
            Yes
          </Button>
        }
      />

<ConfirmDialog
        open={revertBack.value}
        onClose={revertBack.onFalse}
        title="Revert Status"
        content={`Are you sure want to revert the  status to confirmed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onRevert();
            revertBack.onFalse();
          }}>
            Yes
          </Button>
        }
      />

</>
 )

}

CompanyTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onPrint: PropTypes.func
  };
  
