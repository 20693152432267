import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from '../../../components/settings/index.js';
import {Container} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { CompanyUsersListView } from '../users/index.js';



// ----------------------------------------------------------------------

export default function UsersViewCompany() {
  const { state } = useLocation();
  const settings = useSettingsContext();

  // const { enqueueSnackbar } = useSnackbar();




  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3} sx={{py:1}}>
           <CompanyUsersListView companyinfo={state} />
      </Grid>
    </Container>
  );
}

UsersViewCompany.propTypes = {
  currentUser: PropTypes.object,
};
