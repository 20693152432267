import gql from "graphql-tag";

export const GET_COURSE_SESSION = gql`
query GetCourseSession($courseRunId: String!, $uen: String!, $courseReferenceNumber: String!, $sessionId: String, $encryptionkey: String!, $_companyName: String!) {
  getCourseSession(courseRunId: $courseRunId, uen: $uen,  courseReferenceNumber: $courseReferenceNumber, sessionId: $sessionId, encryptionkey: $encryptionkey, _companyName: $_companyName) {
        code
        success
        message
        results        
  }
}`;



export const CREATE_ATTENDANCE = gql`
mutation CreateAttendance($data: AttendanceInput!, $uen: String!, $corppassId: String!, $courseRunId: String!, $encryptionkey: String!, $_companyName: String!) {
    createAttendance(data: $data,  uen: $uen, corppassId: $corppassId, courseRunId: $courseRunId, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
  }
}`;


export const CREATE_E_SIGNATURE = gql`
mutation AddESignature($_id: ID!, $traineeID: ID!, $data: AttendanceSignature!, $fullName: String!, $companyName: String!) {
  addESignature(_id: $_id, traineeID: $traineeID, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

