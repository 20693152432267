import  PropTypes  from "prop-types";
import isEqual from 'lodash/isEqual.js';
import { useEffect, useMemo, useCallback, useState } from "react";
import { useLocalStorage } from '../../../hooks/use-local-storage.js';
import { localStorageGetItem } from '../../../util/storage-available.js';
import { SettingsContext } from "./settings-context.js";


export function SettingsProvider({children ,defaultSettings}){
    const [openDrawer, setOpenDrawer] = useState(false);
    const [settings, setSettings] = useLocalStorage('settings', defaultSettings);

     const isArabic = localStorageGetItem('i18nextLng') === 'ar';

     useEffect(() => {
        if(isArabic){
            onChangeDirectionByLang('ar')
        }
     }, [isArabic]);


    const onUpdate = useCallback((name, value) => {
        setSettings((prevState) => ({
            ...prevState, [name]: value
        }))
    }, [setSettings])


    const onChangeDirectionByLang = useCallback((lang) => {
        onUpdate('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
    }, [onUpdate])

    const onReset = useCallback(() => {
        setSettings(defaultSettings);
    }, [defaultSettings, setSettings]);

    const onToggleDrawer = useCallback(() => {
        setOpenDrawer((prev) => !prev)
    }, []);

    const onCloseDrawer = useCallback(() => {
        setOpenDrawer(false);
    })

    const canReset = !isEqual(settings, defaultSettings);

    const memoizedValue = useMemo(() => ({
        ...settings,
        onUpdate,
        onChangeDirectionByLang,
        canReset,
        onReset,
        open: openDrawer,
        onToggle: onToggleDrawer,
        onClose: onCloseDrawer
    }),
        [
            onReset,
            onUpdate,
            settings,
            canReset,
            openDrawer,
            onCloseDrawer,
            onToggleDrawer,
            onChangeDirectionByLang
        ]
    );

    return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>
}

SettingsProvider.propTypes = {
    children: PropTypes.node,
    defaultSettings: PropTypes.object
}