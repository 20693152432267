import { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Link, Stack, IconButton, InputAdornment, TextField, Typography, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useResponsive } from '../../hooks/useResponsive.js';
import { alpha, useTheme} from '@mui/material/styles/index.js';
import { bgGradient } from '../../theme/css.js';
import { useSnackbar } from '../../components/snackbar/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';
import { AUTH_CREDENTIALS, AUTH_GENERATEOTP } from "../../graphql/modules/auth.js";
import { useMutation } from "@apollo/react-hooks";
import ForgetPassword from './forgetpassword.js';
import ServerMaintenance from './servermaintenance.js';
// components
 import Iconify from '../../components/iconify/index.js';

// ----------------------------------------------------------------------

export function LoginForm() {
  const password = useBoolean();
  const onReset = useBoolean();
  const onServer = useBoolean();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');
  const [showPassword, setShowPassword] = useState(false);
  const { company } = useParams();

  const [state, setState] = useState({
    userName: '',
    password: '',
    companyName: company
  });

  const [LoginMutation] = useMutation(AUTH_CREDENTIALS, {
    variables: {
        userName: state?.userName,
        password: state?.password,
        companyName: state?.companyName
    }
  });


  const [GenerateOtp] = useMutation(AUTH_GENERATEOTP, {
    variables: {
      data: {
        userName: state.userName
      },
      companyName: "iatos"
      
    }
  })
// Announcement in Login
  // useEffect(() => {
  //   onServer.onTrue();
  // }, []);
  
  const handleGenerateOTP = async (e) => {
    e.preventDefault();
    try {

      const { data: { authLogin }, } = await LoginMutation();
      if (authLogin.success) {
        const { data: {  generateOTP }, } = await GenerateOtp();
        if(generateOTP.success){
            navigate('/verifyOtp', { state: { state, authLogin}})
         }else {
          enqueueSnackbar(authLogin?.message, {variant: "error"});
        }
      } else {
        enqueueSnackbar(authLogin?.message, {variant: "error"});
      }

     } catch (error) {
      enqueueSnackbar(error, {variant: "error"});
    }

   
    // try {
    //   const { data: { authLogin }, } = await LoginMutation();
    //   if (authLogin.success) {
    //     dispatch(loginUser(authLogin));
    //   } else {
    //     console.log(authLogin)
    //   }
    // } catch (error) {
    //     console.log(error)
    // }
  };
  

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleresetPass = (e) => {
    onReset.onTrue();
  }
  


  const renderSection = (
    <Stack 
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    spacing={2}
    sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          )
        }),
        maxWidth: '80%'
    }}
    >
            <Typography variant='h5'>Hi, Welcome back</Typography>
                <Box component="div"
                 display="flex"
                 justifyContent="center"
                 alignItems="center"
                sx={{
                          width: '100%',
                          height: '20%',

                      }}>
                <img src='/logo/zing.png' style={{ maxWidth: "100%", height: "100%"}}/>
                </Box>
    
  
    </Stack>

  )


  return (
    <>
      <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh'
      }}
      >
        {upMd && renderSection}
  

                <Stack 
                flexGrow={1}
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{
                    mx: 'auto',
                    maxWidth: 450,
                    px: {xs: 2, md:8},
                    py: {xs:15, md:30},
                    ...bgGradient({
                      color: alpha(
                        theme.palette.background.default,
                        theme.palette.mode === 'dark' ? 0.88 : 0.94
                      )
                    }),
                }}
                >
                    <TextField onChange={handleChange} name="userName" label="Username" fullWidth/>
                    <TextField
                    name="password"
                    label="Password"
                    id="password"
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                               <Iconify icon={showPassword ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    fullWidth
                    />

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <Link variant="subtitle2" onClick={() => handleresetPass()} underline="hover" sx={{cursor: 'pointer'}}>
                    Forgot password?
                    </Link>
                </Stack>

                <LoadingButton onClick={handleGenerateOTP} fullWidth size="large"  variant="contained" >
                    Login
                </LoadingButton>
                </Stack>

                <ForgetPassword
                  open={onReset.value}
                  onClose={onReset.onFalse}
                  title="Reset Password"
                  content={`Please contact your administrator.`}
                  action={
                    <Button variant="contained" color="success" onClick={() => onReset.onFalse()}>
                      Close
                    </Button>
                  }
                />

              <ServerMaintenance
                  open={onServer.value}
                  onClose={onServer.onFalse}
                  title="System Notice"
                  content={`Please utilize the updated QR code for OTP authentication to access the IATOS.`}
                  action={
                    <Button variant="contained" color="warning" onClick={() => onServer.onFalse()}>
                      Close
                    </Button>
                  }
                />


    </Stack>
    </>
  );
}