import PropTypes from 'prop-types';
import { useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
// @mui
import { useLocation } from 'react-router-dom';
import {alpha, IconButton, TextField, Stack, Box, Dialog, Typography, ListItemText, Card, Grid, Switch, FormGroup, FormControlLabel, Divider, MenuItem} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import { SSGDate, fDate } from '../../../../../util/formatTime.js';
import { GRANTS_SEARCH } from "../../../../../graphql/modules/_client/grants.js";
import { useMutation } from "@apollo/react-hooks";





// import ClassEnrolmentToolbar from './class-toolbar.js';


// ----------------------------------------------------------------------

export default function GrantsViewDialog({title, open, onClose, companyInfo, classinfo, traineeUp, enrolmentRef, employer, handleRefetch}) {
    const {fullName} = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const [ssgResponse, setSSGResponse] = useState(null)
    const [traineeInfo, setTraineeInfo] = useState(null)
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const [SearchGrantsMutation] = useMutation(GRANTS_SEARCH);
  console.log(classinfo)

    useEffect(() => {
        if(traineeUp){
          setTraineeInfo(traineeUp)
        }else {
          setTraineeInfo(null)
        }
    }, [traineeUp])



    const handleSearchGrants = async () => {
        if(enrolmentRef){
          const {data: { searchGrants }} = await SearchGrantsMutation({
            variables: {
              encryptionkey: companyProfile?.encryptionKey,
              _companyName: companyProfile?.companyName,
              data: {
                "grants": {
                    "course": {
                      "run": {
                        "id": classinfo?.courseRunId
                      },
                      "referenceNumber": classinfo?.course?.tsgRefNumber
                    },
                    "trainee": {
                      "id": traineeInfo?.nricFin
                    },
                    "employer": {
                      "uen": employer?.uen
                    },
                    "enrolment": {
                      "referenceNumber": enrolmentRef
                    },
                    "trainingPartner": {
                      "uen": companyProfile?.uen,
                      "code": `${companyProfile?.uen}-01`
                    }
                  },
                  "sortBy": {
                    "field": "updatedOn",
                    "order": "asc"
                  },
                  "parameters": {
                    "page": 0,
                    "pageSize": 20
                  }

              }
              }
          });
          if(searchGrants?.success){
              setSSGResponse(searchGrants?.results?.message?.data)
              enqueueSnackbar(searchGrants?.message, {variant: "success"});
              
          }else {
            enqueueSnackbar(searchGrants?.message, {variant: "error"});
          }
        }else {
          enqueueSnackbar("Information not available", {variant: "error"});
        }

    }


    const renderOverview = (
        <>
        <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
        <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant='body1'>Trainee Information</Typography>
          {[
            {
                label: 'NRIC/FIN/Others',
                value: traineeInfo?.nricFin,
                icon: <Iconify icon="solar:user-id-outline" />,
             },
             {
              label: 'ID Type',
              value: traineeInfo?.idType,
              icon: <Iconify icon="la:id-card-alt" />,
           },
            {
              label: 'Full Name',
              value: traineeInfo?.fullName,
              icon: <Iconify icon="ri:user-2-fill" />,
            },
            {
              label: 'Date of Birth',
              value: fDate(traineeInfo?.dob),
              icon: <Iconify icon="solar:calendar-date-bold" />,
            },
            {
              label: 'Email',
              value: traineeInfo?.email,
              icon: <Iconify icon="ic:outline-email" />,
            }
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}

        </Stack>
      
        <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant='body1'>Grants Information</Typography>
              {
                          ssgResponse?.map((res) =>{
                            if(res?.enrolment?.referenceNumber === enrolmentRef){
                              return (
                                <>       
                                 <p>{`Funding Component: ${res?.fundingComponent?.description}`}</p>
                                 <p>{`Funding Scheme: ${res?.fundingScheme?.description}`}</p>
                                 <p>{`Estimated: $${res?.grantAmount?.estimated}`}</p>
                                 <p>{`Paid: $${res?.grantAmount?.paid}`}</p>
                                 <p>{`Recovery: $${res?.grantAmount?.recovery}`}</p>
                                 <p>{`Reference Number: ${res?.referenceNumber}`}</p>
                                 <p>{`Status: ${res?.status}`}</p>
                                </>
  
                              )
                            }
                          })
              }
          {/* {[
            {
                label: 'Enrolment',
                value: classinfo?.course?.courseTitle,
                icon: <Iconify icon="ic:outline-class" />,
             },
             {
              label: 'Grant Amount',
              value: `$ ${classinfo?.course?.courseFee}`,
              icon: <Iconify icon="carbon:task-approved" />,
           },
            {
              label: 'Funding Schema',
              value:ssgResponse?.funding ? `$ ${ssgResponse?.funding?.totalFunding?.amount}` : "",
              icon: <Iconify icon="solar:user-check-linear" />,
            },
            {
              label: 'Reference Number',
              value: ssgResponse?.funding ? `$ ${ssgResponse?.funding?.totalFunding?.amount}` : "",
              icon: <Iconify icon="fluent:feed-20-regular" />,
            },
            {
              label: 'Disbursement Date',
              value: ssgResponse?.funding ? `$ ${ssgResponse?.funding?.nettFee?.amount}` : "",
              icon: <Iconify icon="fluent:payment-16-regular" />,
            },
            {
                label: 'Founding  Component',
                value: ssgResponse?.funding ? `$ ${ssgResponse?.funding?.nettFee?.amount}` : "",
                icon: <Iconify icon="fluent:payment-16-regular" />,
              }

          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))} */}
        </Stack>

        </Box>

        <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
        <IconButton
                    size="small"
                   color="info"
                   sx={{
                    borderRadius: 1,
                       bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                      '&:hover': {
                       bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                      },
                    }}
                    onClick={() => handleSearchGrants()}
                   >
                     <Iconify width={18} icon="gridicons:search" />
                       <Typography variant='subtitle2'> View Grants</Typography>
                  </IconButton>
        </Box>

        </>
      );

  console.log(ssgResponse)

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6">{title}</Typography>
  
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
      <Grid container spacing={2}>

      <Grid item xs={12} md={12}>
        {renderOverview}
      </Grid>

      </Grid>
      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              {/* <LoadingButton variant="contained">Save</LoadingButton> */}
              <LoadingButton variant="contained" onClick={onClose}>Close</LoadingButton>
            </Stack>  
      </Stack>
    

    </Dialog>
  );
}

GrantsViewDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  traineeInfo: PropTypes.object,
  handleRefetch: PropTypes.func
};
