import { createSlice } from "@reduxjs/toolkit";

export const AuthReducer = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: null,
        company: null,
        jwtToken: null,
        user: {},
        attempts: {},
        failedLoginAttempts: {},
        isRole: null,
        companyProfile: null
      },
      reducers: {
        LOGIN_USER: (state, action) => {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.jwtToken = action.payload.token;
            state.user = action.payload.results;
            state.isRole = action.payload.results.userRole;
            state.company = action.payload.company;
            state.companyProfile = action.payload.companyProfile;
        },
        LOGOUT_USER: (state, action) => {
            state.isAuthenticated = false;
            state.jwtToken = null;
            state.user = null;
            state.isRole = null;
            state.companyProfile = null;
        }
      }
      
})

export const { LOGIN_USER, LOGOUT_USER } = AuthReducer.actions

export const loginUser = (payload) => async (dispatch) => {
    await dispatch({
        type: LOGIN_USER,
        payload
    })
} 
export const logoutUsers = (payload) => async (dispatch) => {
    await dispatch({
        type: LOGOUT_USER
    })
} 
