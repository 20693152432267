import { random } from 'lodash';
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Card, Container, Typography, Stack, Divider, IconButton, MenuItem, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';
import { useResponsive } from '../../hooks/useResponsive.js';
import Iconify from 'src/components/iconify/index.js';
import { useSettingsContext } from '../../components/settings/index.js';
import { useSnackbar } from '../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RFHUpload
  } from 'src/components/hook-form';
import Label from '../../components/label/index.js';

import InvoiceNewEditDetails from './invoice-edit-details.js';
import AddressListDialog from './address-list-dialog.js';

import { CREATE_INVOICE, UPDATE_INVOICE } from "../../graphql/modules/invoice.js";
import { useMutation } from "@apollo/react-hooks";


export default function InvoiceCreateUpdate({ currentInvoice }) {
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const [CreateInvoiceMutation] =  useMutation(CREATE_INVOICE);
  const [UpdateInvoiceMutation] =  useMutation(UPDATE_INVOICE);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

    const settings = useSettingsContext();
    const upMd = useResponsive('up', 'md');
    const { state } = useLocation();
    const to = useBoolean();


    const NewInvoiceSchema = Yup.object().shape({
      invoiceTo: Yup.mixed().nullable().required('Invoice to is required'),
      createDate: Yup.mixed().nullable().required('Create date is required'),
      dueDate: Yup.mixed()
        .required('Due date is required')
        .test(
          'date-min',
          'Due date must be later than create date',
          (value, { parent }) => value.getTime() > parent.createDate.getTime()
        ),
      // not required
      taxes: Yup.number(),
      status: Yup.string(),
      discount: Yup.number(),
      shipping: Yup.number(),
      invoiceFrom: Yup.mixed(),
      totalAmount: Yup.number(),
      invoiceNumber: Yup.string(),
    });

    

    const defaultValues = useMemo(
      () => ({
        invoiceNumber: state?.invoiceNumber || `INVNO-${random(10000)}`,
        status: state?.status || 'draft',
        taxes: state?.taxes || 0,
        discount: state?.discount || 0,
        createDate: parseISO(state?.createDate) || new Date(),
        dueDate: parseISO(state?.dueDate) || null,
        invoiceTo: state?.invoiceTo || null,
        items: state?.items || [
          {
            description: '',
            subscription: '',
            quantity: 1,
            price: 0,
            total: 0,
          },
        ],
        totalAmount: state?.totalAmount || 0,
      }),
      [state]
    );
    const methods = useForm({
      resolver: yupResolver(NewInvoiceSchema),
      defaultValues,
    });
  
    const {
      reset,
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onSubmit = handleSubmit(async (data) => {
      try {
      if(state){
    
        const {data: { updateInvoice }} = await UpdateInvoiceMutation({
          variables: {
            companyName: "iatos",
            fullName: fullName,
            _id: state?._id,
              data: {
                invoiceNumber: data?.invoiceNumber,
                status: data?.status,
                taxes: data?.taxes,
                invoiceTo: data?.invoiceTo,
                items: data?.items,
                totalAmount: data?.totalAmount,
                discount: data?.discount,
                createDate: data?.createDate,
                dueDate: data?.dueDate,
                send: 1
              }
            }
        });
    
        if(updateInvoice?.message){
          reset();
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
            navigate(-1);
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }else {
        const {data: { createInvoice }} = await CreateInvoiceMutation({
          variables: {
            companyName: "iatos",
            fullName: fullName,
              data: {
                invoiceNumber: data?.invoiceNumber,
                status: data?.status,
                taxes: data?.taxes,
                invoiceTo: data?.invoiceTo,
                items: data?.items,
                totalAmount: data?.totalAmount,
                discount: data?.discount,
                createDate: data?.createDate,
                dueDate: data?.dueDate,
                read: 0,
                send: 1
              }
            }
        });
    
        if(createInvoice?.message){
          console.log("success")
          reset();
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
            navigate(-1);
        }else {
          console.log("error")
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }

      } catch (error) {
        console.log(error);
      }
 
    });

    const handleSaveAsDraft = handleSubmit(async (data) => {
      try {
        if(state){
      
          const {data: { updateInvoice }} = await UpdateInvoiceMutation({
            variables: {
              companyName: "iatos",
              fullName: fullName,
              _id: state?._id,
                data: {
                  invoiceNumber: data?.invoiceNumber,
                  status: data?.status,
                  taxes: data?.taxes,
                  invoiceTo: data?.invoiceTo,
                  items: data?.items,
                  totalAmount: data?.totalAmount,
                  discount: data?.discount,
                  createDate: data?.createDate,
                  dueDate: data?.dueDate
                }
              }
          });
      
          if(updateInvoice?.message){
            reset();
              enqueueSnackbar(state ? 'Update success!' : 'Create success!');
              navigate(-1);
          }else {
            enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
          }
        }else {
          const {data: { createInvoice }} = await CreateInvoiceMutation({
            variables: {
              companyName: "iatos",
              fullName: fullName,
                data: {
                  invoiceNumber: data?.invoiceNumber,
                  status: data?.status,
                  taxes: data?.taxes,
                  invoiceTo: data?.invoiceTo,
                  items: data?.items,
                  totalAmount: data?.totalAmount,
                  discount: data?.discount,
                  createDate: data?.createDate,
                  dueDate: data?.dueDate,
                  read: 0,
                  send: 0
                }
              }
          });
      
          if(createInvoice?.message){
            console.log("success")
            reset();
              enqueueSnackbar(state ? 'Update success!' : 'Create success!');
              navigate(-1);
          }else {
            console.log("error")
            enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
          }
        }
  
        } catch (error) {
          console.log(error);
        }
    })

    const handleUnsentInvoice = handleSubmit(async (data) => {
      const {data: { updateInvoice }} = await UpdateInvoiceMutation({
        variables: {
          companyName: "iatos",
          fullName: fullName,
          _id: state?._id,
            data: {
              read: 0,
              send: 0
            }
          }
      });
  
      if(updateInvoice?.message){
        reset();
          enqueueSnackbar('Unsent invoice success!');
          navigate(-1);
      }else {
        enqueueSnackbar('Failed to unsent');
      }
    })

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading={state ? "Update Invoice" : "Create a new Invoice" }
                links={[
                {name: 'Dashboard'},
                {name: 'Invoice'},
                {name: 'New Invoice'}
                ]}
                action ={
                  <Label
                  variant="soft"
                  color={
                    (state?.send === 1 && 'success') ||
                    (state?.send === 0  && 'error') || 
                    'error'
                  }
                >
                  {state?.send === 1 ? "Sent" : "Not Sent"}
                </Label>
                 }
            />

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
        
        {/* BILLING ADDRESS */}

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              From:
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{`Zing Technologies Pte Ltd`}</Typography>
            <Typography variant="body2">{companyProfile?.address}</Typography>
            <Typography variant="body2">{`+65 ${companyProfile?.contactNo}`}</Typography>
            <Typography variant="body2">{`${companyProfile?.email}`}</Typography>
          </Stack>
        </Stack>

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              To:
            </Typography>
            <IconButton onClick={to.onTrue}>
              <Iconify icon={'mingcute:add-line'} />
            </IconButton>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{values?.invoiceTo?.companyName}</Typography>
            <Typography variant="body2">{values?.invoiceTo?.address}</Typography>
            <Typography variant="body2">{values?.invoiceTo?.contactNo }</Typography>
          </Stack>
        </Stack>

              <AddressListDialog
              title="Training Provider"
              open={to.value}
              onClose={to.onFalse}
              onSelect={(address) => setValue('invoiceTo', address)}
            />
            </Stack>

              {/* BILLING DATE */}

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
          >
            <RHFTextField
            name="invoiceNumber"
            label="Invoice number"
          />

              <RHFSelect
              fullWidth
              name="status"
              label="Status"
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {['paid', 'pending', 'overdue', 'draft'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </RHFSelect>
            <Controller
              name="createDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date create"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

            <Controller
              name="dueDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Due date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />


  
            </Stack>

            <InvoiceNewEditDetails />
            </Card>

            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
            {
              state ?
                <LoadingButton
                color="inherit"
                size="large"
                variant="outlined"
                onClick={() => handleUnsentInvoice()} 
              >
              Unsent Invoice
             </LoadingButton>
            :
            ""

            }



        <LoadingButton
          color="inherit"
          size="large"
          variant="outlined"
          onClick={() => handleSaveAsDraft()} 
        >
          {state ? 'Update Invoice' : ' Save as Draft'}
         
        </LoadingButton>

        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
        >
          {state ? 'Update' : 'Create'} & Send
        </LoadingButton>
      </Stack>
         </Container>
        </FormProvider>
    )
}