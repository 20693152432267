import * as Yup from 'yup';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { parseISO } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSettingsContext } from '../../../components/settings/index.js';
import { useBoolean } from '../../../hooks/use-boolean.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {Box, Card, Stack, Typography, Container, MenuItem, FormControlLabel, FormGroup, Switch, IconButton, Tooltip, Button, Divider} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { fData } from '../../../util/format-number.js';
import Iconify from 'src/components/iconify/index.js';
import { fTime, LoginId } from '../../../util/formatTime.js';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import { _IDType, Status,  role_bo, role_tm, _nricType} from '../../../util/users.js';
import { _highestQualification } from '../../../util/hqualification.js';
import ESignature from '../../../util/eSignature.jsx';

import FormProvider, {
  RHFTextField,
  RHFUploadAvatar,
  RHFSelect,
  RHFMultiSelect,
  RFHUploadBox,
  RFHAttachedFile
} from 'src/components/hook-form';
// GRAPHQL
import { CREATE_USER, UPDATE_USERS } from "../../../graphql/modules/users.js";
import { useMutation } from "@apollo/react-hooks";

// ----------------------------------------------------------------------

export default function CompanyCreateUpdateUser() {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const { state } = useLocation();
  const [checked, setChecked] = useState(state?.users?.sponsorshipType === 'Employer' ? true : false);
  const settings = useSettingsContext();
  const navigate = useNavigate();
  const eSign = useBoolean();
  const isrole = useSelector((state) => state?.auth?.isRole);
  const isuserRole = ['superadmin', 'bo'].some(r=> isrole.includes(r))
  const [signature, setSignature] = useState(state?.users?.signature || null );
  const [signatureButton, setEsignatureButton] = useState('none');
  const [file, setFile] = useState(null);
  
  const [CreateUserMutation] =  useMutation(CREATE_USER);
   const [UpdateUsersMutation] = useMutation(UPDATE_USERS);

   const { enqueueSnackbar } = useSnackbar();

   const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  const NewUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required').min(8, 'Minimum 8 digits'),
    mobileNo: Yup.string().required('Mobile No is required').min(8, 'Minimum 8 digits'),
    nricType: Yup.string().required('Citizenship is required'),
    countryCode: Yup.string().required('Country code is required').matches(/^[a-zA-Z0-9@]+$/, "Please exclude + from the Country Code"),
    email: Yup.string().required('Email required'),
    status: Yup.string().required('Status is required'),
    userRole: Yup.array().min(1, 'Role is required').required('Role is required'),
    nricFin: Yup.string().required('NRIC / FIN / Others is required'),
    idType: Yup.string().required('ID Type is required'),
    dob: Yup.date().typeError('Invalid Date'),
    // userPhoto: Yup.mixed().required('Userphoto is required'),
    uen: checked ? Yup.string().required('Employer uen is required') : Yup.string(),
    companyName: checked  ? Yup.string().required('Employer name is required') : Yup.string(),
    contactName: checked  ? Yup.string().required('Employer contact name is required') : Yup.string(),
    ecountryCode: checked ? Yup.string().required('Employer country code is required') : Yup.string(),
    phoneNumber: checked ? Yup.string().required('Employer phone number is required') : Yup.string(),
    emailAddress: checked ? Yup.string().required('Employer email address is required') : Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      userPhoto: state?.users?.photo ? `${process.env.PUBLIC_URL}/logo/${company_name}/${state?.users?.photo}` : null,
      fullName: state?.users?.fullName || '',
      userName: state?.users?.userName || '',
      password: state?.users?.password || '00000000',
      mobileNo: state?.users?.mobileNo || '',  
      areaCode: state?.users?.areaCode || '',  
      countryCode: state?.users?.countryCode || '', 
      highestQualification: state?.users?.highestQualification || [],
      email: state?.users?.email || '',
      uen: state?.users?.employer?.uen || '',
      companyName: state?.users?.employer?.companyName || '',
      contactName: state?.users?.employer?.contactName || '',
      emailAddress: state?.users?.employer?.emailAddress || '',
      eareaCode: state?.users?.employer?.areaCode || '',
      ecountryCode: state?.users?.employer?.countryCode || '',
      phoneNumber: state?.users?.employer?.phoneNumber || '',
      occupation: state?.users?.occupation || '',
      status: state?.users?.status || '',
      userRole: state?.users?.userRole || [],
      nricFin: state?.users?.nricFin || '',
      nricType: state?.users?.nricType || '',
      idType: state?.users?.idType || '',
      dob: parseISO(state?.users?.dob) || new Date(),
      titleQualification: '',
      yearAttained: '',
      fileName: '',
      attachedFile: []
    }),
    [state]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const watchAll = watch();
  const watchDOB = watch('dob');
  const nricFinWatchValue = watch('nricFin');
  const userRoleWatchValue = watch('userRole');
  const values = watch();

  useEffect(() => {
    const getDOB = !isNaN(watchDOB) ? LoginId(watchDOB) : ""
    const userNameGeneration = `${watchAll?.fullName?.substring(0,3)}${watchAll?.nricFin?.slice(-4)}${getDOB.slice(0, -4)}`
    setValue('userName', userNameGeneration.replace(/\s+/g, '_'));
  }, [nricFinWatchValue, watchDOB])

  useEffect(() => {
    const isTrainer = ["ta"].some(r=> userRoleWatchValue.includes(r))
    if(isTrainer){
      setEsignatureButton('') 
    }else{
      setSignature(null)
      setEsignatureButton('none')
    }


  }, [userRoleWatchValue])

  const onSubmit = handleSubmit(async (data) => {
    try {
      if(state?.users){
          var dataRes;
          if(data?.logo){
            dataRes = {
              companyLogo:  data?.logo || null,
              fullName: data?.fullName,
              userName: data?.userName, 
              password: data?.password,
              mobileNo: data?.mobileNo, 
              email: data?.email, 
              areaCode: data?.areaCode,
              countryCode: data?.countryCode,
              occupation: data?.occupation,
              sponsorshipType: checked? 'Employer' : 'Individual',
              attachedFile: data?.attachedFile,
              highestQualification: data?.highestQualification,
              employer: {
                uen: data?.uen,
                companyName: data?.companyName,
                contactName: data?.contactName,
                emailAddress: data?.emailAddress,
                areaCode: data?.eareaCode,
                countryCode: data?.ecountryCode,
                phoneNumber: data?.phoneNumber
              },
              status: data?.status,
              userRole: data?.userRole,
              nricFin: data?.nricFin, 
              nricType: data?.nricType, 
              idType: data?.idType,  
              dob: data?.dob,
              signature: signature,
              modifiedBy: fullName,
              dateModified: new Date(),
              company: company_name,
            }
          }else {
            dataRes = {
              fullName: data?.fullName,
              userName: data?.userName, 
              password: data?.password,
              mobileNo: data?.mobileNo,
              areaCode: data?.areaCode,
              countryCode: data?.countryCode,
              sponsorshipType: checked ? 'Employer' : 'Individual',
              occupation: data?.occupation,
              attachedFile: data?.attachedFile,
              highestQualification: data?.highestQualification,
              employer: {
                uen: data?.uen,
                companyName: data?.companyName,
                contactName: data?.contactName,
                emailAddress: data?.emailAddress,
                areaCode: data?.eareaCode,
                countryCode: data?.ecountryCode,
                phoneNumber: data?.phoneNumber
              },
              email: data?.email, 
              status: data?.status,
              userRole: data?.userRole,
              nricFin: data?.nricFin, 
              nricType: data?.nricType, 
              idType: data?.idType,  
              dob: data?.dob,
              signature: signature,
              modifiedBy: fullName,
              modifiedDate: new Date(),
              company: company_name,
            }
          }
        const {data: { updateUsers }} = await UpdateUsersMutation({
          variables: {
            _id: state?.users?._id,
            companyName: state?.info?.databaseName || company, 
            fullName: fullName,
              data: dataRes
            }
        });
    
        if(updateUsers?.success){
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
            navigate(`/${company}/users`)
        }else {
          console.log("error")
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }else {
        const {data: { createUsers }} = await CreateUserMutation({
          variables: {
            companyName: state?.info?.databaseName || company, 
            fullName: fullName,
              data: {
                photo:  data?.userPhoto ? data?.userPhoto?.name : null,
                userPhoto:  data?.userPhoto ? data?.userPhoto  : null,
                fullName: data?.fullName,
                userName: data?.userName, 
                password: data?.password,
                mobileNo: data?.mobileNo, 
                areaCode: data?.areaCode,
                countryCode: data?.countryCode,
                sponsorshipType: checked? 'Employer' : 'Individual',
                email: data?.email, 
                status: data?.status,
                userRole: data?.userRole,
                nricFin: data?.nricFin, 
                nricType: data?.nricType, 
                idType: data?.idType,  
                dob: data?.dob,
                occupation: data?.occupation,
                attachedFile: data?.attachedFile,
                highestQualification: data?.highestQualification,
                employer: {
                  uen: data?.uen,
                  companyName: data?.companyName,
                  contactName: data?.contactName,
                  emailAddress: data?.emailAddress,
                  areaCode: data?.eareaCode,
                  countryCode: data?.ecountryCode,
                  phoneNumber: data?.phoneNumber
                },
                signature: signature,
                createdBy: fullName,
                createdDate: new Date(),
                modifiedBy: fullName,
                modifiedDate: new Date(),
                company: company_name,
              }
            }
        });
    
        if(createUsers?.success){
          reset();
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created', {variant: 'error'});
        }
      }

    } catch (error) {
      console.log(error);
    }
  });


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('userPhoto', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleGoBack = useCallback(() => {
    navigate('/company-view-account', {state: state})
});

const handleAttachedFile = useCallback((acceptedFiles) => {
  const file = acceptedFiles[0];
  if (file) {
    setFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    const newFile =       Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
    setValue('file', newFile, { shouldValidate: true });
  }
}, []);

const { fields, append, remove } = useFieldArray({
  control,
  name: 'highestQualification',
});

const handleHQ = () => {
  append({
    titleQualification: values?.titleQualification,
    yearAttained: values?.yearAttained,
    fileName: file?.name,
  });
  if(file){
    const newArray = [...values?.attachedFile, file];
    setValue('attachedFile', newArray, { shouldValidate: true });
};
  }
  

const handleRemove = (index) => {
  remove(index);
};


  return (

    <FormProvider methods={methods} onSubmit={onSubmit}>
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
         <CustomBreadcrumbs
         heading={state?.users ? 'Update User' : 'Create User'}
         links={[
          {name: 'Dashboard'},
          {name: 'User List'},
          {name: state?.users ? 'Update User' : 'Create User'}
         ]}
        />
      <Grid container spacing={3} sx={{py: 2}}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 5, pb: 2, px: 2 }}>


            <Box sx={{ mb: 2 }}>
              <RHFUploadAvatar
                name="userPhoto"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 1,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>  

            <Stack justifyContent="center" direction="row" spacing={2}>

            {signature
            ? <img src={signature} width="100%" height="100%" />
            : null}
            </Stack>

            <Stack justifyContent="center" direction="row" spacing={2}>

                  <Tooltip title="Add Signature">
                    <IconButton onClick={() => {eSign.onTrue()}} sx={{
                      display: signatureButton
                    }}>
                        <Iconify icon="clarity:digital-signature-line" />
                        <Typography variant='subtitle2'>e-Signature</Typography>
                    </IconButton>
                    </Tooltip>
            </Stack>

          </Card>
 
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="fullName" label="Fullname" />
              <Controller
              name="dob"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date of Birth"
                  format='dd/MM/yyyy'
                  value={field.value}
                  minDate={new Date('1923-01-01')}
                  maxDate={new Date('2025-01-01')}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
              </Box>  

              <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >
              <RHFTextField name="nricFin" label="NRIC / FIN / Others" />
              <RHFSelect
                name="idType"
                label="ID Type"
                InputLabelProps={{ shrink: true }}
              >
                {_IDType?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>
              <RHFSelect
                name="nricType"
                label="Citizenship"
                InputLabelProps={{ shrink: true }}
              >
                {_nricType?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>
              </Box>


   
              <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
            >

              <RHFTextField name="countryCode" label="Country Code" />
              <RHFTextField name="areaCode" label="Area Code" />
              <RHFTextField name="mobileNo" label="Mobile No" />
              </Box>
              <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="email" label="Email" />
              <RHFTextField name="occupation" label="Occupation" />
                
              </Box>
              </Stack>
          </Card>
        </Grid>
        <Grid xs={12} md={12}>

          <Card sx={{ p: 3 }}>
          <Typography variant='title'  sx={{mb: 2}}>User Account</Typography>
          <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              sx={{
                mt: 2
              }}
            >

          </Box>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(4, 1fr)',
              }}
              
            >
              <RHFTextField name="userName" label="User Name"  disabled/>
              <RHFTextField name="password" type="password" label="Password" />
              <RHFMultiSelect
                checkbox
                name="userRole"
                label="User Role"
                options={isuserRole ? role_bo : role_tm }
                sx={{ minWidth: 280 }}
              />
              <RHFSelect
                name="status"
                label="Status"
                InputLabelProps={{ shrink: true }}
              >
                {Status?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>
            </Box>
            </Card>
        </Grid>


        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >
              <FormGroup>
                <FormControlLabel
                    control={<Switch name="sponsorshipType" size="small" checked={checked} onChange={toggleChecked} color='success' 
                    sx={{
                      "&.MuiSwitch-root .MuiSwitch-switchBase": {
                        color: "gray"
                      },
                    
                      "&.MuiSwitch-root .Mui-checked": {
                       color: "green"
                      }
                     }}
                    />}
                    label={`Sponsorship type? ${checked? 'Employer':'Individual'}`}
                />
            </FormGroup>

            </Box>
            {
            checked ?
            <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
            }}
          >
                <RHFTextField name="uen" label="UEN" />
                <RHFTextField name="companyName" label="Company Name" />
                <RHFTextField name="contactName" label="Contact Name"/>
                <RHFTextField name="ecountryCode" label="Country Code"/>
                <RHFTextField name="eareaCode" label="Area Code"/>
                <RHFTextField name="phoneNumber" label="Phone Number" />
                <RHFTextField name="emailAddress" label="Email Address"/>
            </Box>
            :
            ""
        }

          </Card>

        </Grid>

        <Grid xs={12} md={12}>
       
          <Card sx={{ p: 3 }}>
          <Typography variant='title1' sx={{mb: 2}}>Highest Qualification</Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(4, 1fr)',
              }}
              sx={{
                mt: 2
              }}
            >
      
                
                 <RHFSelect
                name="titleQualification"
                label="Highest Qualification"
                InputLabelProps={{ shrink: true }}
              >
                {_highestQualification?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                    {item.label}
                  </MenuItem>
                  )
                })}

              </RHFSelect>

              <RHFTextField name="yearAttained" label="Year Attained"/>

              <RFHAttachedFile
                name="file"
                file={file} 
                onDrop={handleAttachedFile}
                
                // onUpload={() => console.info('ON UPLOAD')}
              />
              <LoadingButton variant="contained" size="small" color="primary" onClick={handleHQ}> <Iconify icon="gg:add" />Add</LoadingButton>

            </Box>

            <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} sx={{mt:3}} spacing={3}>
            {fields?.map((item, index) => (

                    <Box
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                          xs: 'repeat(1, 1fr)',
                          sm: 'repeat(4, 1fr)',
                        }}
                        sx={{
                          mt: 2
                        }}
                      >
                        <Typography variant='body2'>{item?.titleQualification}</Typography>
                        <Typography variant='body2'>{item?.yearAttained}</Typography>
                        <Typography variant='body2'>{item?.fileName}</Typography>
                                                     <Button
                                size="small"
                                color="error"
                                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                onClick={() => handleRemove(index)}
                              >
                                Remove
                            </Button>
                    </Box>
            ))}
            </Stack>




            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
            <LoadingButton variant="contained" loading={isSubmitting} onClick={() => navigate(-1)}>Go Back</LoadingButton>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {state?.users ? 'Save Changes' : 'Create User'}
              </LoadingButton>
            </Stack>
         
            </Card>
        </Grid>



        
      </Grid>

      <ESignature 
      title="e-Signature"
      open={eSign.value} 
      onClose={eSign.onFalse}
      setSignature={setSignature}
  />


      </Container>
    </FormProvider>

  );
}