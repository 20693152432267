import { PropTypes } from "prop-types";
import { Tooltip, Box, ListItemText, Link } from "@mui/material";
import Iconify from "../../iconify/iconify.js";
import { StyledItem, StyledIcon, StyledDotIcon } from "./styles.js";
import { RouterLink } from '../../../routes/components/index.js';


export default function NavItem({item, open, depth, active, config, extenalLink, ...other}){
    const { title, path, icon, info, children, disabled, caption, roles} = item;

    const subItem = depth !==1;
    const renderContent = (
        <StyledItem 
            disableGutters
            disabled={disabled}
            active={active}
            depth={depth}
            config={config}
            {...other}
        >
            <>
            {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}
            {subItem && (
                <StyledItem size={config.iconSize}>
                    <StyledDotIcon active={active} />
                </StyledItem>
            )}
            </>

            {!(config.hiddenLabel && !subItem) && (
                <ListItemText 
                    primary={title}
                    secondary={
                        caption ? (
                            <Tooltip title={caption} placement="top-start">
                            <span>{caption}</span>
                        </Tooltip>
                        ) : null
                    }
                    primaryTypographyProps={{
                        noWrap: true,
                        typography: 'body2',
                        textTransform: 'capitalize',
                        fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium'
                    }}
                    secondaryTypographyProps={{
                        noWrap: true,
                        component: 'span',
                        typography: 'caption',
                        color: 'text.disabled' 
                    }}
                />
            )}


            {info && (
                <Box component="span" sx={{ml: 1, lineHeight: 0}}>
                    {info}
                </Box>
            )}

            {
                !!children && (
                    <Iconify 
                        width={16}
                        icon={open ? 'eva:arrow-ios-dowward-fill' : 'eva:arrow-ios-forward-fill'}
                        sx={{ml: 1, flexShirnk: 0}}
                    />
                )
            }

         </StyledItem>
    );

if(roles && !roles.includes(`${config.currentRole}`)){
    return null
}
// if(externalLink){
//     return (
//         <Link
//         href={path}
//         target={_bloa}
//         ></Link>
//     )
// }

if(children){
    return renderContent
}

return (
    <Link
    component={RouterLink}
     href={path}
     underline="none"
     color="inherit"
     sx={{
        ...(disabled && {
            cursor: 'default'
        })
     }}>
        {renderContent}
     </Link>
)
    
}


NavItem.propTypes = {
    active: PropTypes.bool,
    config: PropTypes.object,
    depth: PropTypes.number,
    item: PropTypes.object,
    open: PropTypes.bool

}