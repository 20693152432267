
import PropTypes from 'prop-types';
import { TableRow, TableCell,  IconButton } from "@mui/material";
import { useSelector } from 'react-redux';
import {SSGDate} from '../../../util/formatTime.js';



export default function UserBulkUploadPreview({
    row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onSelectRow
}){
    // const { fullName, nricFin, mobileNo, email, status, userRole, idType, dob, countryCode, areaCode} = row;
    // const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);

 return (
    <>

               <TableRow hover selected={selected}>
                {
                            row?.map((cell, cellIndex) => (
     
                                <TableCell sx={{whiteSpace: 'nowrap'}} key={cellIndex}>{cell || ''}</TableCell>
                           
                        ))
                }

</TableRow>


</>
 )

}

UserBulkUploadPreview.propTypes = {
    onViewRow: PropTypes.func,
  };
  