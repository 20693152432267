
import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../components/table/index.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../components/snackbar/index.js';
import Iconify from '../../../components/iconify/iconify.js';
import CompanyTableRow from './company-course-table-row.js';
import CompanyTableToolBar from './company-course-table-toolbar.js';
import Label from '../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';


// GRAPHQL
import { GET_COURSE, DELETE_COURSE } from "../../../graphql/modules/_client/course.js";
import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
]
const TABLE_HEAD = [
  {id: 'tsgRefNumber', label: 'TGS Course Reference Number'},
  {id: 'courseTitle', label: 'Course Title'},
  {id: 'modeOfTraining', label: 'Mode of Training'},
  {id: 'mediumInstruction', label: 'Medium of Instruction'},
  {id: 'approvalDate', label: 'Valid From (Date)'},
  {id: 'validUntil', label: 'Valid To (Date)'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function CompanyCourseListView({companyinfo}) {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const company  = useSelector((state) => state?.auth?.company);
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);

  const [DeleteCourseMutation] =  useMutation(DELETE_COURSE);
  const { data, loading, error, refetch } = useQuery(GET_COURSE, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: state?.databaseName || company,
      order: table.order || 'asc',
      orderBy: table.orderBy || 'courseTitle',
      },
      fetchPolicy: "cache-and-network",
    });


    useEffect(() => {
      if(data){
        setResults(data?.getCourse)
      }
  }, [data])

    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
  const handleDeleteRow = useCallback(async (id) => {

    const {data: { deleteCourse }} = await DeleteCourseMutation({
      variables: {
        _id: id,
        companyName: state?.databaseName || company,
        fullName: fullName,
      }
    })

    if(deleteCourse?.success){
      enqueueSnackbar('Deleted success!', {variant: 'success'});
      refetch();
    }else {
      enqueueSnackbar('Failed to delete!', {variant: 'error'});
    }
    },
    [refetch]
  );


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );


  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-course', {state: {info: companyinfo, course: row}})
  });

  const handleCreateNew = useCallback(() => {
    navigate('/company-create-update-course', {state: {info: companyinfo}})
 
});


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading={`${companyinfo?.companyName || company_name} Course`}
         links={[
          {name: 'Dashboard'},
          {name: 'Course List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          onClick={handleCreateNew}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            New Course
          </Button>
         }
        />


        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                >
                  {tab.value === 'all' && results?.count}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <CompanyTableToolBar onFilters={handleFilters} />
        <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <CompanyTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewAccount(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>
          </Scrollbar>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

    </Container>
  );
}

CompanyCourseListView.propTypes = {
  companyinfo: PropTypes.object,
};