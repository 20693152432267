import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {Stack, Button, Badge, Divider, IconButton, Collapse} from '@mui/material';
import { RHFSelect, RHFTextField, RHFRadioGroup } from '../../../components/hook-form/index.js';
import Iconify from '../../../components/iconify/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import AssessmentCriteriaForm from './company-course-assessment-criteria.js'

export default ({ nestIndex, values, control, register, collapse }) => {

  const { fields, remove, append } = useFieldArray({
    control,
    name: `assessmentRecordForm.${nestIndex}.assessmentDetails`
  });

  const handleAddTSC = () => {
    append({
      technicalSkills: values?.technicalSkills,
      // technicalSkills: values?.technicalSkills
    });
  }

const handleRemove = (index) => {
    remove(index)
}

console.log(nestIndex)

  return (
    <Scrollbar>
      <>
      <Collapse
          in={true}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
    <Stack
    flexGrow={1}
     spacing={2}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: 'background.default',
        p: 2
      }}
    >

      {/* {
        <>
            <Typography variant='h6'>{itemContent?.assessmentMethod}</Typography>
           <Divider sx={{ borderStyle: 'dashed' }} />
          <RHFTextField
            name={itemIndex !== null ? `assessmentRecordForm[${itemIndex}].technicalSkills` : "technicalSkills"}
            label="Technical Skills and Competency"
            multiline rows={5}
            value={itemIndex !== null ? values?.assessmentRecordForm[itemIndex]?.technicalSkills : values?.technicalSkills}
            InputLabelProps={{ shrink: true }}  
          />
             
            <RHFTextField
            name={itemIndex !== null ? `assessmentRecordForm[${itemIndex}].assessmentCriteria` : "assessmentCriteria"}
            label="Assessment Criteria"
            value={itemIndex !== null ? values?.assessmentRecordForm[itemIndex]?.assessmentCriteria : values?.assessmentCriteria}
            multiline rows={5}
            InputLabelProps={{ shrink: true }}  
          />
        </>
      } */}

      {
        fields.map((item, index) => {
          return (
            <>
            <Stack
            key={item.id}
            flexGrow={1}
             spacing={2}
             direction="row"
             alignItems="center"
             justifyContent="space-between"
              sx={{
                width: 1,
                minWidth: 0,
                borderRadius: 1.5,
                bgcolor: 'background.default',
                p: 2
              }}
            >
            <RHFTextField
            size="small"
            name={`assessmentRecordForm[${nestIndex}].assessmentDetails.[${index}].technicalSkills`}
            label="Technical Skills and Competency"
            InputLabelProps={{ shrink: true }}
            multiline rows={2}
            defaultValue={item?.technicalSkills}
          />
         <IconButton onClick={() => handleRemove(index)}
                       color='error'> 
                        <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Stack>

        <AssessmentCriteriaForm nestIndex={nestIndex} tscIndex={index} {...{ control, register, values }} />
        </>
          )
        })
      }
  </Stack>
  <Stack
    flexGrow={1}
     spacing={2}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: 'background.default',
        p: 2
      }}
    >
        <Button
        disableRipple
        color="inherit"
        onClick={handleAddTSC}
        endIcon={
          <Badge color="error">
            <Iconify icon="flat-color-icons:plus" />
          </Badge>
        }
        >
        Add Technical Skills & Competency and Assessment Criteria
      </Button>

  </Stack>
  </Collapse>


  </>
  </Scrollbar>
  );
};
