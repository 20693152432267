const _highestQualification = [
    {"value": "No Formal Qualification and Lower Primary", "label": "No Formal Qualification and Lower Primary"},
    {"value": "Primary PSLE", "label": "Primary PSLE"},
    {"value": "Lower Secondary", "label": "Lower Secondary"},
    {"value": "N Level or Equivalent", "label": "N Level or Equivalent"},
    {"value": "O Level or Equivalent", "label": "O Level or Equivalent"},
    {"value": "A Level or Equivalent", "label": "A Level or Equivalent"},
    {"value": "ITE Skills Certification (LSC)", "label": "ITE Skills Certification (LSC)"},
    {"value": "Nitec/Post Nitec", "label": "Nitec/Post Nitec"},
    {"value": "Higher Nitec", "label": "Higher Nitec"},
    {"value": "Master Nitec", "label": "Master Nitec"},
    {"value": "WSQ Certificate", "label": "WSQ Certificate"},
    {"value": "WSQ Higher Certificate", "label": "WSQ Higher Certificate"},
    {"value": "WSQ Advance Certificate", "label": "WSQ Advance Certificate"},
    {"value": "WSQ Diploma", "label": "WSQ Diploma"},
    {"value": "WSQ Specialist Diploma", "label": "WSQ Specialist Diploma"},
    {"value": "WSQ Graduate Certificate", "label": "WSQ Graduate Certificate"},
    {"value": "WSQ Graduate Diploma", "label": "WSQ Graduate Diploma"},
    {"value": "Polytechnic Diploma", "label": "Polytechnic Diploma"},
    {"value": "Professional Qualification And Other Diploma", "label": "Professional Qualification And Other Diploma"},
    {"value": "University First Degree", "label": "University First Degree"},
    {"value": "University Post-Graduate Diploma And Degree/Master/Doctorate", "label": "University Post-Graduate Diploma And Degree/Master/Doctorate"},
    {"value": "Not Reported", "label": "Not Reported"}
]

export {
    _highestQualification,
};