import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate, fDateMonthYear } from '../../util/formatTime.js';
import { fCurrency } from '../../util/format-number.js';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        p1: { paddingTop: 11 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 'bold'},
        body1: { fontSize: 10 },
        body_1: { fontSize: 10},
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMargin_space: {margin: 1},
        textMargin_1: {
          margin: 2,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'right'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultRemark: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function InvoicePDF({
  invoiceDetails, companyProfile
}) {
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const { invoiceNumber, createDate, invoiceTo, items, period, totalAmount, discount, taxes, subscription } = invoiceDetails;

  const rowQty= items?.map((item) => item?.quantity);
  const countQty = sum(rowQty);

  const totalOnRow = items?.map((item) => item?.total);
  const subTotal_less = sum(totalOnRow);
  const subTotal = subTotal_less;
  const tax = (subTotal_less - discount)  * (taxes / 100); 
  const styles = useStyles();

console.log(invoiceDetails)

  return (
 
    <Document>
      <Page size="A4" style={styles.page}>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col4}>
                      <Image source={logo} style={{ width: '100%', height: 60 }} />
                  </View>
                  <View style={styles.col8}>
                    <Text style={styles.h4}>{`Zing Technologies Pte Ltd`}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: +65 ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
   
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.h4, styles.textMargin_space]}>{`Bill To:`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin_space]}>{`Finance Department`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin_space]}>{`${invoiceTo?.companyName}`}</Text>
                <Text style={[styles.subtitle1_1, styles.textMargin_space]}>{`${invoiceTo?.address}`}</Text>
                <Text style={[styles.subtitle1_1, styles.textMargin_space]}>{`${invoiceTo?.email}`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin_space]}>{`${invoiceTo?.contactNo}`}</Text>
                {/* <Text style={[styles.subtitle1, styles.textMargin]}>{`${invoiceTo?.companyName ? 'Attn: ' + invoiceTo?.contact?.fullName : ''}`}</Text> */}
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
              <Text style={[styles.h4, styles.textMargin_space]}>{`Tax Invoice`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin_space]}>{`Invoice Number: ${invoiceNumber}`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin_space]}>{`Date: ${fDate(createDate)} `}</Text>
              </View>
           </View>
           </View>

           <View style={styles.table}>
            <View>
              <View style={styles.tableRow}>
                <View style={[styles.tableCell_4, styles.resultRemark]}>
                  <Text style={[styles.subtitle1, styles.textMargin_2]}>Description</Text>
                </View>
                <View style={[styles.tableCell_2, styles.resultRemark]}>
                  <Text style={[styles.subtitle1, styles.textMargin_1]}>Amount (S$)</Text>
                </View>
              </View>
            </View>

            <View>
    
                    <View style={styles.tableRow}>
                            <View style={styles.tableCell_4}>
                            <Text style={[styles.subtitle1, styles.textMargin]}>{`iATOS Subscription Plan:`}</Text>
                              <>
                              <Text style={[styles.subtitle1, styles.textMargin]}>{`Package Type: ${subscription}`}</Text>
                              <Text style={[styles.subtitle1, styles.textMargin]}>{`Period: ${fDateMonthYear(period)}`}</Text>
                              {
                                items?.map((res, i) => {
                                  const getQty = res?.description === 'Min. account fee applies' ? '' : `- Qty: ${res?.quantity}`
                                  return (
                                    <>
                                    <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description } ${getQty}`}</Text>
                                    </>
                                  )
                                })
                              }
                              {/* {
                                countQty > 10 && subscription === 'Standard'?
                                items?.map((res, i) => {
                                  return (
                                    <>
                                    <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description } - Qty: ${res?.quantity}`}</Text>
                                    </>
                                  )
                                })
                                :
                                countQty < 10 && subscription === 'Standard'?
                                <Text style={[styles.subtitle1, styles.textMargin]}>{`Min. account fee applies`}</Text>
                                :
                                items?.map((res, i) => {
                                  return (
                                    <>
                                    <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description } - Qty: ${res?.quantity}`}</Text>
                                    </>
                                  )
                                })
                              } */}

                               </>
                           </View>
                           <View style={[styles.tableCell_2, styles.resultRemark]}>
                              <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                              <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                              <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                               {items?.map((res, i) => (
                               <Text style={[styles.subtitle1, styles.textMargin_1]}>{fCurrency(res?.total)}</Text>
                               ))}
                                 {/* <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text> */}
                                 {/* <Text style={[styles.subtitle1, styles.textMargin_1]}>{discount !==0 ? `-${fCurrency(discount)}` : ' '}</Text> */}
                           </View>
                     </View>

                     <View style={[styles.table, styles.mb8]}>
                        <View style={[styles.tableRow]}>
                              <View style={[styles.col9, styles.mt1]}>
                                  <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Total:`}</Text>
                                    {/* <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`GST ${taxes}%:`}</Text> */}
                                    <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Balance Payable:`}</Text>
                                  </View>
                                <View style={[styles.colx, styles.mt1]}>
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(subTotal)}`}</Text>
                                      {/* <Text style={[styles.subtitle1, styles.textMargin_1]}>{taxes !==0 ? `${fCurrency(tax)}` : ' '}</Text> */}
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(totalAmount)}`}</Text>
                                </View>
                        </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={styles.col7}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Methods:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(i) By PayNow - UEN ${companyProfile?.uen}`}</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(ii) By Bank Transfer - ${companyProfile?.bankName} A/C  #${companyProfile?.accountNo}. A/C Name ${companyProfile?.accountName}`}</Text>
                            </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                            <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Instructions:</Text>
                            <Text style={[styles.subtitle2, styles.textMargin_3,  styles.textTrans]}>{`${companyProfile?.paymentInstruction}`}</Text>
                            </View>
                    </View>

                    {/* <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Terms & Conditions:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3, styles.textTrans]}>{`${companyProfile?.termsAndCondition}`}</Text>
                            </View>
                    </View> */}
    
               
            </View>
          </View>
  


      </Page>
    </Document>

  );
}

InvoicePDF.propTypes = {
  invoiceDetails: PropTypes.object,
  companyProfile: PropTypes.object
}