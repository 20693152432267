// import PropTypes from 'prop-types';
// import React, { useRef } from 'react';
// import { Stack, Typography } from '@mui/material';
// import {LoadingButton} from '@mui/lab';
// import {Button, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
// import SignatureCanvas from 'react-signature-canvas';

// function SignaturePad({ title, setSignature }) {
//     // Create a ref for the signature canvas
//     const signatureRef = useRef();
  
//     // Function to clear the signature
//     const clearSignature = () => {
//       signatureRef.current.clear();
//     };
  
//     // Function to save the signature data
//     const saveSignature = () => {
//       setSignature(signatureRef.current.toDataURL());
//     };
  
//     return (
//       <div>
//           <Typography variant="h6">{title}</Typography>
//          <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3, border: "solid 1px" }}>
//             <SignatureCanvas
//             penColor="black"
//             canvasProps={{ width: 800, height: 250 }}
//             ref={signatureRef}
//             />
//           </Stack>  

//         <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3 }}>
//               <LoadingButton variant="contained" onClick={clearSignature}>Clear</LoadingButton>
//               <LoadingButton variant="contained" onClick={saveSignature}>Add</LoadingButton>
//         </Stack>  
 
//       </div>
//     );
//   }

//   export default SignaturePad;

//   SignaturePad.propTypes = {
//     title: PropTypes.string,
//     setSignature: PropTypes.func
//   };

import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import {LoadingButton} from '@mui/lab';
// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

// ----------------------------------------------------------------------

export default function ESignature({ title, content, action, open,  onClose, setSignature, ...other }) {
    const signatureRef = useRef();
    const [sign, setSign] = useState(null);
  
    // Function to clear the signature
    const clearSignature = () => {
      signatureRef.current.clear();
    };


  
    // Function to save the signature data
    const saveSignature = () => {
      setSignature(signatureRef.current.toDataURL());
      onClose();
    };

    const onAddSignature = () => {
      setSignature(signatureRef.current.toDataURL());
      setSign(signatureRef.current.toDataURL())
    };


  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}>
        
         <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3, border: "solid 1px" }}>
            <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 400, height: 250 }}
            ref={signatureRef}
            />
          </Stack>  

          <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3, border: "solid 1px", display: sign ? '' : 'none' }}>
          {sign
                ? <img src={sign} width="100%" height="100%" />
                : null}
          </Stack>  

        <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton variant="contained" onClick={clearSignature}>Clear</LoadingButton>
              <LoadingButton variant="contained" onClick={onAddSignature} sx={{display: action ? '' : 'none'}}>Add</LoadingButton>
        </Stack>  
        
        </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={saveSignature} sx={{display: action ? 'none' : ''}}>
          Add
        </Button>

        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ESignature.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  setSignature: PropTypes.func
};
