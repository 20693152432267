import { Helmet } from "react-helmet-async";
import { ClientReport } from "../../sections/company/report/index-view.js";

export default function ReportsTP(){
    return (
        <>
            <Helmet>
                <title>Report Management</title>
            </Helmet>
            <ClientReport />
        </>
    )
}