import SvgColor from '../../components/svgColor/index.js';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


function Navigation() {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const company = useSelector((state) => state?.auth?.company);
  const routingNavigation = useSelector((state) => state?.auth?.companyProfile);
  const compared = routingNavigation?.routing.map((arr1) => arr1?.items.map((arr) => arr?.role.some((item) => isRole.includes(item) )))

  const tpNavigation = routingNavigation?.routing.map((arr1, i) => {
   const arr=  arr1?.items.map((arr) => arr?.role.some((item) => isRole.includes(item)))
    if(arr[0]){
      return (
        {
          subheader: arr1?.subheader,
          items: arr1?.items?.map((item, c) => {
            return (
              {
                title: item?.title,
                path: `/${company}/${item?.path}`,
                icon: icon(item?.icon),
              }
            )
          })
        }
      )
    }

  })
     

  if(isAuthenticated && isRole.some((role)=> ["superadmin", "admin"].includes(role))){
  return (
    [
      {
        subheader: 'Dashboard Overview',
        items: [
          {
            title: 'dashboard',
            path: '/dashboardsuperadmin',
            icon: icon('ic_analytics'),
          }
        ]
      },
      {
        subheader: 'Management',
        items: [
            {
              title: 'Training Provider',
              path: '/company-page-view',
              icon: icon('ic_company'),
            },
            {
              title: 'invoice',
              path: '/invoice-page-view',
              icon: icon('ic_invoice'),
            },
            {
              title: 'subscription',
              path: '/subscription-page-view',
              icon: icon('ic_subscription'),
            },
            {
                title: 'Super Admin Users',
                path: '/users-list-view',
                icon: icon('ic_users'),
            },
            {
                title: 'Activity Logs',
                path: '/auditlog-list-view',
                icon: icon('ic_useractivity'),
            },
        ]
      }
    ]
  );
  }else {

   return (
    tpNavigation && tpNavigation?.filter(value => value !== undefined)
   )

  }
}


export default Navigation;