import gql from "graphql-tag";

export const GET_ALL_USERS= gql`
query GetUsers($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $filter: String, $order: String, $orderBy: String) {
    getUsers(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText,  filter: $filter, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        activeCount
        inactiveCount
        results
  }
}`;

export const GET_TRAINERASSESSOR= gql`
query GetTrainerAssessor($companyName: String!) {
  getTrainerAssessor(companyName: $companyName) {
        code
        success
        message
        count
        results
  }
}`;

export const GET_TRAINEE= gql`
query GetTrainee($companyName: String!, $searchText: String,) {
  getTrainee(companyName: $companyName, searchText: $searchText,) {
        code
        success
        message
        count
        results
  }
}`;





export const CREATE_USER= gql`
mutation CreateUsers($data: UserInput!, $fullName: String!, $companyName: String!) {
    createUsers(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_USERS = gql`
mutation DeleteUsers($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteUsers(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;


export const UPDATE_USERS = gql`
mutation UpdateUsers($_id: ID!, $data: UserInput!,  $fullName: String!, $companyName: String!) {
  updateUsers( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPLOAD_USERS = gql`
mutation UploadUsers($companyName: String!, $data: JSONObject, ) {
  uploadUsers(companyName: $companyName, data: $data) {
    code
    success
    message
  }
}`;




// export const DELETE_USERS= gql`
// mutation DeleteSubscription($_id: ID!, $companyName: String!) {
//   deleteSubscription(_id: $_id, companyName: $companyName) {
//     code
//     success
//     message
//   }
// }`;