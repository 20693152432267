
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "src/components/iconify/iconify.js";
import { ConfirmDialog } from '../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { parseISO, format } from 'date-fns';
import { fCurrency } from '../../../../util/format-number.js';

export default function InvoiceTableRow({
    row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onSelectRow, onReceipt
}){
    const { invoiceNumber, courseInfo, discount, totalGrants,  createDate, totalAmount, status, invoiceToInfo, receiptNo} = row;
    const popover = usePopover();
    const confirm = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{invoiceNumber}</TableCell>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{courseInfo?.courseRun}</TableCell>
             <TableCell>{courseInfo?.courseTitle}</TableCell>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{invoiceToInfo?.empcompanyName || invoiceToInfo?.fullName }</TableCell>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{fCurrency(discount)}</TableCell>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{fCurrency(totalGrants)}</TableCell>
             <TableCell sx={{whiteSpace: 'nowrap'}}>{fCurrency(totalAmount)}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{format(parseISO(createDate), "dd/MM/yyyy")}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{status}</TableCell>

            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>

    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 180}}
    >
        <MenuItem onClick={onViewRow}>
            <Iconify icon="ic:round-print" />
            Print Invoice
        </MenuItem>
        <MenuItem onClick={onEditRow}>
            <Iconify icon="solar:pen-bold" />
            Update Invoice
        </MenuItem>

        <MenuItem onClick={onReceipt}>
            <Iconify icon={receiptNo ? 'solar:document-add-bold-duotone' : 'solar:clipboard-add-line-duotone'} />
            {receiptNo ? 'Update Receipt' : 'Create Receipt'}
        </MenuItem>

        <MenuItem onClick={() => {
            confirm.onTrue();
            popover.onClose();
         }} sx={{ color: 'error.main'}}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete Invoice
        </MenuItem>
            


    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${invoiceNumber}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

</>
 )

}

InvoiceTableRow.propTypes = {
    onViewRow: PropTypes.func,
  };
  