import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { Stack, MenuItem, Checkbox, Typography, TextField, Tooltip, InputLabel, FormControl, OutlinedInput, InputAdornment, Select, Button } from '@mui/material';
import Iconify from '../../../components/iconify/index.js';
import { role} from '../../../util/users.js';

export default function CompanyTableToolBar({
    filters,
    onFilters,
    subsOptions,
    setFilterChange,
    filterRole,
    handleExport
}){
    // const popover = usePopover();
    
    const handleFilterName = useCallback((event) => {
        onFilters('name', event.target.value)
    }, [onFilters])

    const handleFilterSubscription = useCallback((event) =>{
        onFilters('subscription', typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
    },[onFilters])

    const handleChange = useCallback((event) => {
        setFilterChange(event.target.value)
    })

    return (
        <>
            <Stack
                spacing={2}
                alignItems={{xs: 'flex-end', md: 'center'}}
                direction={{
                    xs: 'column',
                    md: 'row'
                }}
                sx={{
                    p: 2.5,
                    pr: {xs: 2.5, md: 1}
                }}
            >


                <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1}} >

                <FormControl sx={{
                    width: 320
                }}>
                    <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="User Role"
                        onChange={handleChange}
                        value={filterRole}
    
                    >   <MenuItem key="All" value="All" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>All</MenuItem>
                        {role?.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                {item.label}
                            </MenuItem>
                            )
                            })}
                    </Select>
                    </FormControl>
    

                    <TextField
                    fullWidth
                    value={filters?.name}
                    onChange={handleFilterName}
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}} />
                            </InputAdornment>
                        )
                    }}
                    />

                    <Tooltip title="Export Users">
                    <Button onClick={handleExport}>
                        <Iconify icon="solar:export-bold-duotone" />
                        <Typography variant='subtitle2'>Export Users</Typography>
                    </Button>
                    </Tooltip>
            
                </Stack>


            </Stack>
        </>
    )
}

CompanyTableToolBar.propTypes = {
    filters: PropTypes.object,
    onFilters: PropTypes.func,
    subsOptions: PropTypes.array,
    setFilterChange: PropTypes.func,
    handleExport: PropTypes.func
}