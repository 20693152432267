
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../components/custom-popover/index.js';
import Iconify from "src/components/iconify/iconify.js";
import { ConfirmDialog } from '../../components/custom-dialog/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';

export default function UsersTableRow({
    row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onSelectRow
}){
    const { fullName, userName, mobileNo, email, status, userRole} = row;
    const popover = usePopover();
    const confirm = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{ display: 'flex', alignItems: 'center'}}>
                <Avatar alt={fullName} sx={{mr: 2}} />
                <ListItemText 
                    primary={fullName}
                    secondary={email}
                    primaryTypographyProps={{ typography: 'body2'}}
                    secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled'
                    }}
                />
            </TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{userName}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{mobileNo}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap', textTransform: 'uppercase'}}>{userRole.join(', ')}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap', textTransform: 'capitalize'}}>{status}</TableCell>
            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 180}}
    >

        <MenuItem onClick={onEditRow}>
            <Iconify icon="solar:pen-bold" />
            Update
        </MenuItem>

        <MenuItem onClick={() => {
            confirm.onTrue();
            popover.onClose();
         }} sx={{ color: 'error.main'}}>
            <Iconify icon="solar:trash-bin-trash-bold" />
            Delete
        </MenuItem>
            


    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${fullName}?`}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />

</>
 )

}

UsersTableRow.propTypes = {
    onViewRow: PropTypes.func,
  };
  