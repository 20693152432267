import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { addYears, format, parseISO } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { useSelector } from 'react-redux';
import { useSettingsContext } from '../../../components/settings/index.js';
import {Box, Card, Stack, Typography, Container, Paper, Button, Divider, Icon, MenuItem, TextField} from '@mui/material';
import { fData } from '../../../util/format-number.js';
import Iconify from '../../../components/iconify/index.js';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import Label from '../../../components/label/index.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import { fDate } from '../../../util/formatTime.js';
// ----------------------------------------------------------------------
import { GET_ALL_SUBSCRIPTION } from "../../../graphql/modules/subscription.js";
import { GET_CLIENT_SUBSCRIPTION, SET_SUBSCRIPTION } from "../../../graphql/modules/_client/subscription.js";
import { UPDATE_COMPANY } from "../../../graphql/modules/company.js";
import { useQuery, useMutation } from "@apollo/react-hooks";


const subsYear = [
  {value: 1, name: "1year"},
  {value: 2, name: "2years"},
  {value: 3, name: "3years"},
  {value: 4, name: "4years"},
  {value: 5, name: "5years"},
  {value: 6, name: "6years"},
  {value: 7, name: "7years"},
  {value: 8, name: "8years"},
  {value: 9, name: "9years"},
  {value: 10, name: "10years"}
]

export default function BillingViewCompany(state) {
  // const router = useRouter();
  const {fullName} = useSelector((state) => state?.auth?.user);
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const [results, setResults] = useState(null);
  const [subscriptionYear, setSubscriptionyear] = useState(null);
  const [subscriptionDate, setSubscriptionDate] = useState('');
  const [expDate, setExpDate] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [clientPlan, setClientPlan] = useState(null)
  const [choosed, setUpdatePlan] = useState(null)
  const [SetClientSubscription] =  useMutation(SET_SUBSCRIPTION);
  const [UpdateCompanyMutation] = useMutation(UPDATE_COMPANY);

  const { data: client_subscription, refetch: selectedRefresh } = useQuery(GET_CLIENT_SUBSCRIPTION, {variables: {companyName: state?.state?.databaseName}})
  const { data, loading, error, refetch } = useQuery(GET_ALL_SUBSCRIPTION, {
    variables: {
      limit: 10,
      offset: 0,
      searchText: "",
      companyName: "iatos",
      order: "asc",
      orderBy: "_id"
      },
      fetchPolicy: "cache-and-network",
    });

    
    const handleSelectPlan = useCallback(
      (newValue, subscription) => {
        console.log(subscription)
        setSelectedPlan(newValue);
        setUpdatePlan(subscription)
      },
      [selectedPlan]
    );

  useEffect(() => {
    if(data){
      setResults(data?.getAllSubscription)
    }
}, [data])

useEffect(() => {
  if(client_subscription){
    setClientPlan(client_subscription?.getClientSubscription?.results[0])
  }
}, [client_subscription])

const handleUpgradePlan = async() => {
  const expirationDate = subscriptionDate ? addYears(subscriptionDate, subscriptionYear) : clientPlan?.subscriptionExp;
  const {data: { createClientSubscription }} = await SetClientSubscription({
    variables: {
      companyName: state?.state?.databaseName,
      fullName: fullName,
        data: {
          title: choosed?.title || clientPlan?.title,
          amount: choosed?.amount || clientPlan?.amount,
          details: choosed?.details || clientPlan?.details,
          routing: choosed?.routing || clientPlan?.routing,
          companyProfile: state?.state,
          subscriptionYear: subscriptionYear || clientPlan?.subscriptionYear,
          subscriptionDate: subscriptionDate || clientPlan?.subscriptionDate,
          subscriptionExp: expirationDate,
          dateCreated: new Date(),
          dateModified: new Date()
        }
      }
    })
    await UpdateCompanyMutation({
      variables: {
        _id: state?.state?._id,
        companyName: "iatos",
        fullName: fullName,
          data: {
            subscription: choosed?.title || clientPlan?.title,
            amount: choosed?.amount || clientPlan?.amount,
            subscriptionExp: expirationDate,
          }
        }
    });

    if(createClientSubscription?.message){
        enqueueSnackbar('Upgrade success', {variant: "success"});
        selectedRefresh();
    }else {
      enqueueSnackbar('Failed to upgrade', {variant: "error"});
    }
  }
  const handleCancelPlan = async() => {
    const {data: { createClientSubscription }} = await SetClientSubscription({
      variables: {
        companyName: state?.state?.databaseName,
        fullName: fullName,
          data: {
            title: 'Cancelled',
            amount: choosed?.amount,
            details: choosed?.details,
            routing: choosed?.routing,
            dateCreated: new Date(),
            dateModified: new Date()
          }
        }
      })
  
      if(createClientSubscription?.message){
          enqueueSnackbar('Successfully cancelled', {variant: "success"});
          selectedRefresh();
      }else {
        enqueueSnackbar('Failed to upgrade', {variant: "error"});
      }
    }

    const handleChange = useCallback((event) => {
      setSubscriptionyear(event.target.value);
    }, []);

  
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3} sx={{py: 2}}>
        <Grid xs={12} md={8}>
          <Card sx={{ pt: 2, pb: 5, px: 3 }}>
              <Typography variant="h5">Plan</Typography>
              <Grid container spacing={2} sx={{ p: 3 }}>

              {results?.results?.map((res) => {
                return (
                  <Grid xs={12} md={4} key={res._id}>
                  <Stack
                    component={Paper}
                    variant="outlined"
                    onClick={() => handleSelectPlan(res.title, res)}
                    sx={{
                      p: 2.5,
                      position: 'relative',
                      cursor: 'pointer',
                      ...(res.title === selectedPlan && {
                        boxShadow: (theme) => `0 0 0 2px ${theme.palette.text.primary}`,
                      }),
                    }}
                  >
                     {res?.title === clientPlan?.title
                      ?
                        <Label
                        color="info"
                        startIcon={<Iconify icon="eva:star-fill" />}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          Current
                        </Label>
                      :
                      ""
                     }


                  <Box sx={{ width: 48, height: 48 }}>
                    {res.title === 'Enhanced' && <Iconify icon="tabler:stack" width={24} />}
                    {res.title === 'Standard' &&       <Iconify icon="tabler:stack-2" width={32} />}
                    {res.title === 'Advanced' && <Iconify icon="tabler:stack-3" width={24} />}
                    {res.title === 'Customized' && <Iconify icon="tabler:stack-pop" width={24} />}
                  </Box>
                <Box
                    sx={{
                      typography: 'subtitle2',
                      mt: 2,
                      mb: 0.5,
                      textTransform: 'capitalize',
                    }}
                  >
                    {res.title}
                  </Box>

                  <Stack direction="row" alignItems="center" sx={{ typography: 'h4' }}>
                    {res.amount || 'Free'}

                    {!!res.amount && (
                      <Box component="span" sx={{ typography: 'body2', color: 'text.disabled', ml: 0.5 }}>
                        {res.title === "Advanced" ? "/year" : "/month"}
                      </Box>
                    )}
                  </Stack>
                  </Stack>
                  </Grid>
                )
              })}
              </Grid>
              {/* BILLING DETIALS */}
              <Stack spacing={2} sx={{ p: 3, pt: 0, typography: 'body2' }}>
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                  <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                  <DatePicker
                      name="subscriptionDate"
                      label="Subscription Start Date"
                      format='dd/MM/yyyy'
                      value={parseISO(clientPlan?.subscriptionDate)}
                      onChange={(newValue) => {
                        setSubscriptionDate(newValue);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                </Grid>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                <TextField
                  select
                  fullWidth
                  label="Select"
                  value={parseInt(clientPlan?.subscriptionYear)}
                  onChange={handleChange}
                >
                {subsYear.map((result) => {
                      return (
                        <MenuItem key={result.value}  value={result.value}>
                          {result.name}
                        </MenuItem>
                      )
                    })}
                </TextField>
      
              </Grid>
              </Grid>

                <Grid container spacing={{ xs: 0.5, md: 2 }}>
                  <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                    Plan
                  </Grid>
                  <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize'  }}>
                    {selectedPlan || clientPlan?.title}
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0.5, md: 2 }}>
                  <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                    Expiration Date
                  </Grid>
                  <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize'  }}>
                    {fDate(clientPlan?.subscriptionExp)}
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                  Billing name
                </Grid>
                <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                    {state?.state.companyName }
                  </Grid>
              </Grid>
              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                  Contact Number
                </Grid>
                <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                    {state?.state.contactNo }
                  </Grid>
              </Grid>

              <Grid container spacing={{ xs: 0.5, md: 2 }}>
                <Grid xs={12} md={4} sx={{ color: 'text.secondary' }}>
                  Billing Address
                </Grid>
                <Grid xs={12} md={8} sx={{ typography: 'subtitle2', textTransform: 'capitalize' }}>
                    {state?.state.address }
                  </Grid>
              </Grid>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack spacing={1.5} direction="row" justifyContent="flex-end" sx={{ p: 3 }}>
                  <Button variant="outlined" color="error" onClick={() => handleCancelPlan()}>Cancel Subscription</Button>
                  <Button variant="contained" color="info" onClick={() => handleUpgradePlan()}>Upgrade Subscription</Button>
                </Stack>
          </Card>
        </Grid>

        <Grid xs={12} md={4}>
          <Card sx={{ pt: 2, pb: 5, px: 3 }}>
               <Typography variant="h6">Invoice History</Typography>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

BillingViewCompany.propTypes = {
  state: PropTypes.object,
};
