import { Helmet } from "react-helmet-async";
 import  {AttendanceListViewReport}  from "../../../sections/company/report/attendanceReport/index.js";

export default function AttendanceListViewReportPage(){
    return (
        <>
            <Helmet>
                <title>Attendance List Report Page</title>
            </Helmet>
            <AttendanceListViewReport />
        </>
    )
}