import PropTypes from 'prop-types';
import { useState, useEffect, useCallback} from 'react';
// @mui
import  {LoadingButton} from '@mui/lab';
import { useSelector } from 'react-redux';
import {ListItemButton, ListItemText, Tooltip, MenuItem, IconButton, Stack, Avatar, TextField, InputAdornment} from '@mui/material';
// routes
// components
import Iconify from '../../../../components/iconify/index.js';
// import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
import { GET_TRAINEE } from "../../../../graphql/modules/users.js";
import { useMutation, useQuery } from "@apollo/react-hooks";
import RHFTextField from 'src/components/hook-form/rhf-text-field.js';


export default function ClassEnrolmentToolbar({state, handleSet, isUpdate}) {
  const company = useSelector((state) => state?.auth?.company);
  const [results, setResults] = useState(null);
  const [searchText, setSearch] = useState("");
  const [traineeInfo, setTraineeInfo] = useState(null);
  const traineeDialog = useBoolean();

  const { data, loading, error, refetch } = useQuery(  GET_TRAINEE, {
    variables: {
      companyName: state?.databaseName || company,
      searchText: searchText
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getTrainee)
      }
  }, [data])

  const handleOpenDialog = (res) => {
    handleSet(res)
  }

  const handleSearchAddress = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const renderItem = (
    <>
    {results?.results?.map((res, index) => (
          <ListItemButton
          onClick={() => handleOpenDialog(res)}
          sx={{
            mb: 0.5,
            borderRadius: 1,
          }}
          disabled={isUpdate}
        >
           <Avatar alt={res?.fullName} sx={{mr: 2}} sizes={25} />
                <ListItemText
                
                primary={res.fullName}
                secondary={res.nricFin}
                primaryTypographyProps={{
                  typography: 'caption',
                  color: 'primary',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  component: 'span',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
          </ListItemButton>
    ))}
    </>
  )
  const renderList = (
    <Stack
    direction="row" 
    justifyContent="space-between"
    sx={{
      borderRadius: 1.5,
      width: 320,
      bgcolor: 'background.default'
    }}
  >
     <Stack sx={{ p: 2, flexGrow: 1}}>
     <TextField
        value={searchText}
        onChange={handleSearchAddress}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />


    <Scrollbar sx={{ p: 2, flexGrow: 1}}>
            {renderItem}
    </Scrollbar>

    
     </Stack>
  </Stack>
  );


  return (
    <Stack
    spacing={1}
    direction="row"
    flexGrow={1}
    sx={{
      height: {
        xs: '62vh',
      },

    }}
  >
      {renderList}
     
    </Stack>
  );
}

ClassEnrolmentToolbar.propTypes = {
  state: PropTypes.object,
  handleSet: PropTypes.object,
  isUpdate: PropTypes.bool
};
