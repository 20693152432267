
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../../components/custom-popover/index.js';
import Iconify from "../../../../../components/iconify/iconify.js";
import Label from '../../../../../components/label/index.js';
import { ConfirmDialog } from '../../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../../hooks/use-boolean.js';
import { fDate } from '../../../../../util/formatTime.js';
import GrantsViewDialog  from './grants-dialog.js';

export default function GrantsTableRow({row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onCancel, classinfo}){
  const { traineeInfo, ssgenrolment, grant, employer, fees } =row;
    const popover = usePopover();
    const grants = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.fullName}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.nricFin}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.email}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.mobileNo}</TableCell>
            <TableCell>
              <Label
                variant="soft"
                color={
                  (ssgenrolment?.status === 'Confirmed' && 'success') ||
                  (ssgenrolment?.status === 'Completed' && 'info') ||
                  (ssgenrolment?.status === 'Cancelled' && 'error') || 
                  (ssgenrolment?.status === 'Draft' && 'warning') ||
                  'default'
                }
              >
                {ssgenrolment?.status}
              </Label>

        </TableCell>
            
        <TableCell sx={{whiteSpace: 'nowrap'}}>{ssgenrolment?.referenceNumber}</TableCell>
            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 230}}
    >

            <MenuItem onClick={() => {
                 grants.onTrue();
                 popover.onClose();
            }} >
            <Iconify icon="solar:pen-bold" />
            view
          </MenuItem>

    </CustomPopover>

    <GrantsViewDialog
        open={grants.value}
        onClose={grants.onFalse}
        title="View Grants"
        traineeUp={traineeInfo}
        enrolmentRef={ssgenrolment?.referenceNumber}
        employer={employer}
        classinfo={classinfo}
      />




</>
 )

}

GrantsTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    classinfo: PropTypes.object,
    onCancel: PropTypes.func
  };
  