import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from '../../../components/settings/index.js';
import {Typography, Container} from '@mui/material';
import { fData } from '../../../util/format-number.js';
import Iconify from 'src/components/iconify';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import Label from '../../../components/label/index.js';
import {CompanyCourseListView} from '../course/index.js';

// ----------------------------------------------------------------------

export default function CoursesViewCompany() {
  const { state } = useLocation();
  const settings = useSettingsContext();

  // const { enqueueSnackbar } = useSnackbar();




  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3} sx={{py: 2}}>
      <CompanyCourseListView companyinfo={state}/>
      </Grid>
    </Container>
  );
}

CoursesViewCompany.propTypes = {
  currentUser: PropTypes.object,
};
