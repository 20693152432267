import { Helmet } from "react-helmet-async";
 import { ViewCompanyAccount } from "../../sections/company/index.js";

export default function ViewCompanyAccountPage(){
    return (
        <>
            <Helmet>
                <title>View Account Page</title>
            </Helmet>
            <ViewCompanyAccount />
        </>
    )
}