import gql from "graphql-tag";

export const GET_COURSE = gql`
query GetCourse($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getCourse(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;

export const CREATE_COURSE = gql`
mutation CreateCourse($data: CourseInput!, $fullName: String!, $companyName: String!) {
  createCourse(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_COURSE = gql`
mutation UpdateCourse($_id: ID!, $data: CourseInput!, $fullName: String!, $companyName: String!) {
  updateCourse( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_COURSE= gql`
mutation DeleteCourse($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteCourse(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;