const courseMode = [
    {"id": "1", "value": "Classroom"},
    {"id": "2", "value": "Asynchronous eLearning"},
    {"id": "3", "value": "In-house"},
    {"id": "4", "value": "On-the-Job"},
    {"id": "5", "value": "Practical / Practicum"},
    {"id": "6", "value": "Supervised Field "},
    {"id": "7", "value": "Traineeship"},
    {"id": "8", "value": "Assessment"},
    {"id": "9", "value": "Synchronous eLearning"}

]


const fundedCourse = [
    {"id": 1, "value": "SSG Funded Course"},
    {"id": 2, "value": "Non SSG Funded Course"},

]

const _tourGuides = [
    {"id": 1, "name": "_tourGuides"},
    {"id": 2, "name": "Non SSG Funded Course"},

]

const _courseVacancy = [
    {"id": "A", "value": "Available"},
    {"id": "F", "value": "Full"},
    {"id": "L", "value": "Limited Vacancy"},

]

const _ampm = [
    {"id": "am", "value": "AM", "label": "AM"},
    {"id": "pm", "value": "pm", "label": "PM"},
]

const _scheduleInfoType =[
    {"code": "01", "name": "description"}
]
const _AssessmentMethod =  [
    {"id": "Assignment", "label": "Assignment"},
    {"id": "Demonstration", "label": "Demonstration"},
    {"id": "Practical Exam", "label": "Practical Exam"},
    {"id": "Project", "label": "Project"},
    {"id": "Online Test", "label": "Online Test"},
    {"id": "Role Play", "label": "Role Play"},
    {"id": "Oral Question", "label": "Oral Questions"},
    {"id": "Written Exams", "label": "Written Exams"},
    {"id": "Others", "label": "Others"}
]

const mediumInstruction = [
    {"id": "English", "label": "English"},
    {"id": "Chinese", "label": "Chinese"},
    {"id": "Malay", "label": "Malay"},
    {"id": "Indian", "label": "Indian"},
    {"id": "Others", "label": "Others"},
]

const _grades = [
    {"label": "A", "value": "A"},
    {"label": "B", "value": "B"},
    {"label": "C", "value": "C"},
    {"label": "D", "value": "D"},
    {"label": "E", "value": "E"},
    {"label": "F", "value": "F"}
]
const _criteriaResults = [
    {"label": "Pass", "value": "Pass"},
    {"label": "Fail", "value": "Fail"},
    {"label": "Exempted", "value": "Exempted"}
]

function countCounter(){
    var score=[];
    for(var i =100; i >= 1; i--){
      score.push(i)
    }
    return score
  }

  const _paymentStatus = [
    {"label": "Pending Payment", "value": "Pending Payment"},
    {"label": "Partial Payment", "value": "Partial Payment"},
    {"label": "Full Payment", "value": "Full Payment"},
    {"label": "Cancelled", "value": "Cancelled"}
]

const _attendance = [
    {"code": "1", "description": "Confirmed"},
    {"code": "2", "description": "Unconfirmed"},
    {"code": "3", "description": "Rejected"},
    {"code": "4", "description": "TP Voided"}
]
  
const _survey = [
    {"code": "EL", "description": "English"},
    {"code": "MN", "description": "Mandarin"},
    {"code": "MY", "description": "Malay"},
    {"code": "TM", "description": "Tamil"}
]



export {
    courseMode,
    fundedCourse,
    _tourGuides,
    _courseVacancy,
    _ampm,
    _scheduleInfoType,
    _AssessmentMethod,
    mediumInstruction,
    _grades,
    _criteriaResults,
    countCounter,
    _paymentStatus,
    _attendance,
    _survey
};