import { applyMiddleware, compose } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import reducers from "./reducers.js";
import { saveState, persistedState } from "./persisted.store.js";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewareEnhancer = applyMiddleware(thunk);
composeEnhancer(middlewareEnhancer);

const store = configureStore({
    reducer: reducers,
    preloadedState: persistedState
});

store.subscribe(() => {
    saveState(store.getState())
  })
  
export default store;  
  
