import { enUS, frFR, zhCN, viVN, arSA } from "@mui/material";

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        icon: 'flagpack:gb-nir'
    }

]

export const defaultLang = allLangs[0];