import { Helmet } from "react-helmet-async";
 import  ClassDetailsView from "../../sections/company/class/enrolment/index.js";

export default function CompanyEnrolment(){
    return (
        <>
            <Helmet>
                <title>Class Enrollment</title>
            </Helmet>
            <ClassDetailsView />
        </>
    )
}