import gql from "graphql-tag";

export const GET_ALL_DASHBOARD = gql`
query getAllDashboard($companyName: String!) {
    getAllDashboard(companyName: $companyName) {
        code
        success
        message
        countCompany
        countUsers
        invoiceresult
  }
}`;

export const GET_CLIENTDASHBOARD_INFO = gql`
query GetClientDashboardInfo($companyName: String!, $dateFrom: String, $dateTo: String) {
  getClientDashboardInfo(companyName: $companyName, dateFrom: $dateFrom, dateTo: $dateTo) {
        code
        success
        message
        countUsers
        countClass
        countCourse
  }
}`;




export const GET_CLIENT_INVOICE = gql`
query GetClientDashboardInvoice($companyName: String!, $dateFrom: String, $dateTo: String) {
  getClientDashboardInvoice(companyName: $companyName, dateFrom: $dateFrom, dateTo: $dateTo) {
        code
        success
        message
        invoiceAmount
        countInvoice
  }
}`;


