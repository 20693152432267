
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Dialog, Box, Button, DialogActions, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Iconify from "../../../../components/iconify/iconify.js";
import Label from '../../../../components/label/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { fDate } from '../../../../util/formatTime.js';
import AssessmentForm  from './assessment/assessment-record-form.js'
import AssessmentSummaryForm from './assessment/assessment-summary-from.js';
import AssessmentFormPDF  from './assessment/assessment-record-form-pdf-view.js'
import AssessmentSummaryPDF  from './assessment/assessment-summary-pdf-view.js'


import { SUBMIT_ASSESSMENT_SUMMARY, UPDATE_VOID_ASSESSMENT } from "../../../../graphql/modules/_client/assessementSummary.js";
import { useMutation } from "@apollo/react-hooks";


export default function ClassAssessmentRow({row, selected, indexNo, onPrintAssForm, onSelected, onSubmitAttendance, onEditRow, onViewRow, onSelectRow, classinfo, onRefetch}){
    const { traineeInfo, ssgenrolment, grant, assessmentSummary, assForm} =row;
    const {fullName} = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const isRole = useSelector((state) => state?.auth?.isRole);
    const [SubmitAssessmentMutation] =  useMutation(SUBMIT_ASSESSMENT_SUMMARY);
    const [UpdateVoidAssessment] =  useMutation(UPDATE_VOID_ASSESSMENT);
    
    const popover = usePopover();
    const assessmentForm = useBoolean();
    const assSummary = useBoolean();
    const viewAssessmentFormPDF = useBoolean();
    const viewAssessmentSummaryPDF = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
   

    const handleSubmit = async() => {
      const {data: { submitAssessment }} = await SubmitAssessmentMutation({
        variables: {
          companyName: company,
          fullName: fullName,
          uen: companyProfile?.uen,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
            data: {
              assessmentSummary: assessmentSummary,
              traineeInfo: traineeInfo,
              classinfo: classinfo
            }
          }
      });
      if(submitAssessment?.success){
        enqueueSnackbar(submitAssessment?.message, {variant: "success"});
      }else {
        enqueueSnackbar(submitAssessment?.message, {variant: "error"});
      }
    }

    const handleUpdateVoid = async(action) => {
      const {data: { updateAssessmentSummary }} = await UpdateVoidAssessment({
        variables: {
          companyName: company,
          fullName: fullName,
          uen: companyProfile?.uen,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
            data: {
              assessmentSummary: assessmentSummary,
              traineeInfo: traineeInfo,
              classinfo: classinfo,
              action: action
            }
          }
      });
      if(updateAssessmentSummary?.success){
        enqueueSnackbar(updateAssessmentSummary?.message, {variant: "success"});
      }else {
        enqueueSnackbar(updateAssessmentSummary?.message, {variant: "error"});
      }
    }

    const filterAssessor = classinfo?.sessions.filter((res) => res?.modeOfTraining === 8 || res?.modeOfTraining === "8")


 return (
    <>
    <TableRow hover selected={selected}>
           <TableCell sx={{whiteSpace: 'nowrap'}}>{indexNo + 1}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.fullName}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{ssgenrolment?.referenceNumber}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>
            <Label
                variant="soft"
                color={
                  (ssgenrolment?.status === 'Confirmed' && 'success') ||
                  (ssgenrolment?.status === 'Completed' && 'info') ||
                  (ssgenrolment?.status === 'Cancelled' && 'error') || 
                  (ssgenrolment?.status === 'Draft' && 'warning') ||
                  'default'
                }
              >
                {ssgenrolment?.status  ? ssgenrolment?.status : ""}
              </Label>

            </TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{assessmentSummary ? assessmentSummary?.createdBy : "NA"}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{assessmentSummary ? fDate(assessmentSummary?.createdDate) : "NA"}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{assessmentSummary ? assessmentSummary?.criteriaResults : "NA"}</TableCell>
            <TableCell>
              <Label
                variant="soft"
                color={
                  ((assessmentSummary)  && 'success') ||
                  ((assessmentSummary || assForm) && 'info') ||
                  'default'
                }
              >
                {assessmentSummary ? "Completed" : assForm ? "In-Progress" : "NA" }
              </Label>
        </TableCell>
        <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}              
                     sx={{
                      display: ssgenrolment?.status === "Cancelled" ? 'none' : ''
                    }} >
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>


    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 260}}
    >

 

        {
          isRole.some((role)=> ["superadmin", "admin", "bo", "tm"].includes(role)) ?
          <>
            <MenuItem onClick={viewAssessmentFormPDF.onTrue} sx={{ display: assForm ? '' : 'none' }} >
                <Iconify icon="uit:print" />
                Print Assessment Form
            </MenuItem>

            <MenuItem onClick={viewAssessmentSummaryPDF.onTrue} sx={{ display: assessmentSummary ? '' : 'none' }} >
                <Iconify icon="uit:print" />
                Print Assessment Summary
            </MenuItem>
            <MenuItem onClick={() => handleSubmit()} sx={{ display: assessmentSummary ? '' : 'none' }}>
                <Iconify icon="mingcute:send-line" />
                Submit Assessment
            </MenuItem>


            <MenuItem onClick={() => handleUpdateVoid("update")} sx={{ display: assessmentSummary ? '' : 'none' }}>
                <Iconify icon="solar:pen-bold" />
                Update Assessment
            </MenuItem>

            <MenuItem onClick={() => handleUpdateVoid("void")} sx={{ display: assessmentSummary ? '' : 'none' }}>
                <Iconify icon="mdi:file-cancel-outline" />
                Void Assessment
            </MenuItem>

            {/* <MenuItem  sx={{ display: assessmentSummary ? 'none' : '' }}>
                <Iconify icon="mdi:file-cancel-outline" />
                No Assessment
            </MenuItem> */}

          </>
          :
          ""

        }

        {
          isRole.some((role)=> ["ta"].includes(role)) ?
                    <>
                    <MenuItem
                      onClick={() => {
                        popover.onClose();
                        assessmentForm.onTrue();
                      }}
                    >
                    <Iconify icon="wpf:edit-file" />
                        Assessment Form
                    </MenuItem>
        
                    <MenuItem
                      onClick={() => {
                        popover.onClose();
                        assSummary.onTrue();
                      }}
                    >
                    <Iconify icon="fluent:comment-edit-20-regular" />
                        Assessment Summary
                    </MenuItem>
                  </>
            :
            ""
        }


    </CustomPopover>

    <AssessmentForm 
        title="Assessment Form"
      open={assessmentForm.value} 
      onClose={() => {
        assessmentForm.onFalse();
        onRefetch();
      }}
      classInfo={classinfo}
      userInfo={row}
      />
      <AssessmentSummaryForm
         title="Assessment Summary"
         open={assSummary.value} onClose={() => {
          assSummary.onFalse();
          onRefetch();
         }}
         classInfo={classinfo}
         userInfo={row}
      />


      <Dialog fullScreen open={viewAssessmentFormPDF.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={viewAssessmentFormPDF.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <AssessmentFormPDF traineeInfo={traineeInfo}  assForm={assForm} assessmentSummary={assessmentSummary} classinfo={classinfo} companyProfile={companyProfile}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

      <Dialog fullScreen open={viewAssessmentSummaryPDF.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={viewAssessmentSummaryPDF.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <AssessmentSummaryPDF traineeInfo={traineeInfo}  assessmentSummary={assessmentSummary} classinfo={classinfo} companyProfile={companyProfile}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

</>
 )

}

ClassAssessmentRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSubmitAttendance: PropTypes.func,
    classinfo: PropTypes.object,
    onPrintAssForm: PropTypes.func
  };
  
