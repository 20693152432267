import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { Card, Container, Stack, Divider, MenuItem, Box, Avatar, useTheme, Typography, IconButton, Button, Chip  } from '@mui/material';
import { useBoolean } from '../../../hooks/use-boolean.js';
import { useForm, Controller } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from 'src/components/iconify/index.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import { useSettingsContext } from '../../../components/settings/index.js';
import { useResponsive } from '../../../hooks/useResponsive.js';
import {courseMode, _courseVacancy, _scheduleInfoType, _tourGuides} from '../../../util/course.js';
import { GET_TRAINERASSESSOR } from "../../../graphql/modules/users.js";
import {fDate,SSGTime, SSGDate, SSGDateNoHype} from '../../../util/formatTime.js';
import AddressVenueDialog from './address-venue-dialog.js';
import AddressNewForm from './address-new-form.js';
import CourseDialogSelect from './course-dialog.js';
import TrainingSession from './training-total-hours-session.js';
// import TrainingSessionDetails from './training-session-details.js'
import TrainingSessionDialog from './training-session-dialog.js';
// import CalendarForm from './hooks/calendar-session.js'

import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFSelect,
    RHFAutocomplete
  } from 'src/components/hook-form';

  import { CREATE_CLASS_AS_DRAFT, UPDATE_CLASSES_AS_DRAFT, CREATE_CLASSES, UPDATE_CLASSES, UPDATE_CONFIRM_SUBMIT, UPDATE_CLASS_ASS_FORM, UPDATE_CLASS_SESSION } from "../../../graphql/modules/_client/class.js";
  import { GET_COURSE_SESSION } from "../../../graphql/modules/_client/attendance.js";
  import { useMutation, useQuery } from "@apollo/react-hooks";


export default function CreateUpdateClassesCompany({ currentInvoice }) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const company = useSelector((state) => state?.auth?.company);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const [results, setResults] = useState(null);
  const [sessResult, setSesResult] = useState(null);
  const [upSession, setUpSession] = useState(null);
  const { state } = useLocation();
  const upMd = useResponsive('up', 'md');
  const theme = useTheme();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [CreateClassMutation] =  useMutation(CREATE_CLASSES);
  const [CreateClassAsDraftMutation] =  useMutation(CREATE_CLASS_AS_DRAFT);
  const [UpdateClassMutation] =  useMutation(UPDATE_CLASSES);
  const [UpdateClassAsDraftMutation] =  useMutation(UPDATE_CLASSES_AS_DRAFT);
  const [UpdateConfirmSubmitMutation] =  useMutation(UPDATE_CONFIRM_SUBMIT);
  const [UpdateClassAssForm] = useMutation(UPDATE_CLASS_ASS_FORM);
  const [UpdateClassSessionMutation] = useMutation(UPDATE_CLASS_SESSION);
  
  

  const venue = useBoolean();
  const addressNew = useBoolean();
  const courseDialog = useBoolean();
  const sessionDialog = useBoolean();


  const { data, loading, error, refetch } = useQuery(  GET_TRAINERASSESSOR, {
    variables: {
      companyName: state?.info?.databaseName || company,
      },
      fetchPolicy: "cache-and-network",
    });

    const { data: dataSession, loading: loadingSession, error: errorSession, refetch: refetchSession} = useQuery(  GET_COURSE_SESSION, {
      variables: {
        uen: companyProfile?.uen,
        courseReferenceNumber: state?.classInfo?.course?.tsgRefNumber,
        courseRunId: state?.classInfo?.courseRunId,
        encryptionkey: companyProfile?.encryptionKey,
        _companyName: companyProfile?.companyName,
        sessionId: ""
        },
        fetchPolicy: "cache-and-network",
      });

    

    useEffect(() => {
      if(data){
        setResults(data?.getTrainerAssessor)
      }
      if(dataSession && state?.classInfo?.courseRunId){
        setSesResult(dataSession?.getCourseSession?.results?.data?.courseRun)
      }
  }, [data, dataSession])

  const NewUserSchema = Yup.object().shape({
    // course: Yup.object().required('Course is required'),
    classCode: Yup.string().required('Class code is required'),
    // courseAdminEmail: Yup.string().required('Admin is required').email('Please enter valid email'),
    // modeOfTraining: Yup.string().required('Course run mode is required'),
    // venue: Yup.object().required('Training venue is required'),
    // session:  Yup.array().required('Training session is required')
  });

  const defaultValues = useMemo(
    () => ({
      course: state?.classInfo?.course || {},
      venue: state?.classInfo?.venue || {},
      session: state?.classInfo?.sessions || [],
      assessors: state?.classInfo?.assessors || [],
      classCode: state?.classInfo?.classCode || '',
      modeOfTraining: state?.classInfo?.modeOfTraining || '',
      courseAdminEmail: state?.classInfo?.courseAdminEmail || '',
      scheduleInfo: state?.classInfo?.scheduleInfo || '',
      scheduleInfoType: state?.classInfo?.scheduleInfoType || {},
      courseVacancy: state?.classInfo?.courseVacancy || '',
      courseStartDate: parseISO(state?.classInfo?.courseDates?.start) || null,
      courseEndDate: parseISO(state?.classInfo?.courseDates?.end) || null,
      openingDate: parseISO(state?.classInfo?.registrationDates?.opening) || null,
      closingDate: parseISO(state?.classInfo?.registrationDates?.closing) || null,
      totalTrainingSelected: 0,
      totalAssessmentSelected: 0,
    }),
    [state]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    if(state?.classInfo && state?.classInfo?.status !== "Draft"){
      const {data: { updateClass }} = await UpdateClassMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.course?._id || state?.classInfo?._id,
          uen: companyProfile?.uen,
          fullName: fullName,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
            data: {
              courseRunId: state?.classInfo?.courseRunId,
              course: data?.course,
              venue: data?.venue,
              courseAdminEmail: data?.courseAdminEmail,
              modeOfTraining: data?.modeOfTraining,
              assessors: data?.assessors,
              sessions: data?.session.map((res, index) => {
                return {
                  action: "update",
                  sessionId: sessResult?.sessions[index].id,
                  endDate: res?.startDate,
                  endTime: res?.endTime,
                  modeOfTraining: res?.modeOfTraining,
                  startDate: res?.startDate,
                  startTime: res?.startTime,
                  trainer: res?.trainer,
                  venue: res?.venue
                }
              }),
              classCode: data?.classCode,
              scheduleInfo: data?.scheduleInfo,
              scheduleInfoType: data?.scheduleInfoType,
              courseVacancy: data?.courseVacancy,
              registrationDates: {
                opening: data?.openingDate,
                closing: data?.closingDate
              },
              courseDates: {
                end: data?.courseEndDate,
                start: data?.courseStartDate
              },
              status: "Confirmed",
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });

      
      if(updateClass?.success){
          enqueueSnackbar("Update success!", {variant: "success"});
          navigate(`/${companyProfile?.databaseName}/class`)
      }else {
        enqueueSnackbar(updateClass?.message, {variant: "error"});
      }
    }else {
    const {data: { createClass }} = await CreateClassMutation({
      variables: {
        companyName: state?.info?.databaseName || company,
        uen: companyProfile?.uen,
        fullName: fullName,
        encryptionkey: companyProfile?.encryptionKey,
        _companyName: companyProfile?.companyName,
          data: {
            course: data?.course,
            venue: data?.venue,
            courseAdminEmail: data?.courseAdminEmail,
            modeOfTraining: data?.modeOfTraining,
            assessors: data?.assessors,
            sessions: data?.session.map((res) => ({
              startDate: SSGDate(res.startDate),
              endDate: SSGDate(res.startDate),
              startTime: res.startTime,
              endTime: res?.endTime,
              modeOfTraining: res?.modeOfTraining,
              venue: res?.sessionVenue,
              trainer: res?.trainer

            })),
            classCode: data?.classCode,
            scheduleInfo: data?.scheduleInfo,
            scheduleInfoType: data?.scheduleInfoType,
            courseVacancy: data?.courseVacancy,
            registrationDates: {
              opening: SSGDate(data?.openingDate),
              closing: SSGDate(data?.closingDate)
            },
            courseDates: {
              end: SSGDate(data?.courseEndDate),
              start: SSGDate(data?.courseStartDate)
            },
            status: "Confirmed",
            createdBy: fullName,
            dateCreated: new Date(),
            modifiedBy: fullName,
            dateModified: new Date()
          }
        }
    });

    if(createClass?.success){
      reset();
      enqueueSnackbar(createClass?.message, {variant: "success"});
      navigate(`/${companyProfile?.databaseName}/class`)
    }else {
      enqueueSnackbar(createClass?.message, {variant: "error"});
    }
  }
  });

  const handleSaveAsDraft = handleSubmit(async (data) => {
    if(state?.classInfo){
      const {data: { updateClassAsDraft }} = await UpdateClassAsDraftMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.course?._id || state?.classInfo?._id,
          fullName: fullName,
            data: {
              course: data?.course,
              venue: data?.venue,
              courseAdminEmail: data?.courseAdminEmail,
              modeOfTraining: data?.modeOfTraining,
              assessors: data?.assessors,
              sessions: data?.session,
              classCode: data?.classCode,
              scheduleInfo: data?.scheduleInfo,
              scheduleInfoType: data?.scheduleInfoType,
              courseVacancy: data?.courseVacancy,
              registrationDates: {
                opening: data?.openingDate,
                closing: data?.closingDate
              },
              courseDates: {
                end: data?.courseEndDate,
                start: data?.courseStartDate
              },
              status: state?.classInfo?.status,
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });

      
      if(updateClassAsDraft?.success){
          enqueueSnackbar("Update success!", {variant: "success"});
          navigate(`/${companyProfile?.databaseName}/class`)
      }else {
        enqueueSnackbar(updateClassAsDraft?.message, {variant: "error"});
      }
      }else {
      const {data: { createClassAsDraft }} = await CreateClassAsDraftMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          fullName: fullName,
            data: {
              course: data?.course,
              venue: data?.venue,
              courseAdminEmail: data?.courseAdminEmail,
              modeOfTraining: data?.modeOfTraining,
              status: "Draft",
              assessors: data?.assessors,
              sessions: data?.session,
              classCode: data?.classCode,
              scheduleInfo: data?.scheduleInfo,
              scheduleInfoType: data?.scheduleInfoType,
              courseVacancy: data?.courseVacancy,
              registrationDates: {
                opening: data?.openingDate,
                closing: data?.closingDate
              },
              courseDates: {
                end: data?.courseEndDate,
                start: data?.courseStartDate
              },
              createdBy: fullName,
              dateCreated: new Date(),
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });

      if(createClassAsDraft?.success){
        // reset();
        enqueueSnackbar(createClassAsDraft?.message, {variant: "success"});
      }else {
        enqueueSnackbar(createClassAsDraft?.message, {variant: "error"});
      }
    }
  })

  const handleConfirmSubmit = handleSubmit(async (data) => {
    if(state?.classInfo){
      const {data: { updateConfirmSubmit }} = await UpdateConfirmSubmitMutation({
        variables: {
          _id: state?.course?._id || state?.classInfo?._id,
          companyName: state?.info?.databaseName || company,
          uen: companyProfile?.uen,
          fullName: fullName,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
          data: {
            course: data?.course,
            venue: data?.venue,
            courseAdminEmail: data?.courseAdminEmail,
            modeOfTraining: data?.modeOfTraining,
            assessors: data?.assessors,
            sessions: data?.session.map((res) => ({
              startDate: SSGDate(res.startDate),
              endDate: SSGDate(res.startDate),
              startTime: res.startTime,
              endTime: res?.endTime,
              modeOfTraining: res?.modeOfTraining,
              venue: res?.sessionVenue,
              trainer: res?.trainer

            })),
            classCode: data?.classCode,
            scheduleInfo: data?.scheduleInfo,
            scheduleInfoType: data?.scheduleInfoType,
            courseVacancy: data?.courseVacancy,
            registrationDates: {
              opening: SSGDate(data?.openingDate),
              closing: SSGDate(data?.closingDate)
            },
            courseDates: {
              end: SSGDate(data?.courseEndDate),
              start: SSGDate(data?.courseStartDate)
            },
            status: "Confirmed",
            createdBy: fullName,
            dateCreated: new Date(),
            modifiedBy: fullName,
            dateModified: new Date()
          }
          }
      });
      if(updateConfirmSubmit?.success){
          enqueueSnackbar("Update success!", {variant: "success"});
          navigate(`/${companyProfile?.databaseName}/class`)
      }else {
        enqueueSnackbar(updateConfirmSubmit?.message, {variant: "error"});
      }
    }
  })

  const handleUpdateAssessmentForm = handleSubmit(async (data) => {
    const {data: { updateClassAssForm }} = await UpdateClassAssForm({
      variables: {
        companyName: state?.info?.databaseName || company,
        _id: state?.course?._id || state?.classInfo?._id,
        fullName: fullName,
          data: {
            course: data?.course,
            modifiedBy: fullName,
            dateModified: new Date(),
          }
        }
  })
    if(updateClassAssForm?.success){
      enqueueSnackbar("Update success!", {variant: "success"});
    }else {
      enqueueSnackbar(updateClassAssForm?.message, {variant: "error"});
    }
  })

  const handleUpdateSession = (item, index) => {
    handleSubmit(async (data) => {
      const {data: { updateClassSession }} = await UpdateClassSessionMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.course?._id || state?.classInfo?._id,
          uen: companyProfile?.uen,
          fullName: fullName,
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
          sessionIndex: index,
            data: {
              courseRunId: state?.classInfo?.courseRunId,
              course: data?.course,
              venue: data?.venue,
              courseAdminEmail: data?.courseAdminEmail,
              modeOfTraining: data?.modeOfTraining,
              assessors: data?.assessors,
              sessions: [{
                  action: "update",
                  sessionId: sessResult?.sessions[index].id,
                  endDate: item?.startDate,
                  endTime: item?.endTime,
                  modeOfTraining: item?.modeOfTraining,
                  startDate: item?.startDate,
                  startTime: item?.startTime,
                  trainer: item?.trainer,
                  venue: item?.venue
              }],
              classCode: data?.classCode,
              scheduleInfo: data?.scheduleInfo,
              scheduleInfoType: data?.scheduleInfoType,
              courseVacancy: data?.courseVacancy,
              registrationDates: {
                opening: data?.openingDate,
                closing: data?.closingDate
              },
              courseDates: {
                end: data?.courseEndDate,
                start: data?.courseStartDate
              },
              status: "Confirmed",
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });

      
      if(updateClassSession?.success){
          enqueueSnackbar("Update success!", {variant: "success"});
          navigate(`/${companyProfile?.databaseName}/class`)
      }else {
        enqueueSnackbar(updateClassSession?.message, {variant: "error"});
      }
    })();
  };


// const handleGetSession = async (e) => {
//   setUpSession(e)
//   handleUpdateSession();
// };



  const watchOpeningDate = watch('openingDate');
  const watchClosingDate = watch('closingDate');
  const watchCourseStartDate= watch('courseStartDate');
  const watchCourseEndDate= watch('courseEndDate');

  useEffect(() => {
      if(watchOpeningDate > watchClosingDate){
        enqueueSnackbar("Closing date not later than opening date", {variant: "error"});
        setValue('closingDate', null);
      }
      if(watchCourseStartDate > watchCourseEndDate){
        enqueueSnackbar("Course start date not later than course end date", {variant: "error"});
        setValue('courseEndDate', null);
      }
  }, [watchClosingDate, watchCourseEndDate])
 

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading={state?.classInfo ? 'Update Class' : 'Create New Class'}
                links={[
                {name: 'Dashboard'},
                {name: 'Class List'},
                {name: state?.classInfo ? 'Update Class' : 'Create New Class'}
                ]}
            />

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              Course:
            </Typography>
            <IconButton onClick={courseDialog.onTrue}>
                  <Iconify icon={'mingcute:add-line'} />
              </IconButton>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="subtitle1">{values?.course?.courseTitle}</Typography>
            <Typography variant="body2">{values?.course?.tsgRefNumber }</Typography>
            {
                state?.classInfo ?
                <Typography variant="body2">{`Course Run ID: ${state?.classInfo?.courseRunId ? state?.classInfo?.courseRunId : "NA"}`}</Typography>
                :
               ""
            }
          </Stack>
          <CourseDialogSelect
              title="Course Details"
              open={courseDialog.value}
              onClose={courseDialog.onFalse}
              company={state?.info?.databaseName}
              onSelect={(course) => setValue('course', course)}
            />
        </Stack>

            <Stack sx={{ width: 1 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
                  Venue:
                </Typography>
                <IconButton onClick={venue.onTrue}>
                  <Iconify icon={'mingcute:add-line'} />
                </IconButton>
              </Stack>

              <Stack spacing={1}>
              <Typography variant="subtitle1">{values?.venue?.block} {values?.venue?.street} {values?.venue?.building} #{values?.venue?.floor}-{values?.venue?.unit}</Typography>

              <Typography variant="body2">Singapore {values?.venue?.postalCode}, Training Room: {values?.venue?.room}</Typography>
              <Typography variant="body2">Wheelchair Access: {values?.venue?.wheelChairAccess ? "Yes" : "No"}</Typography>
              </Stack>
            </Stack>

  


            </Stack>
            <AddressVenueDialog
              title="Training Address"
              open={venue.value}
              onClose={venue.onFalse}
              company={state?.info?.databaseName}
              fullName={fullName}
              onSelect={(venue) => setValue('venue', venue)}
              addedNew={addressNew}
              action={
                <Button
                  size="small"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                  sx={{ alignSelf: 'flex-end' }}
                  onClick={addressNew.onTrue}
                >
                  New
                </Button>
              }
              formclose={addressNew.value}
            />

            <AddressNewForm
              open={addressNew.value}
              onClose={addressNew.onFalse}
              fullName={fullName}
              company={state?.info?.databaseName}
            />
            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
              sx={{ p: 3, bgcolor: 'background.neutral' }}
            >
              <RHFTextField
                name="classCode"
                label="Class Code (Please use new and unique class code)"
              />
               <RHFTextField
                name="courseAdminEmail"
                label="Admin Email"
              />

                  <RHFSelect
                    name="modeOfTraining"
                    label="Course Run Mode Training"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      maxWidth: { md: '100%' },
                    }}
                  >
                    {courseMode.map((result) => {
                      return (
                        <MenuItem key={result.id}  value={result.id}>
                          {result.value}
                        </MenuItem>
                      )
                    })}

              </RHFSelect>
            </Stack>

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
              sx={{ p: 3, bgcolor: 'background.neutral' }}
            >
              <RHFTextField
                name="scheduleInfo"
                label="Schedule Info"
              />

                  <RHFSelect
                    name="courseVacancy"
                    label="Course Vacancy"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      maxWidth: { md: '100%' },
                    }}
                  >
                    {_courseVacancy.map((result) => {
                      return (
                        <MenuItem key={result.id}  value={result.id}>
                          {result.value}
                        </MenuItem>
                      )
                    })}

              </RHFSelect>



            </Stack>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              sx={{ p: 3 }}
            > 
               <RHFAutocomplete
                multiple
                name="assessors"
                placeholder="+ Assessor"
                disableCloseOnSelect
                options={results?.results}
                getOptionLabel={(option) => option.fullName}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, tourGuide) => (
                  <li {...props} key={tourGuide._id}>
                    <Avatar
                      key={tourGuide._id}
                      alt={tourGuide._id}
                      src={tourGuide._id}
                      sx={{ width: 24, height: 24, flexShrink: 0, mr: 1 }}
                    />

                    {tourGuide.fullName}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((tourGuide, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={tourGuide._id}
                      size="small"
                      variant="soft"
                      label={tourGuide.fullName}
                      avatar={<Avatar alt={tourGuide.fullName} src={tourGuide._id} />}
                    />
                  ))
                }                 
              />
            </Box>

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
              sx={{ p: 3, bgcolor: 'background.neutral' }}
            >



              <Controller
              name="openingDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Opening Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="closingDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Closing Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
              <Controller
              name="courseStartDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Course Start Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

            <Controller
              name="courseEndDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Course End Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
 
          </Stack>

          <Stack
             direction="row"
             alignItems="center"
             justifyContent="space-between"
             sx={{ p: 2.5, pr: 2, position: 'relative'}}
            >
                <Typography variant='h6'>Training Sessions</Typography>
                
                <LoadingButton variant="contained" onClick={sessionDialog.onTrue} > {state?.classInfo ? 'View Session' : 'New Session'}</LoadingButton>
            </Stack>

            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <TrainingSession
                title="Training Hours Allowed"
                total={0}
                percent={100}
                price={values?.course?.proposedTrainingHours}
                icon="healthicons:i-training-class-outline"
                color={theme.palette.info.main}
              />

              <TrainingSession
                title="Assessment Hours Allowed"
                total={0}
                price={values?.course?.proposedAssessmentHours}
                icon="ic:outline-assessment"
                color={theme.palette.success.main}
              />


              <TrainingSession
                title="Total Training Hours Selected"
                total={0}
                percent={80}
                price={values?.totalSelectedTraining}
                icon="la:business-time"
                color={theme.palette.warning.main}
              />

              <TrainingSession
                title="Total Assessment Hours Selected"
                price={values?.totalSelectedAssessement}
                icon="healthicons:i-schedule-school-date-time"
                color={theme.palette.error.main}
              />
            </Stack>
            {/* <TrainingSessionDetails
              trainer={values?.course?.trainerAssessor}
            /> */}
             <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
             
            <TrainingSessionDialog 
              title="Session Details"
              open={sessionDialog.value}
              onClose={sessionDialog.onFalse}
              trainer={values?.course?.trainerAssessor}
              company={state?.info?.databaseName}
              totalTrainingAllowed={values?.course?.proposedTrainingHours}
              totalAssessementAllowed={values?.course?.proposedAssessmentHours}
              // sessionTpg={sessResult}
              handleUpdateSession={(e, index) => handleUpdateSession(e, index)}
            />
              <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              {
                state?.classInfo?.status === "Draft" ||  !state?.classInfo ?
                <>
                    <LoadingButton variant="contained" onClick={() => handleSaveAsDraft()} loading={isSubmitting}> {state?.classInfo ? 'Update as Draft' : 'Save as Draft'}</LoadingButton>
                </>
            
                :
                ""
              }
              {
                state?.classInfo?.status === "Draft" ?
                <LoadingButton
                size="large"
                variant="contained"
                onClick={() => handleConfirmSubmit()} loading={isSubmitting}
              >
                Confirm & Submit
              </LoadingButton>
                :
                <LoadingButton
                size="large"
                variant="contained"
                type="submit"
              >
            {state?.classInfo && state?.classInfo?.status !== "Draft" ? 'Update & Submit' : 'Create & Submit'}
          </LoadingButton>

              }

            {
            isRole.some((role)=> ["bo"].includes(role)) ? 
            <>
               <LoadingButton
                size="large"
                variant="contained"
                onClick={() => handleUpdateAssessmentForm()} loading={isSubmitting}
              >
                Update Assessment Form
              </LoadingButton>
            </>
            :
            <></>
            }


             
                
                <LoadingButton variant="contained" loading={isSubmitting} onClick={() => navigate(-1)}>Go Back</LoadingButton>
            </Stack>

            </Card>

  
         </Container>
        </FormProvider>
    )
}