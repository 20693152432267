import PropTypes from 'prop-types';
import { memo, useCallback, useState} from 'react';
// @mui
import { Collapse, Stack, List } from '@mui/material';
//
import { navVerticalConfig } from '../config.js';
import { StyledSubHeader } from './styles.js';
import NavList from './navlist.js';


function NavSectionVertical({ data, config, sx, ...other }) {
  return (
    <Stack sx={sx} {...other}>
        {data?.map((group, index) => (
          <Group
            key={group.subheader || index}
            subheader={group.subheader}
            items={group.items}
            config={navVerticalConfig(config)}
          />
        ))}

    </Stack>
  );
}

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  config: PropTypes.object,
  data: PropTypes.array,
  sx: PropTypes.object
};

export default memo(NavSectionVertical)

function Group({ subheader, items, config }) {
 const [open, setOpen] = useState(true);
 const handleToggle = useCallback(() => {
  setOpen((prev) => !prev)
}, [])

const renderContent = items.map((list) => (
  <NavList 
  key={list.title + list.path}
  data={list}
  depth={1}
  hasChild={!!list.children}
  config={config}
/>
))


  return (
    <List disablePadding sx={{px: 2}}>
      {
        subheader ? (
          <>
           <StyledSubHeader disableGutters disableSticky onClick={handleToggle} config={config}>
            {subheader}
          </StyledSubHeader>

          <Collapse in={open}>{renderContent}</Collapse>
          </>

        ) : (
          renderContent
        )}
    </List>
  );
}

Group.propTypes = {
  config: PropTypes.object,
  items: PropTypes.array,
  subheader: PropTypes.string
}