import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useState, useCallback, useEffect } from 'react';
import {useFormContext,  useFieldArray } from 'react-hook-form';
import { parseISO, format, differenceInMinutes, isWithinInterval } from 'date-fns';
import { useBoolean } from '../../../hooks/use-boolean.js';
// @mui
import {Box, Stack, Dialog, IconButton, Typography, Table, TableBody, TableCell, TableRow, Button, TableHead, TableContainer, listItemButtonClasses, MenuItem} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import AddressVenueDialog from './address-venue-dialog.js';
import { GET_COURSE } from "../../../graphql/modules/_client/course.js";
import { useForm, Controller } from 'react-hook-form';
import { DatePicker, TimePicker, StaticDatePicker } from '@mui/x-date-pickers';
import { useQuery } from "@apollo/react-hooks";
// components
import {courseMode, _ampm} from '../../../util/course.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import Iconify from 'src/components/iconify';

// import SearchNotFound from 'src/components/search-not-found';
import FormProvider, {
    RHFTextField,
    RHFSelect,
    RHFAutocomplete,
    RHFRadioGroup
  } from 'src/components/hook-form';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'startDate', label: 'Date'},
  {id: 'modeOfTraining', label: 'Mode Of Training'},
  {id: 'startTime', label: 'Start Time'},
  {id: 'endTime', label: 'End Time'},
  {id: 'trainer', label: 'Trainer'},
  {id: 'action', label: 'Action'}
]

export default function TrainingSessionDialog({totalTrainingAllowed, totalAssessementAllowed,  company, title = 'Address Book', trainer, list, action, open, onClose, selected, onSelect, handleUpdateSession}) {
  const { control, setValue, watch, reset } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'session',
  });
  const { enqueueSnackbar } = useSnackbar();
  const venueDialog = useBoolean();


  
  const values = watch();

  const totalDifferenceTraining = values.session.map((hours) => {
    const hoursEndTime = !isNaN(hours.endTime) ? hours.endTime : parseISO(hours.endTime);
    const hoursStartTime = !isNaN(hours.startTime) ? hours.startTime : parseISO(hours.startTime)

    const getTime = differenceInMinutes(hoursEndTime, hoursStartTime)
    const totalHours = Math.floor(getTime / 60);
    const remainingMinutes = getTime % 60;
    var additionalHours = 0;

    if (remainingMinutes >= 46 || 59 <= remainingMinutes) {
      additionalHours = 1;
    } else if (remainingMinutes >= 36 || 44 <= remainingMinutes) {
      additionalHours = 0.75;
    }else if (remainingMinutes >= 16 || 35 <= remainingMinutes) {
      additionalHours = 0.50;
    }else if (remainingMinutes >= 5 || 15 <= remainingMinutes) {
      additionalHours = 0.25;
    }

    // if (remainingMinutes > 45) {
    //   additionalHours = 1;
    // } else if (remainingMinutes > 0) {
    //   additionalHours = 0.5;
    // }

    if(hours.modeOfTraining !== "8"){
    const hourTraining = totalHours + additionalHours;
    return hourTraining
    }
  });
  const totalDifferenceAssessement = values.session.map((hours) => {
    const hoursEndTime = !isNaN(hours.endTime) ? hours.endTime : parseISO(hours.endTime);
    const hoursStartTime = !isNaN(hours.startTime) ? hours.startTime : parseISO(hours.startTime)
 
    const getTime = differenceInMinutes(hoursEndTime, hoursStartTime)
    const totalHours = Math.floor(getTime / 60);
    const remainingMinutes = getTime % 60;

    var additionalHours = 0;
    if (remainingMinutes >= 46 || 59 <= remainingMinutes) {
      additionalHours = 1;
    } else if (remainingMinutes >= 36 || 44 <= remainingMinutes) {
      additionalHours = 0.75;
    }else if (remainingMinutes >= 16 || 35 <= remainingMinutes) {
      additionalHours = 0.50;
    }else if (remainingMinutes >= 5 || 15 <= remainingMinutes) {
      additionalHours = 0.25;
    }

        // if (remainingMinutes > 45) {
    //   additionalHours = 1;
    // } else if (remainingMinutes > 0) {
    //   additionalHours = 0.5;
    // }

    if(hours.modeOfTraining === "8"){
    const hourTraining = totalHours + additionalHours;
    return hourTraining
    }
  });
  const totalSelectedTraining = sum(totalDifferenceTraining);
  const totalSelectedAssessemtn = sum(totalDifferenceAssessement);

  useEffect(() => {
    setValue('totalSelectedTraining', totalSelectedTraining);
    setValue('totalSelectedAssessement', totalSelectedAssessemtn);
  }, [setValue, totalSelectedTraining, totalSelectedAssessemtn]);
  
  function isDateInRange(selectedDate, startDate, endDate) {
    const selected = selectedDate;
    const start = startDate;
    const end = endDate;

    if (start > end) {
      throw new Error('Start date must be before or equal to end date');
    }
  
    return isWithinInterval(selected, { start, end });
  }


  const handleAdd = (newValue) => {

    if(!isDateInRange(newValue, values?.courseStartDate, values?.courseEndDate) && newValue){
      enqueueSnackbar('Selected date is not earlier than start date or not later than end date', {variant: "error"});
    }else if(!values?.sessionVenue){
      enqueueSnackbar('Please select venue.', {variant: "error"});
    }else {
      if(newValue){
        onSort();
        append({
          sessionVenue: values?.sessionVenue,
          endTime: values?.endTime,
          startDate: newValue,
          startTime: values?.startTime,
          modeOfTraining: values?.modeOfTraining_1,
          trainer: values.trainer,
        });
  
  
        if(totalSelectedTraining > totalTrainingAllowed){
          enqueueSnackbar('Training hours selected is more than Total Training Hours allowed', {variant: "warning"});
        }
      }
    }
  };
  const handleRemove = (index) => {
    remove(index);
  };

  const onSort = () => {
    values?.session.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }


  const fullAddress =  values?.sessionVenue ? `${values?.sessionVenue?.block} ${values?.sessionVenue?.street} ${values?.sessionVenue?.building} #${values?.sessionVenue?.floor}-${values?.sessionVenue?.unit}` : '-- -- -- -- ---'
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> {title} </Typography>

        {action && action}
      </Stack>

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
              sx={{ p: 3, bgcolor: 'background.neutral' }}
            >
            <RHFSelect
                name="modeOfTraining_1"
                label="Mode of Training"
                InputLabelProps={{ shrink: true }}
                sx={{
                  maxWidth: { md: 210 },
                }}
              >
                {courseMode.map((result) => {
                      return (
                        <MenuItem key={result.id} value={result.id}>
                          {result.value}
                        </MenuItem>
                      )
                    })}
              </RHFSelect>

              <Controller
              name="startTime"
             // control={control}
              render={({ field, fieldState: { error } }) => (
                <TimePicker
                  label="Start Time"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      width: 210,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

             <Controller
              name="endTime"
            //   control={control}
              render={({ field, fieldState: { error } }) => (
                <TimePicker
                  label="End Time"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      width: 210,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

              <RHFSelect
                    name="trainer"
                    label="Select Trainer"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      maxWidth: 340,
                    }}
                  >
                    {trainer?.map((result) => {
                      return (
                        <MenuItem key={result._id}  value={result}>
                          {result.fullName}
                        </MenuItem>
                      )
                    })}

              </RHFSelect>
              <Stack spacing={1}>
              <Typography variant="h6">Session Venue</Typography>
              <Typography variant="subtitle1">{fullAddress}</Typography>
              <Typography variant="body2">Singapore {values?.sessionVenue?.postalCode}, Training Room: {values?.sessionVenue?.room}</Typography>
              <Typography variant="body2">Wheelchair Access: {values?.venue?.wheelChairAccess ? "Yes" : "No"}</Typography>
          </Stack>
              <IconButton onClick={venueDialog.onTrue}>
                  <Iconify icon={'mingcute:add-line'} />
              </IconButton>

      </Stack>

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
              sx={{ p: 2, bgcolor: 'background.neutral' }}
            >
            <Controller
              name="startDate"
             // control={control}
              render={({ field, fieldState: { error } }) => (
                <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="Session Date"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue)
                    handleAdd(newValue);
                  }}
                  slotProps={{
                    textField: {
                      width: 210,
                      error: !!error,
                      helperText: error?.message,
                    },
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                />
              )}
            />

                <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((res) => (
                        <TableCell>{res.label}</TableCell>
                      )
                    )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
{/* 
                  <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>

            </Stack>
          </Stack> */}
                      {values?.session?.map((row, index) => (
                         <TableRow key={row?.id}>

                             <TableCell>
                             <Controller
                                name={`session[${index}].startDate`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <DatePicker
                                    label="Training Date"
                                    format="dd/MM/yyyy"
                                    value={!isNaN(field.value) ? field.value : parseISO(field.value)}
                                    onChange={(newValue) => {
                                      field.onChange(newValue);
                                    }}
                                    slotProps={{
                                      textField: {
                                        maxWidth: { md: 180 },
                                        error: !!error,
                                        helperText: error?.message,
                                      },
                                    }}
                                  />
                                )}
                              />

                             </TableCell>
                             <TableCell>
                             <RHFSelect
                            name={`session[${index}].modeOfTraining`}
                            label="Mode of Training"
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              maxWidth: { md: 280 },
                            }}
                          >
                            {courseMode.map((result) => {
                                  return (
                                    <MenuItem key={result.id} value={result.id}>
                                      {result.value}
                                    </MenuItem>
                                  )
                                })}
                          </RHFSelect>

                             </TableCell>
                             <TableCell>
                             <Controller
                              name={`session[${index}].startTime`}
                              control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <TimePicker
                                    label="Start Time"
                                    value={!isNaN(field.value) ? field.value : parseISO(field.value)}
                                    onChange={(newValue) => {
                                      field.onChange(newValue);
                                    }}
                                    slotProps={{
                                      textField: {
                                        width: 130,
                                        error: !!error,
                                        helperText: error?.message,
                                      },
                                    }}
                                  />
                                )}
                              />
                             </TableCell>
                             <TableCell>
                             <Controller
                               name={`session[${index}].endTime`}
                            //   control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                  label="End Time"
                                  value={!isNaN(field.value) ? field.value : parseISO(field.value)}
                                  onChange={(newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  slotProps={{
                                    textField: {
                                      width: 130,
                                      error: !!error,
                                      helperText: error?.message,
                                    },
                                  }}
                                />
                              )}
                            />

                             </TableCell>
                             <TableCell>
                             <RHFSelect
                               name={`session[${index}].trainer`}
                                label="Select Trainer"
                                value={row?.trainer}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  maxWidth: 340,
                                }}
                              >
                                 <MenuItem key={row?.trainer}  value={row?.trainer}>{row?.trainer?.fullName}</MenuItem>
                                {trainer?.map((result) => {
                                  return (
                                    <MenuItem key={result?._id}  value={result}>
                                      {result?.fullName}
                                    </MenuItem>
                                  )
                                })}

                          </RHFSelect>
                             </TableCell>
                             <TableCell>
                             {/* <IconButton onClick={venue.onTrue}>
                  <Iconify icon={'mingcute:add-line'} />
                </IconButton>
                             <IconButton
                                size="small"
                                color="error"
                                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                onClick={() => handleRemove(index)}
                              >
                                Remove
                              </IconButton> */}

                              <IconButton onClick={() => handleRemove(index)} color='error'>
                                   <Iconify icon={'solar:trash-bin-trash-bold'}/>
                              </IconButton>

                              <IconButton onClick={() => handleUpdateSession(row, index)} color='info'>
                                   <Iconify icon={'solar:plain-3-bold-duotone'}/>
                              </IconButton>
                            {/* {
                              sessionTpg ?
                              <Button
                              size="small"
                              color="info"
                              startIcon={<Iconify icon="mingcute:send-line" />}
                              onClick={() => {
                                handleUpdateSession(sessionTpg?.sessions[index]?.id);
                              }}
                            >
                               Update
                              </Button>
                            :
                            ""
 
                            } */}
 

                             </TableCell>
                        </TableRow>
                      ))}
       
                  </TableBody>
                  </Table>
                </TableContainer>
            </Stack>

            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3, bgcolor: 'background.neutral' }}>
                <Typography variant="title1">Total training hours selected: <span>{totalSelectedTraining}</span></Typography>
                <Typography variant="title1">Total Assessment hours Selected: {totalSelectedAssessemtn}</Typography>
            </Stack>

            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
                <LoadingButton variant="contained" onClick={() => {
                   onSort();
                   onClose()
                }}>Confirm</LoadingButton>
                <LoadingButton variant="contained" onClick={() =>  {
                  onSort();
                  onClose()
                }}>Close</LoadingButton>
            </Stack>
            <AddressVenueDialog
              title="Training Address"
              open={venueDialog.value}
              onClose={venueDialog.onFalse}
              company={company}
              onSelect={(venue) => setValue('sessionVenue', venue)}
            />

    </Dialog>
  );
}

TrainingSessionDialog.propTypes = {
  action: PropTypes.node,
  list: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.func,
  title: PropTypes.string,
  company: PropTypes.string,
  totalTrainingAllowed: PropTypes.number
};


