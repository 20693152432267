import { Helmet } from "react-helmet-async";
 import { CreateUpdateUser } from "../../sections/users/index.js";

export default function UserCreateUpdatePage(){
    return (
        <>
            <Helmet>
                <title>Users Create Update Page</title>
            </Helmet>
            <CreateUpdateUser />
        </>
    )
}