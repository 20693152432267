
import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../components/table/index.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../components/snackbar/index.js';
import Iconify from '../../../components/iconify/iconify.js';
import CompanyTableRow from './company-user-table-row.js';
import CompanyTableToolBar from './company-user-table-toolbar.js';
import Label from '../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import { useBoolean } from '../../../hooks/use-boolean.js';
import {exportDataToCSV} from '../../../util/export.js';


// GRAPHQL
import { GET_ALL_USERS, DELETE_USERS } from "../../../graphql/modules/users.js";
import { useQuery, useMutation } from "@apollo/react-hooks";
import BulkUploadUser from './company-user-bulk-upload.js';

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'Inactive'}
]
const TABLE_HEAD = [
  {id: 'fullName', label: 'Full Name'},
  {id: 'userName', label: 'Username'},
  {id: 'mobileNo', label: 'Mobile No'},
  {id: 'userRole', label: 'User Role'},
  {id: 'status', label: 'Status'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function CompanyUsersListView({companyinfo}) {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const company  = useSelector((state) => state?.auth?.company);
  const company_name  = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [filterRole, setFilterRole] = useState("All");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [DeleteCompanyUserMutation] =  useMutation(DELETE_USERS);

  const uploadUser = useBoolean();

  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      filter: filterRole,
      companyName: state?.databaseName || company,
      order: table.order || 'desc',
      orderBy: table.orderBy || 'createdDate',
      },
      fetchPolicy: "cache-and-network",
    });

 

    useEffect(() => {
        if(data){
          setResults(data?.getUsers)
        }
    }, [data])

    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (id) => {

      const {data: { deleteUsers }} = await DeleteCompanyUserMutation({
        variables: {
          _id: id,
          companyName: state?.databaseName || company,
          fullName: fullName,
        }
      })

      if(deleteUsers?.success){
        enqueueSnackbar('Deleted success!', {variant: 'success'});
        refetch();
      }else {
        enqueueSnackbar('Failed to delete!', {variant: 'error'});
      }
      },
      [refetch]
    );


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-users', {state: {info: companyinfo, users: row}})
  });

  const handleCreateNewUser = useCallback(() => {
    navigate('/company-create-update-users', {state: {info: companyinfo}})
 
});

const handleExport = useCallback(async () => {
  // table.onUpdatePageDeleteRow(90)
 const getExport = await refetch({
    limit: results?.count,
    offset: table?.page,
    searchText: searchText,
    filter: filterRole,
    companyName: state?.databaseName || company,
    order: table.order || 'desc',
    orderBy: table.orderBy || 'createdDate',
  })
  if(getExport?.data){
    exportDataToCSV( getExport?.data?.getUsers?.results, `${filterRole}_Users`)
    refetch({
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      filter: filterRole,
      companyName: state?.databaseName || company,
      order: table.order || 'desc',
      orderBy: table.orderBy || 'createdDate',
    });
  }
  // 
});


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading={`${companyinfo?.companyName || company_name} Users`}
         links={[
          {name: 'Dashboard'},
          {name: 'User List'}
         ]}
         action ={
          <>
          <Button
          componet={RouterLink}
          onClick={() => uploadUser.onTrue()}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          sx={{
            mr: 1
          }}
          >
            Bulk Upload Trainee
          </Button>
                  <Button
                  componet={RouterLink}
                  onClick={handleCreateNewUser}
                  variant="contained"
                  startIcon={<Iconify icon="mingcure:add-line" />}
                  >
                    New User
                  </Button>
                  </>
         }
        />


        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'active' && 'success') ||  (tab.value === 'inactive' && 'warning') || 'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'active' && results?.activeCount}
                  {tab.value === 'inactive' && results?.inactiveCount}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <CompanyTableToolBar onFilters={handleFilters} 
          setFilterChange={setFilterRole}
          filterRole={filterRole}
          handleExport={handleExport}
          />
          <Scrollbar>
          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <CompanyTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewAccount(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>
                </Scrollbar>
          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

        <BulkUploadUser 
          title="User Bulk Upload"
          open={uploadUser.value}
          onClose={() => {
            refetch();
            uploadUser.onFalse();
          }}
        />

    </Container>
  );
}

CompanyUsersListView.propTypes = {
  companyinfo: PropTypes.object,
};