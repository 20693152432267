import React from 'react';
import { useLocation } from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AUTH_VERIFY } from "../../graphql/modules/auth.js";
import { useMutation } from "@apollo/react-hooks";
import { loginUser } from "../../store/modules/index.js";
import { useDispatch } from "react-redux";
import { useSnackbar } from '../../components/snackbar/index.js';

// components
// import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export function OtpForm() {
    const {state} = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [otp, setOtp] = React.useState('');

    
    const handleChange = (newValue) => {
      setOtp(newValue)
    }


      const [AuthVerifyMutation] = useMutation(AUTH_VERIFY, {
        variables: {
            token: otp,
            companyName: state?.state?.companyName
        }
      });
      
      

      const handleLogin = async (e) => {
        e.preventDefault();
        try {
        const { data: { verifyOTP }, } = await AuthVerifyMutation();
        if(verifyOTP.success){
            dispatch(loginUser(state?.authLogin));
            enqueueSnackbar(verifyOTP?.message, {variant: "success"});
        }else {
          enqueueSnackbar(verifyOTP?.message, {variant: "error"});
            console.log(verifyOTP)
        }

 
        } catch (error) {
          enqueueSnackbar(error, {variant: "error"});
        }
      };

  return (
    <>
      <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh'
      }}
      >
        <Stack
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
             spacing={2}
            sx={{
            mx: 'auto',
            maxWidth: 620,
            px: {xs: 2, md:8},
            py: {xs:15, md:30}
            }}
        >
            <Typography variant='body2'>Please enter the OTP sent to your authenticator </Typography>
        <MuiOtpInput TextFieldsProps={{ placeholder: '-' }} length={6} value={otp} onChange={handleChange} />
            <Stack
            component="main"
            direction="row"
            spacing={2}
            >
                <LoadingButton  fullWidth size="large"  variant="contained" >
                            Cancel
                </LoadingButton>
                <LoadingButton onClick={handleLogin} fullWidth size="large"  variant="contained" >
                            Verify
                </LoadingButton>
            </Stack>
        </Stack>
       
    </Stack>
    </>
  );
}