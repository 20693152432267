import { Helmet } from "react-helmet-async";
 import { SubscriptionListView } from "../../sections/subscription/index.js";

export default function SubscriptionPage(){
    return (
        <>
            <Helmet>
                <title>Subscription List Page</title>
            </Helmet>
            <SubscriptionListView />
        </>
    )
}