import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { useLocation } from 'react-router-dom';
import { Stack, Dialog, DialogContent, DialogTitle, DialogActions, Box, Divider} from '@mui/material';

import {LoadingButton} from '@mui/lab';
// import {_paymentStatus} from '../../../util/course.js';
import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFMultiSelect
  } from '../../../components/hook-form/index.js';
  import { role_tm } from '../../../util/users.js';

import { GET_ANNOUNCEMENT, CREATE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from "../../../graphql/modules/_client/announcement.js";

import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";



// ----------------------------------------------------------------------

export default function AnnouncementDialog({open, onClose}) {
    const fullName = useSelector((state) => state?.auth?.user?.fullName);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const [isLoading, setIsLoading] = useState(false)
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [announcementResult, setAnnouncementResult] = useState(null);
    const [CreateAnnouncementMutation] =  useMutation(CREATE_ANNOUNCEMENT);
    const [UpdateAnnouncementMutation] =  useMutation(UPDATE_ANNOUNCEMENT);
    
    const defaultValues = {
      title: announcementResult?.title || '' ,
      description: announcementResult?.description || '',
      announcementTitle: announcementResult?.announcementTitle || '',
      announcementDescription: announcementResult?.announcementDescription || '',
      userRole: announcementResult?.userRole || []
    };

    const { data: announcementData , loading: announcementLoading, error: announcementErr, refetch: announcementRefetch } = useQuery(GET_ANNOUNCEMENT, {
        variables: {
          companyName: company
          },
          fetchPolicy: "cache-and-network",
        });



    const newAnnouncement = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        description: Yup.string().required('description is required')
    
      });
    


      const methods = useForm({
        resolver: yupResolver(newAnnouncement),
        defaultValues,
      });
    
      const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
      } = methods;

      const onSubmit = handleSubmit(async (data) => {
        try {
            if(announcementResult){
                const {data: { updateAnnouncement }} = await UpdateAnnouncementMutation({
                    variables: {
                      companyName: company,
                      fullName: fullName,
                      _id: announcementResult?._id,
                        data: {
                          title: data?.title,
                          description: data?.description,
                          announcementTitle: data?.announcementTitle,
                          announcementDescription: data?.announcementDescription,
                          userRole: data?.userRole,
                          modifiedBy: fullName,
                          dateModified: new Date(),
                        }
                      }
                  });
                  if(updateAnnouncement?.success){
                    //enqueueSnackbar("Update successfully", {variant: "success"});
                    onClose();
                  }else {
                    enqueueSnackbar("Error", {variant: "error"});
                  }
            }else {
                const {data: { createAnnouncement }} = await CreateAnnouncementMutation({
                    variables: {
                      companyName: company,
                      fullName: fullName,
                        data: {
                          title: data?.title,
                          description: data?.description,
                          createdBy: fullName,
                          dateCreated: new Date(),
                          modifiedBy: fullName,
                          dateModified: new Date(),
                        }
                      }
                  });
                  if(createAnnouncement?.success){
                    //enqueueSnackbar("Created successfully", {variant: "success"});
                    onClose();
                  }else {
                    enqueueSnackbar("Error", {variant: "error"});
                  }
            }

          } catch (error) {
            console.error(error);
          }
      });

      useEffect(() => {
     
        if(open){
          setAnnouncementResult(announcementData?.getAnnouncement?.results[0])
          reset(announcementData?.getAnnouncement?.results[0]);
        }
    }, [announcementResult, open, reset])



   
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Announcement Form</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >

              <RHFTextField name="title" label="General Announcement" />
              <RHFTextField
               multiline rows={4}
               name="description" label="General Announcement Description" />

          <Divider orientation="vertical" />
            <RHFMultiSelect
                checkbox
                name="userRole"
                label="User Role"
                options={role_tm}
                sx={{ minWidth: 280 }}
              />
              <RHFTextField name="announcementTitle" label="Announcement Title"  />
              <RHFTextField
               multiline rows={4}
               name="announcementDescription" label="Announcement Description" />

            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>Save</LoadingButton>
              <LoadingButton variant="contained" loading={isLoading} onClick={onClose}>Close</LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AnnouncementDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
