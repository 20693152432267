import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate, fTime } from '../../../../util/formatTime.js';
import { fCurrency } from '../../../../util/format-number.js';
import { text } from 'body-parser';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col0: { width: '5%' },
        col1: { width: '10%' },
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        p1: { paddingTop: 11 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body_1: { fontSize: 10, textTransform: 'lowercase' },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800, textTransform: 'lowercase'},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMargin_1: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'left'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRowHead: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "gray",
          borderColor: '#000000',
        },
        tableRowTop: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "green",
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultAtt: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
      }),
    []
);

// ----------------------------------------------------------------------

export default function AssessmentPDF({ attendance, companyProfile }) {
  const styles = useStyles();
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const filterAssessor = attendance?.sessions.filter((res) => res?.modeOfTraining === 8 || res?.modeOfTraining === "8")
  const assessmentInfo = filterAssessor?.length !== 0 ?  filterAssessor[0] : [];

  const filterAndCount = (arr, name) => {
    const filtered = arr?.filter(ass => ass?.assessmentSummary?.criteriaResults === name);
    return filtered.reduce((acc, res) => {
      const { criteriaResults } = res?.assessmentSummary;
      acc[criteriaResults] = (acc[criteriaResults] || 0) + 1;
      return acc;
    }, {});
  };

  const passCount = filterAndCount(attendance?.enrolment, 'Pass');
  const failCount = filterAndCount(attendance?.enrolment, 'Fail');

  const passRate = (passCount?.Pass / attendance?.enrolment?.length ) * 100;

  const trainee = attendance?.enrolment;
  const course = attendance?.course;
  const courseDate = attendance?.courseDates;
  const session  = attendance?.sessions;
//   const nricFin = res?.nricFin ? res?.nricFin.slice(0, 1) + '*'.repeat(res?.nricFin?.length -4) + res?.nricFin?.slice(-4) : "";
console.log(attendance)


  return (
 
    <Document>
      <Page size="A4" style={styles.page} orientation='landscape'>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
   
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.body2, styles.textMargin]}>{`Course Title: ${course?.courseTitle}`}</Text>
              </View>
              <View style={[styles.tableCell_1]}>
              <Text style={[styles.body2, styles.textMargin]}>{`Assessment Period: ${fDate(assessmentInfo?.startDate)}`}</Text>
              </View>
              <View style={[styles.tableCell_1]}>
              <Text style={[styles.body2, styles.textMargin]}>{`Duration: ${course?.ssgSupportedAssessmentHours}`}</Text>
              </View>
           </View>
           </View>

           <View style={styles.table}>
            <View>
              <View style={styles.tableRowTop}>
                <View style={[styles.col1, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_2]}>S/N</Text>
                </View>
                <View style={[styles.colx, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Learner Name</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>NRIC</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Company</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Date</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Time In</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Time Out</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Sign</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Result</Text>
                </View>
                <View style={[styles.col2, styles.resultAtt]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Remark</Text>
                </View>
              </View>
            </View>
           
                      {
                        trainee?.map((tr, index) => {
                          const nricFin = tr?.traineeInfo ? tr?.traineeInfo?.nricFin.slice(0, 1) + '*'.repeat(tr?.traineeInfo?.nricFin?.length -4) + tr?.traineeInfo?.nricFin?.slice(-4) : "";
                          const company = tr?.sponsorshipType === "Employer" ? tr?.employer?.empcompanyName : "NA";
                          return (
                            <View style={styles.tableRow}>
                            <View style={[styles.col1, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}> {index + 1}</Text>
                            </View>
                            <View style={[styles.colx, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}> {tr?.traineeInfo?.fullName} </Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}> {nricFin} </Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}> {company} </Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}> {fDate(tr?.assessmentSummary?.dateCreated)} </Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}>{fTime(tr?.assessmentSummary?.assessmentStartTime)}</Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}>{fTime(tr?.assessmentSummary?.assessmentEndTime)}</Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                            {tr?.assessmentSummary
                                ?  <Image s src={`${tr?.assessmentSummary?.signature}`}  style={{ width: 60, height: 60 }} /> 
                                : null
                              }
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}>{tr?.assessmentSummary?.criteriaResults}</Text>
                            </View>
                            <View style={[styles.col2, styles.resultAtt]}>
                              <Text style={[styles.body2, styles.textMargin_x1]}>{tr?.assessmentSummary?.criteriaRemarks}</Text>
                            </View>
                          </View>
                          )
                        })
                      }
            <View>

             <View style={styles.tableRow}>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>Assessor Name: {assessmentInfo?.trainer?.fullName} </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>No. of C: {passCount?.Pass ? passCount?.Pass : 0}</Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>Passing Rate </Text>
                  </View>
            </View> 

            <View style={styles.tableRow}>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>Assessor Sign: </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>No. of NYC: {failCount?.Fail ? failCount?.Fail : 0}</Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.resultAtt]}>
                      <Text style={[styles.body2, styles.textMargin_x1]}>{passRate ? passRate?.toFixed(2) + '%' : '0%'}</Text>
                  </View>
            </View>  
          
            </View>
          </View>
  


      </Page>
    </Document>

  );
}

AssessmentPDF.propTypes = {
  attendance: PropTypes.object,
  companyProfile: PropTypes.object
}