import { Box, Stack } from "@mui/material";
import { hideScroll } from "../../theme/css.js";

import { NavSectionMini } from '../../components/navsection/mini/index.js';

import { NAV } from './config-layout.js';
import Navigation from './config-navigation.js';
import {NavToggleButton} from './_common/index.js';

export default function NavMini(){

    return (
        <Box
        component="nav"
        sx={{
            flexShrink: { lg: 0},
            width: {lg: NAV.W_MINI}
        }}
        >
            <NavToggleButton
            sx={{
                top:22,
                left: NAV.W_MINI - 12
            }}
            />
            <Stack 
            sx={{
                pb: 2,
                height: 1,
                position: 'fixed',
                width: NAV.W_MINI,
                borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                ...hideScroll.x
            }}>
                <NavSectionMini
                data={Navigation()}
                />

            </Stack>

        </Box>
    )
}
