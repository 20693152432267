import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd/MM/yyyy';
  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';
  return date ? format(new Date(date), fm) : '';
}

export function fTime(date, newFormat) {
  const fm = newFormat || 'p';
  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDateMonthYear(date, newFormat) {
  const fm = newFormat || 'MMMM yyyy';
  return date ? format(new Date(date), fm) : '';
}

// SGG FORMAT
export function SSGDate(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';
  return date ? format(new Date(date), fm) : '';
}

export function SSGTime(date, newFormat) {
  const fm = newFormat || 'HH:mm';
  return date ? format(new Date(date), fm) : '';
}

export function SSGDateNoHype(date, newFormat) {
  const fm = newFormat || 'yyyyMMdd';
  return date ? format(new Date(date), fm) : '';
}
export function LoginId(date, newFormat) {
  const fm = newFormat || 'ddMMyyyy';
  return date ? format(new Date(date), fm) : '';
}