import gql from "graphql-tag";

export const GRANTS_PERSONALIZED = gql`
mutation GrantsPersonalized($data: GrantsInput!, $encryptionkey: String!, $_companyName: String!) {
    grantsPersonalized(data: $data, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
    results
  }
}`;

export const GRANTS_SEARCH = gql`
mutation SearchGrants($data: SearchGrantsInput!, $encryptionkey: String!, $_companyName: String!) {
  searchGrants(data: $data, encryptionkey: $encryptionkey, _companyName: $_companyName) {
    code
    success
    message
    results
  }
}`;

