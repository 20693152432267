import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useCallback} from 'react';
import { Stack, Popover, appBarClasses } from '@mui/material';
import {useActiveLink} from '../../../routes/hooks/use-active-link.js';
import NavItem from './nav-item.js'
export default function NavList({data, depth, hasChild, config}){
    const navRef = useRef(null);
    const active = useActiveLink(data.path, hasChild)
    const [open, setOpen] = useState(active);
    useEffect(() => {
        if(open){
            handlClose();
        }
    }, [])




    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev)
    }, [])

    const handlClose = useCallback(() => {
        setOpen(false);
    })

    return (
        <>
        <NavItem
        ref={navRef}
         item={data}
         depth={depth}
         open={open}
         active={active}
        //  externalLink={externalLink}
         onMouseEnter={handleToggle}
         onMouseEvent={handlClose}
         config={config}

        />
        {hasChild && (
            <Popover
                open={open}
                anchorEl={navRef.current}
                anchorOrigin={{ vertical: 'center', horizontal: 'right'}}
                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                slotProps={{
                    paper: {
                        onMouseEnter: handleToggle,
                        onMouseEvent: handlClose,
                        sx: {
                            mt: 0.5,
                            width: 160,
                            ...(open && {
                                pointerEvents: 'auto'
                            })
                        }
                    }
                }}
                sx={{
                    pointerEvents: 'none'
                }}
            >
                <NavSubList data={data.children} depth={depth} config={config} />

            </Popover>
        )}

        
        </>
    )

}

NavList.propTypes = {
    config: PropTypes.object,
    data: PropTypes.object,
    depth: PropTypes.number,
    hasChild: PropTypes.bool
}


function NavSubList({ data, depth, config}) {
    return (
        <Stack spacing={0.5}>
            {data.map((list) => {
                <NavList
                    key={list.title + list.path}
                    data={list}
                    depth={depth+1}
                    hasChild={!!list.children}
                    config={config}
                />
            })}
        </Stack>
    )
}

NavSubList.propTypes = {
    config: PropTypes.object,
    data: PropTypes.array,
    depth: PropTypes.number,
}