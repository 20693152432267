import gql from "graphql-tag";

export const GET_ANNOUNCEMENT= gql`
query GetAnnouncement($companyName: String!) {
    getAnnouncement(companyName: $companyName) {
        code
        success
        message
        results
  }
}`;

export const CREATE_ANNOUNCEMENT= gql`
mutation CreateAnnouncement($data: AnnouncementInput!, $fullName: String!, $companyName: String!) {
  createAnnouncement(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_ANNOUNCEMENT = gql`
mutation UpdateAnnouncement($_id: ID!, $data: AnnouncementInput!, $fullName: String!, $companyName: String!) {
  updateAnnouncement( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;