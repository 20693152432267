
import { useState, useEffect, useCallback} from 'react';
import {result, sumBy} from 'lodash';
import { Stack , Container, alpha, useTheme, Typography, Card, Box, Divider, CircularProgress, IconButton, CardHeader, ButtonBase, MenuItem} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import { useSelector } from 'react-redux';
import { useSettingsContext } from '../../components/settings/index.js';
import { bgGradient } from '../../theme/css.js';
import InvoiceAnalytic from '../invoice/invoice-analytics.js';
import Iconify from '../../components/iconify/iconify.js';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { GET_ALL_DASHBOARD, GET_CLIENT_INVOICE, GET_CLIENTDASHBOARD_INFO } from "../../graphql/modules/dashboard.js";
import { GET_ANNOUNCEMENT } from "../../graphql/modules/_client/announcement.js";
import AppAreaInstalled from './graph/app-invoice.js';
// import ReactApexChart from "react-apexcharts";
import Chart, { useChart } from 'src/components/chart/index.js';
// import Chart from 'react-apexcharts/dist/react-apexcharts.js'
import { useQuery } from "@apollo/react-hooks";
import { fCurrency } from '../../util/format-number.js';
import { setDate } from 'date-fns';



export function ClientDashboard({img, ...other}) {
  const fullName = useSelector((state) => state?.auth?.user?.fullName);
  const company = useSelector((state) => state?.auth?.company);
  const isRole = useSelector((state) => state?.auth?.isRole);
  const [results, setResults] = useState(null);
  const [invResult, setInvResult] = useState(null);
  const [announcementResult, setAnnouncementResult] = useState(null);
  const theme = useTheme();
  const settings = useSettingsContext();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
 

  const { data, loading, error, refetch } = useQuery(GET_CLIENTDASHBOARD_INFO, {
    variables: {
      companyName: company,
      dateFrom: dateFrom,
      dateTo: dateTo
      },
      fetchPolicy: "cache-and-network",
    });

    const { data: invData , loading: invLoading, error: invErr, refetch: invRefetch } = useQuery(GET_CLIENT_INVOICE, {
      variables: {
        companyName: company,
        dateFrom: dateFrom,
        dateTo: dateTo
        },
        fetchPolicy: "cache-and-network",
      });

      const { data: announcementData , loading: announcementLoading, error: announcementErr, refetch: announcementRefetch } = useQuery(GET_ANNOUNCEMENT, {
        variables: {
          companyName: company
          },
          fetchPolicy: "cache-and-network",
        });

  

    useEffect(() => {
      if(data){
        setResults(data?.getClientDashboardInfo);
      }
      if(invData){
        setInvResult(invData?.getClientDashboardInvoice)
      }
      if(announcementData){
        setAnnouncementResult(announcementData?.getAnnouncement)
      }
  }, [data, invData, announcementData, dateFrom, dateTo])



  // const popover = usePopover();

  // const [seriesData, setSeriesData] = useState('2024');

  // const handleChangeSeries = useCallback(
  //   (newValue) => {
  //     popover.onClose();
  //     setSeriesData(newValue);
  //   },
  //   [popover]
  // );

  // const chartOptions = useChart({
  //   colors: colors.map((colr) => colr[1]),
  //   fill: {
  //     type: 'gradient',
  //     gradient: {
  //       colorStops: colors.map((colr) => [
  //         { offset: 0, color: colr[0] },
  //         { offset: 100, color: colr[1] },
  //       ]),
  //     },
  //   },
  //   xaxis: {
  //     categories: [
  //       'Jan',
  //       'Feb',
  //       'Mar',
  //       'Apr',
  //       'May',
  //       'Jun',
  //       'Jul',
  //       'Aug',
  //       'Sep',
  //       'Oct',
  //       'Nov',
  //       'Dec',
  //     ],
  //   },
  //   series: [
  //     {
  //       year: '2019',
  //       data: [
  //         {
  //           name: 'Asia',
  //           data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 35, 51, 49],
  //         },
  //         {
  //           name: 'America',
  //           data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 13, 56, 77],
  //         },
  //       ],
  //     },
  //     {
  //       year: '2020',
  //       data: [
  //         {
  //           name: 'Asia',
  //           data: [51, 35, 41, 10, 91, 69, 62, 148, 91, 69, 62, 49],
  //         },
  //         {
  //           name: 'America',
  //           data: [56, 13, 34, 10, 77, 99, 88, 45, 77, 99, 88, 77],
  //         },
  //       ],
  //     },
  //   ],
  // });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3} >
        <Grid xs="12" md={8}>
          <Stack flexDirection={{xs: 'column', md: 'row'}}
          sx={{
            ...bgGradient({
              direction: '135deg',
              startColor: alpha(theme.palette.primary.light, 0.2),
              endColor: alpha(theme.palette.primary.main, 0.2)
            }),
            height: {md: 1},
            borderRadius: 2,
            position: 'relative',
            color: 'primary.darker',
            backgroundColor: 'common.white'
          }}
          {...other}
          >
            
            <Stack
            flexGrow={1}
            justifyContent="center"
            alignItems={{xs: 'center', md: 'flex-start'}}
            sx={{
              p: {
                xs: theme.spacing(5, 3, 0,3),
                md: theme.spacing(5)
              },
              textAlign: {xs: 'center', md: 'left'}
            }}
            >
              <Typography variant='h5' sx={{mb: 2, whiteSpace: 'pre-line'}}>Hi Welcome back {fullName} 👋</Typography>
              <Typography variant='h6'>{announcementResult?.results ? announcementResult?.results[0]?.title : "Admin Announcement" }</Typography>
              <Typography
              variant="body2"
              sx={{
                opacity: 0.8,
                maxWidth: 360,
                mb: {xs: 3, xl: 5}
              }}
              >
                {announcementResult?.results ? announcementResult?.results[0]?.description : "this is no announcement" }
              </Typography>

              {
                isRole?.some((role)=> announcementResult?.results[0]?.userRole?.includes(role)) || isRole?.some((role)=> ["bo", "tm"].includes(role)) ?
                <>
               <Typography variant='h6'>{announcementResult?.results ? announcementResult?.results[0]?.announcementTitle : "Admin Announcement" }</Typography>
                  <Typography
                  variant="body2"
                  sx={{
                    opacity: 0.8,
                    maxWidth: 360,
                    mb: {xs: 3, xl: 5}
                  }}
                  >
                    {announcementResult?.results ? announcementResult?.results[0]?.announcementDescription : "this is no announcement" }
                  </Typography>
                </>
                :
                ""
              }


            </Stack>
          </Stack>

          {img && (
            <Stack
            component="span"
            justifyContent="center"
            sx={{
              p: {xs: 5, md: 3},
              maxWidth: 360,
              mx: 'auto'
            }}>
              {img}
            </Stack>
          )}

        </Grid>

        <Grid xs="12" md={4}>
          <Stack flexDirection={{xs: 'column', md: 'row'}}
          sx={{
            ...bgGradient({
              direction: '135deg',
              startColor: alpha(theme.palette.primary.light, 0.2),
              endColor: alpha(theme.palette.primary.main, 0.2)
            }),
            height: {md: 1},
            borderRadius: 2,
            position: 'relative',
            color: 'primary.darker',
            backgroundColor: 'common.white'
          }}
          {...other}
          >
            
            <Stack
            flexGrow={1}
            justifyContent="center"
            alignItems={{xs: 'center', md: 'flex-start'}}
            sx={{
              p: {
                xs: theme.spacing(5, 3, 0,3),
                md: theme.spacing(5)
              },
              textAlign: {xs: 'center', md: 'left'}
            }}
            >
              <Typography variant='h5' sx={{mb: 2, whiteSpace: 'pre-line'}}>System Announcement</Typography>
              {/* <Typography
              variant="body2"
              sx={{
                opacity: 0.8,
                maxWidth: 360,
                mb: {xs: 3, xl: 5}
              }}
              >
                Updated
              </Typography> */}

            </Stack>
          </Stack>

          {img && (
            <Stack
            component="span"
            justifyContent="center"
            sx={{
              p: {xs: 5, md: 3},
              maxWidth: 360,
              mx: 'auto'
            }}>
              {img}
            </Stack>
          )}

        </Grid>

        <Grid container xs={12} md={12} lg={12} sx={{
          display: isRole?.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
        }}>
            <Grid item xs={12} md={4} lg={3}>
                <DatePicker
                  label="Date From"
                  format='dd/MM/yyyy'
                  value={dateFrom}
                  onChange={(event) => {
                    setDateFrom(event)
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
                       </Grid>
                 <Grid xs={12} md={4} lg={3}>
                   <DatePicker
                  label="Date To"
                  format='dd/MM/yyyy'
                  value={dateTo}
                  onChange={(event) => {
                    setDateTo(event)
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setDateFrom(null);
                      setDateTo(null);
                    }}
   
                  >
              <Iconify icon="carbon:reset" width={24} />
            </IconButton>
                </Grid>
       
        </Grid>

        <Grid xs="12" md={4}
        sx={{
          display: isRole?.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
         }}
        >
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Users</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{results?.countUsers}</Typography>
            </Box>
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
              <Iconify icon={'codicon:organization'} width={32} sx={{ color: theme.palette.info.main, position: 'absolute' }} />

                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.info.main, opacity: 0.48 }}
                />

                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={3}
                  sx={{
                    top: 0,
                    left: 0,
                    opacity: 0.48,
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.grey[500], 0.16),
                  }}
                />
              </Stack>
      </Card>

        </Grid>

        <Grid xs="12" md={4}
          sx={{
              display: isRole?.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
           }}
        >
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Class</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{results?.countClass}</Typography>
            </Box>
            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
              <Iconify icon={'streamline:class-lesson'} width={32} sx={{ color: theme.palette.warning.main, position: 'absolute' }} />

                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={2}
                  sx={{ color: theme.palette.warning.main, opacity: 0.48 }}
                />

                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={56}
                  thickness={3}
                  sx={{
                    top: 0,
                    left: 0,
                    opacity: 0.48,
                    position: 'absolute',
                    color: (theme) => alpha(theme.palette.grey[500], 0.16),
                  }}
                />
              </Stack>
      </Card>
        </Grid>
        <Grid xs="12" md={4}
          sx={{
              display: isRole?.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
           }}
        >
        <Card sx={{ display: 'flex', alignItems: 'center', p: 3}} {...other}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Total Course</Typography>
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            </Stack>
            <Typography variant="h3">{results?.countCourse}</Typography>
            </Box>

            <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
        <Iconify icon={'arcticons:zoho-invoice'} width={32} sx={{ color: theme.palette.error.main, position: 'absolute' }} />

          <CircularProgress
            variant="determinate"
            value={100}
            size={56}
            thickness={2}
            sx={{ color: theme.palette.error.main, opacity: 0.48 }}
          />

          <CircularProgress
            variant="determinate"
            value={100}
            size={56}
            thickness={3}
            sx={{
              top: 0,
              left: 0,
              opacity: 0.48,
              position: 'absolute',
              color: (theme) => alpha(theme.palette.grey[500], 0.16),
            }}
          />
        </Stack>
   
      </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}
        sx={{
                    display: isRole?.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
        }}
      >

          <Grid xs={12} md={12} lg={12}>
          {
            invResult?.invoiceAmount ?
            <AppAreaInstalled
            title="Invoice"
            chart={{
              categories: [
                'Total Invoice',
                'Paid Invoice',
                'Pending Invoice',
                'Overdue invoice',
                'Draft Invoice'
              ],
              series: [
                {
                  year: '2024',
                  data: [
                    {
                      name: 'Total Amount',
                      data: invResult?.invoiceAmount
                    }
                  ],
                }
              ],
            }}
          />
           :
           <></>
          }


        </Grid>
      </Grid>
    </Container>
  );
}