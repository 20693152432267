import * as Yup from 'yup';
import { useMemo } from 'react';
import { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import { useBoolean } from '../../../hooks/use-boolean.js';
import { Card, Container, Stack, Divider, MenuItem, Box, Avatar, Chip,  } from '@mui/material';

import { useForm, Controller } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import { useSettingsContext } from '../../../components/settings/index.js';
import { useResponsive } from '../../../hooks/useResponsive.js';
import {courseMode, fundedCourse, _tourGuides, _AssessmentMethod, mediumInstruction} from '../../../util/course.js';
import {fDate} from '../../../util/formatTime.js';
import AsessmentRecordForm from './company-course-assessment-record-form.js'
import AssessmentForm from './assessment-form.js';

import { useSnackbar } from '../../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFSelect,
    RHFAutocomplete
  } from 'src/components/hook-form';
  import { GET_TRAINERASSESSOR } from "../../../graphql/modules/users.js";
  import { CREATE_COURSE, UPDATE_COURSE } from "../../../graphql/modules/_client/course.js";
  import { useMutation, useQuery } from "@apollo/react-hooks";


export default function CreateUpdateCourseCompany({ currentInvoice }) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const upMd = useResponsive('up', 'md');
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const [results, setResults] = useState(null);
  const navigate = useNavigate();
  const [CreateCourseMutation] =  useMutation(CREATE_COURSE);
  const [UpdateCourseMutation] =  useMutation(UPDATE_COURSE);
  const openForm = useBoolean();

  const { data, loading, error, refetch } = useQuery(  GET_TRAINERASSESSOR, {
    variables: {
      companyName: state?.info?.databaseName || company,
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getTrainerAssessor)
      }
  }, [data])



  const NewUserSchema = Yup.object().shape({
    courseTitle: Yup.string().required('Course Title is required'),
    tsgRefNumber: Yup.string().required('TGS reference number is required'),
    courseDescription: Yup.string().required('Course description required'),
    proposedTrainingHours: Yup.number().required('Proposed training hours is required'),
    proposedAssessmentHours: Yup.number().required('Proposed assessment hours is required'),
    validUntil: Yup.date().typeError('Invalid Date'),
    approvalDate: Yup.date().typeError('Invalid Date'),
    ssgSupportedTrainingHours: Yup.number().required('SSG supported training hours is required'),
    ssgSupportedAssessmentHours: Yup.number().required('SSG supported assessment hours is required'),
    courseFee: Yup.number().required('Course fee  is required'),
    fundedCourses: Yup.string().required('Course funding is required'),
    mediumInstruction: Yup.string().required('Medium of Instruction is required'),
    courseRunMode: Yup.string().required('Course Run Mode of Training is required'),
    trainerAssessor: Yup.array().required('Role is required'),
  });

  const defaultValues = useMemo(
    () => ({
      courseTitle: state?.course?.courseTitle || '',
      courseSkillCode: state?.course?.courseSkillCode || '',
      tsgRefNumber: state?.course?.tsgRefNumber || '',
      courseDescription: state?.course?.courseDescription || '',
      proposedTrainingHours: state?.course?.proposedTrainingHours || 0,
      proposedAssessmentHours: state?.course?.proposedAssessmentHours || 0,
      validUntil: parseISO(state?.course?.validUntil) || null,
      approvalDate: parseISO(state?.course?.approvalDate) || null,
      ssgSupportedTrainingHours: state?.course?.ssgSupportedTrainingHours || 0,
      ssgSupportedAssessmentHours: state?.course?.ssgSupportedAssessmentHours || 0,
      courseFee: state?.course?.courseFee || 0,
      fundedCourses: state?.course?.fundedCourses || '',
      mediumInstruction: state?.course?.mediumInstruction || '',
      courseRunMode: state?.course?.courseRunMode || '',
      trainerAssessor: state?.course?.trainerAssessor || [],
      assessmentRecordForm: state?.course?.assessmentRecordForm || []
    }),
    [state]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    if(state?.course){
      const {data: { updateCourse }} = await UpdateCourseMutation({
        variables: {
          companyName: state?.info?.databaseName || company,
          _id: state?.course?._id,
          fullName: fullName,
            data: {
              courseTitle: data?.courseTitle,
              courseSkillCode: data?.courseSkillCode,
              tsgRefNumber: data?.tsgRefNumber,
              courseDescription: data?.courseDescription,
              proposedTrainingHours: data?.proposedTrainingHours,
              proposedAssessmentHours: data?.proposedAssessmentHours,
              validUntil: data?.validUntil,
              approvalDate: data?.approvalDate,
              ssgSupportedTrainingHours: data?.ssgSupportedTrainingHours,
              ssgSupportedAssessmentHours: data?.ssgSupportedAssessmentHours,
              courseFee: data?.courseFee,
              fundedCourses: data?.fundedCourses,
              mediumInstruction: data?.mediumInstruction,
              courseRunMode: data?.courseRunMode,
              trainerAssessor: data?.trainerAssessor,
              assessmentRecordForm: data?.assessmentRecordForm,
              createdBy: fullName,
              dateCreated: state?.course.dateCreated, 
              modifiedBy: fullName,
              dateModified: new Date(),
            }
          }
      });

      
      if(updateCourse?.message){
          enqueueSnackbar(state ? 'Update success!' : 'Create success!');
          navigate(`/${company}/course`)
      }else {
        enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
      }
    }else {
    const {data: { createCourse }} = await CreateCourseMutation({
      variables: {
        companyName: state?.info?.databaseName || company,
        fullName: fullName,
          data: {
            courseTitle: data?.courseTitle,
            courseSkillCode: data?.courseSkillCode,
            tsgRefNumber: data?.tsgRefNumber,
            courseDescription: data?.courseDescription,
            proposedTrainingHours: data?.proposedTrainingHours,
            proposedAssessmentHours: data?.proposedAssessmentHours,
            validUntil: data?.validUntil,
            approvalDate: data?.approvalDate,
            ssgSupportedTrainingHours: data?.ssgSupportedTrainingHours,
            ssgSupportedAssessmentHours: data?.ssgSupportedAssessmentHours,
            courseFee: data?.courseFee,
            fundedCourses: data?.fundedCourses,
            mediumInstruction: data?.mediumInstruction,
            courseRunMode: data?.courseRunMode,
            trainerAssessor: data?.trainerAssessor,
            assessmentRecordForm: data?.assessmentRecordForm,
            createdBy: fullName,
            dateCreated: new Date(),
            modifiedBy: fullName,
            dateModified: new Date(),
          }
        }
    });

    if(createCourse?.message){
      console.log("success")
      reset();
        enqueueSnackbar(state ? 'Update success!' : 'Create success!');
    }else {
      console.log("error")
      enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
    }
  }
  });
 

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading={state?.course ? "Update Course" : "Create Course"}
                links={[
                {name: 'Dashboard'},
                {name: 'Course List'},
                {name: state?.course ? 'Update Course' : 'Create Course'}
                ]}
            />

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
        
            </Stack>

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
          >
            <RHFTextField
            name="courseTitle"
            label="Course Title"
          />
            <RHFTextField
            name="courseSkillCode"
            label="Course Skill Code"
          />
          <RHFTextField
            name="tsgRefNumber"
            label="TGS Reference Number"
          />
 
          </Stack>

          <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              sx={{ p: 3 }}
            >
             <RHFTextField
                name="courseDescription"
                label="Course Description"
                multiline rows={4}
              />
              </Box>

              <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(4, 1fr)',
              }}
              sx={{ p: 3 }}
            >
             <RHFTextField
                name="proposedTrainingHours"
                label="Proposed Training Hours"
              />
                 <RHFTextField
                name="proposedAssessmentHours"
                label="Proposed Assessment"
              />
               <Controller
              name="approvalDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Approval Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
              <Controller
              name="validUntil"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Valid Until"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

            <RHFTextField
                name="ssgSupportedTrainingHours"
                label="SSG Supported Training Hours"
              />
                 <RHFTextField
                name="ssgSupportedAssessmentHours"
                label="SSG Supported Assessment Hours"
              />
               <RHFTextField
                name="courseFee"
                label="Course Fee Exclude GST"
              />
              <RHFSelect
                name="fundedCourses"
                label="SSG / NON Funded Course"
                InputLabelProps={{ shrink: true }}
                sx={{
                  maxWidth: { md: '100%' },
                }}
              >
                  {fundedCourse.map((result) => {
                      return (
                        <MenuItem key={result.id} value={result.value}>
                          {result.value}
                        </MenuItem>
                      )
                    })}
              </RHFSelect>

              <RHFSelect
                    name="mediumInstruction"
                    label="Medium of Instruction"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      maxWidth: { md: '100%' },
                    }}
                  >
                    {mediumInstruction.map((result) => {
                      return (
                        <MenuItem key={result.id}  value={result.id}>
                          {result.label}
                        </MenuItem>
                      )
                    })}

              </RHFSelect>

              <RHFSelect
                    name="courseRunMode"
                    label="Course Run Mode of Training"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      maxWidth: { md: '100%' },
                    }}
                  >
                    {courseMode.map((result) => {
                      return (
                        <MenuItem key={result.id}  value={result.id}>
                          {result.value}
                        </MenuItem>
                      )
                    })}

              </RHFSelect>



              </Box>
              <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
              sx={{ p: 3 }}
            > 
                <RHFAutocomplete
                multiple
                label="Approved Trainers"
                name="trainerAssessor"
                placeholder="+ Trainer/Assessor"
                disableCloseOnSelect
                options={results?.results}
                getOptionLabel={(option) => option.fullName}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                renderOption={(props, tourGuide) => (
                  <li {...props} key={tourGuide._id}>
                    <Avatar
                      key={tourGuide._id}
                      alt={tourGuide._id}
                      src={tourGuide._id}
                      sx={{ width: 24, height: 24, flexShrink: 0, mr: 1 }}
                    />

                    {tourGuide.fullName}
                  </li>
                )}
                renderTags={(selected, getTagProps) =>
                  selected.map((tourGuide, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={tourGuide._id}
                      size="small"
                      variant="soft"
                      label={tourGuide.fullName}
                      avatar={<Avatar alt={tourGuide.fullName} src={tourGuide._id} />}
                    />
                  ))
                }

              />
              </Box>

              {/* <Stack sx={{ p: 3, bgcolor: 'background.neutral' }}>
                <AssessmentForm
                open={openForm.value}
                onOpen={openForm.onTrue}
                onClose={openForm.onFalse}
              />
            </Stack> */}

  

            <AsessmentRecordForm />


              <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
            <LoadingButton
              size="large"
              variant="contained"
              type="submit"
            >
              {state?.course ? 'Update' : 'Create'}
            </LoadingButton>
            <LoadingButton variant="contained" loading={isSubmitting} onClick={() => navigate(-1)}>Go Back</LoadingButton>
            </Stack>

            </Card>

  
         </Container>
        </FormProvider>
    )
}