import gql from "graphql-tag";

export const GET_ALL_SUBSCRIPTION = gql`
query getAllSubscription($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getAllSubscription(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;

export const CREATE_SUBSCRIPTION = gql`
mutation CreateSubscription($data: SubscriptionInput!, $fullName: String!, $companyName: String!) {
  createSubscription(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_SUBSCRIPTION= gql`
mutation DeleteSubscription($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteSubscription(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_SUBSCRIPTION = gql`
mutation UpdateSubscription($_id: ID!, $data: SubscriptionInput!, $fullName: String!, $companyName: String!) {
  updateSubscription( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

