import * as React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button} from '@mui/material';
// import {MenuIcon} from '@mui/icons-material/';

const drawerWidth = 240;
const navItems = ['About', 'Service', 'Contact Us'];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <nav id="navbarExample" class="navbar navbar-expand-lg fixed-top navbar-light" aria-label="Main navigation">
        <div class="container">

    
            <a class="navbar-brand logo-image" href="/"><img src="/assets/logo2.png" alt="alternative" /></a> 
            <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav ms-auto navbar-nav-scroll">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#aboutus">About Us</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#ourservice">Our Service</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#contactus">Contact Us</a>
                    </li>
                </ul>
                <span class="nav-item">
                    <a class="btn-outline-sm" href="/">Log in</a>
                </span>
            </div>
        </div> 
    </nav>
    <header id="header" class="header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="text-container">
                            <h1 class="h1-large">Integrated Auditing<span class="replace-me"> & Training Operating System</span></h1>
                            <p class="p-large">is an auditing and training management system solution designed for maximum efficiency and productivity to help you run training organisation and manage the administration with ease. Not only will it save you hundreds of man-hours to administer and process your training, it can also generate documentation and reports instantly for auditing with just a click of a button.</p>
                            <a class="btn-solid-lg" href="/">Contact Us</a>
                        </div> 
                    </div>
                    <div class="col-lg-6">
                        <div class="image-container">
                            <img class="img-fluid" src="/assets/consultation.svg" alt="alternative" />
                        </div> 
                    </div>
                </div> 
            </div>
        </header> 

    </>

  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
