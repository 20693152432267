import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { localStorageGetItem } from 'src/util/storage-available.js';
import { useSettingsContext } from 'src/components/settings/index.js';

import { allLangs, defaultLang} from './config-lang.js';

export default function useLocales(){
    const {i18n, t} = useTranslation();
    const settings = useSettingsContext();

    const langStorage = localStorageGetItem('i18nextLang');

    const currentLang = allLangs.find((lang) => lang.value === langStorage || defaultLang);

    const onChangeLang = useCallback((newLang) => {
        i18n.changeLanguage(newLang);
        settings.onChangeDirectionByLang(newLang)
    }, [i18n, settings]);

    return {
        allLangs,
        t,
        currentLang,
        onChangeLang
    }
}