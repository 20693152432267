import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
// @mui
import {Tab, Tabs, Container, Typography, Stack} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
// routes
// import { paths } from 'src/routes/paths';
// _mock
// components
import { useSettingsContext } from '../../../../components/settings/index.js';
//
import Label from '../../../../components/label/label.js';
//import ClassEnrolmentToolbar from './class-toolbar.js';
import ClassDetailsContent from './class-details-content.js';
import {ClassCandidates} from './class-candidates.js';
import { ClassAttendance } from './class-attendance.js'
import { ClassAssessment } from './class-assessment.js'
import { ClassESignAttendance } from './attendance/class-attendance-esign.js';
import { GrantsViewList } from './grants/grants-list-view.js';

// ----------------------------------------------------------------------

const ENROLMENT_TABS = [
  {"value": "Class Details", label: "Class Details", role: ["bo", "tm", "ta"]},
  {"value": "Class Enrolment", label: "Class Enrolment", role: ["bo", "tm"]},
  {"value": "Class Attendance E-Sign", label: "Class Attendance E-Sign", role: ["ta"]},
  {"value": "Class Attendance", label: "Class Attendance", role: ["bo", "tm"]},
  {"value": "Class Assessment", label: "Class Assessment", role: ["bo", "tm", "ta"]},
  {"value": "Grants Information", label: "Grants Information", role: ["bo", "tm"]}

]
export default function ClassDetailsView({ id }) {
  const isRole = useSelector((state) => state?.auth?.isRole);
  const { state } = useLocation();
  const settings = useSettingsContext();
  const [currentTab, setCurrentTab] = useState('Class Details');
  const navigate = useNavigate();

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);



  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{
        mb: { xs: 3, md: 5 },
      }}
    >
      {ENROLMENT_TABS.map((tab) => {
        if(isRole.some((role)=>  tab?.role?.includes(role))){
          return (
            <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
          />
          )
        }

})}
    </Tabs>
  );


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack spacing={{ xs: 1, sm: 2 }} direction="row"  justifyContent="space-between" alignItems="center">
      <Typography variant='h6'> View Class</Typography>
      <LoadingButton variant="contained" onClick={() => navigate(-1)}>Go Back</LoadingButton>
      </Stack>
    
     
       {renderTabs}

 
      {currentTab === 'Class Details' && <ClassDetailsContent classdetails={state}/>}

      {currentTab === 'Class Enrolment' && <ClassCandidates companyinfo={state} />}
      {currentTab === 'Class Attendance E-Sign' && <ClassESignAttendance companyinfo={state} />}
      {currentTab === 'Class Attendance' && <ClassAttendance companyinfo={state} />}
      {currentTab === 'Class Assessment' && <ClassAssessment companyinfo={state} />}
      {currentTab === 'Grants Information' && <GrantsViewList companyinfo={state} />}
      
      <Stack direction="row" alignItems="left" spacing={2} flexGrow={1} sx={{ width: 1, ml: 2, mt:2}} >

     
      </Stack>
   
    </Container>
  );
}

ClassDetailsView.propTypes = {
  id: PropTypes.string
};
