import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {Stack, Button, Badge, Divider, IconButton} from '@mui/material';
import { RHFSelect, RHFTextField, RHFRadioGroup } from '../../../components/hook-form/index.js';
import Iconify from '../../../components/iconify/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';

export default ({ nestIndex, tscIndex, values, control, register }) => {

  const { fields, remove, append } = useFieldArray({
    control,
    name: `assessmentRecordForm.${nestIndex}.assessmentDetails.${tscIndex}.assessmentCriteria`
  });
  

  const handleAssessmentCriteria = () => {
    append({
        assessmentCriteria: values.assessmentCriteria
    });
  }

  const handleRemove = (index) => {
        remove(index)
  }

  return (
    <Scrollbar>
      <>
    <Stack
    flexGrow={1}
     spacing={2}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: 'background.default',
        p: 2
      }}
    >


      {
        fields.map((item, index) => {
          return (
            <>
            <Stack
            key={item.id}
            flexGrow={1}
             spacing={2}
             direction="row"
             alignItems="center"
             justifyContent="space-between"
              sx={{
                width: 1,
                minWidth: 0,
                borderRadius: 1.5,
                bgcolor: 'background.default',
                p: 2
              }}
            >
         <RHFTextField
            size="small"
            name={`assessmentRecordForm[${nestIndex}].assessmentDetails.[${tscIndex}].assessmentCriteria.[${index}].assessmentCriteria`}
            label="Assessment Criteria"
            InputLabelProps={{ shrink: true }}
            ref={register()}
            multiline rows={5}
            defaultValue={item?.assessmentCriteria}
          />

         <IconButton  onClick={() => handleRemove(index)}
                       color='error'> 
                        <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
          <Divider sx={{ borderStyle: 'dashed' }} />
        </Stack>
        </>
          )
        })
      }
  </Stack>

  <Stack
          flexGrow={1}
          spacing={2}
            sx={{
              width: 1,
              minWidth: 0,
              borderRadius: 1.5,
              bgcolor: 'background.default',
              p: 2
            }}
          >
              <Button
              disableRipple
              color="inherit"
              onClick={handleAssessmentCriteria}
              endIcon={
                <Badge color="error">
                  <Iconify icon="flat-color-icons:plus" />
                </Badge>
              }
              >
              Add Assessment Criteria
            </Button>

        </Stack>


  </>
  </Scrollbar>
  );
};
