export function localStorageAvailable(){
    try{
        const key= 'SASDSDL28A1ANSLD';
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    }catch(error){
        return false
    }
}

export function localStorageGetItem(key, defaultValue = ''){
    const storageAvailable = localStorageAvailable();
    let value;
    if(storageAvailable){
        value = localStorage.getItem(key) || defaultValue;
    }

    return value;
}