import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSettingsContext } from '../../../components/settings/index.js';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/index.js';
import {Container, Tabs, Tab} from '@mui/material';
import Iconify from 'src/components/iconify';
import Grid from '@mui/material/Unstable_Grid2/index.js';
import GeneralViewCompany from './company-general-view.js'
import BillingViewCompany from './company-subscription-view.js'
import ClassViewCompany from './company-class-view.js'
import CoursesViewCompany from './company-courses-view.js'
import UsersViewCompany from './company-users-view.js'
import SettingsViewCompany from './company-settings-view.js'

const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'subscription',
      label: 'Subscription',
      icon: <Iconify icon="solar:bill-list-linear" width={24} />,
    },
    {
      value: 'courses',
      label: 'Courses',
      icon: <Iconify icon="fluent-mdl2:publish-course" width={24} />,
    },
    {
      value: 'classes',
      label: 'Classes',
      icon: <Iconify icon="ic:twotone-class" width={24} />,
    },
    {
      value: 'users',
      label: 'Users',
      icon: <Iconify icon="ph:users-three-duotone" width={24} />,
    }
  ];


const Status = [
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'In Active'}
]

// ----------------------------------------------------------------------

export default function ViewCompanyAccount({ currentUser }) {
  const { state } = useLocation();
  const settings = useSettingsContext();
  const [currentTab, setCurrentTab] = useState('general');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>


         <CustomBreadcrumbs
         heading="Training Provider Account"
         links={[
          {name: 'Dashboard'},
          {name: 'Training Provider'},
          {name: 'Account'}
         ]}
        />
      <Grid container spacing={3} sx={{py: 2}}>
      <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>

        {currentTab === 'general' && <GeneralViewCompany state={state}/>}
        {currentTab === 'subscription' && <BillingViewCompany state={state}/>}
        {currentTab === 'courses' && <CoursesViewCompany state={state}/>}
        {currentTab === 'classes' && <ClassViewCompany state={state}/>}
        {currentTab === 'users' && <UsersViewCompany state={state}/>}

        
      </Grid>
    </Container>
  );
}

ViewCompanyAccount.propTypes = {
  currentUser: PropTypes.object,
};
