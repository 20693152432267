import gql from "graphql-tag";

export const GET_ALL_AUDITLOG= gql`
query GetAudtLogs($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getAuditLog(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;
