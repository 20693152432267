
// PUBLIC ROUTE
import LoginPage from "../pages/LoginPage.js";
import OtpPage from "../pages/OtpPage.js";
import HomePage from "../pages/home//index.js";

// PRIVATE ROUTE
import DashboardAdmin from "../pages/sadmin/DashboardAdminPage.js";

// INVOICE ROUTE
import InvoicePage from "../pages/invoice/InvoicePage.js";
import InvoiceCreateUpdatePage from "../pages/invoice/InvoiceCreateUpdate.js";
import InvoiceDetails from "../pages/invoice/InvoiceDetailsView.js";

// SUBSCRIPTION ROUTE
import SubscriptionPage from "../pages/subscription/SubscriptionPage.js";
import SubscriptionDetailsPage from "../pages/subscription/SubscriptionDetails.js";
import SubscriptionCreateUpdatePage from "../pages/subscription/SubscriptionCreateUpdate.js";

// COMPANY ROUTE
import CompanyPage from "../pages/company/CompanyPage.js";
import CreateUpdateCompanyPage from "../pages/company/CompanyCreateUpdate.js";
import ViewCompanyAccountPage from "../pages/company/CompanyViewAccount.js";
import CompanyCreateUserPage from "../pages/company/CompanyCreateUsers.js";
import CompanyCreateCoursePage from "../pages/company/CompanyCreateUpdateCourse.js";
import CreateUpdateClassesCompanyPage from "../pages/company/CompanyCreateUpdateClasses.js"
import CompanyEnrolment from "../pages/company/CompanyEnrolment.js";
import CompanyCreateInvoice from "../pages/company/CompanyCreateInvoice.js";
import CompanyCreateReceipt from "../pages/company/CompanyCreateReceipt.js";


// USERS ROUTE
import UserListPage from "../pages/users/UsersPage.js";
import UserCreateUpdatePage from "../pages/users/UserCreateUpdate.js";

// AUDIT LOGS
import AuditLogListPage from "../pages/auditlog/AuditLogPage.js";


// TP ROUTE
import DashboardTP from "../pages/_client/DashboardPage.js";
import UsersTP from "../pages/_client/UsersPage.js";
import ClassTP from "../pages/_client/ClassPage.js";
import CourseTP from "../pages/_client/CoursePage.js";
import ReportsTP from "../pages/_client/ReportsPage.js";

import FinancePage from "../pages/_client/InvoicePage.js";

// REPORT ROUTE
import AttendanceListViewReportPage from "../pages/reports/attendance/attendance-list-view-page.js";
import AsessmentListViewReportPage from "../pages/reports/assessment/assessment-list-view-page.js";
import AsessignedListViewReportPage from "../pages/reports/assigned/assigned-list-view-page.js";



export const PublicRoutes = [
    {path: "/", element: HomePage},
    {path: "/:company", element: LoginPage},
    {path: "/verifyOtp", element: OtpPage}
]

export const PrivateRoutes = [
    {path: "/dashboardsuperadmin", element: DashboardAdmin, roles: ["superadmin", "admin"]},

    // INVOICE
    {path: "/invoice-page-view", element: InvoicePage, roles: ["superadmin", "admin"]},
    {path: "/invoice-create-update", element: InvoiceCreateUpdatePage, roles: ["superadmin", "admin"]},
    {path: "/invoice-pdf/:_id", element: InvoiceDetails, roles: ["superadmin", "admin"]},

    //SUBSCRIPTION
    {path: "/subscription-page-view", element: SubscriptionPage, roles: ["superadmin", "admin"]},
    {path: "/subscription-details", element: SubscriptionDetailsPage, roles: ["superadmin", "admin"]},
    {path: "/subscription-create-update", element: SubscriptionCreateUpdatePage, roles: ["superadmin", "admin"]},

    // COMPANY
    {path: "/company-page-view", element: CompanyPage, roles: ["superadmin", "admin"]},
    {path: "/company-create-update", element: CreateUpdateCompanyPage, roles: ["superadmin", "admin"]},
    {path: "/company-view-account", element: ViewCompanyAccountPage, roles: ["superadmin", "admin"]},
    {path: "/company-create-update-users", element: CompanyCreateUserPage, roles: ["superadmin", "admin" , "bo", "tm"]},
    {path: "/company-create-update-course", element: CompanyCreateCoursePage, roles: ["superadmin", "admin", "bo", "tm"]},
    {path: "/company-create-update-classes", element: CreateUpdateClassesCompanyPage, roles: ["superadmin", "admin", "bo", "tm"]},
    {path: "/company-enrolment-session", element: CompanyEnrolment, roles: ["superadmin", "admin", "bo", "tm", "ta"]},
    {path: "/company-invoice-create-update", element: CompanyCreateInvoice, roles: ["bo", "tm"]},
    {path: "/company-receipt-create-update", element: CompanyCreateReceipt, roles: ["bo", "tm"]},
    
    
    
    // USER
    {path: "/users-list-view", element: UserListPage, roles: ["superadmin"]},
    {path: "/users-create-update", element: UserCreateUpdatePage, roles: ["superadmin", "bo", "tm"]},

    // AUDIT LOG
    {path: "/auditlog-list-view", element: AuditLogListPage, roles: ["superadmin"]},



    // TP USER
    {path: "/:company/dashboard", element: DashboardTP, roles: ["bo", "tm", "ta"]},
    // USER MANAGEMENT
    {path: "/:company/users", element: UsersTP, roles: ["bo", "tm"]},
    {path: "/:company/course", element: CourseTP, roles: ["bo", "tm"]},
    {path: "/:company/class", element: ClassTP, roles: ["bo", "tm", "ta"]},
    {path: "/:company/reports", element: ReportsTP, roles: ["bo", "tm"]},
    {path: "/:company/company-update-info", element: CreateUpdateCompanyPage, roles: ["bo"]},

        // INVOICE
    {path: "/:company/invoice", element: FinancePage, roles: ["bo", "tm"]},
    // {path: "/:company/invoice-create-update", element: InvoiceCreateUpdatePage, roles: ["superadmin", "admin"]},
    // {path: "/:company/invoice-pdf/:_id", element: InvoiceDetails, roles: ["superadmin", "admin"]},

    // REPORT MANAGEMENT
    {path: "/:company/reports/attendance", element: AttendanceListViewReportPage, roles: ["bo", "tm"]},
    {path: "/:company/reports/assessment", element: AsessmentListViewReportPage, roles: ["bo", "tm"]},
    {path: "/:company/reports/assigned", element: AsessignedListViewReportPage, roles: ["bo", "tm"]},
    
]