import { Helmet } from "react-helmet-async";
 import  {AssessmentListViewReport}  from "../../../sections/company/report/assessmentReport/index.js";

export default function AsessmentListViewReportPage(){
    return (
        <>
            <Helmet>
                <title>Assessment List Report Page</title>
            </Helmet>
            <AssessmentListViewReport />
        </>
    )
}