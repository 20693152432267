import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate } from '../../../util/formatTime.js';
// import { fCurrency } from '../../../../util/format-number.js';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col1: { width: '5%' },
        col1_y: { width: '7%' },
        col1_x: { width: '10%' },
        col2_x: { width: '17%' },
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body_1: { fontSize: 10, textTransform: 'lowercase' },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800, textTransform: 'lowercase'},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMargin_1: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_v1: {
          margin: 2,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'right'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        tableRowTop: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          color: "#ffffff",
          backgroundColor: "green",
          borderColor: '#000000',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultRemark: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        borders: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function ClassPDF({
  classDetails, companyProfile
}) {
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const {courseRunId, course, classCode, courseDates, sessions, venue, venue_old, status, enrolment} = classDetails;
  const venueDetails = venue?.block || venue?.postalCode ? `${venue?.block} ${venue?.street} #${venue?.floor}-${venue?.unit} ${venue?.room} ${venue?.building} ${venue?.postalCode}` : venue_old;
  
  
  const setTrainer = sessions.map(item => item?.trainer?.fullName)
  const filterTrainer = [...new Set(setTrainer)];


  const styles = useStyles();



  return (
 
    <Document>
      <Page size="A4" style={styles.page} orientation='landscape'>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                </View>
                </View>
            </View>
          :
          ""
        }
   
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Course Title: ${course.courseTitle}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Class Code: ${classCode}`}</Text>
              </View>
           </View>
           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Training venue: ${venueDetails}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Start Date - End Date: ${fDate(courseDates.start)} - ${fDate(courseDates.end)}`}</Text>
              </View>
           </View>
           <View style={styles.tableRow}>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Trainers: ${filterTrainer.join(", ")}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Course Run Id: ${courseRunId}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.borders]}>
                <Text style={[styles.body1, styles.textMargin]}>{`Status: ${status}`}</Text>
              </View>
           </View>

           </View>

           <View style={styles.table}>
            <View>
              <View style={styles.tableRowTop}>
                <View style={[styles.col1, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>No #</Text>
                </View>
                <View style={[styles.colx, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Fullname</Text>
                </View>
                <View style={[styles.col2_x, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>NRIC/Fin/Others</Text>
                </View>

                <View style={[styles.colx, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Email</Text>
                </View>

                <View style={[styles.col1_x, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Phone no</Text>
                </View>
                <View style={[styles.col2_x, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Sponsorship Type</Text>
                </View>
                <View style={[styles.col1_y, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Grants</Text>
                </View>
                <View style={[styles.col2, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Payment Status</Text>
                </View>

                <View style={[styles.col2, styles.resultRemark]}>
                  <Text style={[styles.body2, styles.textMargin_1]}>Enrolment Status</Text>
                </View>
              </View>
            </View>

            <View>
    
   
                      {
                        enrolment?.sort((a, b) => a?.traineeInfo?.fullName?.localeCompare(b.traineeInfo?.fullName))?.map((trainee, index) =>
                          {
                            const nricFin = trainee?.traineeInfo?.nricFin ? trainee?.traineeInfo?.nricFin.slice(0, 1) + '*'.repeat(trainee?.traineeInfo?.nricFin?.length -4) + trainee?.traineeInfo?.nricFin?.slice(-4) : "";
                            return (
                              <View style={styles.tableRow}>
                              <View style={[styles.col1, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{index + 1}</Text>      
                             </View>
                             <View style={[styles.colx, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.traineeInfo?.fullName}</Text>      
                             </View>
                             <View style={[styles.col2_x, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{nricFin}</Text>      
                             </View>
                             <View style={[styles.colx, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.traineeInfo?.email}</Text>      
                             </View>
                             <View style={[styles.col1_x, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.traineeInfo?.mobileNo}</Text>      
                             </View>
                             <View style={[styles.col2_x, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.employer && trainee?.sponsorshipType === 'Employer' ? 'Employer' : trainee?.course && trainee?.sponsorshipType === 'Individual'  ? "Individual" : ""  }</Text>      
                             </View>
                             <View style={[styles.col1_y, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.grant ? "Yes" : "No"}</Text>      
                             </View>
                             <View style={[styles.col2, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.fees?.collectionStatus}</Text>      
                             </View>
                             <View style={[styles.col2, styles.resultRemark]}>
                                <Text style={[styles.body2, styles.textMargin]}>{trainee?.ssgenrolment?.status}</Text>      
                             </View>
                       </View>
                            )
                          }
                          )
                      }
                   
            </View>
          </View> 
  


      </Page>
    </Document>

  );
}

ClassPDF.propTypes = {
  classDetails: PropTypes.object,
  companyProfile: PropTypes.object
}