import { Helmet } from "react-helmet-async";
 import { SubscriptionCreateUpdate } from "../../sections/subscription/index.js";

export default function SubscriptionCreateUpdatePage(){
    return (
        <>
            <Helmet>
                <title>Subscription List Page</title>
            </Helmet>
            <SubscriptionCreateUpdate />
        </>
    )
}