
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../components/table/index.js';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../routes/components/index.js';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../components/snackbar/index.js';
import Iconify from '../../components/iconify/iconify.js';
import SubscriptionTableRow from './user-table-row.js';
import SubscriptionTableToolBar from './user-table-toolbar.js';
import Label from '../../components/label/index.js';


// GRAPHQL
import { GET_ALL_USERS, DELETE_USERS } from "../../graphql/modules/users.js";
import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'Inactive'}
]
const TABLE_HEAD = [
  {id: 'fullName', label: 'Full Name'},
  {id: 'userName', label: 'Username'},
  {id: 'mobileNo', label: 'Mobile No'},
  {id: 'userRole', label: 'User Role'},
  {id: 'status', label: 'Status'},
  {}
]

const USERROLE_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'superadmin', label: 'Super Admin'},
  {value: 'admin', label: 'Admin'}
]

const defaultFilters = {
  name: '',
  subscription: ["Superadmin", "admin"],
  status: 'all'
}

export function UsersListView({img, ...other}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const [filterRole, setFilterRole] = useState("All");
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState(company);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [DeleteUsersMutation] =  useMutation(DELETE_USERS);

  const role = useSelector((state) => state?.auth?.isRole);
  const userRole = ['superadmin', 'admin'].some(r=> role.includes(r))


  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: company,
      order: table.order,
      filter: filterRole,
      orderBy: table.orderBy || 'createdDate'
      },
      fetchPolicy: "cache-and-network",
    });


 

    useEffect(() => {
        if(data){
          setResults(data?.getUsers)
        }
    }, [data])

    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );

    const handleDeleteRow = useCallback(async (id) => {
      const {data: { deleteUsers }} = await DeleteUsersMutation({
        variables: {
          _id: id,
          companyName: "iatos",
          fullName: fullName
        }
      })
    
      if(deleteUsers?.success){
        enqueueSnackbar('Deleted success!', {variant: 'success'});
        refetch();
      }else {
        enqueueSnackbar('Failed to delete!', {variant: 'error'});
      }
      },
      [refetch]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/users-create-update', {state: row})
  });

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      if(newValue !== "all"){
        setFilters(newValue);
        setSearch(newValue);
      }else {
        setFilters(newValue);
        setSearch('');
      }
  
    },
    [table]
  );


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading={userRole ? "Super Admin Users List" : "User List"}
         links={[
          {name: 'Dashboard'},
          {name: userRole ? "Super Admin User" : "User"},
          {name: 'List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          href={'/users-create-update'}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            New User
          </Button>
         }
        />


        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'active' && 'success') ||  (tab.value === 'inactive' && 'warning') || 'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'active' && results?.activeCount}
                  {tab.value === 'inactive' && results?.inactiveCount}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <SubscriptionTableToolBar onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <SubscriptionTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewAccount(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

    </Container>
  );
}