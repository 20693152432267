import PropTypes from 'prop-types';
import { useState, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, Box, IconButton, Collapse, Typography, Stack , Paper} from "@mui/material";
import CustomPopover, {usePopover} from '../../../../../components/custom-popover/index.js';
import Iconify from "../../../../../components/iconify/iconify.js";
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import Label from '../../../../../components/label/index.js';
// import AttendanceDialog from './class-attendance-dialog.js';
import { useBoolean } from '../../../../../hooks/use-boolean.js';
import { useLocation } from 'react-router-dom';
import { fDate, SSGTime } from '../../../../../util/formatTime.js';
import { parseISO, format } from 'date-fns';
import { CREATE_ATTENDANCE, CREATE_E_SIGNATURE } from "../../../../../graphql/modules/_client/attendance.js";
import { useMutation } from "@apollo/react-hooks";
import { courseMode } from 'src/util/course.js';
import ESignature from '../../../../../util/eSignature.jsx';

export default function ClassESignRow({row, index,  trainee, refetch, selected, courseRunId, uen, courseReferenceNumber, onSelected, onSubmitAttendance, onEditRow, onViewRow, onSelectRow, classinfo}){
  const {nricFin} = useSelector((state) => state?.auth?.user);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const isRole = useSelector((state) => state?.auth?.isRole);
  const [userInfo, setUserInfo] = useState(null);
  const [sessionNo, setSessionNo] = useState(null);
  const [attendanceValue, setAttendanceValue] = useState(null);
  const [surveryValue, setSurvey] = useState(null);
  const [numberOfHours, setNumberOfHours] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [signature, setSignature] = useState(null);

  
  const { endDate, endTime, id, modeOfTraining, startDate, startTime } =row;
  const sessionNorow = index;
  const [CreateAttendanceMutation] =  useMutation(CREATE_ATTENDANCE);
  const [AddESignatureMutation] = useMutation(CREATE_E_SIGNATURE);

  const modeOfTrainingValue = courseMode?.map((result) => {
     if(result.id === String(modeOfTraining)){
        return result?.value
     }
     })


    const popover = usePopover();
    const attendance = useBoolean();
    const collapse = useBoolean();

    const eSign = useBoolean();

    const handleSaveAttendance = async() => {
        const {data: { createAttendance }} = await CreateAttendanceMutation({
          variables: {
            uen: uen,
            corppassId: nricFin,
            courseRunId: courseRunId,
              data: {
                course: {
                  courseReferenceNumber: courseReferenceNumber,
                  sessionID: id,
                  attendance: attendanceValue
                },
                userInfo: userInfo,
                surveyLanguage: surveryValue,
                numberOfHours: Number(parseFloat(numberOfHours).toFixed(1))
              }
            }
        });
  
        
        if(createAttendance?.success){
            enqueueSnackbar("Submitted successfully!", {variant: "success"});
        }else {
          enqueueSnackbar(createAttendance?.message, {variant: "error"});
        }
     
    }


  const handleESignature = useCallback(async() => {
    const {data: { addESignature }} = await AddESignatureMutation({
      variables: {
        companyName: state?.info?.databaseName || company,
        fullName: fullName,
        _id: classinfo?._id,
        traineeID: userInfo?._id,
          data: {
            eSignature:{
              eSign: signature,
              dateCaptured: Date.now(),
              sessionDate: startDate,
              startTime: startTime,
              endTime: endTime,
              sessionNo: sessionNo
            }
          }
        }
    });

    
    if(addESignature?.success){
        enqueueSnackbar("Submitted successfully!", {variant: "success"});
        refetch();
        eSign.onFalse();
    }else {
      enqueueSnackbar(addESignature?.message, {variant: "error"});
    }
   
  })

    const onChangeAttendance = (value) => {
      setAttendanceValue(value);
    };
    // const onChangeSurvery = (value) => {
    //   setSurvey(value);
    // };

    const onChangeNumberOfHours = (value) => {
      setNumberOfHours(value);
    };
    

    const renderPrimary = (
      <TableRow hover selected={selected}>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{modeOfTrainingValue}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{fDate(startDate)}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{SSGTime(startTime)}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{SSGTime(endTime)}</TableCell>


  <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
  <IconButton
    color={collapse.value ? 'inherit' : 'default'}
    onClick={collapse.onToggle}
    sx={{
      ...(collapse.value && {
        bgcolor: 'action.hover',
      }),
    }}
  >
    <Iconify icon="eva:arrow-ios-downward-fill" />
  </IconButton>

</TableCell>

</TableRow>
    )

    const secondaryPrimary = (
      <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
           <Stack component={Paper} sx={{ m: 1.5 }}>
            {trainee?.map((item, index) => {
              if(item?.ssgenrolment?.status !== "Cancelled"){
                return (
                  <Stack
                  key={item?.traineeInfo?._id}
                  direction="row"
                  alignItems="center"
                  sx={{
                    p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                    '&:not(:last-of-type)': {
                      borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                    },
                  }}
                >
                  <Avatar
                    src={""}
                    variant="rounded"
                    sx={{ width: 48, height: 48, mr: 2 }}
                  />
  
                  <ListItemText
                    primary={item?.traineeInfo?.fullName}
                    secondary={item?.ssgenrolment?.referenceNumber}
                    primaryTypographyProps={{
                      typography: 'body2',
                    }}
                    secondaryTypographyProps={{
                      component: 'span',
                      color: 'text.disabled',
                      mt: 0.5,
                    }}
                  />
                   <Box>
                         <Label
                  variant="soft"
                  color={
                    (item?.ssgenrolment?.status === 'Confirmed' && 'success') ||
                    (item?.ssgenrolment?.status === 'Completed' && 'info') ||
                    (item?.ssgenrolment?.status === 'Cancelled' && 'error') || 
                    (item?.ssgenrolment?.status === 'Draft' && 'primary') ||
                    'default'
                  }
                >
                  {item?.ssgenrolment?.status === 'Confirmed' ? 'Enrolment Confirmed' : item?.ssgenrolment?.status === 'Cancelled' ? 'Enrolment Cancelled' : 'Enrolment Draft'}
                </Label>
                </Box>
                <Box sx={{ width: 110, textAlign: 'right'}}>
                {item?.eAttendance
                  ? <img src={item?.eAttendance[sessionNorow]?.eSignature?.eSign} width="100%" height="100%" />
                  : null}
                </Box>
  
                  <Box sx={{ width: 110, textAlign: 'right'}}>
                  <Tooltip title="Add Attendance">
                      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={() => {
                        setUserInfo(item?.traineeInfo);
                        setSessionNo(index);
                        eSign.onTrue()
                      }}
                      sx={{
                        display: item?.ssgenrolment?.status === 'Cancelled' ? 'none' : ''
                      }}
                      >
                          <Iconify icon="solar:pen-bold" />
                      </IconButton>
                      </Tooltip>
                  </Box>
  
                </Stack>
                )
              }
            })}
          </Stack>
              
        </Collapse>
      </TableCell>
    </TableRow>
    )


 return (
    <>

{renderPrimary}

{secondaryPrimary}
{/* <AttendanceDialog 
        title="Add Attendance"
      open={attendance.value} 
      onClose={attendance.onFalse}
      class={row}
      userInfo={trainee}
      setAttendanceValue={setAttendanceValue}
      setSurvey={setSurvey}
      setNumberOfHours={setNumberOfHours}
      action={
        <Button variant="outlined" color="inherit" onClick={() => handleSaveAttendance()}>
        Save
      </Button>
      }
      /> */}
  <ESignature 
      title="e-Sign"
      open={eSign.value} 
      onClose={eSign.onFalse}
      setSignature={setSignature}
      action={
        <Button variant="outlined" color="inherit" onClick={() => handleESignature()}>
        Save
      </Button>
      }
  />

</>
 )

}

ClassESignRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSubmitAttendance: PropTypes.func,
    classinfo: PropTypes.object
  };
  
