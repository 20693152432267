import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import React, { useState, useMemo, useRef, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import SignaturePad from '../../../../../util/signature.jsx';
import { parseISO } from 'date-fns';
// @mui
import { useLocation } from 'react-router-dom';
import {alpha, IconButton, TextField, Stack, Box, Dialog, Typography, ListItemText, TableBody, Card, Grid, Table, TableRow, TableHead, TableCell, FormGroup, FormControlLabel,  Switch, Divider, MenuItem} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import { CREATE_ASSESSMENT_SUMMARY, GET_ASSESSMENTSUMMARY_BY_ID, UPDATE_ASSESSMENT_SUMMARY } from "../../../../../graphql/modules/_client/assessementSummary.js";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { _grades, _criteriaResults, countCounter } from '../../../../../util/course.js';
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RHFMultiSelect,
    RHFRadioGroup
  } from 'src/components/hook-form';


// ----------------------------------------------------------------------

export default function AssessmentSummaryForm({title, open, onClose, classInfo, userInfo}) {
    const {fullName} = useSelector((state) => state?.auth?.user);
    const user = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const assessmentSum = userInfo?.assessmentSummary ? userInfo?.assessmentSummary : null;
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const { state } = useLocation();
    const [results, setResults] = useState([]);
    const [traineeInfo, setTraineeInfo] = useState(null)
    const [grant, setGrant] = useState(null)
    const [signature, setSignature] = useState(null);
    const [signatureTrainer, setSignatureTrainer] = useState(null);
    const [checked, setChecked] = useState(false);
    const [trainerSignature, setIncludeTrainerSignature] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const filterAssessor = classInfo?.sessions?.filter((res) => res?.modeOfTraining === "8")

    const NewUserSchema = Yup.object().shape({
      criteriaResults: Yup.string().required('Result is required.'),
      createdDate: Yup.date().required().typeError('Invalid date'),
      assessmentStartTime:  Yup.date().typeError('Invalid date'),
      assessmentEndTime:  Yup.date().typeError('Invalid date')

    });
  
    const defaultValues = useMemo(
      () => ({
          criteriaResults: assessmentSum?.criteriaResults || '',
          createdDate: parseISO(assessmentSum?.createdDate) || filterAssessor ? parseISO(filterAssessor[0]?.startDate) : null,
          grade: assessmentSum?.grade || '',
          score: assessmentSum?.score || 0,
          assessmentStartTime: parseISO(assessmentSum?.assessmentStartTime) ||  filterAssessor ? parseISO(filterAssessor[0]?.startTime) : null,
          assessmentEndTime: parseISO(assessmentSum?.assessmentEndTime) || filterAssessor ? parseISO(filterAssessor[0]?.endTime) : null,
          criteriaRemarks: assessmentSum?.criteriaRemarks || ''
      }),
      [state]
    );

    const methods = useForm({
      resolver: yupResolver(NewUserSchema),
      defaultValues,
    });

    const {
      reset,
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;


    const [CreateAssessmentSummaryMutation] =  useMutation(CREATE_ASSESSMENT_SUMMARY);
    const [UpdateAssessmentSummaryMutation] =  useMutation(UPDATE_ASSESSMENT_SUMMARY);
      const { data, loading, error, refetch } = useQuery(GET_ASSESSMENTSUMMARY_BY_ID, {
        variables: {
          classID: classInfo?._id,
          userID: userInfo?.traineeInfo?._id,
          companyName: state?.databaseName || company
          },
          fetchPolicy: "cache-and-network",
        });
    
        useEffect(() => {
            if(data){
              setSignature(assessmentSum?.signature);
              setIncludeTrainerSignature(assessmentSum?.trainerSignature ? true : false)
            }

      }, [data, reset])


      const toggleChecked = () => {
        setChecked((prev) => !prev);
      };

      const toggleSignature = () => {
        setIncludeTrainerSignature((prev) => !prev);
        
      }


      const values = watch();

    const onSubmit = handleSubmit(async (data) => {
      try {
        const {data: { addAssessmentSummary }} = await CreateAssessmentSummaryMutation({
          variables: {
            companyName: state?.info?.databaseName || company,
            fullName: fullName,
            _id: classInfo?._id,
            traineeID: userInfo?.traineeInfo?._id,
              data: {
                assessmentSummary: {
                  classID: classInfo?._id,
                  userID: userInfo?.traineeInfo?._id,
                  criteriaResults: data?.criteriaResults,
                  createdDate: data?.createdDate,
                  score: data?.score,
                  grade: data?.grade,
                  assessmentStartTime: data?.assessmentStartTime,
                  assessmentEndTime: data?.assessmentEndTime,
                  criteriaRemarks: data?.criteriaRemarks,
                  signature: signature,
                  trainerSignature: trainerSignature ? user?.signature : null,
                  createdBy: fullName,
                  dateCreated: new Date(),
                  modifiedBy: fullName,
                  dateModified: new Date(),
                }

              }
            }
        });
        if(addAssessmentSummary?.success){
          if(userInfo?.assessmentSummary){
            enqueueSnackbar("Assessment Summary successfully updated.", {variant: "success"});
          }else {
            enqueueSnackbar("Assessment Summary successfully saved.", {variant: "success"});
          }
         
        }else {
          enqueueSnackbar(addAssessmentSummary?.message, {variant: "error"});
        }

  
      } catch (error) {
        console.log(error);
      }
    });


  
    
    const renderOverview = (
        <>
               <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'Course Title',
                value: classInfo?.course?.courseTitle,
                icon: <Iconify icon="ic:outline-class" />,
             },
             {
              label: 'TSG Reference No',
              value: classInfo?.course?.tsgRefNumber,
              icon: <Iconify icon="la:id-card-alt" />,
           },
            {
              label: 'Course Skill Code',
              value: classInfo?.course?.courseSkillCode,
              icon: <Iconify icon="ri:user-2-fill" />,
            },
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}

        </Stack>
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'Candidate Name',
                value: userInfo?.traineeInfo?.fullName,
                icon: <Iconify icon="ri:user-2-fill" />,
             },
             {
              label: 'NRIC/FIN/Others',
              value: userInfo?.traineeInfo?.nricFin,
              icon: <Iconify icon="solar:user-id-outline" />,
           }
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}
      
        </Stack>
        </Box>
      
        </>
    );

    const assessmentCriteria = (
        <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="h6">Final Outcome of the Assessment</Typography>
            <RHFRadioGroup row spacing={4} name="criteriaResults" options={_criteriaResults} />
            <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(5, 1fr)',
            }}
          >
           <Controller
              name="createdDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Assessment Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
             <RHFSelect
             select
              name="grade"
              label="Grade"
              multiline 
              rows={4}                      
             >
                {
                    _grades.map((res) => (
                        <MenuItem key={res?.value} value={res?.value}>{res?.label}</MenuItem>
                    ))
                }
            </RHFSelect>

            <RHFSelect
             select
              name="score"
              label="Score"
              multiline 
              rows={4}                      
             >
                {
                    countCounter()?.map((res, index) => (
                      <MenuItem key={index} value={res}>{res}</MenuItem>
                    ))
                }
            </RHFSelect>

            <Controller
              name="assessmentStartTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimePicker
                  label="Assessment start time"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
            <Controller
              name="assessmentEndTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimePicker
                  label="Assessment end time"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
            </Box>

            <RHFTextField
                name="criteriaRemarks"
                label="Remarks"
                multiline 
                rows={2}                      
            />


        </Stack>
    )

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
       <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6">{title}</Typography>
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
        {renderOverview}
        </Grid>
        <Grid item xs={12} md={12}>
        {assessmentCriteria}
        </Grid>
        <Grid item xs={6} md={6}>
        <SignaturePad title="Candidate Signature" setSignature={setSignature}/>
        </Grid>
        <Grid item xs={6} md={6}>
        <Typography variant="h6">Signature Preview</Typography>
        <Card sx={{border: 1, height: 299}}>
        {signature
        ? <img src={signature} width="100%" height="100%" />
        : null}
        </Card>

        </Grid>
        <Grid item xs={12} nd={12}>
           <FormGroup>
                <FormControlLabel
                    control={<Switch size="small" checked={trainerSignature} onChange={toggleSignature} />}
                    label={`Include assessor signature? ${trainerSignature? 'Yes':'No'}`}
                />
            </FormGroup>

        </Grid>
      </Grid>
      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton variant="contained" type="submit" loading={isSubmitting}>{userInfo?.assessmentSummary ? "Update" : "Save"}</LoadingButton>
              <LoadingButton variant="contained" onClick={onClose}>Close</LoadingButton>
            </Stack>  
      </Stack>
      </FormProvider>

    </Dialog>

  );
}

AssessmentSummaryForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  traineeInfo: PropTypes.object
};
