import { Helmet } from "react-helmet-async";
 import { InvoiceCreateUpdate } from "../../sections/invoice/index.js";

export default function InvoiceCreateUpdatePage(){
    return (
        <>
            <Helmet>
                <title>Create Invoice Page</title>
            </Helmet>
            <InvoiceCreateUpdate />
        </>
    )
}