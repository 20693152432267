import { Helmet } from "react-helmet-async";
import { DashboardAdmin } from "../../sections/dashboard/dashboardadmin.js";

export default function LoginPage(){
    return (
        <>
            <Helmet>
                <title>Dashboard Admin</title>
            </Helmet>
            <DashboardAdmin />
        </>
    )
}