
import { useState, useCallback , useEffect} from 'react';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom, getComparator} from '../../components/table/index.js';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../routes/components/index.js';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify/iconify.js';
import AuditLogTableRow from './auditlog-table-row.js';
import AuditLogTableToolBar from './auditlog-table-toolbar.js';
import Label from '../../components/label/index.js';


// GRAPHQL
import { GET_ALL_AUDITLOG } from "../../graphql/modules/auditlogs.js";
import { useQuery } from "@apollo/react-hooks";

const TABLE_HEAD = [
  {id: 'user', label: 'User'},
  {id: 'title', label: 'Title'},
  {id: 'activity', label: 'Activity'},
  {id: 'dateCreated', label: 'Date created'}
]


export function AuditLogListView({img, ...other}) {
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_ALL_AUDITLOG, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyName,
      order: table.order,
      orderBy: table.orderBy
      },
      fetchPolicy: "cache-and-network",
    });


    useEffect(() => {
        if(data){
          setResults(data?.getAuditLog)
        }
    }, [data])

    const handleFilters = useCallback((name, value) => {
        setSearch(value)
      },
      [table]
    );
  

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update', {state: row})
  });

  const handleSort = useCallback((name, value) => {
    console.log(table.order)
    console.log(table.orderBy)
    console.log(table.onSort)
  },
  [table]
);



  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading="Activity Logs"
         links={[
          {name: 'Dashboard'},
          {name: 'Activity Log'},
          {name: 'List'}
         ]}
        />
        <Card>

          <AuditLogTableToolBar onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <AuditLogTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewAccount(row)}
                      onEditRow={() => handleEditRow(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
          />
        </Card>

    </Container>
  );
}