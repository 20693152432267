import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useMemo } from 'react';
import { Page, View, Text, Image, Document, Font, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Box, Dialog, Stack } from '@mui/material';
// utils
import { fDate } from '../../../../util/formatTime.js';
import { fCurrency } from '../../../../util/format-number.js';

// ----------------------------------------------------------------------

Font.register({
  family: 'Ubuntu',
  fontStyle: "normal",
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf',
      fontWeight: 'bold',
    }
  ],
});

const useStyles = () =>
  useMemo(
    () =>
      StyleSheet.create({
        col2: { width: '20%' },
        colx: { width: '30%' },
        col3: { width: '80%' },
        col4: { width: '25%' },
        col8: { width: '75%' },
        col6: { width: '50%' },
        col7: { width: '100%' },
        col9: { width: '70%' },
        mt1: { marginTop: 2 },
        mtop4: {marginTop: 8},
        mb8: { marginBottom: 8 },
        mb4: { marginBottom: 4 },
        mb8: { marginBottom: 8 },
        mb40: { marginBottom: 40 },
        h3: { fontSize: 16, fontWeight: 700 },
        h4: { fontSize: 13, fontWeight: 700 },
        body1: { fontSize: 10 },
        body_1: { fontSize: 10, textTransform: 'lowercase' },
        body2: { fontSize: 9 },
        subtitle1: { fontSize: 12, fontWeight: 800},
        subtitle1_1: { fontSize: 12, fontWeight: 800, textTransform: 'lowercase'},
        subtitle2: { fontSize: 10, fontWeight: 700 },
        alignRight: { textAlign: 'right' },
        textMargin: {margin: 2},
        textMargin_1: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_v1: {
          margin: 2,
          textAlign: 'center'
        },
        textMargin_x1: {
          margin: 1,
          textAlign: 'right'
        },
        textMargin_2: {
          margin: 1,
          textAlign: 'center'
        },
        textMargin_3: {
          margin: 1
        },
        page: {
          fontSize: 9,
          lineHeight: 1.6,
          fontFamily: 'Ubuntu',
          backgroundColor: '#FFFFFF',
          textTransform: 'capitalize',
          padding: '40px 24px 40px 24px',
        },
        footer: {
          left: 0,
          right: 0,
          bottom: 0,
          padding: 24,
          margin: 'auto',
          borderTopWidth: 1,
          borderStyle: 'solid',
          position: 'absolute',
          borderColor: '#DFE3E8',
        },
        gridContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        table: {
          display: 'flex',
          width: 'auto',
        },
        tableRow: {
          flexDirection: 'row',
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        tableRow1: {
          flexDirection: 'row',
        },
        
        noBorder: {
          paddingTop: 8,
          paddingBottom: 0,
          borderBottomWidth: 0,
        },
        tableCell_1: {
          width: '50%',
        },
        tableCell_2: {
          width: '30%'
        },
        tableCell_3: {
          width: '30%',
        },
        tableCell_4: {
          width: '70%',
        },
        greenColor: {
          backgroundColor: "#3fb541",
          borderWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        grayColor: {
          backgroundColor: "#878a87",
          width: '100%'
        },
        whiteColor: {
          backgroundColor: "#ffffff",
          width: '100%'
        },
        resultRemark: {
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader: {
          backgroundColor: "#ffffff",
          borderLeftWidth: 0.5,
          borderStyle: 'solid',
          borderColor: '#000000',
        },
        titleheader1: {
          backgroundColor: "#ffffff"
        },
        textTrans: { fontSize: 10, textTransform: 'none' },
      }),
    []
  );

// ----------------------------------------------------------------------

export default function InvoicePDF({
  invoiceDetails, companyProfile
}) {
  const logo =  companyProfile ? `${process.env.PUBLIC_URL}/logo/${companyProfile?.companyName}/${companyProfile?.logo}` : null;
  const { invoiceNumber, status, createDate, invoiceToInfo, grantDetails, items, totalGrants, totalAmount, discount, taxes, courseInfo } = invoiceDetails;
  const grants = grantDetails ? grantDetails[0]?.eligibleGrants?.map((res) => res) : null;

  const getLastGrants = grants ? grants?.pop() : '';


  const allGrants = totalGrants !== 0 ? totalGrants : '';

  const totalOnRow = items?.map((item) => item?.total);
  const subTotal_less = sum(totalOnRow);
  const subTotal = (subTotal_less - allGrants - discount);

  const mappedNumbers = sum(items?.map(num => num?.total < 0 ? Math.abs(num?.total) : 0));
  const tax = (subTotal_less + mappedNumbers - discount)  * (taxes / 100); 

  // SUM ALL TRAINEE
  const sumAllTrainee = sum(items?.map((te) => {
    if(te?.nricFin){
      return  te?.price
    }
  }
))
  const styles = useStyles();

  return (
 
    <Document>
      <Page size="A4" style={styles.page}>
        {
          logo ?
          <View style={[styles.table, styles.mb8]}>
              <View style={styles.tableRow1}>
                  <View style={styles.col2}>
                      <Image source={logo} style={{ width: 80, height: 80 }} />
                  </View>
                  <View style={styles.col3}>
                    <Text style={styles.h4}>{companyProfile?.companyName}</Text>
                    <Text style={styles.body1}>{`Address: ${companyProfile?.address}`}</Text>
                    <Text style={styles.body1}>{`Contact No: ${companyProfile?.contactNo}`}</Text>
                    <Text style={styles.body1}>{`Email: ${companyProfile?.email}`}</Text>
                    {
                      companyProfile?.gstNumber !== "NA" ?
                      <Text style={styles.body1}>{`GST Registration No: ${companyProfile?.gstNumber}`}</Text>
                      :
                      ""
                    }
                </View>
                </View>
            </View>
          :
          ""
        }
   
          <View style={[styles.table, styles.mb8]}>
            <View style={styles.tableRow}>
              <View style={[styles.tableCell_1]}>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`Bill To:`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`${invoiceToInfo?.empcompanyName ? invoiceToInfo?.empcompanyName : invoiceToInfo?.fullName}`}</Text>
                <Text style={[styles.subtitle1_1, styles.textMargin]}>{`${invoiceToInfo?.empcompanyName ? invoiceToInfo?.contact?.emailAddress : invoiceToInfo?.email}`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`${invoiceToInfo?.empcompanyName ? invoiceToInfo?.contact?.contactNumber?.phoneNumber : invoiceToInfo?.mobileNo}`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`${invoiceToInfo?.empcompanyName ? 'Attn: ' + invoiceToInfo?.contact?.fullName : ''}`}</Text>
              </View>
              <View style={[styles.tableCell_1, styles.titleheader]}>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`Invoice Number: ${invoiceNumber}`}</Text>
                <Text style={[styles.subtitle1, styles.textMargin]}>{`Date: ${fDate(createDate)} `}</Text>
              </View>
           </View>
           </View>

           <View style={styles.table}>
            <View>
              <View style={styles.tableRow}>
                <View style={[styles.tableCell_4, styles.resultRemark]}>
                  <Text style={[styles.subtitle1, styles.textMargin_2]}>Description</Text>
                </View>
                <View style={[styles.tableCell_2, styles.resultRemark]}>
                  <Text style={[styles.subtitle1, styles.textMargin_1]}>Amount</Text>
                </View>
              </View>
            </View>

            <View>
    
                    <View style={styles.tableRow}>
                            <View style={styles.tableCell_4}>
                            <Text style={[styles.subtitle1, styles.textMargin]}>{`Trainee:`}</Text>
                              <>
                              {items?.map((res, i) => {
                                  const nricFin = res?.nricFin ? res?.nricFin.slice(0, 1) + '*'.repeat(res?.nricFin?.length -4) + res?.nricFin?.slice(-4) : "";
                                  if(res?.nricFin){
                                    return (
                                  <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description} ${nricFin}`}</Text>
                                   )
                                  }
        
                              })}
                               </>
                               <>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Course Title: ${courseInfo?.courseTitle}`}</Text>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{courseInfo?.tsgRefNumber}</Text>
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Training Dates: ${courseInfo?.courseStart} - ${courseInfo.courseEnd}`}</Text>
                               </>
                               <>
                               {
                                getLastGrants ?
                                <Text style={[styles.subtitle1, styles.textMargin]}>{`Less ${getLastGrants?.schemeName}`}</Text>
                                :
                                ""
                               }
                             
                              {/* {grants?.map((res, i) => (
                                   <Text style={[styles.subtitle1, styles.textMargin]}>{`Less ${res?.schemeName}`}</Text>
                               ))} */}
                               {
                                discount !==0 ? 
                                <Text style={[styles.subtitle1, styles.textMargin]}>{`Less Course fee discount:`}</Text>
                                :
                                ""
                               }

                                {items?.map((res, i) => {
                                    if(!res?.nricFin){
                                      return (
                                        <Text style={[styles.subtitle1, styles.textMargin]}>{`${res?.description}`}</Text>
                                      )
                                  }
                                })}
                               
                               </>
                           </View>
                           <View style={[styles.tableCell_2, styles.resultRemark]}>
                              <Text style={[styles.subtitle1, styles.textMargin_v1]}>{' '}</Text>

                               {items?.map((res, i) => {
                                   if(res?.nricFin){
                                      return (
                                        <Text style={[styles.subtitle1, styles.textMargin_v1]}>{' '}</Text>
                                      )
                                    }
                               })}
                                 <Text style={[styles.subtitle1, styles.textMargin_v1]}>{fCurrency(sumAllTrainee)}</Text>
                                 {
                                  courseInfo?.courseTitle?.length > 60 ?
                                  <Text style={[styles.subtitle1, styles.textMargin_1]}>{' '}</Text>
                                  :
                                  ""
                                 }
                               
                                 <Text style={[styles.subtitle1, styles.textMargin_v1]}>{' '}</Text>
                                 <Text style={[styles.subtitle1, styles.textMargin_v1]}>{' '}</Text>
                                 {
                                  getLastGrants ?
                                  <Text style={[styles.subtitle1, styles.textMargin_v1]}>{totalGrants !== 0 ? `-${fCurrency(allGrants)}` : ''}</Text>
                                  :
                                  ""
                                }
                               
                                 {
                                  discount !==0 ? 
                                  <Text style={[styles.subtitle1, styles.textMargin_v1]}>{discount !==0 ? `-${fCurrency(discount)}` : ' '}</Text>
                                  :
                                  ""
                                 }

                                {items?.map((res, i) => {
                                   if(!res?.nricFin){
                                      return (
                                        <Text style={[styles.subtitle1, styles.textMargin_v1]}>{fCurrency(res?.price)}</Text>
                                      )
                                    }
                               })}
                                 
                           </View>
                     </View>

                     <View style={[styles.table, styles.mb8]}>
                        <View style={[styles.tableRow]}>
                              <View style={[styles.col9, styles.mt1]}>
                                  <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Sub-Total:`}</Text>
                                  {
                                    taxes !==0 ?
                                    <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`GST ${taxes}%:`}</Text>
                                    :
                                    ""
                                  }
                                   
                                    <Text style={[styles.subtitle1, styles.textMargin_x1]}>{`Balance Payable:`}</Text>
                                  </View>
                                <View style={[styles.colx, styles.mt1]}>
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(subTotal)}`}</Text>
                                      {
                                        taxes !==0 ?
                                        <Text style={[styles.subtitle1, styles.textMargin_1]}>{taxes !==0 ? `${fCurrency(tax)}` : ' '}</Text>
                                        :
                                        ""
                                      }
                                     
                                      <Text style={[styles.subtitle1, styles.textMargin_1]}>{`${fCurrency(totalAmount)}`}</Text>
                                </View>
                        </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={styles.col7}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Methods:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(i) By PayNow - UEN ${companyProfile?.uen}`}</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{`(ii) By Bank Transfer - ${companyProfile?.bankName} A/C  #${companyProfile?.accountNo}. A/C Name ${companyProfile?.accountName}`}</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>{invoiceToInfo?.empcompanyName ? '' : `(iii) By SkillsFuture Credit`}</Text>
                            </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                            <Text style={[styles.subtitle2, styles.textMargin_3]}>Payment Instructions:</Text>
                            <Text style={[styles.subtitle2, styles.textMargin_3,  styles.textTrans]}>{`${companyProfile?.paymentInstruction}`}</Text>
                            </View>
                    </View>

                    <View style={styles.tableRow1}>
                            <View style={[styles.tableCell_4, styles.mtop4]}>
                                <Text style={[styles.subtitle2, styles.textMargin_3]}>Terms & Conditions:</Text>
                                <Text style={[styles.subtitle2, styles.textMargin_3, styles.textTrans]}>{`${companyProfile?.termsAndCondition}`}</Text>
                            </View>
                    </View>
    
               
            </View>
          </View>
  


      </Page>
    </Document>

  );
}

InvoicePDF.propTypes = {
  invoiceDetails: PropTypes.object,
  companyProfile: PropTypes.object
}