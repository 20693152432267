import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import { useBoolean } from '../../hooks/use-boolean.js';
import { useResponsive } from '../../hooks/useResponsive.js';

import { useSettingsContext } from '../../components/settings/index.js';

 import Main from './main.js';
import Header from './header.js';
import NavMini from './nav-mini.js';
import NavVertical from './nav-vertical.js';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP =92;



export default function DashboardLayout() {
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();
   const isHorizontal = settings.themeLayout === 'horizontal';
  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;
//  const renderHorizontal = <NavVertical />;
  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />

  // if(isHorizontal){
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />
  //       {lgUp ? renderHorizontal : renderNavVertical}
  //       <Main> <Outlet /></Main>
  //     </>
  //   )
  // }

  if(isMini){
    return (
      <>
        <Header onOpenNav={nav.onTrue} />
        <Box 
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'}
        }}>
           {lgUp ? renderNavMini : renderNavVertical}

           <Main><Outlet /></Main>
        </Box>
      </>
    )
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />
      <Box 
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'}
        }}>
           {renderNavVertical}

           <Main><Outlet /></Main>
        </Box>
    </>
  )
 }

//  DashboardLayout.propTypes = {
//   children: PropTypes.node
//  }