import { Helmet } from "react-helmet-async";
import {ClientDashboard} from "../../sections/_clientdashboard/clientdashboard.js";

export default function DashboardTP(){
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <ClientDashboard />
        </>
    )
}