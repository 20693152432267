import { Helmet } from "react-helmet-async";
 import { UsersListView } from "../../sections/users/index.js";

export default function UserListPage(){
    return (
        <>
            <Helmet>
                <title>Users List Page</title>
            </Helmet>
            <UsersListView />
        </>
    )
}