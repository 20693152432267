import { palette } from "../palette.js";
import {shadows} from "../shadows.js"
import { customShadows } from "../custom-shadow.js";

export function darkMode(mode){
    const theme = {
        palette: palette(mode),
        shadows: shadows(mode),
        customShadows: customShadows(mode)
    }

    return theme
}