import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Stack, Typography } from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SignatureCanvas from 'react-signature-canvas';

function SignaturePad({ title, setSignature }) {
    // Create a ref for the signature canvas
    const signatureRef = useRef();
  
    // Function to clear the signature
    const clearSignature = () => {
      signatureRef.current.clear();
    };
  
    // Function to save the signature data
    const saveSignature = () => {
      setSignature(signatureRef.current.toDataURL());
    };
  
    return (
      <div>
          <Typography variant="h6">{title}</Typography>
         <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3, border: "solid 1px" }}>
            <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 800, height: 250 }}
            ref={signatureRef}
            />
          </Stack>  

        <Stack justifyContent="center" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton variant="contained" onClick={clearSignature}>Clear</LoadingButton>
              <LoadingButton variant="contained" onClick={saveSignature}>Add</LoadingButton>
        </Stack>  
 
      </div>
    );
  }

  export default SignaturePad;

  SignaturePad.propTypes = {
    title: PropTypes.string,
    setSignature: PropTypes.func
  };