import PropTypes from 'prop-types';
import { useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
// @mui
import { useLocation } from 'react-router-dom';
import {alpha, IconButton, TextField, Stack, Box, InputLabel, Dialog, Typography, ListItemText, FormControl, Grid, Select, Switch, FormGroup, FormControlLabel, Divider, MenuItem} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { SSGDate, fDate } from '../../../../util/formatTime.js';
import { traineeCategory } from '../../../../util/enrolment.js';
import {_paymentStatus} from '../../../../util/course.js';

import { CREATE_ENROLMENT, UPDATE_ENROLMENT, CREATE_ENROLMENT_DRAFT, SUBMIT_ENROLMENT_DRAFT_STATUS } from "../../../../graphql/modules/_client/class.js";
import { GRANTS_PERSONALIZED } from "../../../../graphql/modules/_client/grants.js";
import { useMutation } from "@apollo/react-hooks";
import {  Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';

import ClassEnrolmentToolbar from './class-toolbar.js';


// ----------------------------------------------------------------------

export default function ClassTraineeDialog({title, open, onClose, companyInfo, classinfo, traineeUp, handleRefetch}) {
    const {fullName} = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const { state } = useLocation();
    const [traineeInfo, setTraineeInfo] = useState(traineeUp?.traineeInfo)
    const [ssgResponse, setSSGResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [bundleDate, setBundleCourseStartDate] = useState(null);
    const [issuanceDate, setIssuanceDate] = useState(null);
    const [employer, setEmployer] = useState({
      uen: '',
      empcompanyName: '',
      fullName:  '',
      emailAddress:  '',
      areaCode: '',
      countryCode:  '',
      phoneNumber:''
    })
    const [fees, setFees] = useState({
      discountAmount: 0,
      collectionStatus: 'Pending Payment'
    })
    const [bundled, setBundledInfo] = useState({
      code: ''
    })
    const [ibfinfo, setIBFInfo] = useState({
      traineeCategory: ''
    })
    const [grant, setGrant] = useState(null)
    const [checked, setChecked] = useState(false);
    const [isBundle, setBundle] = useState(false);
    const [isIBF, setIBF] = useState(false);
    const [isCPF, setCPF] = useState(true);
    const [isTimeEnrolment, setIsTimeEnrolment] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [CreateEnrolmentMutation] =  useMutation(CREATE_ENROLMENT);
    const [UpdateEnrolmentMutation] =  useMutation(UPDATE_ENROLMENT);
    const [CreateGrantsPersonalized] = useMutation(GRANTS_PERSONALIZED);
    const [CreateEnrolmentDraftMutation] = useMutation(CREATE_ENROLMENT_DRAFT);
    const [SubmitEnrolmentMutation] = useMutation(SUBMIT_ENROLMENT_DRAFT_STATUS);


    useEffect(() => {
      if(traineeUp){
        setTraineeInfo(traineeUp?.traineeInfo)
        setFees(traineeUp?.fees)
        setSSGResponse({funding: traineeUp?.grant})
        if(traineeUp?.sponsorshipType === "Employer"){
          console.log(traineeUp?.employer)
          setChecked(true)
          setEmployer({
            uen: traineeUp?.employer?.uen,
            empcompanyName: traineeUp?.employer?.empcompanyName,
            fullName:  traineeUp?.employer?.contact?.fullName,
            emailAddress:  traineeUp?.employer?.contact?.emailAddress,
            areaCode: traineeUp?.employer?.contact?.contactNumber?.areaCode,
            countryCode:  traineeUp?.employer?.contact?.contactNumber?.countryCode,
            phoneNumber: traineeUp?.employer?.contact?.contactNumber?.phoneNumber,
          })
        }else {
          setChecked(false)
          setEmployer({
            uen: '',
            empcompanyName: '',
            fullName:  '',
            emailAddress:  '',
            areaCode: '',
            countryCode:  '',
            phoneNumber:''
          })
        }
        setBundleCourseStartDate(traineeUp?.traineeInfo?.bundleDate ? parseISO(traineeUp?.traineeInfo?.bundleDate) : null)
        setBundledInfo({code: traineeUp?.traineeInfo?.bundle ? traineeUp?.traineeInfo?.bundle : "" });
        setIssuanceDate(traineeUp?.traineeInfo?.centralProvidentFund?.letterOfSupportIssuanceDate ? parseISO(traineeUp?.traineeInfo?.centralProvidentFund?.letterOfSupportIssuanceDate) : null)
        setIBFInfo({ traineeCategory: traineeUp?.traineeInfo?.centralProvidentFund?.traineeCategory ? traineeUp?.traineeInfo?.centralProvidentFund?.traineeCategory : null });
        setIBF(traineeUp?.traineeInfo?.centralProvidentFund?.traineeCategory)
        setBundle(traineeUp?.traineeInfo?.bundle)

      }else {
        setTraineeInfo(null);
        setSSGResponse(null);
        setFees({
          discountAmount: 0,
          collectionStatus: ''
        })
        setChecked(false)
        setEmployer({
          uen: '',
          empcompanyName: '',
          fullName:  '',
          emailAddress:  '',
          areaCode: '',
          countryCode:  '',
          phoneNumber:''
        })
      }
  }, [traineeUp])

    const toggleChecked = () => {
        setChecked((prev) => !prev);
        setEmployer({
          uen: traineeInfo?.employer?.uen,
          fullName: traineeInfo.employer?.contactName || '',
          emailAddress: traineeInfo?.employer?.emailAddress || '',
          areaCode: traineeInfo?.employer?.areaCode|| '',
          countryCode: traineeInfo?.employer?.countryCode || '',
          phoneNumber: traineeInfo?.employer?.phoneNumber || '',
          empcompanyName: traineeInfo?.employer?.companyName || ''
        })
      };

      // TOGGLE

      const toggleBundle = () => {
        setBundle((prev) => !prev);
        if(isBundle){
          setBundleCourseStartDate(null)
          setBundledInfo({ ...bundled, ['code']: '' });
        }
      };
      const toggleIBF = () => {
        setIBF((prev) => !prev);
        if(isIBF){
          setIssuanceDate(null)
          setIBFInfo({ ...ibfinfo, ['traineeCategory']: '' });
        }
      };
      const toggleContribution = () => {
        setCPF((prev) => !prev);
      };
      const toggleTimeEmployed = () => {
        setIsTimeEnrolment((prev) => !prev);
      };
      

   const handelOnClose = () => {
    setTraineeInfo(null);
    setSSGResponse(null);
    setFees({
      discountAmount: 0,
      collectionStatus: ''
    })
    setChecked(false)
    setEmployer({
      uen: '',
      empcompanyName: '',
      fullName:  '',
      emailAddress:  '',
      areaCode: '',
      countryCode:  '',
      phoneNumber:''
    })

    setBundleCourseStartDate(null)
    setBundledInfo({ ...bundled, ['code']: '' });
    setIssuanceDate(null)
    setIBFInfo({ ...ibfinfo, ['traineeCategory']: '' });
    setIBF(false)
    setBundle(false)
   }

    const onSubmit = async () => {
      setIsLoading(true)
      if(isBundle && !bundled.code){
        enqueueSnackbar("SCTP bundled code is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isBundle && bundleDate === null){
        enqueueSnackbar("SCTP bundled course start date is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isIBF && !isCPF && !ibfinfo?.traineeCategory){
        enqueueSnackbar("IBF trainee category is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isIBF && !isCPF && issuanceDate === null){
        enqueueSnackbar("IBF issuance date is required.", {variant: "error"});
        setIsLoading(false)
      }else {
        if(traineeUp){

          const {data: { updateEnrolment }} = await UpdateEnrolmentMutation({
            variables: {
              companyName: state?.info?.databaseName || company,
              _id: state?.class?._id,
              fullName: fullName,
              encryptionkey: companyProfile?.encryptionKey,
              _companyName: companyProfile?.companyName,
              referenceNumber: traineeUp?.ssgenrolment?.referenceNumber,
                data: {
                    enrolment: {
                      course: { run: {id: state?.class.courseRunId }, referenceNumber: state?.class?.course?.tsgRefNumber},
                      traineeInfo: {
                        _id: traineeInfo?._id,
                        nricFin: traineeInfo?.nricFin,
                        idType: traineeInfo?.idType,
                        fullName: traineeInfo?.fullName,
                        dob: SSGDate(traineeInfo?.dob),
                        email: traineeInfo?.email,
                        mobileNo: traineeInfo?.mobileNo,
                        centralProvidentFund: {
                          traineeCategory: ibfinfo?.traineeCategory,
                          letterOfSupportIssuanceDate: issuanceDate ? SSGDate(issuanceDate) : "",
                          didTraineeReceiveCPFContribution: isCPF ? 'Yes' : 'No',
                          isTraineeEmployedAtTimeofEnrolment: isTimeEnrolment? 'Yes' : 'No'
                        },
                        bundle: bundled?.code ? bundled?.code : "",
                        bundleDate: bundleDate ? SSGDate(bundleDate) : ""
                      },
                      employer: {
                        uen: employer?.uen,
                        empcompanyName: employer?.empcompanyName,
                        contact: {
                          fullName: employer?.fullName,
                          emailAddress: employer?.emailAddress,
                          contactNumber: {
                            areaCode: employer?.areaCode,
                            countryCode: employer?.countryCode,
                            phoneNumber: employer?.phoneNumber
                          }
                        }
                      },
                      fees: {discountAmount: fees?.discountAmount, collectionStatus: fees?.collectionStatus}
                    }
                }
              }
          });
    
          if(updateEnrolment?.success){
            enqueueSnackbar("Updated successfully.", {variant: "success"});
            setIsLoading(false)
          }else {
            enqueueSnackbar(updateEnrolment?.message, {variant: "error"});
            setIsLoading(false)
          }
        }else {
            const {data: { createEnrolment }} = await CreateEnrolmentMutation({
              variables: {
                companyName: state?.info?.databaseName || company,
                _id: state?.class?._id,
                encryptionkey: companyProfile?.encryptionKey,
                _companyName: companyProfile?.companyName,
                fullName: fullName,
                  data: {
                      enrolment: {
                        course: { run: {id: classinfo?.courseRunId }, referenceNumber: classinfo?.course?.tsgRefNumber},
                        traineeInfo: {
                          _id: traineeInfo?._id,
                          nricFin: traineeInfo?.nricFin,
                          idType: traineeInfo?.idType,
                          fullName: traineeInfo?.fullName,
                          dob: SSGDate(traineeInfo?.dob),
                          email: traineeInfo?.email,
                          mobileNo: traineeInfo?.mobileNo,
                          centralProvidentFund: {
                            traineeCategory: ibfinfo?.traineeCategory,
                            letterOfSupportIssuanceDate: issuanceDate ? SSGDate(issuanceDate) : "",
                            didTraineeReceiveCPFContribution: isCPF ? 'Yes' : 'No',
                            isTraineeEmployedAtTimeofEnrolment: isTimeEnrolment? 'Yes' : 'No'
                          },
                          bundle: bundled?.code ? bundled?.code : "",
                          bundleDate: bundleDate ? SSGDate(bundleDate) : ""
                        },
                        employer: {
                          uen: employer?.uen,
                          empcompanyName: employer?.empcompanyName,
                          contact: {
                            fullName: employer?.fullName,
                            emailAddress: employer?.emailAddress,
                            contactNumber: {
                              areaCode: employer?.areaCode,
                              countryCode: employer?.countryCode,
                              phoneNumber: employer?.phoneNumber
                            }
                          }
                        },
                        fees: {discountAmount: fees?.discountAmount, collectionStatus: fees?.collectionStatus},
                        grant: ssgResponse?.funding,
                        enrolmentID: traineeInfo?._id,
                        sponsorshipType: checked ? "Employer" : "Individual",
                        trainingPartner: {uen: companyProfile?.uen, code: companyProfile?.uen + "-01"}, 
                        courseReferenceNumber: classinfo?.course?.tsgRefNumber,
                      }
                  }
                }
            });
            if(createEnrolment?.success){
                enqueueSnackbar("Created successfully!", {variant: "success"});
                setFees({
                  discountAmount: 0,
                  collectionStatus: ''
                })
                setChecked(false)
                setSSGResponse(null)
                setTraineeInfo(null)
                setIsLoading(false)

                setBundleCourseStartDate(null)
                setBundledInfo({ ...bundled, ['code']: '' });
                setIssuanceDate(null)
                setIBFInfo({ ...ibfinfo, ['traineeCategory']: '' });
                setIBF(false)
                setBundle(false)
            }else {
              enqueueSnackbar(createEnrolment?.message, {variant: "error"});
              setIsLoading(false)
            }
        

     
        }
      }
    }

    const onSaveDraft = async () => {
      setIsLoading(true)
      if(isBundle && !bundled.code){
        enqueueSnackbar("SCTP bundled code is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isBundle && bundleDate === null){
        enqueueSnackbar("SCTP bundled course start date is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isIBF && !isCPF && !ibfinfo?.traineeCategory){
        enqueueSnackbar("IBF trainee category is required.", {variant: "error"});
        setIsLoading(false)
      }else if(isIBF && !isCPF && issuanceDate === null){
        enqueueSnackbar("IBF issuance date is required.", {variant: "error"});
        setIsLoading(false)
      }else {
        if(fees?.collectionStatus){ 
          if(traineeUp?.ssgenrolment?.status){
            const {data: { submitEnrolment }} = await SubmitEnrolmentMutation({
              variables: {
                companyName: state?.info?.databaseName || company,
                _id: state?.class?._id,
                fullName: fullName,
                encryptionkey: companyProfile?.encryptionKey,
                _companyName: companyProfile?.companyName,
                referenceNumber: traineeUp?.ssgenrolment?.referenceNumber,
                  data: {
                      enrolment: {
                        course: { run: {id: state?.class.courseRunId }, referenceNumber: state?.class?.course?.tsgRefNumber},
                        traineeInfo: {
                          _id: traineeInfo?._id,
                          nricFin: traineeInfo?.nricFin,
                          idType: traineeInfo?.idType,
                          fullName: traineeInfo?.fullName,
                          dob: SSGDate(traineeInfo?.dob),
                          email: traineeInfo?.email,
                          mobileNo: traineeInfo?.mobileNo,
                          centralProvidentFund: {
                            traineeCategory: ibfinfo?.traineeCategory ? ibfinfo?.traineeCategory : "",
                            letterOfSupportIssuanceDate: issuanceDate ? SSGDate(issuanceDate) : "",
                            didTraineeReceiveCPFContribution: isCPF ? 'Yes' : 'No',
                            isTraineeEmployedAtTimeofEnrolment: isTimeEnrolment? 'Yes' : 'No'
                          },
                          bundle: bundled?.code,
                          bundleDate: bundleDate
                        },
                        employer: {
                          uen: employer?.uen,
                          empcompanyName: employer?.empcompanyName,
                          contact: {
                            fullName: employer?.fullName,
                            emailAddress: employer?.emailAddress,
                            contactNumber: {
                              areaCode: employer?.areaCode,
                              countryCode: employer?.countryCode,
                              phoneNumber: employer?.phoneNumber
                            }
                          }
                        },
                        fees: {discountAmount: fees?.discountAmount, collectionStatus: fees?.collectionStatus},
                        grant: ssgResponse?.funding,
                        enrolmentID: traineeInfo?._id,
                        sponsorshipType: checked ? "Employer" : "Individual",
                        trainingPartner: {uen: companyProfile?.uen, code: companyProfile?.uen + "-01"}, 
                        courseReferenceNumber: classinfo?.course?.tsgRefNumber,
                      }
                  }
                }
            });
      
            if(submitEnrolment?.success){
                enqueueSnackbar("Submitted successfully.", {variant: "success"});
                setChecked(false)
                setSSGResponse(null)
                setTraineeInfo(null)
                setIsLoading(false)

                setBundleCourseStartDate(null)
                setBundledInfo({ ...bundled, ['code']: '' });
                setIssuanceDate(null)
                setIBFInfo({ ...ibfinfo, ['traineeCategory']: '' });
                setIBF(false)
                setBundle(false)
            }else {
              enqueueSnackbar(submitEnrolment?.message, {variant: "error"});
              setIsLoading(false)
            }

          }else{
            const {data: { createEnrolmentDraft }} = await CreateEnrolmentDraftMutation({
              variables: {
                companyName: state?.info?.databaseName || company,
                _id: state?.class?._id,
                fullName: fullName,
                  data: {
                      enrolment: {
                        course: { run: {id: classinfo?.courseRunId }, referenceNumber: classinfo?.course?.tsgRefNumber},
                        traineeInfo: {
                          nricFin: traineeInfo?.nricFin,
                          idType: traineeInfo?.idType,
                          fullName: traineeInfo?.fullName,
                          dob: SSGDate(traineeInfo?.dob),
                          email: traineeInfo?.email,
                          mobileNo: traineeInfo?.mobileNo,
                          _id: traineeInfo?._id,
                          centralProvidentFund: {
                            traineeCategory: ibfinfo?.traineeCategory ? ibfinfo?.traineeCategory : "",
                            letterOfSupportIssuanceDate: issuanceDate ? SSGDate(issuanceDate) : "",
                            didTraineeReceiveCPFContribution: isCPF ? 'Yes' : 'No',
                            isTraineeEmployedAtTimeofEnrolment: isTimeEnrolment? 'Yes' : 'No'
                          },
                          bundle: bundled?.code,
                          bundleDate: bundleDate
                        },
                        employer: {
                          uen: employer?.uen,
                          empcompanyName: employer?.empcompanyName,
                          contact: {
                            fullName: employer?.fullName,
                            emailAddress: employer?.emailAddress,
                            contactNumber: {
                              areaCode: employer?.areaCode,
                              countryCode: employer?.countryCode,
                              phoneNumber: employer?.phoneNumber
                            }
                          }
                        },
                        fees: {discountAmount: fees?.discountAmount, collectionStatus: fees?.collectionStatus},
                        grant: ssgResponse?.funding,
                        enrolmentID: traineeInfo?._id,
                        sponsorshipType: checked ? "Employer" : "Individual",
                        trainingPartner: {uen: companyProfile?.uen, code: companyProfile?.uen + "-01"}, 
                        courseReferenceNumber: classinfo?.course?.tsgRefNumber,
                        ssgenrolment: {
                          referenceNumber: '',
                          status: 'Draft'
                        }
                      }
                  }
                }
            });
      
            if(createEnrolmentDraft?.success){
              enqueueSnackbar("Created successfully!", {variant: "success"});
              setFees({
                discountAmount: 0,
                collectionStatus: ''
              })
              setChecked(false)
              setSSGResponse(null)
              setTraineeInfo(null)
              setIsLoading(false)

              setBundleCourseStartDate(null)
              setBundledInfo({ ...bundled, ['code']: '' });
              setIssuanceDate(null)
              setIBFInfo({ ...ibfinfo, ['traineeCategory']: '' });
              setIBF(false)
              setBundle(false)

            }else {
              enqueueSnackbar(createEnrolmentDraft?.message, {variant: "error"});
              setIsLoading(false)
            }
          }
          }else {
            enqueueSnackbar('Please select payment status.', {variant: "error"});
            setIsLoading(false)
          }
      }
    }




    const handleCalculateGrants = async () => {
      const {data: { grantsPersonalized }} = await CreateGrantsPersonalized({
        variables: {
          encryptionkey: companyProfile?.encryptionKey,
          _companyName: companyProfile?.companyName,
          data: {
            course: {
              startDate: SSGDate(classinfo?.courseDates?.start),
              referenceNumber: classinfo?.course?.tsgRefNumber
            },
            trainee: {
              id: traineeInfo?.nricFin,
              idType: traineeInfo?.idType,
              dateOfBirth: SSGDate(traineeInfo?.dob),
              sponsoringEmployerUen: employer?.uen,
              modularisedSCTPBundleCourseStartDate: bundleDate !== null ? SSGDate(bundleDate) : ''
            }
          }
          }
      });
      if(grantsPersonalized?.success){
          setSSGResponse(grantsPersonalized?.results?.message?.data)
          enqueueSnackbar(grantsPersonalized?.message, {variant: "success"});
          
      }else {
        enqueueSnackbar(grantsPersonalized?.message, {variant: "error"});
      }

    }


const handleOnChange = useCallback(event => {
  const { name, value } = event.target;
  setFees({ ...fees, [name]: value });
});

const handleOnChangeEmployer = useCallback(event => {
  const { name, value } = event.target;
  setEmployer({ ...employer, [name]: value });
});

const handleOnChangeBundled = useCallback(event => {
  const { name, value } = event.target;
  setBundledInfo({ ...bundled, [name]: value });
});

const handleOnChangeIBF = useCallback(event => {
  const { name, value } = event.target;
  setIBFInfo({ ...ibfinfo, [name]: value });
});

const handleSelected = useCallback(event => {
  handelOnClose();
  setTraineeInfo(event)
});


    const renderOverview = (
        <>
        <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'NRIC/FIN/Others',
                value: traineeInfo?.nricFin,
                icon: <Iconify icon="solar:user-id-outline" />,
             },
             {
              label: 'ID Type',
              value: traineeInfo?.idType,
              icon: <Iconify icon="la:id-card-alt" />,
           },
            {
              label: 'Full Name',
              value: traineeInfo?.fullName,
              icon: <Iconify icon="ri:user-2-fill" />,
            },
            {
              label: 'Date of Birth',
              value: fDate(traineeInfo?.dob),
              icon: <Iconify icon="solar:calendar-date-bold" />,
            },
            {
              label: 'Email',
              value: traineeInfo?.email,
              icon: <Iconify icon="ic:outline-email" />,
            }
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}

          <FormGroup>
                <FormControlLabel
                    control={<Switch size="small" checked={checked} onChange={toggleChecked} />}
                    label={`Sponsorship type? ${checked? 'Employer':'Individual'}`}
                disabled={traineeUp?.ssgenrolment?.status  ? true : false}
                />
            </FormGroup>

          <FormGroup>
                <FormControlLabel
                    control={<Switch size="small" checked={isBundle} onChange={toggleBundle} />}
                    label={`Is the trainee enrolling in a bundled programme? ${isBundle ? 'Yes' : 'No'}`}
                />
            </FormGroup>

            <FormGroup>
                <FormControlLabel
                    control={<Switch size="small" checked={isIBF} onChange={toggleIBF} />}
                    label={`Is the course accredited by IBF? ${isIBF? 'Yes' : 'No'}`}
                />
            </FormGroup>



        </Stack>
      
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'Course Title',
                value: classinfo?.course?.courseTitle,
                icon: <Iconify icon="ic:outline-class" />,
             },
             {
              label: 'Approved Course Fee',
              value: `$ ${classinfo?.course?.courseFee}`,
              icon: <Iconify icon="carbon:task-approved" />,
           },
            {
              label: 'Eligible Grants',
              value: ssgResponse?.funding?.eligibleGrants.map((res) => {
                return(
                  <>
                    <Typography>{res?.schemeName} : $ {res?.amount}</Typography>
                  </>
                )
              }),
              icon: <Iconify icon="solar:user-check-linear" />,
            },
            {
              label: 'Total Funding',
              value: ssgResponse?.funding ? `$ ${ssgResponse?.funding?.totalFunding?.amount}` : "",
              icon: <Iconify icon="fluent:feed-20-regular" />,
            },
            {
              label: 'Nett Fee',
              value: ssgResponse?.funding ? `$ ${ssgResponse?.funding?.nettFee?.amount}` : "",
              icon: <Iconify icon="fluent:payment-16-regular" />,
            }
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}
           <TextField size='small' value={fees?.discountAmount} name="discountAmount" label="Discount Amount" onChange={handleOnChange}/>
           <TextField select size='small' name="collectionStatus" value={fees?.collectionStatus} label="Payment Status *" onChange={handleOnChange}> 
                {_paymentStatus.map((res) => {
                  return (
                    <MenuItem key={res.value} value={res.value}>{res?.label}</MenuItem>
                  )
                })}
           </TextField>

             <IconButton
                    size="small"
                   color="info"
                   sx={{
                    borderRadius: 1,
                       bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                      '&:hover': {
                       bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                      },
                    }}
                    onClick={() => handleCalculateGrants()}
                   >
                     <Iconify width={18} icon="bi:calculator" />
                       <Typography variant='subtitle2'> View Grant Eligibility</Typography>
                  </IconButton>

                  <Typography variant='body2'>{'The Grant Calculator provides an estimated grant amount and does not factor in discount (if any).'}</Typography>
        </Stack>
        </Box>

        
        {
            checked ?
            <>
            <Typography variant='body2' sx={{mt: 2, mb: 1}}>{`Employer Info`}</Typography>
            <Box
            rowGap={3}
            columnGap={2}
            display="grid"  
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
            }}
          >
                <TextField size='small' name="uen" label="UEN" value={employer?.uen} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="empcompanyName" label="Company Name" value={employer?.empcompanyName} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="contactName" label="Contact Name" value={employer?.fullName} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="countryCode" label="Country Code" value={employer?.countryCode} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="areaCode" label="Area Code" value={employer?.areaCode} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="phoneNumber" label="Phone Number" value={employer?.phoneNumber} onChange={handleOnChangeEmployer}/>
                <TextField size='small' name="emailAddress" label="Email Address" value={employer?.emailAddress} onChange={handleOnChangeEmployer}/>
            </Box>

            <Divider sx={{borderColor: 'white', border: 2, mt: 2, mb: 2}}/>
            </>
            :
            ""
        }

        {
          isBundle ?
          <>
            <Typography variant='body2' sx={{mt: 2, mb: 1}}>{`SCTP Bundled Programme`}</Typography>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"  
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
              <TextField size='small' name="code" label="SCTP Bundle Code" value={bundled?.code} onChange={handleOnChangeBundled}/>
              <DatePicker
                  label="SCTP Bundle Course Start Date"
                  format='dd/MM/yyyy'
                  value={bundleDate}
                  onChange={(event) => {
                    setBundleCourseStartDate(event)
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: 'small'
                    },
                  }}
                  
                />
          </Box>
          <Divider sx={{borderColor: 'white', border: 2, mt: 2, mb: 2}}/>
          </>
          :
          ""
        }
  
         {
          isIBF ?
          <>
            <Typography variant='body2' sx={{mt: 2, mb: 1}}>{`IBF Accredited Course`}</Typography>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"  
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
            
          >
               <FormGroup>
                <FormControlLabel
                    control={<Switch size="small"  checked={isCPF} onChange={toggleContribution}/>}
                    label={`Did trainee receive CPF Contribution? ${isCPF ? "Yes" : "No"}`}
                />
            </FormGroup>

            <FormGroup>
                <FormControlLabel
                    control={<Switch size="small"  checked={isTimeEnrolment} onChange={toggleTimeEmployed}/>}
                    label={`Is trainee employed at time of enrolment? ${isTimeEnrolment ? "Yes" : "No"}`}
                    disabled={isCPF}
                />
            </FormGroup>

                <FormControl sx={{
                    width: 350
                }}>
                    <InputLabel id="demo-simple-select-label">Trainee Category</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        name="traineeCategory"
                        label="Trainee Category"
                        onChange={handleOnChangeIBF}
                        value={ibfinfo?.traineeCategory}
                        size="small"
                        disabled={isCPF}
                    >   
                        {traineeCategory?.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value} sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                {item.label}
                            </MenuItem>
                            )
                         })}
                    </Select>
                    </FormControl>

              {/* <TextField size='small' name="traineeCategory" label="Trainee Category" /> */}

              <DatePicker
                  label="Letter of Support Issuance Date"
                  format='dd/MM/yyyy'
                  value={issuanceDate}
                  onChange={(event) => {
                    setIssuanceDate(event)
                  }}
                  slotProps={{
                    textField: {
                      size: 'small'
                    },
                  }}
                  sx={{
                    width: 350
                  }}
                  disabled={isCPF}
                />

          </Box>
          </>
          :
          ""
        }




        </>
      );

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => {
      onClose();
      handelOnClose();
    }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6">{traineeUp ? "Update Trainee" : "Enrol Trainee"}</Typography>
  
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
      <Grid container spacing={2}>
      <Grid item xs={4} md={4} justifyContent="center">
        <ClassEnrolmentToolbar  state={companyInfo} handleSet={(e) => handleSelected(e)} isUpdate={traineeUp}/>
       
      </Grid>
      <Grid item xs={8} md={8}>
      {renderOverview}
      </Grid>

      </Grid>
      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton variant="contained" loading={isLoading} onClick={onSaveDraft} sx={{display: traineeUp?.ssgenrolment?.status === 'Draft' || !traineeUp ? '' : 'none'}} >{traineeUp ? "Submit Enrolment" : "Save Enrolment Draft"}</LoadingButton>
              <LoadingButton variant="contained" loading={isLoading}   onClick={() => onSubmit()} sx={{display: traineeUp?.ssgenrolment?.status !== 'Draft' ? '' : 'none'}}>{traineeUp ? "Update Enrolment" : "Submit Enrolment"}</LoadingButton>
              <LoadingButton variant="contained" loading={isLoading} onClick={() => {
                handelOnClose();
                onClose();
              }}>Close</LoadingButton>
            </Stack>  
      </Stack>
    

    </Dialog>
  );
}

ClassTraineeDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  traineeInfo: PropTypes.object,
  handleRefetch: PropTypes.func
};
