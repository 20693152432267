import { sum } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import {Box, Stack, Button, Divider, MenuItem, Typography, inputBaseClasses, InputAdornment} from '@mui/material';
// utils
import { fCurrency } from '../../../../util/format-number.js';



// components
import Iconify from '../../../../components/iconify/index.js';
import { RHFSelect, RHFTextField,  } from '../../../../components/hook-form/index.js';

// ----------------------------------------------------------------------

import { GET_ALL_SUBSCRIPTION } from "../../../../graphql/modules/subscription.js";
import { useQuery } from "@apollo/react-hooks";


const SUBSCRIPTION_DATA = [
    {id: 'basic', name: 'Basic', price: 100},
    {id: 'standard', name: 'Standard', price: 150},
    {id: 'premium', name: 'Premium', price: 200},
    {id: 'customized', name: 'Customized', price: 250}
]


// ----------------------------------------------------------------------

export default function ReceiptNewEditDetails() {
  const { control, setValue, watch, resetField } = useFormContext();
  const [results, setResults] = useState(null);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receipt',
  });

  const { data, loading, error, refetch } = useQuery(GET_ALL_SUBSCRIPTION, {
    variables: {
      limit: 10,
      offset: 0,
      searchText: "",
      companyName: "iatos",
      order: "asc",
      orderBy: "_id"
      },
      fetchPolicy: "cache-and-network",
    });

  const values = watch();


  const totalOnRow = values?.receipt?.map((item) => item?.total);
  const subTotalless = sum(totalOnRow);
  // const subTotal = (subTotalless );


  const totalItem = values?.items?.map(num => num?.price);
  const subTotallessItem = sum(totalItem);


  const mappedNumbers = sum(values?.items?.map(num => num?.price < 0 ? Math.abs(num?.quantity * num?.price) : 0));
  const tax = (subTotallessItem + mappedNumbers - values.discount) * (values.taxes / 100); 

  console.log(values)
  const mappedItem = values?.items?.map(num => num?.price < 0 ? num?.price : 0);
  const sumBalance = sum(mappedItem);
  const totalBalanceToPay = sum([values?.totalAmount, sumBalance, 100]);


  const totalAmountSum = subTotalless;
  
  useEffect(() => {
    setValue('totalAmountSum', totalAmountSum);
  }, [setValue, totalAmountSum]);

  useEffect(() => {
    if(data){
      setResults(data?.getAllSubscription)
    }
}, [data])

  const handleAdd = () => {
    append({
      paymentMethod: '',
      paymentMethodNo: '',
      total: 0
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };


  // const handleSelectService = useCallback(
  //   (index, option, amount) => {
  //     console.log(amount)
  //     setValue(
  //       `items[${index}].price`,
  //       amount
  //     );
  //     setValue(
  //       `items[${index}].total`,
  //       values.items.map((item) => item.quantity * item.price)[index]
  //     );
  //   },
  //   [setValue, values.items]
  // );

  // const handleChangeQuantity = useCallback(
  //   (event, index) => {
  //     setValue(`items[${index}].quantity`, Number(event.target.value));
  //     setValue(
  //       `items[${index}].total`,
  //       values.items.map((item) => item.quantity * item.price)[index]
  //     );
  //   },
  //   [setValue, values.items]
  // );

  const handleChangePrice = useCallback(
    (event, index) => {
      // setValue(`receipt[${index}].price`, Number(event.target.value));
      setValue(
        `items[${index}].total`,
        values.receipt.map((item) => item.total)[index]
      );
    },
    [setValue, values.receipt]
  );

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: 'right', typography: 'body2' }}
    >

      <Stack direction="row" sx={{ typography: 'subtitle1' }}>
        <Box>Receipt Total</Box>
        <Box sx={{ width: 160 }}>{fCurrency(totalAmountSum) || '-'}</Box>
      </Stack>
    </Stack>
  );


  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Details:
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => {
          return (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>

              
              <RHFTextField
                name={`receipt[${index}].paymentMethod`}
                label="Payment Method"
                InputLabelProps={{ shrink: true }}
              />

                <RHFTextField
                name={`receipt[${index}].paymentMethodNo`}
                label="Payment Method Number"
                InputLabelProps={{ shrink: true }}
              />

              {/* <RHFSelect
                name={`items[${index}].service`}
                size="small"
                label="Service"
                InputLabelProps={{ shrink: true }}
                sx={{
                  maxWidth: { md: 160 },
                }}
              >
                <MenuItem
                  value=""
                  onClick={() => handleClearService(index)}
                  sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                >
                  None
                </MenuItem>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {results?.results?.map((service) => (
                  <MenuItem
                    key={service._id}
                    value={service.title}
                    onClick={() => handleSelectService(index, service.title, service.amount)}
                  >
                    {service.title}
                  </MenuItem>
                ))}
              </RHFSelect> */}

              {/* <RHFTextField
                type="number"
                name={`items[${index}].price`}
                label="Price"
                placeholder="0.00"
                onChange={(event) => handleChangePrice(event, index)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ typography: 'subtitle2', color: 'text.disabled' }}>$</Box>
                    </InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { md: 180 } }}
              /> */}

              <RHFTextField
                type="number"
                name={`receipt[${index}].total`}
                label="Amount"
                placeholder="0.00"
               
                // value={ values?.receipt?.items ? values?.receipt?.items[index]?.total?.toFixed(2) : ''}
                // onChange={(event) => handleChangePrice(event, index)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ typography: 'subtitle2'}}>$</Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  maxWidth: { md: 180 },
                  [`& .${inputBaseClasses.input}`]: {
                    textAlign: { md: 'right' },
                  },
                }}
              />
            </Stack>

            <Button
              size="small"
              color="error"
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
              onClick={() => handleRemove(index)}
            >
              Remove
            </Button>
          </Stack>
        )})}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
          <Stack
              spacing={2}
              justifyContent="flex-start"
              direction={{ xs: 'column', md: 'column' }}
              sx={{ width: 1 }}
            >
              
                <Typography variant='body1'>{`Invoice Number: ${values?.invoiceNumber ? values?.invoiceNumber : ''}`}</Typography>
                <Typography variant='body1'>{`Total Payable: ${totalBalanceToPay ? fCurrency(totalBalanceToPay) : '0.00'}`}</Typography>

          </Stack>
        </Stack>


      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ xs: 'flex-end', md: 'center' }}
      >
        <Button
          size="small"
          color="primary"
          startIcon={<Iconify icon="mingcute:add-line" />}
          onClick={handleAdd}
          sx={{ flexShrink: 0 }}
        >
          Add Receipt Item
        </Button>

      </Stack>

      {renderTotal}
    </Box>
  );
}
