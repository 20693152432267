
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "../../../../components/iconify/iconify.js";
import Label from '../../../../components/label/index.js';
import { ConfirmDialog } from '../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { fDate } from '../../../../util/formatTime.js';
import { parseISO, format } from 'date-fns';

export default function AssignedReportTableRow({row, selected, onSelected, onDeleteRow, onCancelledRow, onEditRow, onViewRow, onCompleted, onRevert}){
  const isRole = useSelector((state) => state?.auth?.isRole);
  const { courseRunId, course, classCode, courseDates, sessions, venue, venue_old, status, trainerAssessor } =row;
  const venueDetails = venue?.block || venue?.postalCode ? `${venue?.block} ${venue?.street} #${venue?.floor}-${venue?.unit} ${venue?.room} ${venue?.building} ${venue?.postalCode}` : venue_old;
  const setTrainer = sessions.map(item => item?.trainer?.fullName)
  const filterTrainer = [...new Set(setTrainer)];

    const popover = usePopover();
    const confirm = useBoolean();
    const cancel = useBoolean();
    const completed = useBoolean();
    const revertBack = useBoolean();
  const trainerFullName = course?.trainerAssessor?.map((item) => item?.fullName)

 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{courseRunId}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{classCode}</TableCell>
            <TableCell>{course?.courseTitle}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{trainerFullName?.join(', ')}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{fDate(courseDates.start)}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{fDate(courseDates.end)}</TableCell>
    </TableRow>
    {/* <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 210}}
    >

        <MenuItem
          onClick={() => {
            popover.onClose();
            onViewRow();
          }}
        >
        <Iconify icon="ic:round-print" />
            Print Course Assigned
        </MenuItem>

            


    </CustomPopover> */}

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

<ConfirmDialog
        open={cancel.value}
        onClose={cancel.onFalse}
        title="Cancel"
        content={`Are you sure want to cancel ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={onCancelledRow}>
            Cancel
          </Button>
        }
      />

      <ConfirmDialog
        open={completed.value}
        onClose={completed.onFalse}
        title="Completed"
        content={`Are you sure want to update ${classCode} status to completed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onCompleted();
            completed.onFalse();
          }}>
            Yes
          </Button>
        }
      />

<ConfirmDialog
        open={revertBack.value}
        onClose={revertBack.onFalse}
        title="Revert Status"
        content={`Are you sure want to revert the  status to confirmed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onRevert();
            revertBack.onFalse();
          }}>
            Yes
          </Button>
        }
      />

</>
 )

}

AssignedReportTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func
  };
  
