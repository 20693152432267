import PropTypes from 'prop-types';
import merge from 'lodash/merge.js';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles/index.js';

import { useSettingsContext } from '../components/settings/index.js';
import { useLocales } from '../locales/index.js';
//
 import { palette  } from './palette.js';
 import { shadows } from './shadows.js';
// import typography from './typography';
 import { customShadows } from './custom-shadow.js';
import ComponentsOverrides from './overrides/index.js';



import { presets } from './options/presets.js';
import { darkMode } from './options/dark-mode.js';
import { contrast } from './options/contrast.js';
 import RTL, {direction} from './options/right-to-left.js'


// ----------------------------------------------------------------------


export default function ThemeProvider({ children }) {
const { currentLang } = useLocales();
const settings = useSettingsContext();
const darkModeOption = darkMode(settings.themeMode);
const presetsOption = presets(settings.themeColorPresets);
 const constrasOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

const baseOption = useMemo(() => ({
  palette: palette('light'),
  shadows: shadows('light'),
  customShadow: customShadows('light'),
  // typography,
  shape: {borderRadius: 8}
}))

const memoizedValue = useMemo(() => 
  merge(
    baseOption,
    darkModeOption,
    presetsOption
  ),
  [baseOption]
)

const theme = createTheme(memoizedValue);
theme.components = merge(ComponentsOverrides(theme), constrasOption.components);

const themeWithLocale = useMemo(() => createTheme(theme, currentLang.systemValue),
[currentLang.systemValue, theme]
);

  return (
      <MUIThemeProvider theme={themeWithLocale}>

        <CssBaseline />
          {children}
      </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}