import gql from "graphql-tag";

export const GET_VENUE= gql`
query GetVenue($companyName: String!, $limit: Int, $offset: Int, $searchText: String, $order: String, $orderBy: String) {
    getVenue(companyName: $companyName, limit: $limit, offset: $offset, searchText: $searchText, order: $order, orderBy: $orderBy) {
        code
        success
        message
        count
        results
  }
}`;

export const CREATE_VENUE= gql`
mutation CreateVenue($data: VenueInput!, $fullName: String!, $companyName: String!) {
  createVenue(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const UPDATE_VENUE = gql`
mutation UpdateVenue($_id: ID!, $data: VenueInput!, $fullName: String!, $companyName: String!) {
  updateVenue( _id: $_id, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

export const DELETE_VENUE= gql`
mutation DeleteVenue($_id: ID!, $fullName: String!, $companyName: String!) {
  deleteVenue(_id: $_id, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;