import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { useTheme, Link, Tooltip, ListItemText } from '@mui/material';
import Iconify from '../../iconify/index.js';

import { StyledItem, StyledIcon } from './styles.js';

const NavItem = forwardRef(({ item, depth, open, active, externalLink, config, ...other}, ref) => {

    const theme = useTheme();

    const {title, path, icon, children, disabled, caption, roles} = item;

    const subItem = depth !== 1;
    const renderContent = (
        <StyledItem
        disabledGutters
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        config={config}
        {...other}>
            {icon && (
                <StyledIcon
                size={config.iconSize}
                sx={{
                    ...(subItem && {mr: 1.5})
                }}>
                    {icon}
                </StyledIcon>
            )}

            {!(config.hiddenLabel && !subItem) && (
                <ListItemText
                sx={{
                    width: 1,
                    flex: 'unset',
                    ...(!subItem && {
                        px: 0.5,
                        mt: 0.5
                    })
                }}
                primary={title}
                primaryTypographyProps={{
                    noWrap: true,
                    fontSize: 10,
                    lineHeight: '16px',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
                    ...(subItem && {
                        textAlign: 'unset',
                        fontSize: theme.typography.body2.fontSize,
                        lineHeight: theme.typography.body2.lineHeight,
                        fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium'
                    })
                }}
                />
            )}

            {caption && (
                <Tooltip title={caption} arrow placement='right'>
                    <Iconify width={16}
                    icon="eva:info-outline"
                    sx={{
                        color: 'text.disabled',
                        ...(!subItem && {
                            top: 11,
                            left: 6,
                            position: 'absolute'
                        })
                    }}
                    />
                </Tooltip>
            )}

            {!!children && (
                <Iconify 
                width={16}
                icon="eva:arrow-ios-forward-fill"
                sx={{
                    top: 11,
                    right: 6,
                    position: 'absolute'
                }}
                />
            )}
        </StyledItem>
    );

    return (
        <Link
        href={path}
        underline='none'
        sx={{
            width: 1,
            ...(disabled && {
                cursor: 'default'
            })
        }}
        >
            {renderContent}

        </Link>
    )

})

NavItem.propTypes = {
    active: PropTypes.bool,
    config: PropTypes.object,
    depth: PropTypes.number,
    item: PropTypes.object,
    open: PropTypes.bool
}

export default NavItem;