import gql from "graphql-tag";
export const GET_ASSESSMENT_BY_ID = gql`
query GetAssessmentFormById($classID: String!, $userID: String!, $companyName: String!) {
    getAssessmentFormById(classID: $classID, userID: $userID,  companyName: $companyName) {
        code
        success
        message
        results        
  }
}`;

// export const CREATE_ASSESSMENT_FORM = gql`
// mutation CreateAssessmentForm($data: AssessmentFormInput!, $fullName: String!, $companyName: String!) {
//   createAssessmentForm(data: $data, fullName: $fullName, companyName: $companyName) {
//     code
//     success
//     message
//   }
// }`;


export const UPDATE_ASSESSMENT_FORM = gql`
mutation UpdateAssessmentForm($classID: String!, $userID: String!, $data: AssessmentFormInput!, $fullName: String!, $companyName: String!) {
  updateAssessmentForm(classID: $classID, userID: $userID, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

// NEW ASSESSMENT FORM

export const CREATE_ASSESSMENT_FORM = gql`
mutation AddAssessmentForm($_id: ID!, $traineeID: ID!, $data: AssFormInput!, $fullName: String!, $companyName: String!) {
  addAssessmentForm(_id: $_id, traineeID: $traineeID, data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;

