import { Helmet } from "react-helmet-async";
 import { ReceiptCreateUpdate } from "../../sections/company/finance/receipt/index.js";

export default function CompanyCreateReceipt(){
    return (
        <>
            <Helmet>
                <title>Create Receipt</title>
            </Helmet>
            <ReceiptCreateUpdate />
        </>
    )
}