import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Box, Pagination, paginationClasses} from '@mui/material';
import { useSettingsContext } from '../../../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../../components/table/index.js';
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import { useBoolean } from '../../../../../hooks/use-boolean.js';
import Iconify from '../../../../../components/iconify/iconify.js';
import GrantsTableRow from './grants-row.js';
// import CompanyTableToolBar from './class-enrolment-table-toolbar.js';
// import AssessmentResultSSG from './assessment/assessment-result-ssg.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import Label from '../../../../../components/label/index.js';
// import JobItem from './class-enrolment-table-row.js';


// GRAPHQL
import { GET_CLASS_BY_ID } from "../../../../../graphql/modules/_client/class.js";
 import { useQuery } from "@apollo/react-hooks";

 

const STATUS_OPTION = [
  {value: 'all', label: 'All'}
]
const TABLE_HEAD = [
  {id: 'fullName', label: 'Full Name'},
  {id: 'nricFin', label: 'NRIC/FIN/Others'},
  {id: 'email', label: 'Email'},
  {id: 'mobileNo', label: 'Mobile No'},
  {id: 'status', label: 'Enrolment Status'},
  {id: 'referenceNumber', label: 'Enrolment ID'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function GrantsViewList({companyinfo}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const assessmentResult = useBoolean();


  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);



  const { data, loading, error, refetch } = useQuery(GET_CLASS_BY_ID, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyinfo?.info?.databaseName || company,
      order: table.order,
      orderBy: table.orderBy,
      _id: companyinfo?.class?._id
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getClassesById)
      }
  }, [data])


    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (id) => {
      console.log(id)
      
});


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-classes', {state: {info: companyinfo, users: row}})
  });

const handleView = useCallback(
  (row) => {
    navigate('/company-enrolment-session', {state: {class: row, info: companyinfo}})
  },
  []
);

const handleEdit = useCallback(
  (id) => {
    console.log(id)
    // router.push(paths.dashboard.job.edit(id));
  },
  []
);

const handleDelete = useCallback((id) => {
  console.info('DELETE', id);
}, []);

  const handleCreateNewClass = useCallback(() => {
    navigate('/company-create-update-classes', {state: {info: companyinfo}})
 
});

const onPrintAssForm = useCallback((row) => {
  console.log(row)
})


  return (
     <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${companyinfo?.class?.course?.courseTitle}`}
          links={[
           {name: 'Dashboard'},
           {name: 'Class'},
           {name: 'Grant information'}
          ]}
       />

  

        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'confirmed' && 'success') ||  (tab.value === 'draft' && 'warning') ||  (tab.value === 'cancelled' && 'error') || 'default'
                }
                >
                  {tab.value === 'all' && results?.results?.length}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          {/* <CompanyTableToolBar onFilters={handleFilters} /> */}

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
            <Scrollbar>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <GrantsTableRow
                      key={row?.id}
                      row={row}
                      classinfo={state?.class}
                      onViewRow={() => handleView(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                      onPrintAssForm={() => onPrintAssForm(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
              </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          /> */}
        </Card>


    </Container>
  );
}

GrantsViewList.propTypes = {
  companyinfo: PropTypes.object,
};
