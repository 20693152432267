import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import {Box, Stack, Dialog, TextField, Typography, InputAdornment, ListItemButton, listItemButtonClasses, IconButton} from '@mui/material';
import { GET_VENUE, DELETE_VENUE } from "../../../graphql/modules/_client/venue.js";
import { useQuery, useMutation } from "@apollo/react-hooks";
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// import SearchNotFound from 'src/components/search-not-found';

// ----------------------------------------------------------------------

export default function AddressVenueDialog({ company, fullName, title = 'Address Book', list, action, open, onClose, selected, onSelect, addedNew, formclose}) {
  const company1 = useSelector((state) => state?.auth?.company);
  const [searchText, setSearch] = useState("");
  const [results, setResults] = useState(null);
  const [DeleteVenueMutation] =  useMutation(DELETE_VENUE);

  
  const { data, loading, error, refetch } = useQuery(GET_VENUE, {
    variables: {
      limit: 5,
      offset: 0,
      searchText: searchText,
      companyName: company || company1
      },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
      if(data){
        setResults(data?.getVenue)
      }
  }, [data, addedNew, formclose])



  const handleSearchAddress = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleSelectAddress = useCallback(
    (address) => {
      onSelect(address);
      onClose();
    },
    [onClose, onSelect]
  );

  const handleDelete = useCallback(async (ID) => {
    const {data: { deleteVenue }} = await DeleteVenueMutation({
      variables: {
        _id: ID,
        companyName: company1,
        fullName: fullName,
      }
    })
    if(deleteVenue?.success){
      refetch();
    }
    },
    [refetch]);

  const renderList = (
    <Stack
      spacing={0.5}
      sx={{
        p: 0.5,
        maxHeight: 80 * 8,
        overflowX: 'hidden',
      }}
    >
      {results?.results?.map((address) => (
             <Stack direction="row" spacing={3}>
   
        <Stack
          key={address?._id}
          spacing={0.5}
          component={ListItemButton}
          onClick={() => handleSelectAddress(address?.venue)}
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`&.${listItemButtonClasses.selected}`]: {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{address?.venue?.block} {address?.venue?.street} {address?.venue?.building}</Typography>
          </Stack>

          {address.venue && (
            <Box sx={{ color: 'primary.main', typography: 'caption' }}>{address?.venue?.postalCode}</Box>
          )}


          {address.venue && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Wheelchair Access: {address?.venue?.wheelChairAccess ? "Yes" : "No"}
            </Typography>
          )}
  
        </Stack>
        <IconButton onClick={() => handleDelete(address?._id)} color='error'>  <Iconify icon="solar:trash-bin-trash-bold" /></IconButton>
        </Stack>
  
      ))}
      
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> {title} </Typography>

        {action && action}
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
        <TextField
         value={searchText}
          onChange={handleSearchAddress}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {renderList}
    </Dialog>
  );
}

AddressVenueDialog.propTypes = {
  action: PropTypes.node,
  list: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.func,
  title: PropTypes.string,
};
