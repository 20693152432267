import { Helmet } from "react-helmet-async";
 import CreateUpdateCourseCompany  from "../../sections/company/course/company-create-update-course.js";

export default function CreateUpdateCourseCompanyPage(){
    return (
        <>
            <Helmet>
                <title>Company Course Page</title>
            </Helmet>
            <CreateUpdateCourseCompany />
        </>
    )
}