import PropTypes from 'prop-types';
// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent} from '@mui/material';

// ----------------------------------------------------------------------

export default function ForgetDialog({ title, content, action, open, onClose, ...other }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}
      </DialogActions>
    </Dialog>
  );
}

ForgetDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
