import { Helmet } from "react-helmet-async";
// import { DashboardAdmin } from "../../sections/dashboard/dashboardadmin.js";
import { InvoiceListView } from "../../sections/company/finance/invoice/index.js";

export default function InvoiceTP(){
    return (
        <>
            <Helmet>
                <title>Finance Management</title>
            </Helmet>
           <InvoiceListView />
        </>
    )
}