import { useState, useCallback } from "react";
import { alpha, ButtonBase, Box } from "@mui/material";
import SvgColor from "../../svgColor/svgColor.js";

export default function FullScreenOption(){
    const [fullscreen, setFullScreen] = useState(false);

    const onToggleFullScreen = useCallback(() => {
        if(!document.fullscreenElement){
            document.documentElement.requestFullscreen();
            setFullScreen(true);
        }else if(document.exitFullscreen){
            document.exitFullscreen();
            setFullScreen(false);
        }
    }, [])

    return (
        <Box sx={{ p: 2.5}}>
            <ButtonBase
            onClick={onToggleFullScreen}
            sx={{
                width: 1,
                height: 48,
                borderRadius: 1,
                color: 'text.disabled',
                typography: 'subtitle2',
                border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
                ...(fullscreen && {
                    color: 'text.primary'
                }),
                '& .svg-color': {
                    background: (theme) =>
                    `linear-gradient(135deg, ${theme.palette.grey[500]} 0%, ${theme.palette.grey[600]} 100%)`,
                    ...(fullscreen && {
                        background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                    })
                }
            }}
            >
                <SvgColor src={`/assets/icons/settings/${fullscreen ? 'ic_exit_full_screen': 'ic_full_screen'}.svg`} 
                sx={{width: 16, height: 16, mr:1}}
                />
                {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            </ButtonBase>
        </Box>
    )
}