import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useState, useMemo, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useBoolean } from '../../../../../hooks/use-boolean.js';
// @mui
import { useLocation } from 'react-router-dom';
import {alpha,  Button,IconButton, TextField, Stack, Box, Dialog, Typography, ListItemText, TableBody, Card, Grid, Table, TableRow, TableHead, TableCell, FormGroup, FormControlLabel, Divider} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import AssessmentFormUpdate from './assessment-record-details.js';
import { GET_ASSESSMENT_BY_ID, CREATE_ASSESSMENT_FORM, UPDATE_ASSESSMENT_FORM } from "../../../../../graphql/modules/_client/assessmentForm.js";
import { useQuery, useMutation } from "@apollo/react-hooks";
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RHFMultiSelect,
    RHFRadioGroup
  } from 'src/components/hook-form';


const _criteriaResults = [
    {"label": "Competent", "value": "Competent"},
    {"label": "Not Yet Competent", "value": "Not Yet Competent"}
]


// ----------------------------------------------------------------------

export default function AssessmentForm({title, open, onClose, classInfo, userInfo, assessmentMethod}) {
    const {fullName} = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const assessmentForm = userInfo?.assForm ? userInfo?.assForm?.assessmentRecordForm : null;
    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [results, setResults] = useState([]);
    const [CreateAssessmentMutation] =  useMutation(CREATE_ASSESSMENT_FORM);
    const [UpdateAssessmentMutation] =  useMutation(UPDATE_ASSESSMENT_FORM);

    const assessmentFormUp = useBoolean();


    const crtAss = classInfo?.course?.assessmentRecordForm?.map((item, index) => {
      return {
        assessmentMethod: item.assessmentMethod,
        assessmentDetails: item.assessmentDetails
      }
    })

    const methods = useForm({
      defaultValues: {
        assessmentRecordForm:  assessmentForm || crtAss
      },
    });

    const {
      reset,
      watch,
      control,
      setValue,
      handleSubmit,
      register,
      formState: { isSubmitting },
    } = methods;

  const values = watch();


    // const { data, loading, error, refetch } = useQuery(GET_ASSESSMENT_BY_ID, {
    //   variables: {
    //     companyName: classInfo?.info?.databaseName || company,
    //     classID: classInfo?._id,
    //     userID: userInfo?.traineeInfo?._id
    //     },
    //     fetchPolicy: "cache-and-network",
    //   });


    //   useEffect(() => {
    //     if(data){
    //       setResults(data?.getAssessmentFormById)
    //     }
    // }, [data, reset])




    // const updtAss = results?.results?.assessmentMethod?.map((item, index) => {
    //   return {
    //     assessmentMethod: item.assessmentMethod,
    //     assessmentDetails: item.assessmentDetails
    //   }
    // })




    // const assessmentRecordForm = !results?.results ? crtAss: updtAss;





    const onSubmit = handleSubmit(async (data) => {
        try {
          const {data: { addAssessmentForm }} = await CreateAssessmentMutation({
            variables: {
              companyName: state?.info?.databaseName || company,
              fullName: fullName,
              _id: classInfo?._id,
              traineeID: userInfo?.traineeInfo?._id,
                data: {
                  assessmentForm: {
                    assessmentRecordForm: data?.assessmentRecordForm,
                    createdBy: fullName,
                    dateCreated: new Date(),
                    modifiedBy: fullName,
                    dateModified: new Date(),
                  }
                }
              }
          });
          if(addAssessmentForm?.success){
            if(assessmentForm){
              enqueueSnackbar("Assessment Form successfully updated.", {variant: "success"});
            }else {
              enqueueSnackbar("Assessment Form successfully saved.", {variant: "success"});
            }
           
          }else {
            enqueueSnackbar(addAssessmentForm?.message, {variant: "error"});
          }
    
        } catch (error) {
          console.log(error);
        }
    });
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6">{title}</Typography>
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
        <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'Course Title',
                value: classInfo?.course?.courseTitle,
                icon: <Iconify icon="ic:outline-class" />,
             },
             {
              label: 'TSG Reference No',
              value: classInfo?.course?.tsgRefNumber,
              icon: <Iconify icon="la:id-card-alt" />,
           },
            {
              label: 'Course Skill Code',
              value: classInfo?.course?.courseSkillCode,
              icon: <Iconify icon="ri:user-2-fill" />,
            },
          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}

        </Stack>
        <Stack spacing={2} sx={{ p: 3 }}>
          {[
            {
                label: 'Candidate Name',
                value: userInfo?.traineeInfo?.fullName,
                icon: <Iconify icon="ri:user-2-fill" />,
             },
             {
              label: 'NRIC/FIN/Others',
              value: userInfo?.traineeInfo?.nricFin,
              icon: <Iconify icon="solar:user-id-outline" />,
           }

          ].map((item) => (
            <Stack key={item.label} spacing={1.5} direction="row">
              {item.icon}
              <ListItemText
                primary={item.label}
                secondary={item.value}
                primaryTypographyProps={{
                  typography: 'body2',
                  color: 'text.secondary',
                  mb: 0.5,
                }}
                secondaryTypographyProps={{
                  typography: 'subtitle2',
                  color: 'text.primary',
                  component: 'span',
                }}
              />
            </Stack>
          ))}
      
        </Stack>
        </Box>
        </Grid>
        <Grid item xs={12} md={12}>
        <Stack spacing={2} sx={{ p: 3 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Assessment Criteria</TableCell>
                            <TableCell>Result</TableCell>
                            <TableCell align='center'>Assessor's Remarks</TableCell>
                       </TableRow>
                    </TableHead>
                </Table>
                <Grid container>
                  {classInfo?.course?.assessmentRecordForm?.map((item, index) => {
                        return(
                          <>
                          <Grid item xs={12} md={12} sx={{p:1, bgcolor: 'background.default'}}>
                          <Typography variant='h6'>{item.assessmentMethod}</Typography> 
                          </Grid>
                          {item?.assessmentDetails?.map((res, i) => {
                            return (
                              <>
                              <Grid item xs={12} md={12} sx={{marginBottom: 3}}>
                              <Typography variant='body1' style={{whiteSpace: "pre-wrap" }}>{res?.technicalSkills}</Typography>
                              </Grid>
                               
                                   {res?.assessmentCriteria && res?.assessmentCriteria?.map((ac, acind) => (
                                      <>
                                     <Grid item xs={5} md={5} sx={{marginBottom: 3}}>
                                     <Typography variant='body1' style={{whiteSpace: "pre-wrap" }}>{ac?.assessmentCriteria}</Typography>
                                     </Grid>
                                     <Grid item xs={4} md={4}>
                                <RHFRadioGroup 
                                row spacing={4} name={`assessmentRecordForm.${[index]}.assessmentDetails.${[i]}.assessmentCriteria.${[acind]}.criteriaResults`} options={_criteriaResults}
                                // value={results?.results?.assessmentRecordForm[index]?.assessmentDetails[i]?.criteriaResults}
                                />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                  <RHFTextField
                                  name={`assessmentRecordForm.${[index]}.assessmentDetails.${[i]}.assessmentCriteria.${[acind]}.criteriaRemarks`}
                                  label="Remark"
                                  multiline 
                                  rows={4} 
                                  // value={results?.results?.assessmentRecordForm[index]?.assessmentDetails[i]?.criteriaRemarks}
                                 />
                                </Grid>
                                     </>
                                   ))}
                                 
                     
 
                              </>
                            )
                          })}
                          </>
                        )
                   })}
                 </Grid>

                {/* {!results?.results
                  ?
      
                 :
                 <Grid container>
                 {results?.results?.assessmentRecordForm?.map((item, index) => {
                       return(
                         <>
                         <Grid item xs={12} md={12} sx={{p:1, bgcolor: 'background.default'}}>
                         <Typography variant='body1'> {item.assessmentMethod}</Typography> 
                         </Grid>
                         {item?.assessmentDetails?.map((res, i) => {
                           return (
                             <>
                             <Grid item xs={12} md={12}>
                             <Typography variant='body1' style={{whiteSpace: "pre-wrap" }}>{res?.technicalSkills}</Typography>
                             </Grid>
                               <Grid item xs={5} md={5}>
                                 <Typography variant='body2' style={{whiteSpace: "pre-wrap" }}>{res?.assessmentCriteria}</Typography>
                               </Grid>
                               <Grid item xs={4} md={4}>
                               <Typography variant='body2' style={{whiteSpace: "pre-wrap" }}>{res?.criteriaResults}</Typography>
                               </Grid>
                               <Grid item xs={3} md={3}>
                               <Typography variant='body2' style={{whiteSpace: "pre-wrap" }}>{res?.criteriaRemarks}</Typography>
                               </Grid>
                  
                             </>
                           )
                         })}
                         </>
                       )
                  })}
                </Grid>
                } */}

  
        </Stack>
        </Grid>
      </Grid>
      </Stack>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>{assessmentForm ? 'Update' : 'Save' }</LoadingButton>
              <LoadingButton variant="contained" onClick={onClose}>Close</LoadingButton>
            </Stack>  
      </FormProvider>

      <AssessmentFormUpdate
         title="Assessment Form Update"
        open={assessmentFormUp.value} 
        onClose={assessmentFormUp.onFalse}
        classInfo={classInfo}
        userInfo={userInfo}
        assesmentUpdate={results?.results?.assessmentRecordForm}
      
      />
         
  
    </Dialog>

  );
}

AssessmentForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  traineeInfo: PropTypes.object
};
