import gql from "graphql-tag";

export const GET_CLIENT_SUBSCRIPTION= gql`
query GetClientSubscription($companyName: String!) {
    getClientSubscription(companyName: $companyName) {
        code
        success
        message
        count
        results
  }
}`;

export const SET_SUBSCRIPTION = gql`
mutation CreateClientSubscription($data: SubscriptionInput!, $fullName: String!, $companyName: String!) {
  createClientSubscription(data: $data, fullName: $fullName, companyName: $companyName) {
    code
    success
    message
  }
}`;