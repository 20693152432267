import { random } from 'lodash';
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Card, Container, Typography, Stack, Divider, Box,  MenuItem, Button, InputAdornment, inputBaseClasses } from '@mui/material';
import { useFormContext, useForm, useFieldArray } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import { useBoolean } from '../../hooks/use-boolean.js';
import { useResponsive } from '../../hooks/useResponsive.js';
import Iconify from 'src/components/iconify/index.js';
import { useSettingsContext } from '../../components/settings/index.js';
import { useSnackbar } from '../../components/snackbar/index.js';
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RFHUpload
  } from 'src/components/hook-form';

 import SubscriptionRouteDetails from './subscription-route-details.js';
// import AddressListDialog from './address-list-dialog.js';

import { CREATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION } from "../../graphql/modules/subscription.js";
import { useMutation } from "@apollo/react-hooks";


export default function SubscriptionCreateUpdate({ currentInvoice }) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const [CreateSubscription] =  useMutation(CREATE_SUBSCRIPTION);
  const [UpdateSubscriptionMutation] =  useMutation(UPDATE_SUBSCRIPTION);
  const { enqueueSnackbar } = useSnackbar();
  const settings = useSettingsContext();
  const upMd = useResponsive('up', 'md');
  const { state } = useLocation();
  const to = useBoolean();



    const NewInvoiceSchema = Yup.object().shape({
      title: Yup.string().required('Subscription name is required'),
      amount: Yup.number().required('Amount is required')
    });

    

    const defaultValues = useMemo(
      () => ({
        title: state?.title || '',
        amount: state?.amount || 0,
        details: state?.details || '',
        routing: state?.routing || [
          {
            subheader: '',
            items: [
              {title: '',
              path: '',
              icon: '',
              role: []}
            ]
          },
        ]
      }),
      [state]
    );
    const methods = useForm({
      resolver: yupResolver(NewInvoiceSchema),
      defaultValues,
    });
  
    const {
      reset,
      control,
      watch,
      resetField,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
    const values = watch();


    const onSubmit = handleSubmit(async (data) => {
      try {
      if(state){
        const {data: { updateSubscription }} = await UpdateSubscriptionMutation({
          variables: {
            companyName: "iatos",
            fullName: fullName,
            _id: state?._id,
              data: {
                title: data?.title,
                amount: data?.amount,
                details: data?.details,
                routing: data?.routing,
                dateModified: new Date()
              }
            }
        });
    
        if(updateSubscription?.message){
          reset();
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }else {
          console.log(data)
        const {data: { createSubscription }} = await CreateSubscription({
          variables: {
            companyName: "iatos",
            fullName: fullName,
              data: {
                title: data?.title,
                amount: data?.amount,
                details: data?.details,
                routing: data?.routing,
                dateCreated: new Date(),
                dateModified: new Date()
              }
            }
        });
    
        if(createSubscription?.message){
            enqueueSnackbar(state ? 'Update success!' : 'Create success!');
            reset();
        }else {
          console.log("error")
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created');
        }
      }

      } catch (error) {
        console.log(error);
      }
 
    });

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <CustomBreadcrumbs
                heading="Create a new Route"
                links={[
                {name: 'Dashboard'},
                {name: 'Subscription'},
                {name: 'New Route'}
                ]}
            />

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              Subscription Details:
            </Typography>
          </Stack>
        </Stack>
            </Stack>
            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
          >
            <RHFTextField
            name="title"
            label="Subscription Name"
          />
           <RHFTextField
            name="amount"
            label="Amount "
          />
            <RHFTextField
            name="details"
            label="Description "

          />
            </Stack>

            <SubscriptionRouteDetails />

            </Card>


      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
        >
          {state ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>
         </Container>
        </FormProvider>
    )
}