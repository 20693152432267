import { Helmet } from "react-helmet-async";
 import { CompanyListView } from "../../sections/company/index.js";

export default function CompanyPage(){
    return (
        <>
            <Helmet>
                <title>Training Provider List Page</title>
            </Helmet>
            <CompanyListView />
        </>
    )
}