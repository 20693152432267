import PropTypes from 'prop-types';

import { Box, Switch, FormControlLabel, TablePagination } from '@mui/material';

export default function TablePaginationCustom({dense, onChangeDense, rowsPerPageOptions = [10, 25, 50], sx, ...other}){
    return (
        <Box sx={{ positon: 'relative', ...sx}}>
            <TablePagination 
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                {...other}
                sx={{
                    borderTopColor: 'transparent'
                }}
            />
            {onChangeDense && (
                <FormControlLabel 
                    label="Dense"
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    sx={{
                        pl: 2,
                        py: 1.5,
                        top: 0,
                        position: {
                            sm: 'absolute'
                        }
                    }}
                />
            )}
        </Box>
    )
}

TablePaginationCustom.propTypes ={
    dense: PropTypes.bool,
    onChangeDense: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    sx: PropTypes.object
}