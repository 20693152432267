import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import {Box, Stack, Dialog, TextField, Typography, InputAdornment, ListItemButton, listItemButtonClasses} from '@mui/material';
import { GET_COURSE } from "../../../graphql/modules/_client/course.js";
import { useQuery } from "@apollo/react-hooks";
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
// import SearchNotFound from 'src/components/search-not-found';

// ----------------------------------------------------------------------

export default function CourseDialogSelect({ company, title = 'Address Book', list, action, open, onClose, selected, onSelect}) {
  const company1 = useSelector((state) => state?.auth?.company);
  const [searchAddress, setSearchAddress] = useState('');
  const [searchText, setSearch] = useState("");
  const [results, setResults] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_COURSE, {
    variables: {
      limit: 5,
      offset: 0,
      searchText: searchText,
      companyName: company || company1
      },
      fetchPolicy: "cache-and-network",
    });


  useEffect(() => {
      if(data){
        setResults(data?.getCourse)
      }
  }, [data])
 

  const handleSearchAddress = useCallback((event) => {
    setSearch(event.target.value);
  }, []);

  const handleSelectAddress = useCallback(
    (course) => {
      onSelect(course);
      setSearch('');
      onClose();
    },
    [onClose, onSelect]
  );

  const renderList = (
    <Stack
      spacing={0.5}
      sx={{
        p: 0.5,
        maxHeight: 80 * 8,
        overflowX: 'hidden',
      }}
    >
      {results?.results?.map((course) => (
        <Stack
          key={course?._id}
          spacing={0.5}
          component={ListItemButton}
          onClick={() => handleSelectAddress(course)}
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`&.${listItemButtonClasses.selected}`]: {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle1">{course?.courseTitle}</Typography>
          </Stack>


          {course.tsgRefNumber && (
            <Typography variant="body2" sx={{ color: 'primary.main' }}>
              {course.tsgRefNumber}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> {title} </Typography>

        {action && action}
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
        <TextField
          value={searchText}
          onChange={handleSearchAddress}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {renderList}
    </Dialog>
  );
}

CourseDialogSelect.propTypes = {
  action: PropTypes.node,
  list: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.func,
  title: PropTypes.string,
  company: PropTypes.string
};


