import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import { FormHelperText } from '@mui/material';
import { UploadAvatar, Upload, UploadBox, UploadAttachedFile } from '../upload/index.js';


export function RHFUploadAvatar({ name, ...other}){
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error} }) => (
                <div>
                    <UploadAvatar error={!!error} file={field.value} {...other} />
                    {!!error && (
                        <FormHelperText error  sx={{ px: 2, textAlign: 'center'}}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    )
}

RHFUploadAvatar.propTypes = {
    name: PropTypes.string
}

export function RFHUploadBox({ name, ...other}){
    const { control } = useFormContext();

    return (
        <Controller 
         name={name}
         control={control}
         render={({ field, fieldState: {error}}) => (
            <div>
            <UploadBox file={field.value} error={!!error} {...other} />
            {!!error && (
              <FormHelperText error  sx={{ px: 2, textAlign: 'center'}}>
                  {error.message}
              </FormHelperText>
          )}
          </div>
         )}
        />
    )

}

RFHUploadBox.propTypes = {
    name: PropTypes.string
}

export function RFHAttachedFile({ name, ...other}){
  const { control } = useFormContext();

  return (
      <Controller 
       name={name}
       control={control}
       render={({ field, fieldState: {error}}) => (
          <div>
          <UploadAttachedFile file={field.value} error={!!error} {...other} />
          {!!error && (
            <FormHelperText error  sx={{ px: 2, textAlign: 'center'}}>
                {error.message}
            </FormHelperText>
        )}
        </div>
       )}
      />
  )

}

RFHAttachedFile.propTypes = {
  name: PropTypes.string
}

export function RFHUpload({ name, multiple, helperText, ...other }) {
    const { control } = useFormContext();
  
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) =>
          multiple ? (
            <Upload
              multiple
              accept={{ '*': [] }}
              files={field.value}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          ) : (
            <Upload
              accept={{ '*': [] }}
              file={field.value}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          )
        }
      />
    );
  }
  
  RFHUpload.propTypes = {
    helperText: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
  };

