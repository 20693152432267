import merge from 'lodash/merge.js'
//  import { defaultProps } from './default-props';
import {defaultProps} from "./default-props.js";
import {textfield} from "./textfield.js";
import {button} from "./button.js";
import {cssBaseline} from "./css-baseline.js";
import {table} from "./table.js";

export default function ComponentsOverrides(theme) {
    const components = merge(
        defaultProps(theme),
        //
        textfield(theme),
        button(theme),
        cssBaseline(theme),
        table(theme)
    )

    return components;
}