import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Dialog,
  DialogActions
} from '@mui/material';
// utils
import { fToNow, fDate } from '../../../util/formatTime.js';
// components
import { PDFViewer } from '@react-pdf/renderer';
import Iconify from '../../../components/iconify/index.js';
import { useBoolean } from '../../../hooks/use-boolean.js';
import InvoicePDF from './invoice-pdf-view.js'
import PopUpNotification from './popUpNotification.js';
import { useNavigate } from 'react-router-dom';

import Scrollbar from '../../../components/scrollbar/scrollbar.js';
import { GET_TP_INVOICE_NT, UPDATE_INVOICE } from "../../../graphql/modules/invoice.js";
import { useQuery, useMutation } from "@apollo/react-hooks";

// ----------------------------------------------------------------------



export default function NotificationsPopover() {
  const company = useSelector((state) => state?.auth?.companyProfile?.companyProfile?.companyName);
  const [notifications, setNotifications] = useState(null);
  const onNotification = useBoolean();
  var totalUnRead = 0;

  const { data, loading, error, refetch } = useQuery(GET_TP_INVOICE_NT, {
    variables: {
      companyName: "iatos",
      searchText: company,
      "order": "desc",
      "orderBy": "createDate",
      "offset": 0,
      "limit": 100
      },
      fetchPolicy: "cache-and-network",
    });

   totalUnRead = data?.getTpInvoice?.results?.filter((item) => item?.read === 0).length;



  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };



  useEffect(() => {
    if(open){
      setNotifications(data?.getTpInvoice?.results)
    }
}, [open])


useEffect(() => {
  if(totalUnRead > 0){
    onNotification.onTrue();
  }
}, [totalUnRead])





  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Please see the attached for your new subscription invoice.
            </Typography>
          </Box>
{/* 
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

  
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          sx={{height: 210}}
          >
            <Scrollbar>
            {notifications?.map((notification) => (
              <NotificationItem key={notification._id} notification={notification} refetch={refetch}/>
            ))}
            </Scrollbar>

          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
     

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          {/* <Button fullWidth disableRipple>
            View All
          </Button> */}
        </Box>
      </Popover>


      <PopUpNotification
                  open={onNotification.value}
                  onClose={onNotification.onFalse}
                  title="Invoice Notice"
                  content={`Your new subscription invoice is ready. Please check your notifications.`}
                  action={
                    <Button variant="contained" color="warning" onClick={() =>  onNotification.onFalse()}>
                      Close
                    </Button>
                  }
                />
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, refetch }) {
  // const { invoiceNumber } = renderContent(notification);
  const [UpdateInvoiceMutation] =  useMutation(UPDATE_INVOICE);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const [invoiceDetails, setInvoice] = useState(null);
  const invoicePdf = useBoolean();
  const navigate = useNavigate();

  const handleOpenInvoice = (notification) => {
    setInvoice(notification);
    handleMarkAllAsRead(notification);
    invoicePdf.onTrue();
  }

  const handleMarkAllAsRead = async(notification) => {
     await UpdateInvoiceMutation({
      variables: {
        companyName: "iatos",
        fullName: fullName,
        _id: notification?._id,
          data: {
            read: 1
          }
        }
    });
  };

  return (
    <>
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.read && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => handleOpenInvoice(notification)}
    >
      <ListItemAvatar><Iconify icon="mage:file-3" /> </ListItemAvatar>
      <ListItemText
        primary={notification?.invoiceNumber}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fDate(notification?.createDate)}
          </Typography>
        }
      />
    </ListItemButton>


    <Dialog fullScreen open={invoicePdf.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={() => {
              invoicePdf.onFalse();
              refetch();
            }}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoiceDetails={invoiceDetails} companyProfile={companyProfile}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}