import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Box, Stack, Drawer } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive.js';
import Scrollbar from '../../components/scrollbar/scrollbar.js';
import { NavSectionVertical } from '../../components/navsection/vertical/index.js';

import { NAV } from './config-layout.js';
import Navigation from './config-navigation.js';

import {NavToggleButton} from './_common/index.js';

export default function NavVertical({ openNav, onCloseNav}){
    const lgUp = useResponsive('up', 'lg');
    
    useEffect(() => {
        if(openNav){
            onCloseNav();
        }
    }, [])

    const renderContent = (
        <Scrollbar
        sx={{
            height: 1,
            '& .simplebar-content': {
                height: 1,
                display: 'flex',
                flexDirection: 'column'
            }
        }}
        >
                <Box component="div" sx={{
                    width: '100%',
                    height: '20%',
                    display: 'inline-flex'
                }}>
                <img src='/assets/logo.png'/>
                </Box>
       
            <NavSectionVertical data={Navigation()}/> 
            <Box sx={{flexGrow:  1}} />
        </Scrollbar>
    )

    return (
        <Box component="nav"
        sx={{ 
            flexShrink: { lg: 0},
            width: { lg: NAV.W_VERTICAL}
        }}>
            <NavToggleButton />
            {lgUp ? (
                <Stack sx={{
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_VERTICAL,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`
                }}
                >
                    {renderContent}
                </Stack>
            ): (
                <Drawer
                open={openNav}
                onClose={onCloseNav}
                PaperProps={{
                    sx: {
                        width: NAV.W_VERTICAL   
                    }
                }}>
                    {renderContent}
                </Drawer>
            )}

        </Box>
    )

}

NavVertical.propTypes = {
    onCloseNav: PropTypes.func,
    openNav: PropTypes.bool
}