import PropTypes from 'prop-types';
// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, MenuItem, Stack} from '@mui/material';
import { _attendance, _survey } from '../../../../util/course.js';

// ----------------------------------------------------------------------

export default function AttendanceDialog({ title, content, setNumberOfHours, setSurvey, setAttendanceValue, attendanceStatus, attendanceSurvey,  action, open, onClose, ...other }) {
  const onChangeSurvery = (e) => {
    setSurvey(e.target.value);
  };

  const onChangeAttendance = (e) => {
    setAttendanceValue(e.target.value);
  };

  const onChangeNumberOfHours = (e) => {
    setNumberOfHours(e.target.value);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
  
         <DialogContent sx={{ typography: 'body2' }}>
         <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
            >
        <TextField onChange={onChangeAttendance} select name="attendance" defaultValue={attendanceStatus} label="Attendance" sx={{
            width: "33.33%"
        }}>
            {_attendance?.map((res) => {
                return (
                    <MenuItem key={res?.code} value={res.code} >{res?.description}</MenuItem>
                )
            })}
        </TextField>
        <TextField onChange={onChangeSurvery} select name="survey" defaultValue={attendanceSurvey} label="Survey" sx={{
            width: "33.33%"
        }}>
            {_survey?.map((res) => {
                return (
                    <MenuItem key={res?.code} value={res.code} >{res?.description}</MenuItem>
                )
            })}
        </TextField>
        <TextField onChange={onChangeNumberOfHours} name="numberOfHours" type='number'  label="Number of Hours" helperText="Applicable for OJT only."  sx={{
            width: "33.33%"
        }}/> 
        </Stack>


         </DialogContent>
            
      <DialogActions>
        {action}

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AttendanceDialog.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  setNumberOfHours: PropTypes.func,
  setSurvey: PropTypes.func,
  setAttendanceValue: PropTypes.func,
  attendanceStatus: PropTypes.string,
  attendanceSurvey: PropTypes.string,
};
