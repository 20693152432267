import PropTypes from 'prop-types';
import { useState, useCallback, useEffect} from 'react';
import { useSelector } from 'react-redux';
// @mui
import { useLocation } from 'react-router-dom';
import {alpha, IconButton, TextField, Stack, Box, Dialog, Typography, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import Iconify from 'src/components/iconify';
import { useSnackbar } from '../../../../../components/snackbar/index.js';
import { SSGDate, fDate } from '../../../../../util/formatTime.js';
import {_paymentStatus} from '../../../../../util/course.js';


import { SEARCH_ASSESSMENT_SSG } from "../../../../../graphql/modules/_client/assessementSummary.js";
 import { useQuery } from "@apollo/react-hooks";



// ----------------------------------------------------------------------

export default function AssessmentResultSSG({title, open, onClose, companyInfo, classinfo}) {
    const {fullName} = useSelector((state) => state?.auth?.user);
    const company = useSelector((state) => state?.auth?.company);
    const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
    const { enqueueSnackbar } = useSnackbar();
    const [result, setResults] = useState(null);

    const { data, loading, error, refetch } = useQuery(SEARCH_ASSESSMENT_SSG, {
      variables: {
        companyName: companyProfile?.databaseName || company,
        courseRunId: classinfo?.courseRunId,
        courseReferenceNumber: classinfo?.course?.tsgRefNumber,
        _companyName: companyProfile?.companyName,
        encryptionkey: companyProfile?.encryptionKey,
        uen: companyProfile?.uen
        },
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.searchAssessmentSSG)
      }
  }, [data, open])


  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ p: 3, pr: 1.5 }}
      >
               <Typography variant="h6">{title}</Typography>
        </Stack>

      <Stack
        direction="row"
        alignItems="center"
        sx={{ p: 3, pr: 1.5 }}
      >

        <Grid container spacing={2}>
            <TableContainer>
                <Table>
                    <TableHead>
                      <TableRow>
                      <TableCell align='left'>No #</TableCell>
                      <TableCell align='left'>FullName</TableCell>
                          <TableCell align='left'>ID Type</TableCell>
                          <TableCell align='left'>NRIC/FIN</TableCell>
                          <TableCell align='left'>Result</TableCell>
                          <TableCell align='left'>Assessment Date</TableCell>
                      </TableRow>

                    </TableHead>
                    <TableBody>
                    {result?.results?.sort((a, b) => a?.trainee?.fullName.localeCompare(b?.trainee?.fullName)).map((row, index) => (
                                <TableRow key={index}>
                                <TableCell align='left'>{index + 1}</TableCell>
                                <TableCell align='left'>{row?.trainee?.fullName}</TableCell>
                                <TableCell align='left'>{row?.trainee?.idType?.type}</TableCell>
                                <TableCell align='left'>{row?.trainee?.id}</TableCell>
                                <TableCell align='left'>{row?.result}</TableCell>
                                <TableCell align='left'>{row?.assessmentDate}</TableCell>
                            </TableRow>
                    ))}
          
                    </TableBody>
                </Table>
            </TableContainer>

      </Grid>
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ p: 3 }}>
              <LoadingButton variant="contained" onClick={onClose}>Close</LoadingButton>
            </Stack>  
      </Stack>
    

    </Dialog>
  );
}

AssessmentResultSSG.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  traineeInfo: PropTypes.object
};
