import { Helmet } from "react-helmet-async";
 import { CreateUpdateCompany } from "../../sections/company/index.js";

export default function CreateUpdateCompanyPage(){
    return (
        <>
            <Helmet>
                <title>Company List Page</title>
            </Helmet>
            <CreateUpdateCompany />
        </>
    )
}