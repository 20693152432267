
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "../../../../components/iconify/iconify.js";
import Label from '../../../../components/label/index.js';
import { ConfirmDialog } from '../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { fDate } from '../../../../util/formatTime.js';
import { parseISO, format } from 'date-fns';

export default function AssessmentReportTableRow({row, selected, onSelected, onDeleteRow, onCancelledRow, onEditRow, onViewRow, onCompleted, onRevert}){
  const isRole = useSelector((state) => state?.auth?.isRole);
  const { courseRunId, course, classCode, courseDates, sessions, venue, venue_old, status, enrolment } =row;
  const venueDetails = venue?.block || venue?.postalCode ? `${venue?.block} ${venue?.street} #${venue?.floor}-${venue?.unit} ${venue?.room} ${venue?.building} ${venue?.postalCode}` : venue_old;

  const filterAndCount = (arr, name) => {
    // Filter the array by the specified name
    const filtered = arr?.filter(ass => ass?.assessmentSummary?.criteriaResults === name);

    // Count occurrences of the filtered names
    return filtered.reduce((acc, res) => {
      const { criteriaResults } = res?.assessmentSummary;
      acc[criteriaResults] = (acc[criteriaResults] || 0) + 1;
      return acc;
    }, {});
  };

  const passCount = filterAndCount(enrolment, 'Pass');
  const failCount = filterAndCount(enrolment, 'Fail');
  const passRate = (passCount?.Pass / enrolment?.length ) * 100;
  
  // const countPass = arr =>
  //   enrolment?.reduce((acc, val) => {
  //     acc[val?.assessmentSummary?.criteriaResults] = (acc[val?.assessmentSummary?.criteriaResults] || 0) + 1;
  //     return acc;
  //   }, {});


  const setTrainer = sessions.map(item => item?.trainer?.fullName)
  const filterTrainer = [...new Set(setTrainer)];

    const popover = usePopover();
    const confirm = useBoolean();
    const cancel = useBoolean();
    const completed = useBoolean();
    const revertBack = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{courseRunId}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{classCode}</TableCell>
            <TableCell>{course?.courseTitle}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{fDate(courseDates.start)}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{passCount?.Pass ? passCount?.Pass : 0}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{failCount?.Fail ? failCount?.Fail : 0}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{passRate ? passRate?.toFixed(2) + '%' : 0 + '%'}</TableCell>

            <TableCell align="right" sx={{px: 1, whiteSpace: 'nowrap'}}>
                    <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                        <Iconify icon="eva:more-vertical-fill" />
                    </IconButton>
            </TableCell>
    </TableRow>
    <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{width: 180}}
    >

        <MenuItem
          onClick={() => {
            popover.onClose();
            onViewRow();
          }}
        >
        <Iconify icon="ic:round-print" />
            Print Assessment
        </MenuItem>

            


    </CustomPopover>

    <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={`Are you sure want to delete ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={() => {
            onDeleteRow();
            confirm.onFalse();
          }}>
            Delete
          </Button>
        }
      />

<ConfirmDialog
        open={cancel.value}
        onClose={cancel.onFalse}
        title="Cancel"
        content={`Are you sure want to cancel ${classCode}?`}
        action={
          <Button variant="contained" color="error" onClick={onCancelledRow}>
            Cancel
          </Button>
        }
      />

      <ConfirmDialog
        open={completed.value}
        onClose={completed.onFalse}
        title="Completed"
        content={`Are you sure want to update ${classCode} status to completed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onCompleted();
            completed.onFalse();
          }}>
            Yes
          </Button>
        }
      />

<ConfirmDialog
        open={revertBack.value}
        onClose={revertBack.onFalse}
        title="Revert Status"
        content={`Are you sure want to revert the  status to confirmed?`}
        action={
          <Button variant="contained" color="success" onClick={() => {
            onRevert();
            revertBack.onFalse();
          }}>
            Yes
          </Button>
        }
      />

</>
 )

}

AssessmentReportTableRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func
  };
  
