import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import {styled, Box, Card, Table, Stack, Divider, TableRow, TableHead, TableCell, TableBody, Grid, Typography, TableContainer} from '@mui/material';
// utils
import { fDate } from '../../util/formatTime.js';
import { fCurrency } from '../../util/format-number.js';
// components
import Label from '../../components/label/index.js';
import Scrollbar from '../../components/scrollbar/index.js';
//
// import InvoiceToolbar from './invoice-toolbar';

// ----------------------------------------------------------------------

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    textAlign: 'right',
    borderBottom: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function InvoiceDetails({ invoice }) {
    const { state } = useLocation();
  const [currentStatus, setCurrentStatus] = useState(state?.status);
  const totalOnRow = state?.items.map((item) => item.quantity * item.price);
  const subTotal = sum(totalOnRow);

  const handleChangeStatus = useCallback((event) => {
    setCurrentStatus(event.target.value);
  }, []);

  const renderTotal = (
    <>
      <StyledTableRow>
        <TableCell colSpan={3} />
        <TableCell sx={{ color: 'text.secondary' }}>
          <Box sx={{ mt: 2 }} />
          Subtotal
        </TableCell>
        <TableCell width={120} sx={{ typography: 'subtitle2' }}>
          <Box sx={{ mt: 2 }} />
          {fCurrency(subTotal)}
        </TableCell>
      </StyledTableRow>

      <StyledTableRow>
        <TableCell colSpan={3} />
        <TableCell sx={{ color: 'text.secondary' }}>Discount</TableCell>
        <TableCell width={120} sx={{ color: 'error.main', typography: 'body2' }}>
          {fCurrency(-state?.discount)}
        </TableCell>
      </StyledTableRow>

      <StyledTableRow>
        <TableCell colSpan={3} />
        <TableCell sx={{ color: 'text.secondary' }}>Taxes</TableCell>
        <TableCell width={120}>{fCurrency(state?.taxes)}</TableCell>
      </StyledTableRow>

      <StyledTableRow>
        <TableCell colSpan={3} />
        <TableCell sx={{ typography: 'subtitle1' }}>Total</TableCell>
        <TableCell width={140} sx={{ typography: 'subtitle1' }}>
          {fCurrency(state?.totalAmount)}
        </TableCell>
      </StyledTableRow>
    </>
  );

  const renderFooter = (
    <Grid container>
      <Grid xs={12} md={9} sx={{ py: 3 }}>
        <Typography variant="subtitle2">NOTES</Typography>

        <Typography variant="body2">
          Thank you!
        </Typography>
      </Grid>

      <Grid xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
        <Typography variant="subtitle2">Have a Question?</Typography>

        <Typography variant="body2">info@iatos.com</Typography>
      </Grid>
    </Grid>
  );

  const renderList = (
    <TableContainer sx={{ overflow: 'unset', mt: 5 }}>
      <Scrollbar>
        <Table sx={{ minWidth: 960 }}>
          <TableHead>
            <TableRow>
              <TableCell width={40}>#</TableCell>

              <TableCell sx={{ typography: 'subtitle2' }}>Description</TableCell>

              <TableCell>Qty</TableCell>

              <TableCell align="right">Unit price</TableCell>

              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state?.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>

                <TableCell>
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography variant="subtitle2">{row.title}</Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                      {row.description}
                    </Typography>
                  </Box>
                </TableCell>

                <TableCell>{row.quantity}</TableCell>

                <TableCell align="right">{fCurrency(row.price)}</TableCell>

                <TableCell align="right">{fCurrency(row.price * row.quantity)}</TableCell>
              </TableRow>
            ))}

            {renderTotal}
          </TableBody>
        </Table>
      </Scrollbar>
    </TableContainer>
  );

  return (
    <>
      {/* <InvoiceToolbar
        invoice={invoice}
        currentStatus={currentStatus || ''}
        onChangeStatus={handleChangeStatus}
        statusOptions={INVOICE_STATUS_OPTIONS}
      /> */}

      <Card sx={{ pt: 5, px: 5 }}>

        <Grid container spacing={2}>
            <Grid item xs={2} md={2}>
              <Box
              component="img"
              alt="logo"
              src="/assets/logo.png"
              sx={{ width: 180, height: 90 }}
            />
            </Grid>

            <Grid item xs={10} md={10}>
                  <Typography variant="h6">Zing Technologies Pte Ltd</Typography>
                  <Typography variant="subtitle1">Reg No: 201431343R</Typography>
                  <Typography variant="subtitle1">3 Ang Mo Kio St 62 #07-17 Link@AMK Singapore 569139</Typography>
                <Typography variant="subtitle1">Contact no: +65 64514530 Email: info@zingtechnologies.asia</Typography>
            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{mt: 3}}>
          <Grid item xs={6} md={6}>
            <Typography variant="h6" sx={{ mb: 1 }}> Bill To </Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>{state?.invoiceTo?.companyName}</Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>{state?.invoiceTo?.address}</Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>{`Contact no: ${state?.invoiceTo?.contactNo} Email: ${state?.invoiceTo?.contactNo}`}</Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            <Typography variant="h6" sx={{ mb: 1 }}> Tax Invoice</Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>{`Invoice Number: ${state?.invoiceNumber || ''}`}</Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>{`Date: ${fDate(state?.createDate)}`}</Typography>
          </Grid>
        </Grid>





          {/* <Stack spacing={1} alignItems={{ xs: 'flex-start', md: 'flex-start' }}> */}


            {/* <Typography variant="h6">{state?.invoiceNumber}</Typography> */}
          {/* </Stack> */}

          {/* <Stack sx={{ typography: 'body2' }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Invoice From
            </Typography>
            {invoice.invoiceFrom.name}
            <br />
            {invoice.invoiceFrom.fullAddress}
            <br />
            Phone: {invoice.invoiceFrom.phoneNumber}
            <br />
          </Stack> */}

    

        {renderList}

        <Divider sx={{ mt: 5, borderStyle: 'dashed' }} />

        {renderFooter}
      </Card>
    </>
  );
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
};
