import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../components/snackbar/index.js';
import { TableHeadCustom} from '../../../components/table/index.js';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse, parseISO } from 'date-fns';
import { SSGDate } from '../../../util/formatTime.js';

// @mui
import {Button, Dialog, DialogTitle, DialogActions, DialogContent, TableContainer, Table,  TableBody, Stack, Tooltip, IconButton, TableRow} from '@mui/material';
import Iconify from '../../../components/iconify/iconify.js';
import { _attendance, _survey } from '../../../util/course.js';
import { UPLOAD_USERS } from "../../../graphql/modules/users.js";
import { useMutation } from "@apollo/react-hooks";
import FormProvider, {
  RFHUploadBox
} from 'src/components/hook-form';
import UserBulkUploadPreview from './company-bulkupload-preview.js';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'fullName', label: 'Fullname'},
  {id: 'nricFin', label: 'Date of Birth'},
  {id: 'fullName', label: 'NRIC / FIN / Others'},
  {id: 'dob', label: 'ID Type'},
  {id: 'areaCode', label: 'Citizenship'},
  {id: 'countryCode', label: 'Trainee Phone Country Code'},
  {id: 'mobileNo', label: 'Trainee Phone Area Code'},
  {id: 'email', label: 'Trainee Mobile No'},
  {id: 'userRole', label: 'Email'},
  {id: 'status', label: 'Occupation'},
  {id: 'status', label: 'Highest Qualification'},
  {id: 'status', label: 'Sponsorship Type'},
  {id: 'status', label: 'Employer UEN'},
  {id: 'status', label: 'Employer Company Name'},
  {id: 'status', label: 'Employer Contact Name'},
  {id: 'status', label: 'Employer Phone Country Code'},
  {id: 'status', label: 'Employer Phone Area Code'},
  {id: 'status', label: 'Employer Phone Number'},
  {id: 'status', label: 'Employer Email'},
]

export default function BulkUploadUser({ title, content, action, open, onClose, ...other }) {
  const company = useSelector((state) => state?.auth?.company);

  const { state } = useLocation();
  const [file, setFile] = useState(null);
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [UploadUserMutation] = useMutation(UPLOAD_USERS);

  const NewUserSchema = Yup.object().shape({});

  const defaultValues = useMemo(
    () => ({
      results: results || null
    }),
    [state]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data1) => {
    try {
      var  usersData = [];
      if(results){

      results?.slice(1)?.map((res, resIndex) => {
        const userNameGeneration = `${res[0]?.substring(0,3)}${res[2]?.slice(-4)}${res[1]?.slice(-2)}${res[1]?.slice(5, -3)}`;
        usersData.push({
          fullName: res[0],
          dob: res[1],
          nricFin: res[2],
          idType: res[3],
          nricType: res[4],
          countryCode: res[5],
          userName: userNameGeneration.replace(/\s+/g, '_'),
          password: "00000000",
          areaCode: res[6],
          mobileNo: res[7],
          email: res[8],
          occupation: res[9],
          'highestQualification': [{
            'titleQualification': res[10]
          }] ,
          sponsorshipType: res[11],
         'employer.uen': res[12],
         'employer.companyName': res[13],
         'employer.contactName': res[14],
         'employer.countryCode': res[15],
         'employer.areaCode': res[16],
         'employer.phoneNumber': res[17],
         'employer.emailAddress': res[18],
         status: "inactive",
          'userRole': "te"
        })
      })


      const {data: { uploadUsers }} = await UploadUserMutation({
        variables: {
          companyName: state?.info?.databaseName || company, 
          data: usersData
        }
      });
      if(uploadUsers?.success){
       setFile(null);
       setResults(null);
      reset();
        enqueueSnackbar('Successfully uploaded', {variant: "success"});
      }else {
        enqueueSnackbar(uploadUsers?.message, {variant: "error",transitionDuration: 2});
      }

      }else {
        enqueueSnackbar("File upload error.", {variant: "error"});
      }


    }catch(err) {
      console.log(err)
    }
  })


  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    if(file){
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array', cellDates: true });
  
        // Assuming the first sheet is the one we want to use
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];
        const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false, dateNF:'ddMMyyyy', defval: ''});
        setResults(excelData);
      };
  
      reader.readAsArrayBuffer(file);
    }
 
    // if (file) {
    //   setFile(
    //     Object.assign(file, {
    //       preview: URL.createObjectURL(file),
    //     })
    //   );
    //   const newFile =       Object.assign(file, {
    //     preview: URL.createObjectURL(file),
    //   })
    //   setValue('file', newFile, { shouldValidate: true });

    //   Papa.parse(file, {
    //     complete: (result) => {
    //       // `result.data` contains the parsed CSV data
    //       setResults(result.data);
    //     },
    //     header: true, // If the CSV file has a header row
    //   });

    // }
  }, []);

  const downloadFile = () => {
    window.location.href =  `${process.env.PUBLIC_URL}/Upload_Users_template.xlsx`
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose} {...other}>
       <FormProvider methods={methods} onSubmit={onSubmit}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
      <Tooltip title="Download Template">
            <IconButton
             onClick={() => downloadFile()}
             sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
                     <Iconify icon="eva:cloud-download-fill" />
                </IconButton>
      </Tooltip>


         <DialogContent sx={{ typography: 'body2' }}>
         <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            >

              <RFHUploadBox
                name="file"
                file={file} 
                onDrop={handleDrop}
                
                // onUpload={() => console.info('ON UPLOAD')}
              />
          </Stack>
         <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            >

            <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              {/* <TableHeadCustom
                headLabel={TABLE_HEAD}
              /> */}
                <TableBody>
                  

                {results?.map((row, rowIndex) => (
                     <UserBulkUploadPreview key={rowIndex} row={row}/>
                 ))}
  

                </TableBody>

              </Table>
          </TableContainer>

        </Stack>


         </DialogContent>
            
      <DialogActions>
        {action}
        <Button type="submit" variant="outlined" color="inherit">
          Upload
        </Button>
        <Button variant="outlined" color="inherit" onClick={() => {
          onClose();
          setFile(null);
          setResults(null);
        }}>
          Cancel
        </Button>
      </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

BulkUploadUser.propTypes = {
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,

};
