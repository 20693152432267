
const traineeCategory = [
    {value: "NA", label: "NA"},
    {value: "Licensed Representative", label: "Licensed Representative"},
    {value: "General Insurance Agent", label: "General Insurance Agent"},
    {value: "Secondee from Financial Institution (FI)", label: "Secondee from Financial Institution (FI)"},
    {value: "FI's Intern and Contract Staff Administered by Agencies", label: "FI's Intern and Contract Staff Administered by Agencies"},
    {value: "Employees from FI's subsidiaries", label: "Employees from FI's subsidiaries"},
] 

export {
    traineeCategory
}