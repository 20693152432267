
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha} from '@mui/material';
import { useSettingsContext } from '../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../components/table/index.js';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../routes/components/index.js';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify/iconify.js';
import CompanyTableRow from './company-table-row.js';
import CompanyTableToolBar from './company-table-toolbar.js';
import Label from '../../components/label/index.js';


// GRAPHQL
import { GET_ALL_COMPANY, DELETE_COMPANY } from "../../graphql/modules/company.js";
import { useQuery, useMutation } from "@apollo/react-hooks";

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'active', label: 'Active'},
  {value: 'inactive', label: 'In Active'}
]
const TABLE_HEAD = [
  {id: 'companyName', label: 'Company Name'},
  {id: 'contactNo', label: 'Contact No'},
  {id: 'email', label: 'Email'},
  {id: 'status', label: 'Status'},
  {id: 'dateCreated', label: 'Date created'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function CompanyListView({img, ...other}) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);
  const [DeleteCompanyMutation] =  useMutation(DELETE_COMPANY);

  const { data, loading, error, refetch } = useQuery(GET_ALL_COMPANY, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyName,
      order: table.order,
      orderBy: table.orderBy || 'createdDate'
      },
      fetchPolicy: "cache-and-network",
    });

 

    useEffect(() => {
        if(data){
          setResults(data?.getAllCompany)
        }
    }, [data])

    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (id) => {

      const {data: { deleteCompany }} = await DeleteCompanyMutation({
        variables: {
          _id: id,
          companyName: "iatos",
          fullName: fullName,
        }
      })

      if(deleteCompany?.success){
        console.log("deleted")
        refetch();
      }else {
        console.log("failed")
      }
      },
      [refetch]
    );


    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update', {state: row})
  });


  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
         heading="Training Provider List"
         links={[
          {name: 'Dashboard'},
          {name: 'Training Provider'},
          {name: 'List'}
         ]}
         action ={
          <Button
          componet={RouterLink}
          href={'/company-create-update'}
          variant="contained"
          startIcon={<Iconify icon="mingcure:add-line" />}
          >
            New Training Provider
          </Button>
         }
        />


        <Card>
         <Tabs 
          value={filters}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'active' && 'success') ||  (tab.value === 'inactive' && 'warning') || 'default'
                }
                >
                  {tab.value === 'all' && results?.count}
                  {tab.value === 'active' && results?.activeCount}
                  {tab.value === 'inactive' && results?.inactiveCount}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <CompanyTableToolBar onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {results?.results?.map((row) => (
                      <CompanyTableRow
                      key={row?.id}
                      row={row}
                      onViewRow={() => handleViewAccount(row)}
                      onDeleteRow={() => handleDeleteRow(row._id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                ))}
  

                </TableBody>

              </Table>
          </TableContainer>

          <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>

    </Container>
  );
}