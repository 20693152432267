import PropTypes from 'prop-types';
import { useState, useCallback , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Container, useTheme, TableContainer, Card, Table, TableBody, Button, Tabs, Tab, alpha, Box, Pagination, paginationClasses} from '@mui/material';
import { useSettingsContext } from '../../../../components/settings/index.js';
import {useTable, TableHeadCustom, TablePaginationCustom} from '../../../../components/table/index.js';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import {RouterLink} from '../../../../routes/components/index.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import Iconify from '../../../../components/iconify/iconify.js';
import ClassAssessmentRow from './class-assessment-row.js';
import CompanyTableToolBar from './class-enrolment-table-toolbar.js';
import AssessmentResultSSG from './assessment/assessment-result-ssg.js';
import Label from '../../../../components/label/index.js';
import Scrollbar from 'src/components/scrollbar/scrollbar.js';
import JobItem from './class-enrolment-table-row.js';


// GRAPHQL
import { GET_CLASS_BY_ID } from "../../../../graphql/modules/_client/class.js";
 import { useQuery } from "@apollo/react-hooks";

 

const STATUS_OPTION = [
  {value: 'all', label: 'All'},
  {value: 'completed', label: 'Completed'},
  {value: 'inProgress', label: 'In-Progress'},
  {value: 'na', label: 'NA'}
]
const TABLE_HEAD = [
  {id: 'No', label: 'No #'},
  {id: 'fullName', label: 'Full Name'},
  {id: 'nricFin', label: 'Enrolment ID'},
  {id: 'status', label: 'Enrolment Status'},
  {id: 'assessor', label: 'Assessor'},
  {id: 'assessmentDate', label: 'Assessment Date'},
  {id: 'assessmentResult', label: 'Assessment Result'},
  {id: 'status', label: 'Assessment Status'},
  {}
]

const defaultFilters = {
  name: '',
  subscription: [],
  status: 'all'
}

export function ClassAssessment({companyinfo}) {
  const isRole = useSelector((state) => state?.auth?.isRole);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const table = useTable();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const assessmentResult = useBoolean();


  const [filters, setFilters] = useState('all');
  const [searchText, setSearch] = useState("");
  const [companyName, setCompanyName] = useState("iatos");
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(null);


  const { data, loading, error, refetch } = useQuery(GET_CLASS_BY_ID, {
    variables: {
      limit: table?.rowsPerPage,
      offset: table?.page,
      searchText: searchText,
      companyName: companyinfo?.info?.databaseName || company,
      order: table.order || 'asc',
      orderBy: table.orderBy || 'fullName',
      _id: companyinfo?.class?._id
      },
      fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
      if(data){
        setResults(data?.getClassesById)
      }
  }, [data])


    const handleFilters = useCallback((name, value) => {
      setSearch(value)
    },
    [table]
  );
  
    const handleDeleteRow = useCallback(async (id) => {
      console.log(id)
      
});

const completed = results?.results?.filter((res => res?.assessmentSummary));
const inprogress = results?.results?.filter((res =>  res?.assForm && !res?.assessmentSummary));
const notapplicable = results?.results?.filter((res =>  !res?.assForm && !res?.assessmentSummary && res?.ssgenrolment?.status !== 'Cancelled'));

const allResult = results?.results?.filter((res => res?.ssgenrolment?.status !== 'Cancelled'));



    const handleFilterStatus = useCallback(
      (event, newValue) => {
        if(newValue !== "all"){
          setFilters(newValue);
          setSearch(newValue);
        }else {
          setFilters(newValue);
          setSearch('');
        }

      },
      [table]
    );

  

  const handleViewAccount = useCallback((row) => {
      navigate('/company-view-account', {state: row})
    },
    []
  );
  const handleEditRow = useCallback((row) => {
      navigate('/company-create-update-classes', {state: {info: companyinfo, users: row}})
  });

const handleView = useCallback(
  (row) => {
    navigate('/company-enrolment-session', {state: {class: row, info: companyinfo}})
  },
  []
);

const handleEdit = useCallback(
  (id) => {
    console.log(id)
    // router.push(paths.dashboard.job.edit(id));
  },
  []
);

const handleDelete = useCallback((id) => {
  console.info('DELETE', id);
}, []);

  const handleCreateNewClass = useCallback(() => {
    navigate('/company-create-update-classes', {state: {info: companyinfo}})
 
});

const onPrintAssForm = useCallback((row) => {
  console.log(row)
})

const onRefetch = () => {
  refetch();
}

  return (
     <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${companyinfo?.class?.course?.courseTitle}`}
          links={[
           {name: 'Dashboard'},
           {name: 'Class'},
           {name: 'Class Assessment'}
          ]}
          action ={
            <Button
            componet={RouterLink}
            onClick={() => assessmentResult.onTrue()}
            variant="contained"
            startIcon={<Iconify icon="mingcure:add-line" />}
            sx={{
              display: isRole.some((role)=> ["bo", "tm"].includes(role)) ?  '' : 'none'
             }}
            >
              View Result (TPG)
           </Button>
         }
       />

  

        <Card>
         <Tabs 
          value={filters}
        // onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
          }}
         >
          {STATUS_OPTION.map((tab) => (
            <Tab
              key={tab?.value}
              iconPosition='end'
              value={tab?.value}
              label={tab?.label}
              icon={
              <Label 
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={
                  (tab.value === 'completed' && 'success') ||  (tab.value === 'inProgress' && 'info') ||  (tab.value === 'draft' && 'warning') ||  (tab.value === 'cancelled' && 'error') || 'default'
                }
                >
                  {tab.value === 'all' && allResult?.length}
                  {tab.value === 'completed' && completed?.length}
                  {tab.value === 'inProgress' && inprogress?.length}
                  {tab.value === 'na' && notapplicable?.length}
              </Label>
              }
           />
  
          ))}

         </Tabs>

          <CompanyTableToolBar onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset'}}>
            <Scrollbar>
              <Table  sx={{minWidth: 960}}>
                
              <TableHeadCustom
                headLabel={TABLE_HEAD}
                order={table.order}
                orderBy={table.orderBy}
                onSort={table.onSort}
              />
  

   
                <TableBody>

                {allResult?.map((row, indexNo) => {
                    return (
                        <ClassAssessmentRow
                                      indexNo={indexNo}
                                      key={row?.id}
                                      row={row}
                                      classinfo={state?.class}
                                      onViewRow={() => handleView(row)}
                                      onDeleteRow={() => handleDeleteRow(row._id)}
                                      onEditRow={() => handleEditRow(row)}
                                      onPrintAssForm={() => onPrintAssForm(row)}
                                      onRefetch={onRefetch}
                         />
                    )
                })}
  

                </TableBody>

              </Table>
              </Scrollbar>
          </TableContainer>

          {/* <TablePaginationCustom 
            count={results?.count}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          /> */}
        </Card>
        <AssessmentResultSSG 
          title="Assessment Result"
          open={assessmentResult.value}
          onClose={assessmentResult.onFalse}
          companyInfo={state?.info}
          classinfo={state?.class}
        />

    </Container>
  );
}

ClassAssessment.propTypes = {
  companyinfo: PropTypes.object,
};
