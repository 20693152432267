import { Helmet } from "react-helmet-async";
 import CreateUpdateClassesCompany  from "../../sections/company/class/company-create-update-classes.js";

export default function CreateUpdateClassesCompanyPage(){
    return (
        <>
            <Helmet>
                <title>Company Class Page</title>
            </Helmet>
            <CreateUpdateClassesCompany />
        </>
    )
}