
import PropTypes from 'prop-types';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, MenuItem, IconButton } from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "src/components/iconify/iconify.js";
import Label from '../../../../components/label/index.js';
import { ConfirmDialog } from '../../../../components/custom-dialog/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { parseISO, format } from 'date-fns';
import { fCurrency } from '../../../../util/format-number.js';

export default function EnrolmentTableRow({
    row, selected, onSelected, onDeleteRow, onEditRow, onViewRow, onSelectRow
}){
    const { traineeInfo, employer, ssgenrolment } = row;
    const popover = usePopover();
    const confirm = useBoolean();


 return (
    <>
    <TableRow hover selected={selected}>
    <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.fullName}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{traineeInfo?.nricFin}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{ssgenrolment?.referenceNumber}</TableCell>
            <TableCell sx={{whiteSpace: 'nowrap'}}>{employer?.empcompanyName}</TableCell>
            <TableCell>
              <Label
                variant="soft"
                color={
                  (ssgenrolment?.status === 'Confirmed' && 'default') ||
                  (ssgenrolment?.status === 'Completed' && 'info') ||
                  (ssgenrolment?.status === 'Cancelled' && 'error') || 
                  (ssgenrolment?.status === 'Draft' && 'warning') ||
                  'default'
                }
              >
                {ssgenrolment?.status}
              </Label>
        </TableCell>


            
    </TableRow>

    

</>
 )

}

EnrolmentTableRow.propTypes = {
    onViewRow: PropTypes.func,
    selected: PropTypes.func,
    onSelected: PropTypes.func
  };
  