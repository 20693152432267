import { random, sum, flattenDeep } from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Card, Container, Typography, Stack, Divider, IconButton, MenuItem, Button, Dialog, Box, DialogActions,  } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers';
import { fDate } from '../../../../util/formatTime.js';
import { PDFViewer } from '@react-pdf/renderer';
import { _receiptStatus } from '../../../../util/invoice.js';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/index.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { useResponsive } from '../../../../hooks/useResponsive.js';
import Iconify from 'src/components/iconify/index.js';
import { useSettingsContext } from '../../../../components/settings/index.js';
import { useSnackbar } from '../../../../components/snackbar/index.js';
import { courseMode } from 'src/util/course.js';
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
    RHFSelect,
    RFHUpload
  } from 'src/components/hook-form';

import ReceiptNewEditDetails from './receipt-edit-details.js';
import ReceiptPDF from './receipt-pdf-view.js'
// import AddressListDialog from './address-list-dialog.js';

import { UPDATE_CLIENT_INVOICE, UPDATE_CLIENT_RECEIPT } from "../../../../graphql/modules/_client/invoiceClient.js";
import { useMutation } from "@apollo/react-hooks";


export default function ReceiptCreateUpdate({ currentState }) {
  const {fullName} = useSelector((state) => state?.auth?.user);
  const companyInfo  = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const [selectedClass, setSelectedClass] = useState({});
  const navigate = useNavigate();
  const [receiptDetails, setReceipt] = useState(null);
  const location = useLocation();
  const [state, setState] = useState(location?.state)

  
  // const [CreateInvoiceMutation] =  useMutation(CREATE_CLIENT_INVOICE);
  const [UpdateReceiptMutation] =  useMutation(UPDATE_CLIENT_RECEIPT);
  const { enqueueSnackbar } = useSnackbar();
  



    const settings = useSettingsContext();
    const upMd = useResponsive('up', 'md');

    const to = useBoolean();
    const receiptPdf = useBoolean();

 
    const NewInvoiceSchema = Yup.object().shape({
      receiptNo: Yup.string().required('Receipt number is required'),
      invoiceToInfo: Yup.mixed().nullable().required('Receipt to is required'),
      createDate: Yup.mixed().nullable().required('Create date is required'),
      dueDate: Yup.mixed()
        .required('Due date is required')
        .test(
          'date-min',
          'Due date must be later than create date',
          (value, { parent }) => value.getTime() > parent.createDate.getTime()
        ),
      // not required
    });

    useEffect(() => {
      // if(!state){
      //   const modeOfTrainingValue = courseMode?.map((result) => {
      //     if(result.id === selectedClass?.modeOfTraining){
      //        return result?.value
      //     }
      //     })
  
      //   setValue('items', values.invoiceTo?.map((item) => ({
      //     description:  item?.traineeInfo?.fullName,
      //     nricFin: item?.traineeInfo.nricFin,
      //     quantity: 1,
      //     price: selectedClass?.course?.courseFee,
      //     total: selectedClass?.course?.courseFee,
      //   })))
      //    // COURSE DETAILS
      //   setValue('courseInfo', {
      //     courseTitle: selectedClass?.course?.courseTitle,
      //     tsgRefNumber: selectedClass?.course?.tsgRefNumber,
      //     courseRun: selectedClass?.courseRunId,
      //     modeOfTraining: modeOfTrainingValue,
      //     courseStart: fDate(selectedClass?.courseDates?.start),
      //     courseEnd: fDate(selectedClass?.courseDates?.end)
      //   })
  
      //   if(values.invoiceTo){
      //     // TRAINEE AND EMPLOYER
      //     if(values.invoiceTo[0]?.sponsorshipType === 'Individual'){
      //       setValue('invoiceToInfo', values.invoiceTo[0]?.traineeInfo)
      //     }else{
      //       setValue('invoiceToInfo', values.invoiceTo[0]?.employer)
      //     } 
      //     // GRANTS
      //     if(values.invoiceTo[0].grant){
      //       const totalGrants = values?.invoiceTo?.map((item) => item?.grant?.eligibleGrants?.map((res) => res?.amount));
      //       const flattenedArray = flattenDeep(totalGrants);
      //       setValue('totalGrants', sum(flattenedArray)?.toFixed(2))
            
      //       const uniqueValues = [...new Set(values?.invoiceTo?.flatMap(obj => obj?.grant?.eligibleGrants?.map(item => item.schemeName)))];
       
      //       const dataResult = uniqueValues?.map((res) => {
      //         return {
      //           schemeName: res
      //         }
      //       })

      //       // const uniqueArray = values?.invoiceTo?.filter(
      //       //   (obj, index, self) =>
      //       //     index ===
      //       //     self?.findIndex((t) => t.grant?.eligibleGrants?.schemeName === obj?.grant?.eligibleGrants?.schemeName)
      //       // );
      //       //setValue('grantDetails', uniqueArray[0].grant)
              
      //       setValue('grantDetails', {eligibleGrants: dataResult})
      //     }
          
      //     // DISCOUNT CALCULATE
      //     const totalDiscount = values?.invoiceTo?.map((item) => parseFloat(item?.fees?.discountAmount));
      //     setValue('discount', sum(totalDiscount))
      //   }
      // }

    }, [setState]);

    const defaultValues = useMemo(
      () => ({
   
        invoiceNumber: state?.invoiceNumber || `INVNO-${random(10000)}`,
        status: state?.status || 'draft',
        taxes: state?.taxes || companyInfo?.gst,
        discount: state?.discount || 0,
        createDate: parseISO(state?.createDate) || new Date(),
        dueDate: parseISO(state?.dueDate) || null,
        invoiceTo: state?.invoiceTo || null,
        invoiceToInfo: state?.invoiceToInfo || null,
        items: state?.items || [],
        totalAmount: state?.totalAmount || 0,
        totalGrants: state?.totalGrants || 0,
        grantDetails: state?.grantDetails[0] || [],
        courseInfo: state?.courseInfo || {
          courseTitle: '',
          tgsReferenceNumber: '',
          courseRun: '',
          modeOfTraining: '',
          courseStart: '',
          courseEnd: ''
        },
        receipt: state?.receipt,
        receiptNo: state?.receiptNo,
        receiptStatus: state?.receiptStatus || "No Payment",
        receiptDate: state?.receiptDate ? parseISO(state?.receiptDate) : new Date()

      }),
      [state]
    );

  
 

  
    const methods = useForm({
      resolver: yupResolver(NewInvoiceSchema),
      defaultValues,
    });
  
    const {
      reset,
      watch,
      control,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
    } = methods;
    const values = watch();
    const onSubmit = handleSubmit(async (data) => {
      try {
        const recStatus =  data?.totalAmountSum === 0 ? "No Payment" : data?.totalAmount > data?.totalAmountSum ? "Partial Payment" : "Full Payment" ;
        const invStatus = data?.totalAmountSum === 0 ? values?.status : data?.totalAmount > data?.totalAmountSum ? "pending" : "paid";
        const {data: { updateClientReceipt }} = await UpdateReceiptMutation({
          variables: {
            companyName: companyInfo?.databaseName,
            fullName: fullName,
            _id: state?._id,
              data: {
                receipt: data?.receipt,
                receiptNo: data?.receiptNo,
                receiptDate: data?.receiptDate,
                receiptStatus: recStatus,
                status: invStatus
              }
            }
        });
        if(updateClientReceipt?.success){
          // reset();
          enqueueSnackbar(state ? 'Update success!' : 'Create success!', {variant: 'success'});
         // navigate('/company-receipt-create-update', {state: updateClientReceipt?.result})
          navigate(-1);
        }else {
          enqueueSnackbar(state ? 'Failed to update' : 'Failed to created', {variant: 'error'});
        }

      } catch (error) {
        console.log(error);
      }
 
    });



    const handleViewInvoice = useCallback((row) => {
      setReceipt(row);
      receiptPdf.onTrue();
    },
    []
  );



    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>

                  <Stack spacing={{ xs: 1, sm: 2 }} direction="row"  justifyContent="space-between" alignItems="center">
                  <CustomBreadcrumbs
                heading={values?.receiptNo ? "Update Receipt" : "Create a new Receipt"}
                links={[
                {name: 'Receipt'},
                {name: values?.receiptNo ? 'Update Receipt' : 'New Receipt'}
                ]}
            />
            <LoadingButton variant="contained" onClick={() => navigate(`/${companyInfo?.databaseName}/invoice`)}>Go Back</LoadingButton>
            </Stack>

            <Card>
            <Stack
            spacing={{ xs: 3, md: 5 }}
            direction={{ xs: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={upMd ? 'vertical' : 'horizontal'}
                sx={{ borderStyle: 'dashed' }}
              />
            }
            sx={{ p: 3 }}
          >
        
        {/* BILLING ADDRESS */}

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              From:
            </Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{companyInfo?.companyName}</Typography>
            <Typography variant="body2">{companyInfo?.address}</Typography>
            <Typography variant="body2">{companyInfo?.contactNo}</Typography>
            <Typography variant="body2">{companyInfo?.email}</Typography>
          </Stack>
        </Stack>

        <Stack sx={{ width: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ color: 'text.disabled', flexGrow: 1 }}>
              To:
            </Typography>
            {/* <IconButton onClick={() => handleViewInvoice(state)}>
              <Iconify icon={'ic:round-print'}  />
            </IconButton> */}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2">{values?.invoiceToInfo?.fullName || values?.invoiceToInfo?.empcompanyName}</Typography>
            <Typography variant="subtitle2">{values?.invoiceToInfo?.email || values?.invoiceToInfo?.contact?.emailAddress}</Typography>
            <Typography variant="body2">{values?.invoiceToInfo?.mobileNo ||  values?.invoiceToInfo?.contact?.contactNumber?.phoneNumber}</Typography>
            <Typography variant="body2">{values?.invoiceToInfo?.contact?.fullName ? `Att: ${values?.invoiceToInfo?.contact?.fullName}` : ''}</Typography>
          </Stack>
        </Stack>

              {/* <AddressListDialog
              title="Class"
              open={to.value}
              onClose={to.onFalse}
              onSelect={(selectedTrainee) => setValue('invoiceTo', selectedTrainee)}
              selected={setSelectedClass}
            /> */}
            </Stack>

              {/* BILLING DATE */}

            <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ p: 3, bgcolor: 'background.neutral' }}
          >
            <RHFTextField
            name="receiptNo"
            label="Receipt number"
          />

            <RHFTextField
            name="receiptStatus"
            label="Payment Status"
            disabled
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />

              {/* <RHFSelect
              fullWidth
              name="receiptStatus"
              label="Payment Status"
              InputLabelProps={{ shrink: true }}
            >
              {_receiptStatus?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </RHFSelect> */}
            <Controller
              name="receiptDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Receipt Date"
                  format='dd/MM/yyyy'
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

  
            </Stack>

            <ReceiptNewEditDetails />
            </Card>

            <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
            
        
            <LoadingButton
        color="inherit"
          size="large"
          variant="outlined"
          loading={isSubmitting} 
          onClick={() => handleViewInvoice(values)}
        >
          Print Receipt
        </LoadingButton>

        <LoadingButton
        color="inherit"
          size="large"
          variant="outlined"
          type="submit"
          loading={isSubmitting} 
        >
          {state?.receiptNo ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>

      <Dialog fullScreen open={receiptPdf.value}>
        <Box sx={{ height: 1, display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              p: 1.5,
            }}
          >
            <Button color="inherit" variant="contained" onClick={receiptPdf.onFalse}>
              Close
            </Button>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: 1, overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <ReceiptPDF receiptDetails={receiptDetails} companyProfile={companyInfo}/>
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
      
         </Container>
        </FormProvider>
    )
}