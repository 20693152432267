import PropTypes from 'prop-types';
import { useState} from 'react';
import { useSelector } from 'react-redux';
import { TableRow, TableCell, Checkbox, Avatar, Button, ListItemText, Table, Tooltip, Box, IconButton, Collapse, Typography, Stack , Paper} from "@mui/material";
import CustomPopover, {usePopover} from '../../../../components/custom-popover/index.js';
import Iconify from "../../../../components/iconify/iconify.js";
import { useSnackbar } from '../../../../components/snackbar/index.js';
import Label from '../../../../components/label/index.js';
import AttendanceDialog from './class-attendance-dialog.js';
import { useBoolean } from '../../../../hooks/use-boolean.js';
import { useLocation } from 'react-router-dom';
import { fDate } from '../../../../util/formatTime.js';
import { parseISO, format } from 'date-fns';
import AssessmentForm  from './assessment/assessment-record-form.js'
import AssessmentSummaryForm from './assessment/assessment-summary-from.js';
import { courseMode } from 'src/util/course.js';
import { CREATE_ATTENDANCE, CREATE_E_SIGNATURE } from "../../../../graphql/modules/_client/attendance.js";
import { useMutation } from "@apollo/react-hooks";
import ESignature from '../../../../util/eSignature.jsx';
import { array } from 'yup';

export default function ClassAttendanceRow({row, trainee, selected, courseRunId, uen, courseReferenceNumber, onSelected, onSubmitAttendance, onEditRow, onViewRow, onSelectRow, classinfo}){
  const companyProfile = useSelector((state) => state?.auth?.companyProfile?.companyProfile);
  const {nricFin} = useSelector((state) => state?.auth?.user);
  const {fullName} = useSelector((state) => state?.auth?.user);
  const company = useSelector((state) => state?.auth?.company);
  const { state } = useLocation();
  const isRole = useSelector((state) => state?.auth?.isRole);
  const [userInfo, setUserInfo] = useState(null);
  const [attendanceValue, setAttendanceValue] = useState(null);
  const [attendanceStatus] = useState('1');
  const [attendanceSurvey] = useState('EL');
  const [surveryValue, setSurvey] = useState(null);
  const [numberOfHours, setNumberOfHours] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [signature, setSignature] = useState(null);

  
  const { endDate, endTime, id, modeOfTraining, startDate, startTime } =row;
  const [CreateAttendanceMutation] =  useMutation(CREATE_ATTENDANCE);
  const [AddESignatureMutation] = useMutation(CREATE_E_SIGNATURE);

    const popover = usePopover();
    const attendance = useBoolean();
    const collapse = useBoolean();

    const eSign = useBoolean();

    const modeOfTrainingValue = courseMode?.map((result) => {
      if(result.id === modeOfTraining){
         return result?.value
      }
      })

    const handleSaveAttendance = async() => {
        const {data: { createAttendance }} = await CreateAttendanceMutation({
          variables: {
            uen: uen,
            corppassId: nricFin,
            courseRunId: courseRunId,
            encryptionkey: companyProfile?.encryptionKey,
            _companyName: companyProfile?.companyName,
              data: {
                course: {
                  courseReferenceNumber: courseReferenceNumber,
                  sessionID: id,
                  attendance: attendanceValue
                },
                userInfo: userInfo,
                surveyLanguage: surveryValue,
                numberOfHours: Number(parseFloat(numberOfHours).toFixed(1))
              }
            }
        });
  
        
        if(createAttendance?.success){
            enqueueSnackbar("Submitted successfully!", {variant: "success"});
        }else {
          enqueueSnackbar(createAttendance?.message, {variant: "error"});
        }
     
    }




  const handleESignature = async() => {
    const {data: { addESignature }} = await AddESignatureMutation({
      variables: {
        companyName: companyProfile?.databaseName || company,
        fullName: fullName,
        _id: classinfo?._id,
        traineeID: userInfo?._id,
          data: {
            eSignature:{
              eSign: signature,
              dateCaptured: Date.now(),
              sessionDate: startDate,
              startTime: startTime,
              endTime: endTime,
              sessionNo: id
            }
          }
        }
    });

    
    if(addESignature?.success){
        enqueueSnackbar("Submitted successfully!", {variant: "success"});
    }else {
      enqueueSnackbar(addESignature?.message, {variant: "error"});
    }
   
  }

    const onChangeAttendance = (value) => {
      setAttendanceValue(value);
    };
    // const onChangeSurvery = (value) => {
    //   setSurvey(value);
    // };

    const onChangeNumberOfHours = (value) => {
      setNumberOfHours(value);
    };

    
   const addEnrolmentStatus = trainee?.map(obj => {
      const enrolStatus =  row?.attendance?.find(item => item?.nric === obj?.traineeInfo?.nricFin);
      if(enrolStatus){
         return({
        name: enrolStatus?.trainee?.name,
        id: enrolStatus?.trainee?.id,
        status: enrolStatus?.status,
        enrolmentStatus: obj?.ssgenrolment?.status,
        idType: {
          code: obj?.traineeInfo.idType
        },
        "email": obj?.traineeInfo?.email,
        "contactNumber": {
          "countryCode": "65",
          "areaCode": "0",
          "mobile": obj?.traineeInfo?.mobileNo
        },
      })
      }
      return null;

   }).filter(item => item !== null)
    
  //   row?.attendance?.find(item => {
  //   if(item?.nric === obj?.traineeInfo?.nricFin){
  //     return({
  //       name: item?.trainee?.name,
  //       id: item?.trainee?.id,
  //       status: item?.status,
  //       enrolmentStatus: obj?.ssgenrolment?.status,
  //       idType: {
  //         code: obj?.traineeInfo.idType
  //       },
  //       "email": obj?.traineeInfo?.email,
  //       "contactNumber": {
  //         "countryCode": "65",
  //         "areaCode": "0",
  //         "mobile": obj?.traineeInfo?.mobileNo
  //       },
  //     })
  //   }
  //  }))

   const getUnSubmittedAttendance = trainee?.filter(a => !row?.attendance?.find(p => p.nric === a?.traineeInfo?.nricFin))

    const renderPrimary = (
      <TableRow hover selected={selected}>
                  <TableCell sx={{whiteSpace: 'nowrap'}}>{id}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{modeOfTrainingValue}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{startDate}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{startTime}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{endDate}</TableCell>
                <TableCell sx={{whiteSpace: 'nowrap'}}>{endTime}</TableCell>


  <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
  <IconButton
    color={collapse.value ? 'inherit' : 'default'}
    onClick={collapse.onToggle}
    sx={{
      ...(collapse.value && {
        bgcolor: 'action.hover',
      }),
    }}
  >
    <Iconify icon="eva:arrow-ios-downward-fill" />
  </IconButton>

</TableCell>

</TableRow>
    )

    const secondaryPrimary = (
      <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          sx={{ bgcolor: 'background.neutral' }}
        >
           <Stack component={Paper} sx={{ m: 1.5 }}>
            {addEnrolmentStatus?.sort((a, b) => a?.name.localeCompare(b?.name)).map((item) => (
                         <Stack
                         key={item?.id}
                         direction="row"
                         alignItems="center"
                         sx={{
                           p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                           '&:not(:last-of-type)': {
                             borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                           },
                         }}
                       >
                         <Avatar
                           src={""}
                           variant="rounded"
                           sx={{ width: 48, height: 48, mr: 2 }}
                         />
         
                         <ListItemText
                           primary={item?.name}
                           secondary={item?.id}
                           primaryTypographyProps={{
                             typography: 'body2',
                           }}
                           secondaryTypographyProps={{
                             color: 'text.disabled',
                             mt: 0.5,
                           }}
                         />
                         <Box sx={{ textAlign: 'left'}}>
                         <Label
                         variant="soft"
                         color={
                           (item?.enrolmentStatus === 'Confirmed' && 'success') ||
                           (item?.enrolmentStatus === 'Cancelled' && 'error') || 
                           (item?.enrolmentStatus === 'Draft' && 'warning') ||
                           'default'
                         }
                       >
                         {item?.enrolmentStatus === 'Confirmed' ? 'Enrolment Confirmed' : item?.enrolmentStatus === 'Cancelled' ? 'Enrolment Cancelled' : item?.enrolmentStatus}
                       </Label>
                       </Box>

                        <Box sx={{ textAlign: 'left', marginLeft: '10px'}}>
                         <Label
                         variant="soft"
                         color={
                           (item?.status === 'Confirmed' && 'success') ||
                           (item?.status === 'Cancelled' && 'error') || 
                           (item?.status === 'Draft' && 'warning') ||
                           'default'
                         }
                       >
                         {item?.status === 'Confirmed' ? 'Attendance Confirmed' : item?.status === 'Cancelled' ? 'Attendance Cancelled' : item?.status}
                       </Label>
                       </Box>
                
         
                       {/* <Box sx={{ width: 110, textAlign: 'right'}}>
                           <Tooltip title="Add Attendance">
                             <IconButton color={popover.open ? 'inherit' : 'default'} onClick={() => {
                               setUserInfo(item);
                               eSign.onTrue()
                             }}
                             sx={{
                               display: item?.ssgenrolment?.status === 'Cancelled' ? 'none' : ''
                             }}
                             >
                                 <Iconify icon="solar:pen-bold" />
                             </IconButton>
                             </Tooltip>
                         </Box> */}
         
                         <Box sx={{ width: 110, textAlign: 'right', display: isRole?.some((role)=> ["ta", "te"].includes(role)) ?  'none' : '' }}>
                           <Tooltip title="Submit Attendance">
                             <IconButton color={popover.open ? 'inherit' : 'default'} onClick={() => {
                               setUserInfo(item);
                               attendance.onTrue();
                             }}
                             sx={{
                               display: item?.ssgenrolment?.status === 'Cancelled' ? 'none' : ''
                             }}
                             >
                                 <Iconify icon="carbon:send-filled" />
                             </IconButton>
                             </Tooltip>
                         </Box>
                       </Stack>

            ))}
            {getUnSubmittedAttendance?.map((item, index) => {
              if(item?.ssgenrolment?.status !== "Cancelled"){
                return (
                  <Stack
                  key={item?.id}
                  direction="row"
                  alignItems="center"
                  sx={{
                    p: (theme) => theme.spacing(1.5, 2, 1.5, 1.5),
                    '&:not(:last-of-type)': {
                      borderBottom: (theme) => `solid 2px ${theme.palette.background.neutral}`,
                    },
                  }}
                >
                  <Avatar
                    src={""}
                    variant="rounded"
                    sx={{ width: 48, height: 48, mr: 2 }}
                  />
  
                  <ListItemText
                    primary={item?.traineeInfo?.fullName}
                    secondary={item?.traineeInfo?.nricFin}
                    primaryTypographyProps={{
                      typography: 'body2',
                    }}
                    secondaryTypographyProps={{
                      color: 'text.disabled',
                      mt: 0.5,
                    }}
                  />

                  <Box sx={{ textAlign: 'left'}}>
                         <Label
                         variant="soft"
                         color={
                           (item?.ssgenrolment?.status === 'Confirmed' && 'success') ||
                           (item?.ssgenrolment?.status === 'Cancelled' && 'error') || 
                           (item?.ssgenrolment?.status=== 'Draft' && 'warning') ||
                           'default'
                         }
                       >
                         {item?.ssgenrolment?.status === 'Confirmed' ? 'Enrolment Confirmed' : item?.ssgenrolment?.status === 'Cancelled' ? 'Enrolment Cancelled' : item?.ssgenrolment?.status}
                       </Label>
                       </Box>

                       <Box sx={{ textAlign: 'left', marginLeft: '10px', width: 175}}>
 
                       </Box>

  
                <Box sx={{ width: 110, textAlign: 'right'}}>
                    <Tooltip title="Add Attendance">
                      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={() => {
                        setUserInfo(item?.traineeInfo);
                        eSign.onTrue()
                      }}
                      sx={{
                        display: item?.ssgenrolment?.status === 'Cancelled' ? 'none' : ''
                      }}
                      >
                          <Iconify icon="solar:pen-bold" />
                      </IconButton>
                      </Tooltip>
                  </Box>
  
                  <Box sx={{ width: 110, textAlign: 'right', display: isRole?.some((role)=> ["ta", "te"].includes(role)) ?  'none' : '' }}>
                    <Tooltip title="Submit Attendance">
                      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={() => {
                        setUserInfo(
                          {
                            name: item?.traineeInfo.fullName,
                            id: item?.traineeInfo.nricFin,
                            idType: {
                                code: item?.traineeInfo.idType
                            },
                            "email": item?.traineeInfo?.email,
                            "contactNumber": {
                              "countryCode": "65",
                              "areaCode": "0",
                              "mobile": item?.traineeInfo?.mobileNo
                            },

                        }
                        );
                        attendance.onTrue();
                      }}
                      sx={{
                        display: item?.ssgenrolment?.status === 'Cancelled' ? 'none' : ''
                      }}
                      >
                          <Iconify icon="carbon:send-filled" />
                      </IconButton>
                      </Tooltip>
                  </Box>
                </Stack>
                )

              }
            })}
          </Stack>
              
        </Collapse>
      </TableCell>
    </TableRow>
    )


 return (
    <>

{renderPrimary}

{secondaryPrimary}
<AttendanceDialog 
        title="Add Attendance"
      open={attendance.value} 
      onClose={attendance.onFalse}
      class={row}
      userInfo={trainee}
      setAttendanceValue={setAttendanceValue}
      setSurvey={setSurvey}
      setNumberOfHours={setNumberOfHours}
      attendanceStatus={attendanceStatus}
      attendanceSurvey={attendanceSurvey}
      action={
        <Button variant="outlined" color="inherit" onClick={() => handleSaveAttendance()}>
        Save
      </Button>
      }
      />
  <ESignature 
      title="e-Sign"
      open={eSign.value} 
      onClose={eSign.onFalse}
      setSignature={setSignature}
      action={
        <Button variant="outlined" color="inherit" onClick={() => handleESignature()}>
        Save
      </Button>
      }
  />

</>
 )

}

ClassAttendanceRow.propTypes = {
    onViewRow: PropTypes.func,
    onEditRow: PropTypes.func,
    onSubmitAttendance: PropTypes.func,
    classinfo: PropTypes.object
  };
  
