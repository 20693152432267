import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns/index.js'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/react-hooks";
import { apollo } from "../graphql/index.js";
import { SnackbarProvider } from '../components/snackbar/index.js';
import 'simplebar-react/dist/simplebar.min.css';

import Router from "../routes/index.js";
import { store } from "../store/index.js";
import ThemeProvider from '../theme/index.js';

import { SettingsProvider, SettingsDrawer } from "src/components/settings/index.js";


export default function App() {
  return (
    
      <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter >
            <SettingsProvider
            defaultSettings={{
              themeMode: 'light',
              themeDirection: 'ltr',
              themeContrast: 'default',
              themeLayout: 'vertical',
              themeColorPresets: 'default',
              themeStretch: false
            }}
            >
              <SnackbarProvider>
            <ThemeProvider>
     
            <ApolloProvider client={apollo}>
            <SettingsDrawer />
            <Provider store={store}>
 
              <Router />
        
            </Provider>
  
            </ApolloProvider>
 
            </ThemeProvider>
            </SnackbarProvider>
            </SettingsProvider>
            </BrowserRouter>
            </LocalizationProvider>
      </HelmetProvider>
 
  );
}


