import { alpha } from "@mui/material";
import { palette as themePalette } from "../palette.js";

export function presets(presetsColor){

    const primary = primaryPresets.find((i) => i.name === presetsColor);

    const theme = {
        palette: {
            primary
        },
        customShadows:{
            primary: `0 8px 16px 0 ${alpha(`${primary?.main}`, 0.24)}`
        }
    }
    return theme;
}

const palette = themePalette('light');

export const primaryPresets = [
    {
        name: 'default',
        ...palette.primary
    },
    {
        name: 'cyan',
        lighter: '#CCF4FE',
        LIGHT: '#68CDF9',
        main: '#078dee',
        dark: '#0351AB',
        darker: '#012972',
        contrastText: '#FFFFFFF'
    },
    {
        name: 'purple',
        lighter: '#EBD6FD',
        LIGHT: '#B985F4',
        main: '#7635DC',
        dark: '#431a9e',
        darker: '#200a69',
        contrastText: '#FFFFFFF'
    },
    {
        name: 'orange',
        lighter: '#FEF4D4',
        light: '#FED680',
        main: '#fda92d',
        dark: '#B66816',
        darker: '#793908',
        contrastText: palette.grey[800]
    },

    {
        name: 'red',
        lighter: '#ffe3d5',
        light: '#ffc1ac',
        main: '#ff3030',
        dark: '#b71833',
        darker: '#7a0930',
        contrastText: '#ffffff'
    },
    {
        name: 'blue',
        lighter: '#d1e9fc',
        light: '#76b0f1',
        main: '#2065d1',
        dark: '#103996',
        darker: '#061b64',
        contrastText: '#ffffff'
    },


]